import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { FormGroup, Input, Label, ModalBody } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useForm from '../CustomHooks/useForm';
import basePCUrl from '../../../Globals';
const EditUserModal = (props) => {
  const [isLoading, setLoading] = useState();
  const [error, setError] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const { values: newAccessLevel, bind: bindNewAccessLevel, reset: resetNewAccessLevel } = useForm('');

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const displayUserForEdit = () => {
    return <h5>{getTranslation('Editing User', preferredLanguage)}: {props.selected}</h5>;
  };

  const handleNewAccessLevelClick = (event) => {
    event.preventDefault();
    editUserAccessLevel(props.usersForEdit);
    props.toggle();
    resetNewAccessLevel();
  };


  const editUserAccessLevel = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();

    var data = {
      email: props.selected,
      accessLevel: newAccessLevel,
    };

    fetch(basePCUrl + 'UserManagement/ModifyUserSettings', {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          if (data.accessLevel === 'Error') {
            setError(true);
            console.log('There was an error while editing this user');
            setLoading(false);
          } else {
            props.retrieveUsers();
            props.resetSelected([]);
            setLoading(false);
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //-----User Modal close button toggle-----//

  return (
    <Paper>
      <Dialog
        className={styles.root}
        onClose={props.toggle}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.toggle} />

        <ModalBody>
          <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
            {' '}
            {getTranslation('Edit User Access Level', preferredLanguage)}
          </Typography>
          <br></br>

          <div className="ModalForm">
            {displayUserForEdit()}
            <FormGroup
              className="editUserRadioButtons"
              check
              style={{ fontSize: '18px', marginLeft: '25%', padding: '10px', alignItems: 'center' }}
            >
              <Input
                type="radio"
                id="accessLevelUser"
                name="accessLevel"
                checked={newAccessLevel === 'User'}
                {...bindNewAccessLevel}
                value="User"
                style={{ paddingLeft: '30px' }}
              />{' '}
              <Label check for="accessLevelUser">
                {getTranslation('User', preferredLanguage)}
              </Label>
              <Input
                type="radio"
                id="accessLevelAdmin"
                name="accesLevel"
                {...bindNewAccessLevel}
                value="CompanyAdmin"
                checked={newAccessLevel === 'CompanyAdmin'}
                style={{ marginLeft: '40px' }}
              />{' '}
              <Label check for="accessLevelAdmin" style={{ paddingLeft: '53px' }}>
                {getTranslation('Company Admin', preferredLanguage)}
              </Label>
            </FormGroup>
          </div>

          <div className="editUserButton">
            <Button
              block
              onClick={handleNewAccessLevelClick}
              id="editSelectedEmail"
              fullWidth="true"
              variant="contained"
              color="primary"
            >
              {getTranslation('Edit', preferredLanguage)}
            </Button>
          </div>
        </ModalBody>
      </Dialog>
    </Paper>
  );
};
export default EditUserModal;
