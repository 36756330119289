import * as React from 'react'
import HelpHomeTopNav from "./HelpHomeLayout/HelpHomeTopNav";
import HelpHomeTopNavBigger from './HelpHomeLayout/HelpHomeTopNavBigger';

const HelpHome =()=> {


    return(
/* <div style={{ width: "100vw"}}><HelpHomeTopNav/></div> */
<div style={{ width: "100vw"}}><HelpHomeTopNavBigger/></div>
    )
}; export default HelpHome