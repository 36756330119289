import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from './Layout';

import SignUpPage from './SignUpPage/SignUpPage';
import EmailVerification from './Success/EmailVerification';
import Success from './Success/Success';
import SuccessEmailVerification from './Success/SuccessEmailVerification';

import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';

const Signup = (props) => {
  //static displayName = App.name;

  useChangePageTitle('SignUp');

  const renderBody = (location, history) => {
    return (
      <main>
        <Route
          exact
          path="/SignUp"
          location={location}
          component={(props) => <SignUpPage location={props.location} history={props.history} />}
        />
        <Route
          path="/SignUp/Result"
          location={location}
          component={(props) => <Success location={props.location} history={props.history} />}
        />
        <Route
          path="/SignUp/SuccessEmailVerification"
          location={location}
          component={(props) => <SuccessEmailVerification location={props.location} history={props.history} />}
        />
        <Route
          path="/SignUp/Verify"
          location={location}
          component={(props) => <EmailVerification location={props.location} history={props.history} />}
        />
      </main>
    );
  };
  return (
    <div className="App">
      <Router>
        <Layout>
          <Route
            render={({ location, history }) => (
              <React.Fragment>
                <div>
                  <div>{renderBody(location, history)}</div>
                </div>
              </React.Fragment>
            )}
          />
        </Layout>
      </Router>
    </div>
  );
};
export default Signup;
