import React, { useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import EmailLogin from '../../EmailLogin/EmailLogin';

import Auth0SpaLogin from '../../Login/auth0SpaLogin';
import LoginCallback from '../../LoginCallback';

const AdminRoute = ({ component: Component, path, location, accessLevel, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();

  const [isDelayed, setIsDelayed] = useState(true);
  const [delayCount, setDelayCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelayed(false);
    }, 2000);

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    if (location !== undefined && location.pathname === path) {
      const fn = async () => {
        await loginWithRedirect({
          appState: { targetUrl: path },
        });
      };
      fn();
    }
  }, []);

  const checkAccessLevel = (accessLevel) => {
    if (accessLevel === 'CompanyAdmin') {
      return true;
    } else if (accessLevel === 'AccountAdmin') {
      return true;
    } else {
      return false;
    }
  };

  if (!isAuthenticated && !loading) {
    localStorage.setItem('intendedRoute', window.location.pathname + window.location.search);
  }

  const render = (props) =>
    isAuthenticated === true && checkAccessLevel(accessLevel) === true ? <Component {...props} /> : <Auth0SpaLogin history={props.history} />;

  return (
    <Route
      path={path}
      render={(props) => isDelayed ? null : render(props)}
      {...rest}
    />
  );
};

export default AdminRoute;
