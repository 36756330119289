import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useQuery } from 'react-query';
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import { Chart } from 'react-google-charts';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TrainingTotalsWidget from './TrainingTotalsWidget';
import Paper from '@material-ui/core/Paper';
import './TrainingSuite.css';
import { fetchApiData } from '../../../utils/service';
import { generateTrainingReportData, generateChartData } from './trainingUtils';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const viewOption = ['Pie Chart', 'Donut'];
const statusType = ['Completed', 'InProgress', 'All'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const chartOptions = {
  title: 'Training Report',
  is3D: true,
};

const courseStatsAPI = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

export default function TrainingReport(props) {

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const classes = useStyles();
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [tableData, setTableData] = useState();
  const [personName, setPersonName] = React.useState('All');
  const [viewType, setViewType] = React.useState('Pie Chart');
  const [selectedUser, setSelectedUser] = React.useState('All');
  const [chartData, setChartData] = React.useState([
    ['Status', 'Count'],
    ['Course Assigned', 0],
    ['Completed', 0],
    ['In Progress', 0],
  ]);
  const reportColumns = [
    { title: 'User', field: 'userName' },
    {
      title: 'Assigned Course',
      field: 'trainingCoursesAssigned',
      type: 'numeric',
      align: 'center',
      render: (rowData) => <p>{rowData?.trainingCoursesAssigned?.length || 0}</p>,
    },
    {
      title: 'Complete Course',
      field: 'trainingCoursesCompleted',
      type: 'numeric',
      align: 'center',
      render: (rowData) => <p>{rowData?.trainingCoursesCompleted?.length || 0}</p>,
    },
    {
      title: 'In Progress',
      field: 'trainingCoursesInProgress',
      type: 'numeric',
      align: 'center',
      render: (rowData) => <p>{rowData?.trainingCoursesInProgress?.length || 0}</p>,
    },
  ];

  
  const { isLoading, data, isError, error } = useQuery('training-stats', async () => {
    const token = await getAccessTokenSilently();
    return fetchApiData(courseStatsAPI, token, 'getClientTrainingStats', props.accessLevel, props.selectedClient.clientId);
  });
  const usertrainingData = useQuery('individual-training-stats', async () => {
    const token = await getAccessTokenSilently();
    return fetchApiData(courseStatsAPI, token, 'getUserLevelTrainingStats',  props.accessLevel, props.selectedClient.clientId);
  });

  useEffect(() => {
    data && setChartData(generateChartData(data, 'All'));
  }, [data]);

  if (isLoading || usertrainingData.isLoading) {
    return <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />;
  }
  let trainingData;
  let userList = [];
  let completedTrainingUsers = [];

  if (usertrainingData?.data) {
    trainingData = generateTrainingReportData(usertrainingData.data);
    userList = trainingData ? trainingData.map((user) => user.userName) : [];
    completedTrainingUsers = trainingData.filter((item) => item.trainingCoursesCompleted.length > 0);
    //.map((item) => item.userName);
  }

  const handleChangeStatus = (event) => {
    const status = event.target.value;
    let data = [];
    if (status === 'InProgress') {
      data = trainingData.filter((data) => data?.trainingCoursesCompleted.length > 0);
    } else if (status === 'Completed') {
      data = trainingData.filter((data) => data?.trainingCoursesCompleted.length > 0);
    } else {
      data = trainingData;
    }
    setTableData(data);
    setPersonName(status);
  };
  const handleSelectUser = (event) => {
    const user = event.target.value;
    let individualChartData;
    const userData = trainingData.filter((data) => data.userName === user);
    if (viewType != 'Table') {
      individualChartData = user === 'All' ? generateChartData(data, 'All') : generateChartData(userData[0]);
      setChartData(individualChartData);
    }
    setSelectedUser(user);
  };
  const handleChangeView = (event) => {
    setViewType(event.target.value);
  };

  if (isError) {
    return <h2>{error.message}</h2>;
  }
  const getTableData = (trainingData = []) => {
    if (selectedUser == 'All') {
      return trainingData;
    }
    return trainingData.filter((user) => user.userName === selectedUser);
  };

  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.headerPaper}>
            <Typography variant="h5" component="h3" style={{ textAlign: 'center', padding: '5px' }}>
              Training Dashboard
            </Typography>
            <br />

            <Grid container direction="row">
              <Grid item align="center" xs={12} sm={4} md={4} lg={4}>
                <TrainingTotalsWidget
                  widgetNumber={data?.totalUsersAssignedTraining || 0}
                  widgetTitle={'Assigned Course'}
                />
              </Grid>
              <Grid item align="center" xs={12} sm={4} md={4} lg={4}>
                <TrainingTotalsWidget widgetNumber={data?.totalCoursesCompleted || 0} widgetTitle={'Completed'} />
              </Grid>
              <Grid item align="center" xs={12} sm={4} md={4} lg={4}>
                <TrainingTotalsWidget widgetNumber={data?.totalCoursesInProgress || 0} widgetTitle={'In Progress'} />
              </Grid>
            </Grid>

            <br />
          </Paper>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {viewType === 'Table' && trainingData && (
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-name-label">Filter By Status</InputLabel>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              value={personName}
              defaultValue="All"
              onChange={handleChangeStatus}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {statusType.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {viewType != 'Table' && userList.length > 0 && (
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-name-label">Select User</InputLabel>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              value={selectedUser}
              onChange={handleSelectUser}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {userList &&
                userList.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                    {name}
                  </MenuItem>
                ))}
              <MenuItem value="All">All Users</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-name-label">Select View</InputLabel>
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            value={viewType}
            defaultValue="Pie Chart"
            onChange={handleChangeView}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {viewOption.map((name) => (
              <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <>
        <Chart
          chartType="PieChart"
          data={chartData}
          options={{
            ...chartOptions,
            ...(viewType === 'Donut' && { pieHole: 0.4, is3D: false }),
          }}
          width={'100%'}
          height={'400px'}
        />
        {console.log('completed', completedTrainingUsers)}
        {
          <MaterialTable
            title="Training Report View By Table"
            columns={reportColumns}
            data={getTableData(trainingData) || []}
            options={{
              actionsColumnIndex: -1,
              search: true,
            }}
          />
        }
      </>
    </div>
  );
}
