import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";


//----------Reactstrap---------//
import { Form, FormGroup, Input, ModalBody } from 'reactstrap';

//----------Material UI Imports---------//
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const TrainingCompleteModal = (props) => {
  const pushToDashboard = () => {
    props.history.push({
      pathname: '/Dashboard/History',
    });
    window.scrollTo({ top: 110, left: 100, behavior: 'smooth' });
  };

  return (
    
      <Dialog onClose={props.toggle} aria-labelledby="Training Complete" open={props.modal}>
        <ModalBody style={{ padding: '2rem', }}>
          <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
            Training Complete
          </Typography>
          <br></br>
          You answered {props.correctAnswersCount} out of {props.quizLength} correctly for a passing score of {props.scorePercentage}%!
        </ModalBody>

        <Button variant="contained" style={{ width: '80%', marginBottom: '1rem', alignSelf: 'center'}} color="primary" onClick={pushToDashboard}>
          Return to Dashboard
        </Button>
      </Dialog>
  );
};
export default TrainingCompleteModal;
