import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import MSSPCreatClientButton from './MSSPCreateClientButton';
import MSSPClientCard from './MSSPClientCard';
import MSSPClientCardTile from './MSSPClientCardTile';
import { useQuery } from 'react-query';
import { fetchOldApiData } from '../../../utils/service';
import basePCUrl from '../../../Globals';

//--------------Material UI Imports--------------//
import { createTheme, MuiThemeProvider, makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ViewListIcon from '@material-ui/icons/ViewList';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';


import './MSSP.css';
import MSSPReportGenButton from './MSSPReportGenButton';
import MSSPNewFeatureDialogue from './MSSPNewFeatureDialogue.js';
import useFilterForm from '../CustomHooks/useFilterForm';
import { ArrowRight } from '@material-ui/icons';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';

const MSSPBaseView = (props) => {
  //Will need MSSP role/definer to pass down as props
  //Remove and/or alter sideNav for MSSP role/addOn

  useChangePageTitle('AccountManagement');

  const { loading, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [inputValue, setInputValue] = useState(props.selectedClient.name);
  const [clientCardData, setClientCardData] = useState([]);
  const [listView, setListView] = useState(false);

  const accountAdminData = props.accountAdminData

  const [MSSPNewFeatureDialogueState, setMSSPNewFeatureDialogueState] = useState(false);

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const toggleMSSPNewFeatureDialogue = () => {
    setMSSPNewFeatureDialogueState(!MSSPNewFeatureDialogueState);
  };

  const [cardsPerRow, setCardsPerRow] = useState(6);

  const handleChange = (event) => {
    setCardsPerRow(event.target.value);
  };


  const clients = accountAdminData?.map((client) => {
    const firstLetter = client.companyName[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...client,
    };
  });
  

  var currentlySelectedClient = props.selectedClient;

  const getClientCardData = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:current_user",
  });
    console.log(token)

    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
    fetch(api, {
      method: 'get',
      headers: { action: 'getAcctCards', 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          //setError(true);
          return;
        }

        response.json().then((data) => {
          setClientCardData(data);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    getClientCardData();
  }, []);

  //-------Handle Search Filter-------//

  const [values, handleFilterChange] = useFilterForm({
    filter: '',
  });

  let filter = values.filter;
  const lowercasedFilter = filter.toLowerCase();

  //Filter out null values so Object.keys doesn't throw and error
  var noNullForFilter = clientCardData?.map((el) => {
    return Object.keys(el).reduce((newObj, key) => {
      const value = el[key];
      if (value !== null) {
        newObj[key] = value;
      }
      return newObj;
    }, {});
  });

  const filteredData = noNullForFilter.filter((item) => {
    return Object.keys(item)
      .filter(Boolean)
      .some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
  });

  console.log(filteredData)

  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
    formControl: {
      marginLeft: 50,
    },
    listViewButton: {
      marginLeft: 50,
    },
    dialogueTitleRoot: {
      margin: 0,
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      float: 'right',
    },
  });

  const classes = useStyles();


  const returnFormattedSparklineData = (dates, firstArray, secondArray) => {
    var initialArray = [];

    !secondArray ? (initialArray = [dates, firstArray]) : (initialArray = [dates, firstArray, secondArray]);

    let numberOfDataPoints = initialArray.length;

    var combinedArray = [];
    var finalArray = [];

    for (var i = 0; initialArray.length !== 0; i++) {
      var j = 0;
      while (j < initialArray.length) {
        if (i >= initialArray[j].length) {
          initialArray.splice(j, 1);
        } else {
          combinedArray.push(initialArray[j][i]);
          j += 1;
        }
      }
    }

    while (combinedArray.length > 0) {
      let chunk = combinedArray.splice(0, numberOfDataPoints);
      finalArray.push(chunk);
    }

    finalArray[0].length === 3
      ? finalArray.unshift(['x', 'high alerts', 'medium alerts'])
      : finalArray.unshift(['dates', 'active users']);

    return finalArray;
  };

  const newFeatureDialogueContents = () => {
    return (
      <div style={{ height: '100px', alignItems: 'center' }}>
        <DialogTitle />
        <p> {getTranslation("This feature will be coming in the future!", preferredLanguage)} </p>
      </div>
    );
  };

  const DialogTitle = () => {
    return (
      <MuiDialogTitle disableTypography>
        {toggleMSSPNewFeatureDialogue ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleMSSPNewFeatureDialogue}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const getCurrentltySelectedClientName =(currentlySelectedClient)=> {
    const company = accountAdminData.find(company => company.clientId === currentlySelectedClient);
    return company ? company.companyName : null;

  }

  

  return (
    <div>
      <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
     {getTranslation("Account Management", preferredLanguage)} 
      </Typography>
      {!loading && accountAdminData.length !== 0 ? (
        <Grid
          container
          style={{ flexWrap: 'noWrap' }}
          alignItems="center"
          //justify="space-between"
          direction="row"
        >
          <Grid item>
            {accountAdminData[0]?.companyName != 'no client selected' ? (
              <Autocomplete
                disableClearable
                //style={{ height: '56px' }}
                options={clients.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                noOptionsText={getTranslation('No Matching Clients', preferredLanguage)}
                groupBy={(client) => client.firstLetter}
                getOptionLabel={(client) => (typeof client === 'string' ? client : client.companyName)}
                style={{ maxWidth: 400, minWidth: 200, height: '100%' }}
                value={getCurrentltySelectedClientName(currentlySelectedClient)}
                onChange={(event, newValue) => {
                  console.log(newValue.clientId)
                  props.setSelectedClient(newValue.clientId)
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" style={{ height: '56px' }} />}
              />
            ) : (
              <LoadingSpinner />
            )}
          </Grid>
          <Grid item>
            <Link to={'/Dashboard/CompanyOverviewAccountAdmin'}>
              <Button variant="contained" color="primary" style={{ height: '56px' }} disableElevation>
              {getTranslation('Go', preferredLanguage)} 
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <MSSPCreatClientButton
              toggleMSSPNewFeatureDialogue={toggleMSSPNewFeatureDialogue}
              MSSPNewFeatureDialogueState={MSSPNewFeatureDialogueState}
              preferredLanguage={props.preferredLanguage}
              getTranslation={getTranslation}
            />
          </Grid>
          <Grid item>
            <MSSPReportGenButton
              toggleMSSPNewFeatureDialogue={toggleMSSPNewFeatureDialogue}
              MSSPNewFeatureDialogueState={MSSPNewFeatureDialogueState}
              preferredLanguage={props.preferredLanguage}
              getTranslation={getTranslation}

            />
          </Grid>
          <Grid item>
            {' '}
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">{getTranslation('Cards', preferredLanguage)}</InputLabel>
              <Select labelId="cards per row" value={cardsPerRow} onChange={handleChange} label="Cards">
                <MenuItem value={12}>1</MenuItem>
                <MenuItem value={6}>2</MenuItem>
                <MenuItem value={4}>3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Tooltip title={getTranslation("List View", preferredLanguage)}>
              <IconButton
                aria-label="View as List"
                className={classes.listViewButton}
                onClick={() => setListView(!listView)}
              >
                <ViewListIcon style={{ float: 'right' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : null}
      <br />

      <Grid container spacing={4} alignItems="flex-end" justify="flex-start" direction="row">
        {/* {props.clientList.map(value => ( */}
        {clientCardData !== undefined && !clientCardData.hasOwnProperty('error')
          ? clientCardData?.map((value, idx) => (
              <MSSPClientCard
                key={`${value}-${idx}`}
                preferredLanguage={preferredLanguage}
                getTranslation={getTranslation}
                history={props.history}
                cardsPerRow={cardsPerRow}
                listView={listView}
                clientId={value.clientId}
                toggle={toggleMSSPNewFeatureDialogue}
                setSelectedClient={props.setSelectedClient}
                companyLogo={value.companyLogo}
                companyName={value.companyName}
                companyContact={value.companyContact}
                lastContactDate={value.lastContactDate}
                riskLevel={value.companyRiskLevel}
                activeAlertTotal={value.totalActiveAlerts}
                alertScore={value.activeAlerts.alertScore}
                activeUserScore={value.activeUsers.activeUserScore}
                chartDataAlerts={returnFormattedSparklineData(
                  value.activeAlerts.dates,
                  value.activeAlerts.highAlerts,
                  value.activeAlerts.medAlerts
                )}
                chartDataActiveUsers={returnFormattedSparklineData(
                  value.activeUsers.dates,
                  value.activeUsers.numActiveUsers
                )}
                threatModel={value.threatModel}
              />
            ))
          : null}

        <MSSPNewFeatureDialogue
          toggle={toggleMSSPNewFeatureDialogue}
          isOpen={MSSPNewFeatureDialogueState}
          content={newFeatureDialogueContents()}
          preferredLanguage={props.preferredLanguage}
          getTranslation={getTranslation}
        />
      </Grid>
    </div>
  );
};
export default MSSPBaseView;

