import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { useAuth0 } from "@auth0/auth0-react";

import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import useCheckPermissionsForAPICalls from '../CustomHooks/useCheckPermissionsForAPICalls';
import useFilterForm from '../CustomHooks/useFilterForm';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import AddMultipleUsersModal from './AddMultipleUsersModal';
import './UserManagement.css';
import UserManagementModal from './UserManagementModal';
import UserManagementTable from './UserManagementTable';


const UserManagement = (props) => {
  useChangePageTitle('UserManagement');

  const [error, setError] = useState();
  const [retrievedUsers, setRetrievedUsers] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const { getAccessTokenSilently, logout } = useAuth0();
  const uid = props.uid;

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const accountForNullData = ['nullData'];

  const { requestType, callRoute } = useCheckPermissionsForAPICalls(props.accessLevel);

  const [values, handleFilterChange] = useFilterForm({
    filter: '',
  });

 
  if (props.selectedClient === undefined || props.selectedClient=== '') {
    var clientId = props.defaultClient;
  } else {
    var clientId = props.selectedClient;
  }

  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

  //----------Retrieve Users-------------//
  const retrieveUsers = async (finalClientId) => {
    const token = await getAccessTokenSilently();
    setLoading(true);

    fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
      method: 'GET',
      headers: {
        action: 'getUserList',
        'content-type': 'application/json',
        //testacct: uid,
        id: finalClientId,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then((data) => {
          if (Object.entries(data).includes('not able to verify access token or resolve to a valid user')) {
            logout({
              returnTo: 'https://portal.phishcloud.io/PortalSignIn',
            });
          } else if (data.users === null || data.users === undefined) {
            setRetrievedUsers(accountForNullData);
          } else {
            setRetrievedUsers(data.users);
            setLoading(false);
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    if (clientId != undefined) {
      retrieveUsers(clientId);
    } else {
      retrieveUsers('');
    }
  }, [clientId]);

  //-------Handle Search Filter-------//

  let filter = values.filter;
  const lowercasedFilter = filter.toLowerCase();

  //Filter out null values so Object.keys doesn't throw and error
  var noNullForFilter = retrievedUsers.map((el) => {
    return Object.keys(el).reduce((newObj, key) => {
      const value = el[key];
      if (value !== null) {
        newObj[key] = value;
      }
      return newObj;
    }, {});
  });

  const filteredData = noNullForFilter.filter((item) => {
    return Object.keys(item)
      .filter(Boolean)
      .some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
  });

  return (
    <div className="userManagementContainer">
      <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
      {getTranslation('User Management', preferredLanguage)} 
      </Typography>

      <Row className="userManagementTabs"></Row>
      <Row>
        <form id="userManagementSearchForm">
          <InputBase
            type="text"
            defaultValue=""
            placeholder="Search Users"
            id="userManagementSearchInput"
            name="filter"
            value={values.filter}
            onChange={handleFilterChange}
          />

          <Button disabled id="search" outline color="white">
            <FontAwesomeIcon icon={['fas', 'search']} />
          </Button>
        </form>

        <UserManagementModal
          retrieveUsers={retrieveUsers}
          requestType={requestType}
          callRoute={callRoute}
          clientId={clientId}
          accessLevel={props.accessLevel}
          uid={props.uid}
          preferredLanguage={props.preferredLanguage}
          getTranslation={props.getTranslation}
        />

        <AddMultipleUsersModal
          retrieveUsers={retrieveUsers}
          requestType={requestType}
          callRoute={callRoute}
          clientId={clientId}
          accessLevel={props.accessLevel}
          uid={props.uid}
          preferredLanguage={props.preferredLanguage}
          getTranslation={props.getTranslation}
        />
      </Row>
      {isLoading ? (
        <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />
      ) : error === true ? (
        'There was an error'
      ) : (
        <UserManagementTable
          rows={filteredData}
          retrieveUsers={retrieveUsers}
          requestType={requestType}
          callRoute={callRoute}
          clientId={clientId}
          uid={props.uid}
          accessLevel={props.accessLevel}
          preferredLanguage={props.preferredLanguage}
          getTranslation={props.getTranslation}
        />
      )}
    </div>
  );
};
export default UserManagement;


/*

< AddMultipleUsersModal
retrieveUsers = { retrieveUsers } />

*/
