import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Form, FormGroup, Input, ModalBody } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTour } from '@reactour/tour';

import basePCUrl from '../../../Globals';

import useForm from '../CustomHooks/useForm';
import Error from '../Error/Error';
import { set } from 'date-fns';

const UserManagementModal = (props) => {
  const [errorMessage, setErrorMessage] = useState('errorMessage');
  const [invalidEmail, setInvalidEmail] = useState('');
  const [refreshUserList, setRefreshUserList] = useState('');

  const [loadingAddedUsers, setLoadingAddedUsers] = useState(false);

  const { getAccessTokenSilently, logout } = useAuth0();

  const { values: email, bind: bindEmail, reset: resetEmail } = useForm('');
  const { values: accessLevel, bind: bindAccessLevel, reset: resetAccessLevel } = useForm('');

  const [emailTypeToggle, setEmailTypeToggle] = useState(false);
  const [emailType, setEmailType] = useState();

  const clientId = props.clientId;
  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setErrorMessage('');
    resetEmail();
    resetAccessLevel();
  };

  const toggleClose = () => {
    setModal(!modal);
    setRefreshUserList('');
    setErrorMessage('');
  };

  const changeEmailSendTypeSwitch = () => {
    setEmailTypeToggle(!emailTypeToggle);
  };

  useEffect(() => {
    emailTypeToggle ? setEmailType('gpo') : setEmailType('manual');
  }, [emailTypeToggle]);

  //----------------Send Activations/Resend Activations---------------//

  const sendActivationsToUsers = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      listOfEmails: [email],
    };

    fetch('UserManagement/EmailUserActivationInstructions', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);

          return;
        }
        response.json().then((data) => {
          if (data.status === 'Not Authorized') {
          } else if (data.status === 'Error') {
          } else {
            return;
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  //------------Add Uesr-------------//
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingAddedUsers(true);
    const token = await getAccessTokenSilently();

    let check =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //if (clientId !== undefined) {

    var data = {
      clientId: clientId,
      //props.clientId,
      installMethod: emailType,
      listOfEmails: [email],
      //.toLowerCase()],
      listOfAccessLevels: [accessLevel],
    };

    //}

    // else {
    //     var data =
    //     {
    //         clientId: "",
    //         //props.clientId,
    //         installMethod: emailType,
    //         listOfEmails: [email],
    //         //.toLowerCase()],
    //         listOfAccessLevels: [accessLevel],
    //     };
    // }

    if (check.test(email)) {
      fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
        method: 'POST',
        headers: {
          action: 'createPhishCloudUser',
          'content-type': 'application/json',
          id: clientId,
          //testacct: uid,
          //uid,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            setLoadingAddedUsers(false);
            setErrorMessage(Object.entries(data));

            // console.log(data.status);
            // setErrorMessage(data.status);
            // if (!data.status.includes("Duplicate Emails")) {
            //     sendActivationsToUsers();
            // }
            //setTimeout(props.retrieveUsers(props.uid, props.clientId), 200);
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    } else {
      setInvalidEmail('That is an invalid email address.');

      return console.log('That is not a valid email address');
    }
  };

  useEffect(() => {

    if (errorMessage.includes('duplicate email')) {
      displayErrorMessage();
    } else if (errorMessage.toString().includes('successfully added')) {
      toggleClose();
      if (clientId !== undefined) {
        setTimeout(props.retrieveUsers(clientId), 10000);
      } else {
        setTimeout(props.retrieveUsers(''), 10000);
      }
    } else if (errorMessage.toString().includes('failed to add')) {
      toggleClose();
      Error();
    } else if (errorMessage.includes('not able to verify access token or resolve to a valid user')) {
      logout({
        returnTo: 'https://portal.phishcloud.io/PortalSignIn',
      });
    }
  }, [errorMessage]);

  const displayErrorMessage = () => {
    if (errorMessage != null && errorMessage.toString().includes('duplicate email')) {
      return (
        <p style={{ textAlign: 'center' }}>
          {getTranslation('Oops!', preferredLanguage)} <b>{email}</b> {getTranslation('is already in our system. Please contact support@phishcloud.com if you think this is a mistake', preferredLanguage)}.
        </p>
      );
    }
  };

  const displayInvalidEmailMessage = () => {
    if (invalidEmail != '') return <h5 style={{ color: 'red', textAlign: 'center' }}>{invalidEmail}</h5>;
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //-----User Modal close button toggle-----//
  const closeBtn = (
    <Button outline color="white" onClick={toggleClose}>
      <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
    </Button>
  );

  return (
    <div>
      {!loadingAddedUsers ? (
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={['fas', 'plus-square']} />}
            onClick={toggle}
          >
           {getTranslation('Add Single User', preferredLanguage)} 
          </Button>

          <Paper>
            <Dialog className={styles.root} onClose={toggle} aria-labelledby="customized-dialog-title" open={modal}>
              <DialogTitle id="customized-dialog-title" onClose={toggle}></DialogTitle>

              <ModalBody>
                <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
                {getTranslation('Add a User', preferredLanguage)} 
   
                </Typography>
                <br></br>

                <Form id="addUserForm" onSubmit={handleSubmit}>
                  {displayErrorMessage()}
                  {displayInvalidEmailMessage()}

                  <FormGroup style={{ fontSize: '12px', alignContent: 'center', alignItems: 'center' }}>
                    <FormControl style={{ marginBottom: '10px', width: '100%' }}>
                      <TextField
                        type="email"
                        name="email"
                        placeholder="User Email"
                        required
                        label="Email"
                        variant="outlined"
                        {...bindEmail}
                      />
                    </FormControl>
                  </FormGroup>

                  <FormGroup
                    style={{ fontSize: '12px', marginLeft: '0px', alignContent: 'center', alignItems: 'center' }}
                  >
                    <FormControl
                      variant="outlined"
                      style={{ marginBottom: '10px', width: '100%' }}
                      //className={classes.formControl}
                    >
                      <InputLabel>
                      {getTranslation('Access Level', preferredLanguage)} 

                      </InputLabel>
                      <Select
                        {...bindAccessLevel}
                        id="demo-simple-select-outlined"
                        //value={capitalizeSeverity(props.alertDetails.baseAlert.severity)}
                        //onChange={handleChange}
                        required
                        name="accessLevel"
                        label="Access Level"
                      >
                        <MenuItem value=""> {getTranslation('Access Level', preferredLanguage)}</MenuItem>
                        <MenuItem value="User"> {getTranslation('User', preferredLanguage)}</MenuItem>
                        <MenuItem value="CompanyAdmin"> {getTranslation('Company Admin', preferredLanguage)}</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={emailTypeToggle}
                          onChange={changeEmailSendTypeSwitch}
                          name="emailTypeToggle"
                          color="primary"
                        />
                      }
                      label="Use GPO set up for this user"
                    />
                  </FormGroup>

                  <div className="addUserButton">
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth="true"
                      color="primary"
                      onClick={() => setRefreshUserList(true)}
                      size="lg"
                    >
                      {getTranslation('Add', preferredLanguage)}
                    </Button>{' '}
                  </div>
                </Form>
              </ModalBody>
            </Dialog>
          </Paper>
        </div>
      ) : (
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={['fas', 'plus-square']} />}
            onClick={toggle}
          >
                                  {getTranslation('Add Single User', preferredLanguage)}

            
          </Button>
          <Paper>
            <Dialog className={styles.root} onClose={toggle} aria-labelledby="customized-dialog-title" open={modal}>
              <DialogTitle id="customized-dialog-title" onClose={toggle}></DialogTitle>

              <ModalBody>
                <br />
                <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
                {getTranslation('New users being added', preferredLanguage)}!

                  <br />
                  {getTranslation('Check back in a few minutes', preferredLanguage)}.
                </Typography>
                <br />
              </ModalBody>
            </Dialog>
          </Paper>
        </div>
      )}
    </div>
  );
};
export default UserManagementModal;

//< Modal isOpen = { modal } toggle = { toggle } >
//    <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>

//< Modal isOpen = { modal } toggle = { toggle } >
//    <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
//    <ModalBody>
//        <h1 className="userManagementModalLabel">Add a User</h1>
//        <br></br>

//        <Form id="addUserForm" onSubmit={handleSubmit}>

//            {displayErrorMessage()}
//            {displayInvalidEmailMessage()}

//            <div className="ModalForm">

//                <FormGroup style={{ fontSize: '12px', alignContent: 'center', alignItems: 'center' }} >
//                    <Input type="email" name="email" placeholder="User Email" required {...bindEmail} />
//                </FormGroup>

//                <FormGroup style={{ fontSize: '12px', marginLeft: '0px', alignContent: 'center', alignItems: 'center' }} >
//                    <Input type="select" required name="accessLevel" {...bindAccessLevel} style={{ marginLeft: '0px' }}>
//                        <option disabled selected value="">Access Level</option>
//                        <option value="User">User</option>
//                        <option value="CompanyAdmin">CompanyAdmin</option>
//                    </Input>

//                </FormGroup>

//            </div>

//            <div className='addUserButton'>
//                <Button type="submit" onClick={() => setRefreshUserList(true)} size="lg" color="primary" block >Add</Button>{' '}
//            </div>
//        </Form>

//    </ModalBody>
//        </Modal >
