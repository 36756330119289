import React from 'react';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import './AboutUs.css';
import TranslationModule from '../../../Translate/TranslationModule';

const AboutPhishCloud = (props) => {
  useChangePageTitle('AboutUs');

  let preferredLanguage = props.preferredLanguage

  console.log(props.preferredLanguage)

  return (
    <div className="aboutUs">
      <h2 className="greeting"><TranslationModule text="About Us" targetLanguage={preferredLanguage}/> </h2>
      <p id="body">
      <TranslationModule text="At" targetLanguage={preferredLanguage}/>  
      <b><TranslationModule text="PhishCloud" targetLanguage={preferredLanguage}/></b>
      <TranslationModule text="we believe that our users are an integral part of protection from phishing and need strong
        tools to be effective." targetLanguage={preferredLanguage}/>
      </p>{' '}
      <p id="body">
        {' '}
        <TranslationModule text="Rather than expecting our users to determine if a link is safe or unsafe; using our Artificial Intelligence
        backend, PhishCloud Agents integrate with applications to automatically determine link safety and notify users
        before they click on a malicious link." targetLanguage={preferredLanguage}/>
        
      </p>{' '}
      <p id="body">
        {' '}
        <TranslationModule text="We support automated phishing analysis and notification for several email providers including Gmail, Outlook
        (Office365) and Hotmail. For all otherwebsites we provide right click functionally to allow you to check the
        safety of any link." targetLanguage={preferredLanguage}/>
        
      </p>
    </div>
  );
};
export default AboutPhishCloud;
