import React from 'react';
import TrainingSuite from './TrainingSuite';
import Radio from '@material-ui/core/Radio';
import { useQuery } from 'react-query';
import { useAuth0 } from "@auth0/auth0-react";

import { fetchApiData } from '../../../utils/service';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {Tabs, Tab } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TrainingReport from './TrainingReport';
import AssignTraining from './AssignTraining/AssignTraining';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const trainingAPI = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
const userType = ['AccountAdmin', 'CompanyAdmin'];
export default function Training({ accessLevel, selectedClient, history, location, trainingViewType, getTranslation, preferredLanguage }) {
  //const [value, setValue] = React.useState(trainingViewType || 'view-training');
  // const { isLoading, data, isError, error } =
  const { getAccessTokenSilently } = useAuth0();

  const [value, setValue] = React.useState(trainingViewType || 'view-training');


  const handleChange = (event, newValue) => {
    setValue(newValue)

  };


  const courseData = useQuery('user-training', async () => {
    const token = await getAccessTokenSilently();
    return fetchApiData(trainingAPI, token, 'userTraining', accessLevel, selectedClient.clientId);
  });

console.log(courseData)

  const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#0055A2',
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      backgroundColor: '#0055A2',
      color: 'lightgrey',
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      //marginRight: theme.spacing(4),
      fontFamily: 'Montserrat, sans-serif',
      '&:hover': {
        color: '#40a9ff',
        //opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      //backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#0055A2',
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: '#fff',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        //opacity: 1,
      },
    },
  }))((props) => <Tab disableRipple {...props} />);

  const useTabStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginBottom: '1rem'

    },
    padding: {
      padding: theme.spacing(3),
    },
    demo1: {
      backgroundColor: theme.palette.background.paper,
    },
  }));

  function CustomizedTabs() {
    const tabClasses = useTabStyles();



    return (
      <div className={tabClasses.root}>
        <div className={tabClasses.demo1}>
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label={getTranslation("Assign Training", preferredLanguage)} value="assign-training" />
            {/* <AntTab label="Training Report" value="training-report" /> */}
          </AntTabs>
        </div>
      </div>
    );
  }


  const renderSwitchTraining = (param) => {
    switch (value) {
      case 'view-training':
        return (
          <TrainingSuite
            accessLevel={accessLevel}
            selectedClient={selectedClient}
            history={history}
            location={location}
            courseData={courseData}
          />
        );
      case 'training-report':
        return <TrainingReport page="training-report" selectedClient={selectedClient} preferredLanguage={preferredLanguage} getTranslation={getTranslation}/>;

      case 'assign-training':
        return <AssignTraining courseList={courseData?.data?.trainingItems || []} accessLevel={accessLevel} selectedClient={selectedClient} preferredLanguage={preferredLanguage} getTranslation={getTranslation}/>;

      default:
        return null;
    }
  };

  return (
    <div className="traininig-suite-container">
      <div style={{ padding: '5px' }}>
        <Link to="/Dashboard/AdminTrainingDashboard">
          <ArrowBackIcon style={{ paddingBottom: '3px' }} /> {getTranslation("Back to Training Dashboard", preferredLanguage)}
        </Link>
      </div>
      <CustomizedTabs />
      {renderSwitchTraining(value)}
    </div>
  );
}
