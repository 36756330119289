import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from './Layout';

import NewUserView from './NewUser/NewUserView';
import EmailVerification from './Success/EmailVerification';
import RegisterError from './NewUser/RegisterError';

import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';

const Register = (props) => {
  useChangePageTitle('Register');

  const renderBody = (location, history) => {
    return (
      <main>
        <Route
          exact
          path="/Register"
          component={(props) => <NewUserView history={props.history} location={props.location} />}
        />
        <Route
          path="/Register/Verify"
          component={(props) => <EmailVerification history={props.history} location={props.location} />}
        />
        <Route
          path="/Register/Error"
          component={(props) => <RegisterError history={props.history} location={props.location} />}
        />
      </main>
    );
  };

  return (
    <div className="App">
      <Router>
        <Layout navTitle={'Register'}>
          <Route
            render={({ location, history }) => (
              <React.Fragment>
                <div>
                  <div>{renderBody(location, history)}</div>
                </div>
              </React.Fragment>
            )}
          />
        </Layout>
      </Router>
    </div>
  );
};
export default Register;
