import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Chart } from 'react-google-charts';
import { useAuth0 } from "@auth0/auth0-react";

import PropTypes from 'prop-types';
import { makeStyles, useTheme, lighten } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

const CompanyInfoGlobalSafeUnsafe = (props) => {
  //--------Null Charts -------//
  const accountForNullChartData = [[new Date(), 0, 0, 0]];

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const theme = useTheme();

  // --------Google Chart Logic -------- //
  const renderChartData = () => {
    if (props.chartData != null && props.chartData != undefined && props.chartData.length > 0) {
      //COPY ARRAYS TO ALTER/MANIPULATE
      let alterChartData = props.chartData.map((arr) => arr.slice());
      let parseDateChartData = props.chartData.map((arr) => arr.slice());

      //REMOVE DATES FROM ARRAY - WILL ADD THEM BACK WHEN THEY ARE FORMATTED
      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 1);
        }
      }

      //PARSE DATES WITH .MAP INTO NEW ARRAY
      if (dateRange === 'today') {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0]));
          //let dateTime = new Date((date.getFullYear()) + "," + (date.getMonth() + 1) + "," + (date.getDate()));
          let dateTime = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()));

          return {
            dateTime,
          };
        });
      } else {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0]));
          let dateTime = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()) + 86400000);

          return {
            dateTime,
          };
        });
      }

      //CONVERT ARRAY OF OBJECTS TO ARRAY
      let finalFormattedDateTime = formattedChartData.map(({ dateTime }) => dateTime);

      //ADD BACK FORMATTED DATES AND REFORMAT INTO ARRAY OF ARRAYS SO DATA IS USABLE WITH GOOGLE CHARTS

      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 0, finalFormattedDateTime[i]);
        }
      }

      alterChartData.splice(0, 0, ['Day', 'Global Safe', 'Global Unknown', 'Global Unsafe']);

      // --------- End of Chart Logic -------- //
      return <GlobalSafeUnsafeChart chartData={alterChartData} ticks={finalFormattedDateTime} />;
    } else {
      return <p> Could not load chart...</p>;
    }
  };

  let dateRange = props.dateRange;

  const GlobalSafeUnsafeChart = (props) => {
    if (dateRange === 'today') {
      return (
        <div>
          <Chart
            chartType="ColumnChart"
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            height={'100%'}
            columns={columns}
            options={{
              chartArea: { width: '50%', height: '50%' },
              colors: ['#2CCC72', '#F1C411', '#EE311E'],
              bar: { groupWidth: '55%' },
              font: 'Montserrat',
              yAxis: {
                title: 'Total Links',
                scaleType: 'log',
                titleTextStyle: { color: theme.palette.text.primary },
              },
              isStacked: true,
              backgroundColor: { fill: 'transparent' },
              chart: {
                //title: 'Company Performance',
                //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
              hAxis: {
                format: ['MMM DD YYYY'],
                slantedText: true,
                slantedTextAngle: 45,
                showTextEvery: 1,
                maxAlternation: 1,
                showTextEvery: 1,
                minTextSpacing: 12,
                textStyle: { fontSize: 12, color: theme.palette.text.primary },
                ticks: props.ticks,
              },
              vAxis: {
                logScale: true,
                viewWindow: { min: 0 },
                format: '0',
                title: 'Total Links',
                textStyle: { color: theme.palette.text.primary },
                titleTextStyle: { color: theme.palette.text.primary },
              },
              annotations: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    } else if (dateRange === 'sevenDays') {
      return (
        <div>
          <Chart
            chartType="ColumnChart"
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            height={'100%'}
            columns={columns}
            options={{
              chartArea: { width: '50%', height: '50%' },
              colors: ['#2CCC72', '#F1C411', '#EE311E'],
              bar: { groupWidth: '55%' },
              font: 'Montserrat',
              yAxis: {
                title: getTranslation('Total Links', preferredLanguage),
                scaleType: 'log',
                titleTextStyle: { color: theme.palette.text.primary },
              },
              isStacked: true,
              backgroundColor: { fill: 'transparent' },
              chart: {
                //title: 'Company Performance',
                //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
              hAxis: {
                format: 'MM/dd',
                slantedText: true,
                slantedTextAngle: 45,
                showTextEvery: 1,
                maxAlternation: 1,
                showTextEvery: 1,
                minTextSpacing: 12,
                ticks: props.ticks,
                textStyle: { fontSize: 12, color: theme.palette.text.primary },
              },
              vAxis: {
                logScale: true,
                viewWindow: { min: 0 },
                format: '0',
                title: getTranslation('Total Links', preferredLanguage),
                textStyle: { color: theme.palette.text.primary },
                titleTextStyle: { color: theme.palette.text.primary },
              },
              annotations: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    } else if (dateRange === 'thirtyDays') {
      return (
        <div>
          <Chart
            chartType="ColumnChart"
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            height={'100%'}
            columns={columns}
            options={{
              chartArea: { width: '50%', height: '50%' },
              colors: ['#2CCC72', '#F1C411', '#EE311E'],
              bar: { groupWidth: '55%' },
              font: 'Montserrat',
              yAxis: {
                title: getTranslation('Total Links', preferredLanguage),
                scaleType: 'log',
                titleTextStyle: { color: theme.palette.text.primary },
              },
              isStacked: true,
              backgroundColor: { fill: 'transparent' },
              chart: {
                //title: 'Company Performance',
                //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
              hAxis: {
                format: 'MM/dd',
                slantedText: true,
                slantedTextAngle: 45,
                //showTextEvery: 1,
                //maxAlternation: 1,
                //showTextEvery: 1,
                minTextSpacing: 8,
                ticks: props.ticks,
                textStyle: { color: theme.palette.text.primary },
              },
              vAxis: {
                logScale: true,
                viewWindow: { min: 0 },
                format: '0',
                title: getTranslation('Total Links', preferredLanguage),
                textStyle: { color: theme.palette.text.primary },
                titleTextStyle: { color: theme.palette.text.primary },
              },
              annotations: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    }
  };

  const columns = [
    {
      type: 'date',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
  ];

  //-------Chart Title------//

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const useStyles = makeStyles({
    paper: {},
    root: {
      width: '100%',
      fontFamily: 'Montserrat, sans-serif',
      height: 'auto',
      maxWidth: '700',
      minWidth: '400',
    },
    container: {
      height: '40vh',
      maxHeight: '400',
      minHeight: '200',
      width: '100%',
      maxWidth: '700',
      minWidth: '400',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
  });

  const paperClasses = useStyles();

  return <div className={paperClasses.root}>{renderChartData()}</div>;
};
export default CompanyInfoGlobalSafeUnsafe;
