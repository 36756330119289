/* eslint-disable no-use-before-define */

import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AutocompleteProps from '@material-ui/lab';
import { Divider } from '@material-ui/core';
import Box from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const AutocompleteCheckboxes = (props) => {
   // const [selectAll, setSelectAll] = useState(false);
    const [value, setValue] = React.useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const filteredOptions = props.filteredOptions

  const groupedOptions = filteredOptions.map((option) => {
    const firstLetter = option.id[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });


  return (
    <Autocomplete
      multiple
      value={value}
      filterOptions={(options, params) => { // <<<--- inject the Select All option
        const filter = createFilterOptions()
        const filtered = filter(options, params)
        return [{ id: 'Select All', all: true }, ...filtered]
      }}
      onChange={(event, newValue) => {
        if (newValue.find(option => option.all))
          return setValue(value.length === groupedOptions.length ? [] : groupedOptions)
  
          setValue(newValue)
          props.setList(newValue)
      }}
      options={groupedOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      disableCloseOnSelect
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.id}
      getOptionSelected={(option, value) => option.id === value.id}
    loadingText={'Loading...'}
      // onChange={(event, newValue) => {
      //   handleChange(newValue)
      // }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            // checked={selected} <<<--- OLD
            color={"primary"}
            checked={option.all ? !!(value.length === groupedOptions.length) : selected}
          />
          {option.id}
          </React.Fragment>
      )}
//       renderOption={(option, { selected }) => (
//         <React.Fragment>
//             {/* {!option.id ?  <Checkbox icon={icon} checkedIcon={checkedIcon}  onChange={selectAllChange} color={"primary"} style={{ marginRight: 8 }} checked={selectAll} /> */}
//             {/* : */}
//              <Checkbox icon={icon} checkedIcon={checkedIcon} color={"primary"} style={{ marginRight: 8 }}  checked={option.all ? !!(value.length === filteredOptions.length) : selected} />
// {/* } */}
//           {option.id}
//         </React.Fragment>
//       )}
      style={{ width: 500, margin: "20px" }}
      renderInput={(params) => <TextField {...params} variant="outlined"  placeholder={"Users"} />}
    />
  );
};
export default AutocompleteCheckboxes;
