import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Button,
  Typography,
  FormControl,
  Input,
  MenuItem,
  Select,
  InputLabel,
  Tabs,
  Tab,
  Toolbar,
  InputBase,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import UserTrainingTable from './UserTrainingTable';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth0 } from "@auth0/auth0-react";

import { fetchApiData } from '../../../utils/service';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import UserTrainingDashboard from './UserTrainingDashboard';
import ActiveTrainingDonut from './TrainingDashboard/ActiveTrainingDonut';
import TrainingDashboardTabs from './TrainingDashboardTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateTrainingReportData, generateChartData } from './trainingUtils';
import AdminTrainingTableCollapsible from './AdminTrainingTableCollapsible';
import useFilterForm from '../CustomHooks/useFilterForm';
import { getTabId } from '@material-ui/lab';

const AdminTrainingDashboard = (props) => {

  document.title = 'Training Dashboard'
  const [values, handleFilterChange] = useFilterForm({
    filter: '',
  });

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      border: '#061739',
      marginRight: '1rem',
      marginLeft: '1rem',
      width: '90%', 
      display: 'inherit'
    },
    clickActionContainer: {
      border: '#061739',
      padding: '2rem',
      // marginBottom: '2rem'
    },
    paperBody: {
      //marginBottom: '2rem',
      '& p': {
        marginLeft: '1rem',
      },
      '& h2': {
        padding: '1rem',
      },
    },
    topRowPaper: {
      '& p': {
        marginLeft: '1rem',
      },
      '& h2': {
        padding: '1rem',
      },
      height: '14rem',
      alignContent: 'center'
    },
    actionRowPaper: {
      '& p': {
        marginLeft: '1rem',
      },
      '& h2': {
        padding: '1rem',
      },
      marginBottom: '2rem',
    },

    button: {
      // width: '100%',
      // height: '70px',
      backgroundColor: '#0055A2',
      // '&:hover': {
      //   color: 'black',
      //   backgroundColor: '#428BC9',
      // },
      fontFamily: 'Montserrat, sans-serif',
    },
    circleContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    circle: {
      background: '#0055A2',
      borderRadius: '50%',
      borderColor: '#061739',
      display: 'flex',
      color: 'white',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '200px',
      height: '200px',
      padding: '10px',
      '& p': {
        fontWeight: 'bold',
      },
      // '&:hover': {
      //   transform: 'scale(1.1)',
      //   cursor: 'pointer',
      //   background: '#428BC9',
      // },
    },
    statsBoxTitle: {
      padding: '20px',
    },
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const classes = useStyles();

  const trainingAPI = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

  const { getAccessTokenSilently } = useAuth0();

  let userClientId = props.userClientId;
  const selectedClientId = props.selectedClient.clientId;


  // var { isLoading, data, isError, error } = useQuery('training-stats', async () => {
  //   const token = await getAccessTokenSilently();
  //   return fetchApiData(courseStatsAPI, token, 'getClientTrainingStats', props.accessLevel, selectedClientId);
  // });

  // var usertrainingData = useQuery('individual-training-stats', async () => {
  //   const token = await getAccessTokenSilently();
  //   return fetchApiData(courseStatsAPI, token, 'getUserLevelTrainingStats', props.accessLevel, selectedClientId);
  // });

  const trainingModules = useQuery('user-training', async () => {
    const token = await getAccessTokenSilently();
    return fetchApiData(trainingAPI, token, 'userTraining', props.accessLevel, selectedClientId);
  });


  let isLoading = props.isLoading
  let usertrainingData = props.adminTrainingData.getUserLevelTrainingStats
  let data = props.adminTrainingData.getClientTrainingStats

  var trainingData;
  let userList = [];
  let completedTrainingUsers = [];

  if (usertrainingData) {
    trainingData = generateTrainingReportData(usertrainingData);
    userList = trainingData ? trainingData.map((user) => user.userName) : [];
    completedTrainingUsers = trainingData
      .filter((item) => item.trainingCoursesCompleted.length > 0)
      .map((item) => item.userName);

    //-------Handle Search Filter-------//

    let filter = values.filter;
    const lowercasedFilter = filter.toLowerCase();

    //Filter out null values so Object.keys doesn't throw and error
    var noNullForFilter = trainingData.map((el) => {
      return Object.keys(el).reduce((newObj, key) => {
        const value = el[key];
        if (value !== null) {
          newObj[key] = value;
        }
        return newObj;
      }, {});
    });

    var filteredData = trainingData.filter((item) => {
      return Object.keys(item)
        .filter(Boolean)
        .some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
    });
  }

  //console.log(filteredData)

  if (usertrainingData.isError) {
    return <h2>{usertrainingData.error.message}</h2>;
  }



  const EnhancedTableToolbar = (props) => {
    return (
      <Toolbar style={{ display: 'flex', justifyContent: 'right' }}>

        <Button disabled className={classes.search}>
          <FontAwesomeIcon icon={['fas', 'search']} />
        </Button>
      </Toolbar>
    );
  };

  return (
    <div className={classes.root}>
      <TrainingDashboardTabs />
      <h2>
       {getTranslation('Admin Training Dashboard', preferredLanguage)}
      </h2>
      <Grid container spacing={3} direction="row" justifycontent="space-between" alignItems="center">
        <Grid item xs={12} sm={12} md={12}>
          
            {/* <h3>
              <div className={classes.statsBoxTitle}> {getTranslation('Overview', preferredLanguage)}</div>
            </h3> */}
            <Grid container spacing={4}>
              
              <Grid item xs={12} sm={3} md={3}>
                <Paper className={classes.topRowPaper}>
                  <p>
                    {getTranslation("Modules in Progress", preferredLanguage)}
                  </p>
                  <h2>{data?.totalCoursesInProgress || 0}</h2>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Paper className={classes.topRowPaper}>
                  <p>
                    {getTranslation('Modules Assigned To Users', preferredLanguage)}
                  </p>
                  <h2>{data?.totalUsersAssignedTraining || 0}</h2>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Paper className={classes.topRowPaper}>
                  <p>
                    {getTranslation('Incomplete Modules', preferredLanguage)}
                  </p>
                  <h2>{data?.totalCoursesAssigned || 0}</h2>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
              <Paper className={classes.topRowPaper}>
            <p>
              
              {getTranslation('Training Module Completion %', preferredLanguage)}
                  
            </p>
            {!isLoading && data.totalUsersAssignedTraining != 0 ? (
              <ActiveTrainingDonut activeTrainingData={data} getTranslation={getTranslation} preferredLanguage={props.preferredLanguage} />
            ) : (
              <div style={{ padding: '10px' }}>{getTranslation('No courses assigned.', preferredLanguage)}
              </div>
            )}
          </Paper>
          </Grid>
            </Grid>
          
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
        
        </Grid>
      </Grid>
      <br />
      <Paper className={classes.actionRowPaper}>
        <h3>
          <div className={classes.statsBoxTitle}>
            {getTranslation('Actions', preferredLanguage)}
              </div>
        </h3>
        <Grid container spacing={3} className={classes.clickActionContainer}>
          <Grid item>
            <Button
              component={Link}
              to="/Dashboard/AssignTraining"
              variant="contained"
              color="primary"
              width="100%"
              className={classes.button}
            >
              {getTranslation('Assign Training', preferredLanguage)}
            </Button>{' '}
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to="/Dashboard/TrainingReport"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={true}
            >
              {getTranslation('Generate Report', preferredLanguage)}
            </Button>{' '}
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" className={classes.button} disabled={true}>
              {getTranslation('Send Reminder', preferredLanguage)}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" className={classes.button} disabled={true}>
              {getTranslation('Update Compliance Deadline', preferredLanguage)}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper></Paper>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar /> */}
        <div style={{ display: 'flex', justifyContent: 'right' }}>
        <form id="userManagementSearchForm" >
          <InputBase
            type="text"
            placeholder="Search Users"
            id="userManagementSearchInput"
            name="filter"
            value={values.filter}
            onChange={handleFilterChange}
            style={{marginTop: "10px"}}
          />

          <Button disabled id="search" outline="true" color="default">
            <FontAwesomeIcon icon={['fas', 'search']} />
          </Button>
        </form>
        </div>
        {/* <UserTrainingTable
          usertrainingData={usertrainingData}
          filteredData={filteredData}
          accessLevel={props.accessLevel}
          clientId={props.clientId}
          selectedClientId={props.selectedClientId}
          getTranslation={getTranslation}
          preferredLanguage={props.preferredLanguage}
        /> */}
        {trainingModules.data?.trainingItems !== undefined ?
        <AdminTrainingTableCollapsible 
        userTrainingData={filteredData}
        trainingModules={trainingModules.data}
        accessLevel={props.accessLevel}
        clientId={props.clientId}
        selectedClientId={props.selectedClientId}
        getTranslation={getTranslation}
        preferredLanguage={props.preferredLanguage}
        /> : null }
      </Paper>
    </div>
  );
};
export default AdminTrainingDashboard;
