import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import queryString from 'querystring';
import './Success.css';
import Auth0Lock from 'auth0-lock';
import basePCUrl from '../../../Globals';

const Success = (props) => {
  const { getUserInfo, loading } = useAuth0();

  const [accessToken, setAccessToken] = useState();

  const [code, setcode] = useState();

  const [error, setError] = useState(0);

  const [email, setEmail] = useState();

  const [emailVerified, setEmailVerified] = useState(false);

  const [alreadySentSuccess, setAlreadySentSuccess] = useState(false);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (emailVerified == false) {
      getUserProfile().then(function (data) {
        if (data.email_verified == true) {
          setEmailVerified(true);
          setAlreadySentSuccess(true);
          if (!alreadySentSuccess) {
            console.log('send success message');
            SendSignUpSuccess(email);
          }
        }
      });
    }
  }, 5000);

  const getUserProfile = (token) => {
    return new Promise((resolve) => {
      var url = 'https://phishcloud.auth0.com/userinfo';

      if (token == undefined) token = accessToken;

      console.log(token);

      fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            if (data.accessLevel === 'Error') {
              console.log('Error');
            } else {
              console.log(data);
              resolve(data);
            }
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    });
  };

  const SendSignUpSuccess = async (parsedEmail) => {
    var data = {
      email: `${parsedEmail}`,
    };

    fetch(basePCUrl + 'Signup/userSignupSuccess', {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        //Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          console.log(data);
          console.log(data.status);
          if (data.status === 'Error') {
            console.log('Error');
          } else {
            return;
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  let parsedQuery = queryString.parse(props.location.search);
  let parsedEmail = parsedQuery.signUpEmail;

  useEffect(() => {
    if (!loading) {
      console.log(parsedQuery);
      console.log({ code: parsedQuery.code });
      setcode(parsedQuery.code);

      let parsedFragment = queryString.parse(props.location.hash.replace('#', ''));

      //console.log(parsedFragment);
      setAccessToken(parsedFragment.access_token);

      getUserProfile(parsedFragment.access_token).then(function (data) {
        if (data.email != null) {
          var domain1 = data.email.split('@')[1];
          var username1 = data.email.split('@')[0];

          var domain2 = parsedEmail.split('@')[1];
          var username2 = parsedEmail.split('@')[0];

          setEmail(data.email);

          if (domain1 === domain2 && username1.toLowerCase() === username2.toLowerCase()) {
            setError(false);
            console.log({ errorIs: error });

            if (!alreadySentSuccess && data.email_verified === true) {
              setEmailVerified(true);
              setAlreadySentSuccess(true);
              console.log('send success message');
              SendSignUpSuccess(parsedEmail);
            }
          } else {
            setError(true);
            console.log({ errorIs: error });
          }
        }
      });
    }
  }, [loading]);

  const SuccessMessage = () => {
    return (
      <div>
        {' '}
        <h1 id="result">Email Successfully Registered!</h1>
        <p>You are halfway there! Please follow the download link to continue the setup process.</p>
        <a href="https://portal.phishcloud.io/InitialDownloads">
          <h4>Download</h4>
        </a>
      </div>
    );
  };

  const errorMessageRedirect = 'signup?pad=&code=' + code;
  console.log(errorMessageRedirect);

  const ErrorMessage = () => {
    if (parsedEmail != undefined) {
      return (
        <div>
          {' '}
          <h1 id="result">Error!</h1>
          <p>
            The Email that you created was not the same email associated with PhishCloud. You Signed up with{' '}
            <span id="email">{email}</span>, but your PhishCloud acount is associated with{' '}
            <span id="email">{parsedEmail}</span>. Please go back to the{' '}
            <Link to={errorMessageRedirect}> signup email</Link> and sign up with the correct account.{' '}
          </p>
        </div>
      );
    }
  };

  const VerifyEmailMessage = () => {
    return (
      <div>
        {' '}
        <h1 id="result">Verify Email to Continue</h1>
        <p>
          An email was sent to <b>{email}</b>. Please Check your email and click the verification link to continue.{' '}
        </p>
        <div className="lds-dual-ring"></div>
      </div>
    );
  };

  const BodyRender = () => {
    console.log({ emailVerifiedIs: emailVerified });
    console.log({ errorIs: error });
    if (error === true) {
      return ErrorMessage();
    } else if (error == false && emailVerified == true) {
      return SuccessMessage();
    } else if (error === 0) {
      return <div></div>;
    } else {
      return VerifyEmailMessage();
    }
  };

  return <div id="resultBody">{BodyRender()}</div>;
};
export default Success;
