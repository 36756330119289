import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Button, Row } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import LinkSafetyRulesTable from './LinkSafetyRulesTable';
import LinkSafetyRulesModal from './LinkSafetyRulesModal';
import useFilterForm from '../CustomHooks/useFilterForm';
import useCheckPermissionsForAPICalls from '../CustomHooks/useCheckPermissionsForAPICalls';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import './LinkSafetyRules.css';
import basePCUrl from '../../../Globals';

const LinkSafetyRules = (props) => {
  useChangePageTitle('LinkSafetyRules');

  const [activeTab, setActiveTab] = useState('1');
  const [error, setError] = useState(false);

  const { requestType, callRoute } = useCheckPermissionsForAPICalls(props.accessLevel);

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [values, handleFilterChange] = useFilterForm({
    filter: '',
  });

  //const [filter, setFilter] = useState('')

  const [retrievedLinkSafetyRules, setRetrievedLinkSafetyRules] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

  const accountForNullData = ['nullData'];

  if (props.selectedClient === undefined || props.selectedClient === '') {
    var clientId = props.defaultClient;
  } else {
    var clientId = props.selectedClient;
  }

  const retrieveLinkSafetyRules = async () => {
    const token = await getAccessTokenSilently();

        if (localStorage.getItem('usid')) {
    var usid = localStorage.getItem('usid');
      var headers = {
        user_id: usid,
        id: clientId,
        Action: "getLinkSafety",
         //...(queryId && { id: queryId }),
         //'Content-Type': 'application/json',
       }
      } else {
        var headers = {
          id: clientId,
          Authorization: `Bearer ${token}`,
          Action: "getLinkSafety",
           'Content-Type': 'application/json',
         }
     }

    if (requestType != '') {
      var data = {
        clientId: clientId,
      };

        var fetchDetails = {
          credentials: 'same-origin',
          //body: JSON.stringify(data),
          method: 'GET',
          headers: headers,
        };


      if (requestType != '') {
        fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', fetchDetails)
          .then((response) => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }

            response.json().then((data) => {
              //console.log(data);
              //console.log(data.accessLevel);
              if (data.accessLevel === 'Error') {
                setError(true);
              } else if (data === null || data === undefined) {
                setRetrievedLinkSafetyRules(accountForNullData);
              } else {
                setRetrievedLinkSafetyRules(data);

                setLoading(false);
              }
            });
          })
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
          });
      }
    }
  };

  useEffect(() => {
    retrieveLinkSafetyRules();
  }, [requestType]);

  //-------Handle Search Filter-------//

  let filter = values.filter;
  const lowercasedFilter = filter.toLowerCase();

  //Filter out null values so Object.keys doesn't throw and error
  var noNullForFilter = retrievedLinkSafetyRules.map((el) => {
    return Object.keys(el).reduce((newObj, key) => {
      const value = el[key];
      if (value !== null) {
        newObj[key] = value;
      }
      return newObj;
    }, {});
  });

  const filteredData = noNullForFilter.filter((item) => {
    return Object.keys(item)
      .filter(Boolean)
      .some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
  });

  return (
    <div className="companyDesignationContainer">
      <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
      {getTranslation('Link Safety Rules', preferredLanguage)} 
      </Typography>

      <Row>
        <div id="companyDesignationSearchForm">
          <InputBase
            id="companyDesignationSearchInput"
            type="text"
            placeholder="Search"
            name="filter"
            value={values.filter}
            onChange={handleFilterChange}
          />
          <Button
            id="search"
            disabled
            className={classnames({ active: activeTab === '4' })}
            onClick={() => {
              toggleTab('4');
            }}
            outline
            color="white"
          >
            <FontAwesomeIcon icon={['fas', 'search']} size="sm" />
          </Button>
        </div>
        <div className="DesignationModal">
          <LinkSafetyRulesModal
            retrieveLinkSafetyRules={retrieveLinkSafetyRules}
            requestType={requestType}
            callRoute={callRoute}
            clientId={clientId}
            accessLevel={props.accessLevel}
            preferredLanguage={preferredLanguage}
            getTranslation={getTranslation}
          />
        </div>
      </Row>
      {isLoading ? (
        <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />
      ) : (
        <LinkSafetyRulesTable
          rows={filteredData}
          retrieveLinkSafetyRules={retrieveLinkSafetyRules}
          requestType={requestType}
          callRoute={callRoute}
          clientId={clientId}
          accessLevel={props.accessLevel}
          preferredLanguage={preferredLanguage}
          getTranslation={getTranslation}
        />
      )}
      {error === true ? getTranslation('There was an error', preferredLanguage) + "."  : <div></div>}
    </div>
  );
};
export default LinkSafetyRules;

LinkSafetyRules.propTypes = {
  retrieveLinkSafetyRules: PropTypes.func,
};

//---------- Tabs Unnecessary with new table? ----------------//

//< Row className = "companyDesignationTabs" >

//    <Button className={classnames({ active: activeTab === '1' })}
//        onClick={() => { toggleTab('1'); }}
//        outline color="white" >
//        <h5>All</h5>
//    </Button>
//    <Button className={classnames({ active: activeTab === '2' })}
//        onClick={() => { toggleTab('2'); }} outline color="white" >
//        <h5>Domains</h5>
//    </Button>
//    <Button className={classnames({ active: activeTab === '3' })}
//        onClick={() => { toggleTab('3'); }} outline color="white">
//        <h5>URLs</h5>
//    </Button>

//        </Row >
