import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import classnames from 'classnames';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from '../CustomHooks/useForm';

const AlertDeleteCheckModal = (props) => {
  const preferredLanguage = props.preferredLanguage;
  const getTranslation = props.getTranslation;

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    deleteTitle: {
      textAlign: 'center',
    },
    deleteAllButton: {
      fontFamily: 'Montserrat, sans-serif',
      width: 'inherit',
      textAlign: 'center',
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const displayLoader = () => {
    if (props.deleteLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress style={{ color: 'black' }} size={80} />
        </div>
      );
    }
  };

  const displayDeleteSuccessMessage = () => {
    if (props.deleteSuccessMessage) {
      return (
      <div id="deletionSuccessMessage">
        <p>
          Your deletion request has been successfully processed. The associated records will be permanently removed
          within the next 10 minutes.
        </p>
      </div>);
    }
  };

  //-----Designation Modal close button toggle-----//

  return (
    <Paper>
      <Dialog
        className={styles.root}
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.onClose}></DialogTitle>

        <ModalBody>
          {!props.deleteSuccessMessage ? (
            <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
              {getTranslation('Are you sure?', preferredLanguage)}
            </Typography>
          ) : null}

          <br></br>
          {displayLoader()}
          {displayDeleteSuccessMessage()}
          <br />

          {!props.deleteSuccessMessage ? (
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={props.handleDeletion}
              id="deleteAllSelected"
              className={styles.deleteAllButton}
            >
              {getTranslation('Delete Selected', preferredLanguage)}
            </Button>
          ) : null}
        </ModalBody>
      </Dialog>
    </Paper>
  );
};
export default AlertDeleteCheckModal;

//< FormGroup check >
//    <input check style={{ marginLeft: '10px' }} type="checkbox" checked={props.isAlertDeleteChecked} onClick={() => props.setIsAlertDeleteChecked(!props.isAlertDeleteChecked)}  ></input >
//    <Label check style={{ fontSize: '18px', marginLeft: '12px', padding: '10px' }} for="confirmDeleteCheck" >{props.confirmDeleteMessage}</Label>
//</FormGroup >

//disabled={ !props.isAlertDeleteChecked }
