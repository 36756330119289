import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RefreshIcon from '@material-ui/icons/Refresh';
import SortIcon from '@material-ui/icons/Sort';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import basePCUrl from '../../../Globals';
import { useAuth0 } from "@auth0/auth0-react";

import { DateCreateTimeStamp } from './../DateCreate/DateCreateTimeStamp';
import { postApiData } from '../../../utils/service';
import { useQuery } from 'react-query';
import DeleteAlertModal from './DeleteAlertModal';

var baseUrl = basePCUrl + 'Alerts/GetCompanyAlerts';

const AlertsTableCollapsible = (props) => {
  const [selected, setSelected] = React.useState([]);
  const [selectedAndDeleted, setSelectedAndDeleted] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(false);
  //const [open, setOpen] = React.useState(false);

  const [alertDeleteCheckModal, setAlertDeleteCheckModal] = useState(false);
  const toggleAlertDeleteCheck = () => setAlertDeleteCheckModal(!alertDeleteCheckModal);
  const closeAlertDeleteCheck = () => {
    setAlertDeleteCheckModal(false)
    setDeleteSuccessMessage(false)
  }

  const [isAlertDeleteChecked, setIsAlertDeleteChecked] = useState(false);
  const [alertsForDelete, setAlertsForDelete] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState({});
  //const [deleted, setDeleted] = useState(false);

  const preferredLanguage = props.preferredLanguage;
  const getTranslation = props.getTranslation;

  const { getAccessTokenSilently } = useAuth0();

  const mobileCheck = useMediaQuery('(min-width:830px)');

  function getAllAlertIds(data) {
    const alertIds = [];

    // Iterate over each object in the data array
    data.forEach((item) => {
      // Iterate over the data array within each object
      item.data.forEach((innerItem) => {
        // Push the alertId to the alertIds array
        alertIds.push(innerItem.alertId);
      });
    });

    return alertIds;
  }

  useEffect(() => {
    //const alertIds = getAllAlertIds(selected)
    if (selected != undefined) {
      setAlertsForDelete(selected);
    }
  }, [selected]);

  // useEffect(() => {
  //   if (selected != undefined) {
  //     setToBeDeleted(selectedAndDeleted.map((value) => ({ id: value.alertId })));
  //   }
  //   console.log(toBeDeleted);
  // }, [selectedAndDeleted]);

  //----------Table Logic---------//

  const alertDetailPush = (alertDetails) => {
    props.history.push('/Dashboard' + alertDetails);
  };

  let rowsForFilter = props.alertData;

  for (var i = rowsForFilter.length - 1; i >= 0; i--) {
    if (rowsForFilter[i].status === 'Closed') {
      rowsForFilter.splice(i, 1);
    }
  }


  for (const x of props.alertData) {
    x.createDate = x.timestamp.createDate;
    //delete x.timestamp.createDate
  }
  let alertsData = props.alertData;
  //let rows = props.alertData;

  const [sortByProperty, setSortByProperty] = useState('domain');
  const handleSortByChange = (event) => {
    setSortByProperty(event.target.value);
  };

 const createArrayOfObjectsForSorting =(data, property)=> {
    // Initialize an empty object to store data by properties
    const result = {};

    // Iterate over each object in the data array
    data.forEach((item) => {
      // Extract the value of the specified property from the current object
      const propertyValue = item[property];

      // Check if the property value already exists in the result object
      if (result.hasOwnProperty(propertyValue)) {
        // If it exists, push the current item to the array under that property value
        result[propertyValue].data.push(item);
      } else {
        // If it doesn't exist, create a new object with the current item in the 'data' property
        result[propertyValue] = { username: propertyValue, data: [item] };
      }
    });

    // Transform the result object into an array of objects
    const arrayOfObjects = Object.values(result);

    return arrayOfObjects;
  }

  const sortedRows = createArrayOfObjectsForSorting(alertsData, sortByProperty);
  let rows = sortedRows;
  //const sortByUserName = createArrayOfObjectsForSorting(alertsData, 'userName');

  //----------logic for conditional color fill on first column's table data-------//

  const tdColorCheck = (value) => {
    if (value === '3: high') {
      return classes.priorityFillHigh;
    } else if (value === '2: med') {
      return classes.priorityFillMed;
    } else if (value === '1: low') {
      return classes.priorityFillLow;
    }
  };

  const priorityColorCheck = (value) => {
    if (value === '3: high') {
      return classes.alertsHIGH;
    } else if (value === '2: med') {
      return classes.alertsMED;
    } else if (value === '1: low') {
      return classes.alertsLOW;
    }
  };

  const capitalizeSeverity = (value) => {
    return value.toUpperCase();
  };

  const checkAlertType = (value) => {
    if (value === 'LinkClicks') {
      return 'Link Click';
    } else if (value === 'LinkReports') {
      return 'Link Report';
    }
  };

  const accountForUrlLength = (linkForCheck) => {
    if (linkForCheck.length >= 60 && mobileCheck === false) {
      return <span style={{ fontSize: '10px' }}>{linkForCheck.slice(0, 30)}...</span>;
    } else if (linkForCheck.length >= 60 && mobileCheck === true) {
      return <span id="alertsLinkLong">{linkForCheck.slice(0, 50)}...</span>;
    } else if (mobileCheck === false && linkForCheck.length <= 60) {
      return <span style={{ fontSize: '10px' }}>{linkForCheck}</span>;
    } else {
      return <span id="alertsLinkNormal">{linkForCheck}</span>;
    }
  };

  const accountForUserLength = (linkForCheck) => {
    if (linkForCheck != null && linkForCheck.length >= 15 && mobileCheck === false) {
      //var name = linkForCheck.indexOf('@');
      //return <span>{linkForCheck.substring(0, name != -1 ? name : linkForCheck.length)}</span>
      return <span>{linkForCheck.slice(0, 15)} ...</span>;
    } else {
      return <span id="alertsLinkNormal">{linkForCheck}</span>;
    }
  };

  const checkLastUpdatedSize = () => {
    if (mobileCheck === false) {
      return 'mobileLastUpdated';
    } else {
      return 'desktopLastUpdated';
    }
  };

  const refreshTable = () => {
    props.refreshAlerts();
  };

  ////------Normal Sorting Logic------////
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const headCells = [
    //{ id: 'priorityFill', numeric: false, disablePadding: true, label: '' },
    //{ id: 'dateCreated', numeric: false, disablePadding: false, label: 'Date Created' },
    { id: 'severity', numeric: false, disablePadding: false, label: getTranslation('Severity', preferredLanguage) },
    { id: 'status', numeric: false, disablePadding: false, label: getTranslation('Status', preferredLanguage) },
    {
      id: 'createDate',
      numeric: false,
      disablePadding: false,
      label: getTranslation('Date Created', preferredLanguage),
    },
    { id: 'alertType', numeric: false, disablePadding: false, label: getTranslation('Type', preferredLanguage) },
    { id: 'incidentType', numeric: false, disablePadding: false, label: getTranslation('Incident', preferredLanguage) },
    { id: 'userName', numeric: false, disablePadding: false, label: getTranslation('User', preferredLanguage) },
    { id: 'URL', numeric: false, disablePadding: false, label: 'URL' },
    //{ id: 'alertsDetail', numeric: false, disablePadding: false, label: 'Details' },
  ];

  const useHeaderStyles = makeStyles((theme) => ({
    tableHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 80,

      '@media(max-width:830px)': {
        fontSize: 10,
        paddingTop: 0,
        paddingBottom: 0,
        width: 50,
      },
    },
    detailsHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 50,
      textAlign: 'right',
      '@media(max-width:830px)': {
        fontSize: 10,
        width: 45,
        padding: 5,
      },
    },
    colorHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 20,
      '@media(max-width:820px)': {
        width: 15,
      },
      padding: 0,
    },
    checkboxHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 30,
      '@media(max-width:820px)': {
        width: 25,
      },
      padding: 0,
    },
    zeroHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 0,
      padding: 0,
    },
    formControl: {
      width: '100px',
    },
  }));

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headerClasses = useHeaderStyles();

    return (
      <TableHead>
        <TableRow>
          {mobileCheck === true ? (
            <TableCell
              className={headerClasses.colorHeader}
              key={headCells[0].id}
              align={headCells[0].numeric ? 'right' : 'left'}
              padding={headCells[0].disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCells[0].id ? order : false}
            ></TableCell>
          ) : (
            <TableCell
              className={headerClasses.zeroHeader}
              key={headCells[0].id}
              align={headCells[0].numeric ? 'right' : 'left'}
              //padding={headCells[0].disablePadding ? 'none' : 'default'}
              //sortDirection={orderBy === headCells[0].id ? order : false}
            ></TableCell>
          )}
          <TableCell className={headerClasses.checkboxHeader} padding="checkbox">
            {/* <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rules' }}
            /> */}
          </TableCell>

          {headCells.map((headCell) => (
            <TableCell
              className={headerClasses.tableHeader}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell className={headerClasses.detailsHeader}>{getTranslation('Details', preferredLanguage)}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      //paddingLeft: theme.spacing(2),
      //paddingRight: theme.spacing(1),
      '@media(max-width:830px)': {
        height: '25px',
      },
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
    icons: {
      '@media(max-width:830px)': {
        fontSize: 25,
      },
    },
    sortSelect: {
      width: '100px',
      marginRight: '40px',
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} {getTranslation('selected', preferredLanguage)}
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle"></Typography>
        )}

        {/* <Tooltip title={getTranslation('Sort By', preferredLanguage)}>
          <IconButton className={classes.icons} onClick={refreshTable} aria-label="delete">
            <SortIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}

        <FormControl>
          <Tooltip title={getTranslation('Sort By', preferredLanguage)}>
            <Select
              className={classes.sortSelect}
              labelId="select-sort-by"
              value={sortByProperty}
              onChange={handleSortByChange}
              IconComponent={SortIcon}
            >
              <MenuItem value={'userName'}>User</MenuItem>
              <MenuItem value={'domain'}>Domain</MenuItem>
              <MenuItem value={'alertType'}>Type</MenuItem>
            </Select>
          </Tooltip>
        </FormControl>

        <Tooltip title={getTranslation('Refresh', preferredLanguage)}>
          <IconButton className={classes.icons} onClick={refreshTable} aria-label="delete">
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>

        {numSelected === 0 ? (
          <IconButton className={classes.icons} disabled aria-label="delete">
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title={getTranslation('Delete', preferredLanguage)}>
            <IconButton className={classes.icons} onClick={toggleAlertDeleteCheck} aria-label="delete">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '@media(max-width:600px)': {
        width: '80%',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      fontFamily: 'Montserrat, sans-serif',
    },
    container: {
      maxHeight: 500,
      '@media(max-width:600px)': {
        maxHeight: '200px',
      },
    },
    table: {
      minWidth: 750,
      '@media(max-width:820px)': {
        minWidth: 550,
      },
    },
    tableCell: {
      fontFamily: 'Montserrat, sans-serif',
      paddingLeft: 6,
      paddingRight: 6,
      '@media(max-width:820px)': {
        fontSize: '.75rem',
      },
    },
    tableRow: {
      paddingLeft: 0,
    },
    detailsCell: {
      fontFamily: 'Montserrat, sans-serif',
      width: 80,
    },
    alertsHIGH: {
      color: 'red',
      fontWeight: 600,
    },
    alertsMED: {
      color: '#F1C411',
      fontWeight: 600,
    },
    alertsLOW: {
      color: 'dodgerblue',
      fontWeight: 600,
    },
    priorityFillHigh: {
      backgroundColor: 'red',
      width: 15,
      height: 'auto',
      marginLeft: 0,
      paddingLeft: 0,
    },
    priorityFillMed: {
      backgroundColor: '#F1C411',
      width: 15,
      height: 'auto',
      marginLeft: 0,
      paddingLeft: 0,
    },
    priorityFillLow: {
      backgroundColor: 'dodgerblue',
      width: 15,
      height: 'auto',
      marginLeft: 0,
      paddingLeft: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerCell: {
      width: '100%',
    },
  }));

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createDate');

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(rows.length);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  function extractAlertIds(array) {
    // Map each object in the array to its alertId property
    return array.map((item) => item.alertId);
  }

  const handleSelectAllClick = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      const newSelecteds = extractAlertIds(alertsData);

      setSelected(newSelecteds);

      return;
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name.alertId);
    var newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.alertId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected)
    event.stopPropagation();
  };

  const handleGroupClick = (event, rowData) => {
    event.stopPropagation();
    const newSelected = [...selected]; // Create a copy of the selected array
    let alertIds = rowData.map((obj) => obj['alertId']);

    // Check if all alert IDs in the group are already selected
    const allSelected = alertIds.every((alertId) => newSelected.includes(alertId));

    // If all alert IDs are already selected, deselect them
    if (allSelected) {
      alertIds.forEach((alertId) => {
        const selectedIndex = newSelected.indexOf(alertId);
        newSelected.splice(selectedIndex, 1); // Remove the alert ID from the selection
      });
    } else {
      // Add only the unselected alert IDs to the selection
      alertIds.forEach((alertId) => {
        if (!newSelected.includes(alertId)) {
          newSelected.push(alertId);
        }
      });
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const clientId = props.clientId;

  //----------Delete Alert Logic---------//

  const deleteAlerts = useQuery(
    ['removeAlert'],
    async () => {
      const token = await getAccessTokenSilently();
      var data = {
        data: alertsForDelete,
      };
      return postApiData(
        'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
        token,
        data,
        'removeAlert'
      );
    },
    {
      enabled: false,
      onSuccess: () => {
        //toggleAlertDeleteCheck();
          //console.log(data);
          setSelected([]);
          setDeleteSuccessMessage(true)
      },
    }
  );

  const completeDeletion = () => {
    //setDeleted(true);
    deleteAlerts.refetch();
    setSelectedAndDeleted(selectedAndDeleted.concat(selected));
    //console.log(selectedAndDeleted);
    props.refreshAlerts();
    //hideDeletedAlerts();
  };


  function getObjectFromArray(array) {
    // Check if the array has at least one element
    if (array.length > 0) {
      // Return the first element of the array
      return array[0];
    } else {
      // If the array is empty, return null or handle the error accordingly
      return null;
    }
  }

  // Example usage:
  const array = [{ key: 'value' }];

  function stringExistsInArray(array, searchString) {
    // Use the includes method to check if the array contains the searchString
    return array.includes(searchString);
  }

  function compareArrays(array1, array2) {
    return array2.every((item) => array1.includes(item));
  }

  const [open, setOpen] = useState(false);

  const handleToggleCollapse = (event, index) => {
    setOpen((prevOpen) => (prevOpen === index ? null : index));
    //event.stopPropagation();
  };

  const isSelected = (alert) => selected.indexOf(alert) !== -1;

  function Row(props) {
    const { initialRow, index } = props;

    const classes = useStyles();
    const labelId = `enhanced-table-checkbox-${index}`;
    let row = initialRow;
    const rowAlertIds = row.data.map((obj) => obj['alertId']);

    return (
      <React.Fragment>
        <TableRow
          hover
          className={classes.tableRow}
          //onClick={event => handleClick(event, row.alertId)}
          role="checkbox"
          //aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.alertId}
          selected={compareArrays(
            selected,
            row.data.map((obj) => obj['alertId'])
          )}
          //selected={isItemSelected}
        >
          <TableCell className={classes.tableCell}>
            <Tooltip title={getTranslation('Select Group', preferredLanguage)}>
              <Checkbox
                checked={compareArrays(selected, rowAlertIds)}
                inputProps={{ 'aria-labelledby': labelId }}
                onClick={(event) => handleGroupClick(event, row.data)}
              />
            </Tooltip>
          </TableCell>
          <TableCell className={classes.tableCell}>{row.data.length}</TableCell>
          <TableCell className={classes.tableCell}>{accountForUserLength(row.username)}</TableCell>
          <TableCell TableCell align="right" className={classes.tableCell}>
            <IconButton aria-label="expand row" size="small" onClick={(event) => handleToggleCollapse(event, index)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open === index} timeout="auto" unmountOnExit>
              <Box margin={0}>
                <Table aria-label="alerts">
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {row.data.map((dataRow) => (
                      <TableRow
                        key={dataRow.alertId}
                        hover
                        className={classes.tableRow}
                        //onClick={event => handleClick(event, row.alertId)}
                        role="checkbox"
                        aria-checked={stringExistsInArray(selected, dataRow.alertId)}
                        tabIndex={-1}
                        selected={stringExistsInArray(selected, dataRow.alertId)}
                      >
                        {mobileCheck === true ? (
                          <TableCell className={tdColorCheck(dataRow.severity)}></TableCell>
                        ) : null}

                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={stringExistsInArray(selected, dataRow.alertId)}
                            inputProps={{ 'aria-labelledby': labelId }}
                            //onChange={}
                            onClick={(event) => handleClick(event, dataRow)}
                          />
                        </TableCell>
                        <TableCell align="left" className={priorityColorCheck(dataRow.severity)}>
                          {capitalizeSeverity(dataRow.severity)}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {accountForUrlLength(dataRow.status)}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <DateCreateTimeStamp date={dataRow.timestamp.createDate} />
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {getTranslation(dataRow.alertType, preferredLanguage)}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {getTranslation(dataRow.incidentType, preferredLanguage)}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {accountForUserLength(dataRow.userName)}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {accountForUrlLength(dataRow.URL)}
                        </TableCell>
                        <TableCell align="right" className={classes.detailsCell}>
                          {' '}
                          <IconButton
                            onClick={() => alertDetailPush(`/AlertsDetail?pad=&id=${dataRow.alertId}`)}
                            aria-label="details"
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            {/* <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            /> */}
            <TableHead className={classes.headerCell}>
              <TableRow>
                <TableCell style={{ paddingLeft: '6px' }} className={classes.headerCell}>
                  <Tooltip title={getTranslation('Select All', preferredLanguage)}>
                    <Checkbox onClick={handleSelectAllClick} />
                  </Tooltip>
                </TableCell>
                <TableCell style={{ paddingLeft: '6px' }} className={classes.headerCell}>
                  Number of Alerts
                </TableCell>
                <TableCell style={{ paddingLeft: '6px' }} className={classes.headerCell}>
                  {sortByProperty === 'userName' ? 'User' : sortByProperty}
                </TableCell>
                <TableCell style={{ paddingLeft: '6px' }} className={classes.headerCell}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.data.alertId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row key={row.data.name} initialRow={row} index={index} />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, rows.length]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={classes.tableCell}
        />
      </Paper>

      <DeleteAlertModal
        isOpen={alertDeleteCheckModal}
        toggle={toggleAlertDeleteCheck}
        onClose={closeAlertDeleteCheck}
        deleteLoading={deleteAlerts.isLoading}
        confirmDeleteMessage={getTranslation('Yes, I want to delete the selected alerts.', preferredLanguage)}
        deleteSuccessMessage={deleteSuccessMessage}
        handleDeletion={completeDeletion}
        isAlertDeleteChecked={isAlertDeleteChecked}
        setIsAlertDeleteChecked={setIsAlertDeleteChecked}
        preferredLanguage={preferredLanguage}
        getTranslation={getTranslation}
      />
      
    </div>
  );
};
export default AlertsTableCollapsible;
