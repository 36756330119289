import React from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import useStickyState from '../../CustomHooks/useStickyState';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import basePCUrl from '../../../../Globals';

import './GoogleLinkHistoryChart.css';
//var baseUrl = basePCUrl + 'UserData/GetUserChartData'

const GoogleLinkHistoryChart = (props) => {
  const [dateRange, setDateRange] = useStickyState(8, 'dateRange');


  // const accountForNullData = [
  //     [new Date, 0, 0, 0],
  //     [new Date, 0, 0, 0],
  //     [new Date, 0, 0, 0],
  //     [new Date, 0, 0, 0],
  //     [new Date, 0, 0, 0],
  //     [new Date, 0, 0, 0],
  //     [new Date, 0, 0, 0],
  // ]

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  var safeLinksSeen = getTranslation('Safe Links Seen', preferredLanguage)
  var unknownLinksSeen = getTranslation('Unknown Links Seen', preferredLanguage)
  var unsafeLinksSeen = getTranslation('Unsafe Links Seen', preferredLanguage)

  const theme = useTheme();

  // --------Google Chart Logic -------- //
  const renderChartData = () => {
    if (props.chartData !== null && props.chartData !== undefined && props.chartData.length > 0) {
      //COPY ARRAYS TO ALTER/MANIPULATE
      let alterChartData = props.chartData.map((arr) => arr.slice());
      let parseDateChartData = props.chartData.map((arr) => arr.slice());

      //REMOVE DATES FROM ARRAY - WILL ADD THEM BACK WHEN THE ARE FORMATTED
      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 1);
        }
      }

      //PARSE DATES WITH .MAP INTO NEW ARRAY
      const formattedChartData = parseDateChartData.map((value) => {
        let date = new Date(Date.parse(value[0]));
        let dateTime = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()) + 86400000);

        return {
          dateTime,
        };
      });

      //CONVERT ARRAY OF OBJECTS TO ARRAY
      let dateRangeFormattedDateTime = formattedChartData.slice(0, dateRange - 1);
      let finalFormattedDateTime = dateRangeFormattedDateTime.map(({ dateTime }) => dateTime);

      //ADD BACK FORMATTED DATES AND REFORMAT INTO ARRAY OF ARRAYS SO DATA IS USABLE WITH GOOGLE CHARTS

      for (var idx = 0; idx < alterChartData.length; idx++) {
        for (var jdx = 0; jdx <= alterChartData[idx].length; jdx++) {
          if (jdx === 1) {
            break;
          }
          alterChartData[idx].splice(jdx, 0, finalFormattedDateTime[idx]);
        }
      }

      if(safeLinksSeen) {
      alterChartData.splice(0, 0, ['Date', getTranslation('Safe Links Seen', preferredLanguage), getTranslation('Unknown Links Seen', preferredLanguage), getTranslation('Unsafe Links Seen', preferredLanguage)]);
      }

      let finalAlteredChartData = alterChartData.slice(0, dateRange);

      // --------- End of Chart Logic -------- //
      return <GoogleChart chartData={finalAlteredChartData} ticks={finalFormattedDateTime} />;
    } else {
      return <p>
        {getTranslation("could not load chart...", preferredLanguage)}
       </p>;
    }
  };


  const GoogleChart = (props) => {
    return (
      <div>
        <TitleToolbar />
        <Chart
          chartType="AreaChart"
          width="100%"
          height="283px"
          data={props.chartData}
          columns={columns}
          //legendToggle
          //isStacked="true"
          options={{
            colors: ['#2CCC72', '#F1C411', '#EE311E'],
            font: 'Montserrat',
            backgroundColor: { fill: 'transparent' },
            chartArea: { width: '65%', height: '65%' },
            pointSize: 5,
            vAxis: {
              viewWindow: { min: 0 },
              logScale: true,
              textStyle: { color: theme.palette.text.primary },
            },
            hAxis: {
              format: 'MM/dd',
              ticks: props.ticks,
              slantedText: true,
              slantedTextAngle: 45,
              textStyle: { color: theme.palette.text.primary },
            },
            legend: {
              textStyle: { color: theme.palette.text.primary },
            },
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      type: 'date',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
  ];

  //-------Chart Title------//

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const TitleToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle">
        {getTranslation("link safety history", preferredLanguage)}
        </Typography>

        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="date range select"
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            style={{ marginLeft: '0px', marginRight: '35px', width: '150px' }}
          >
            <MenuItem value="8">7  {getTranslation("days", preferredLanguage)}</MenuItem>
            <MenuItem value="15">14 {getTranslation("days", preferredLanguage)}</MenuItem>
            <MenuItem value="31">30 {getTranslation("days", preferredLanguage)}</MenuItem>
          </Select>
        </FormControl>
      </Toolbar>
    );
  };

  const useStyles = makeStyles({
    root: {
      width: '98%',
      fontFamily: 'Montserrat, sans-serif',

      maxWidth: '700',
      minWidth: '400',
    },
    container: {
      height: '40vh',
      maxHeight: '400',
      minHeight: '200',
      width: '100%',
      maxWidth: '700',
      minWidth: '400',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
  });

  return <Paper className="rectangle">{renderChartData()}</Paper>;
};
export default GoogleLinkHistoryChart;

GoogleLinkHistoryChart.propTypes = {
  chartData: PropTypes.any,
};
