import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import './CircleWidget.css';

const CircleWidget = (props) => {
  return (
    <Paper
      className="circleWidgetRectangle"
      elevation={props.elevation}
      style={{
        height: props.height,
        width: props.width,
        padding: props.padding,
        backgroundColor: props.backgroundColor,
        border: props.border,
        boxShadow: props.boxShadow,
      }}
    >
      <div id="circleContainer">
        <div style={{ backgroundColor: props.circleColor }} className="original-circle">
          <div id="circleIcon" style={{ marginTop: props.iconMarginTop, fontSize: props.fontSize }}>
            {props.icon}
          </div>
        </div>
      </div>
      <div className="circleContent">
        <span id="circleNumber">{props.number}</span>
        <br />
        <span id="circleText"><p>{props.text}</p></span>
      </div>
    </Paper>
  );
};
export default CircleWidget;
