import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '../../react-auth0-spa';
import Button from '@material-ui/core/Button';
import InstallationMessage from '../Login/InstallationMessage';
import chromeStoreLink from '../ChromeStoreLinkGlobal';
import firefoxStoreLink from '../FirefoxStoreLinkGlobal';
import NewUserNavBar from './NewUser/NewUserNavBar';
import '../App.css';

const DetectBrowser = () => {
  const [extensionCheck, setExtensionCheck] = useState();
  const [desktopCheck, setDesktopCheck] = useState();

  const [userBrowser, setUserBrowser] = useState();

  useEffect(() => {
    detectBrowser();
  }, []);

  const showExtensionCheck = () => {
    if (extensionCheck != null) {
      return <span></span>;
    } else if (extensionCheck === null) {
      return <h4>It looks like you do not have the extension installed yet.</h4>;
    }
  };

  useInterval(() => {
    //--To be used when there is something to scan for--//
    //if (document.getElementById("PhishCloudExtensionActive") != null) {
    //    setExtensionCheck(true)
    //} else {
    //    setExtensionCheck(false)
    //}
    setExtensionCheck(document.getElementById('PhishCloudExtensionActive'));
    setDesktopCheck(document.getElementById('PhishCloudDesktopActive'));
    //console.log(extensionCheck);
  }, 5000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    //Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const detectBrowser = () => {
    // var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    console.log('It is ' + isFirefox + ' that your browser is Firefox');
    // Safari 3.0+ "[object HTMLElementConstructor]"
    // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    console.log('It is ' + isIE + ' that your browser is Internet Explorer');
    // Edge 20+
    var isEdge = navigator.userAgent.indexOf('Edge') != -1;
    //!isIE && !!window.StyleMedia;
    console.log('It is ' + isEdge + ' that your browser is Edge');
    // Chrome 1 - 79
    var isChrome = navigator.userAgent.indexOf('Chrome') != -1;
    //!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    console.log('It is ' + isChrome + ' that yoru browser is Chrome');
    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1;
    console.log('It is ' + isEdgeChromium + ' that your broswer is Edge Chromium');

    if (isChrome && !isEdgeChromium) {
      setUserBrowser('Chrome');
    } else if (isFirefox === true) {
      setUserBrowser('Firefox');
    } else if (isIE === true) {
      setUserBrowser('InternetExplorer');
    } else if (isEdge === true) {
      setUserBrowser('Edge');
    } else if (isChrome && isEdgeChromium) {
      setUserBrowser('EdgeChromium');
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const extensionBasedOnBrowser = () => {
    if (userBrowser === 'Chrome') {
      window.location.replace(chromeStoreLink);
    } else if (userBrowser === 'EdgeChromium' || userBrowser === 'Edge') {
      window.location.replace(chromeStoreLink);
    } else if (userBrowser === 'Firefox') {
      window.location.replace(firefoxStoreLink);
    } else {
      return (
        <div className="outer">
          {/* <NewUserNavBar /> */}
          <div className="middle">
            <div className="inner">
              <div>
                <h4>
                  We were unable to detect your browser type or your browser is not currently supported by PhishCloud.
                </h4>
              </div>
              <br />
              <br />
              <div>
                <h6>You can manually choose your browser extension below.</h6>
              </div>
              <br />
              <div>
                <table>
                  <tr>
                    <td>Chrome</td>

                    <td id="button">
                      <a target="_blank" href={chromeStoreLink}>
                        <Button id="download" variant="contained" color="primary">
                          Download
                        </Button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Edge</td>

                    <td id="button">
                      <a target="_blank" href={chromeStoreLink}>
                        <Button id="download" variant="contained" color="primary">
                          Download
                        </Button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Firefox</td>
                    <td id="button">
                      <a target="_blank" href={firefoxStoreLink}>
                        <Button id="download" variant="contained" color="primary">
                          Download
                        </Button>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  console.log(userBrowser);

  return (
    <div>
      {extensionBasedOnBrowser()}
      <br />
    </div>
  );
};
export default DetectBrowser;
