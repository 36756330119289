import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TranslationModule from '../../../Translate/TranslationModule';

const ReturnToAlertDetailsButton = (props) => {

  return (
    <Button variant="contained" color="primary" onClick={props.goBack} startIcon={<ArrowBackIcon />}>
     <TranslationModule text={"Return To Alert Details"} targetLanguage={props.preferredLanguage}/> 
     </Button>
  );
};
export default ReturnToAlertDetailsButton;
