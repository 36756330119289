import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { jwtDecode } from 'jwt-decode';
import { fetchEmailData } from '../../utils/service';
import ShieldLogo from './Shield.png'
import './EmailLogin.css';
import EmailLoginBrandingStyles from '../../Assets/BrandingStyles/BrandingStyles';

const EmailLogin = () => {
  const [email, setEmail] = useState('');
  const [coolDown, setCoolDown] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [queryCount, setQueryCount] = useState(0);

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const storedQueryCount = localStorage.getItem('emailQueryCount');
    if (storedQueryCount) {
      setQueryCount(parseInt(storedQueryCount));
    }

    const cooldownExpiration = localStorage.getItem('emailQueryCooldownExpiration');
    if (cooldownExpiration && Date.now() < parseInt(cooldownExpiration) && storedQueryCount >=5) {
      setCoolDown(true);
    } else {
      setCoolDown(false)
      localStorage.setItem('emailQueryCount', 0)
      localStorage.removeItem('emailQueryCooldownExpiration');
    }
  }, []);

  useEffect(() => {
    if (queryCount >= 5) {
      setCoolDown(true);
    }
  }, [queryCount]);

  const showTooManyError = () => {
    if (coolDown === true) {
      return (
        <div>
          <Alert severity="error">
            Too many failed login attempts - Please wait 30 minutes and try again, or contact support
          </Alert>
        </div>
      );
    }
  };

  const showEmailNotFoundError = () => {
    if (emailNotFound === true) {
      return (
        <div>
          <Alert severity="error">Email not found. Please check your email address and try again.</Alert>
        </div>
      );
    }
  };


  const getEmailResult = useQuery(
    ['getEmailResult'],
    async () => {

      const data = {
        email: email,
      }; 


      return fetchEmailData('https://wami.phishcloud.io/api/initial_check', data);
    },
    {
      enabled: false,
      onSuccess: (response) => {
        decodeJwtToken(response.response);
      }
    }
  );

  const submitEmail = (event) => {
    if (localStorage.getItem('emailQueryCount') <= 4){
    event.preventDefault();
    getEmailResult.refetch();
    } else {
      
    }
  };

  function base64urlEncode(data) {
    return btoa(data).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Remove padding characters
  }

  function decodeJwtToken(enteredToken) {
    const secretKey = process.env.REACT_APP_VERIFY_KEY;
    const token = enteredToken;
    try {
      const decodedPayload = jwtDecode(token);

      const segments = token.split('.');
      const signatureInput = segments[0] + '.' + segments[1];

      const encoder = new TextEncoder();
      const keyData = encoder.encode(secretKey);

      window.crypto.subtle
        .importKey('raw', keyData, { name: 'HMAC', hash: { name: 'SHA-256' } }, false, ['sign'])
        .then((key) => crypto.subtle.sign('HMAC', key, encoder.encode(signatureInput)))
        .then((signatureBuffer) => {
          const signature = base64urlEncode(String.fromCharCode.apply(null, new Uint8Array(signatureBuffer)));

          if (signature === segments[2]) {
            console.log(decodedPayload.login_page)
            if (decodedPayload.login_page) {
              setEmailNotFound(false);
              loginWithRedirect({ redirect_uri: decodedPayload.login_page });
              //window.location.replace(decodedPayload.login_page);
            } else if (decodedPayload.status_code === 400) {
              setEmailNotFound(true);
              updateQueryCount();
            }
          } else {
            setEmailNotFound(true);
            throw new Error('JWT signature verification failed');
          }
        })
        .catch((error) => {
          console.error('Error:', error.message);
        });
    } catch (error) {
      console.error('Error:', error.message);
    }
  }

  const updateQueryCount = () => {
    const newCount = queryCount + 1;
    localStorage.setItem('emailQueryCount', newCount);
    setQueryCount(newCount);

    if (newCount === 1) {
      const expirationTime = Date.now() + 30 * 60 * 1000; // 30 minutes
      localStorage.setItem('emailQueryCooldownExpiration', expirationTime);
    }

    if (newCount >= 5) {
      setCoolDown(true);
    }
  };

  return (
    <div className="parentContainer">
      <div className="container-login100" style={{ backgroundColor: '#0055A2'}}>
        <Paper className="wrap-login100">
          <form className="login100-form validate-form">
            <span className="login100-form-title p-b-26">Welcome</span>
            <span className="login100-form-title p-b-48">
              <img src={ShieldLogo} width={100} />
            </span>
            {showTooManyError()}
            {showEmailNotFoundError()}
            <br />
            <div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
              <InputBase
                className="input100"
                type="text"
                id="email"
                name="email"
                placeholder="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <span className="focus-input100" data-placeholder="Email"></span>
            </div>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button onClick={(event) => submitEmail(event)} id="emailLoginButton" className="login100-form-btn">
                  Login
                </button>
              </div>
            </div>
            <br />
          </form>
        </Paper>
      </div>
    </div>
  );
};
export default EmailLogin;