import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DownloadCheckModal from './DownloadCheckModal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import chromeStoreLink from '../../ChromeStoreLinkGlobal.js';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import './Install.css';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const Install = (props) => {
  useChangePageTitle('Downloads');

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  }));

  const classes = useStyles();

  return (
    <div>
      {!props.accessLevel ? (
        ''
      ) : (
        <div>
          <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
            Downloads
          </Typography>

          <div id="latestVersion">
            <table>
              <tr>
                <td>Outlook Extension 3.1.6</td>
                <td id="button">
                  <a
                    target="_blank"
                    href="https://phishcloud-io-downloads.s3.us-west-2.amazonaws.com/phishcloud+OutlookAddIn+3.1.6.msi"
                  >
                    <Button id="download" variant="contained" color="primary">
                      Download
                    </Button>
                  </a>
                </td>
              </tr>

              <tr>
                <td>Chrome Extension</td>

                <td id="button">
                  <a target="_blank" href={chromeStoreLink}>
                    <Button id="download" variant="contained" color="primary">
                      Download
                    </Button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>Firefox Extension</td>
                <td id="button">
                  <a target="_blank" href=" https://addons.mozilla.org/en-US/firefox/addon/phishcloud/">
                    <Button id="download" variant="contained" color="primary">
                      Download
                    </Button>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
export default Install;

//<DownloadCheckModal history={props.history} />
