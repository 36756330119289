import React, { useState, useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginDesktop from './components/Login/LoginDesktop';
import LoginPortal from './components/Login/LoginPortal';
import Success from './components/Login/Success';
import DetectBrowser from './components/Signup/DetectBrowser';
import Dashboard from './components/Dashboard/Dashboard';
import LandingPage from './components/LandingPages/LandingPage';
import CheckingOutsideLinkNotice from './components/LandingPages/CheckingOutsideLinkNotice';
import TrainingItemView from './components/Dashboard/Training/TrainingItemView';
import { QueryClient, useQuery } from 'react-query';
import Signup from './components/Signup/Signup';
import Support from './components/Support/Support';
import BrowserConfig from './components/BrowserConfig/BrowserConfig';
import Register from './components/Signup/Register';
import NewSignUp from './components/Signup/NewSignUp/NewSignUp';
import Error from './components/Dashboard/Error';
import MoreInfo from './components/MoreInfo/MoreInfo';
import InitialDownloads from './components/InitialDownloads/InitialDownloads';
import history from './utils/history';
import Activation from './components/Activation/Activation';
import ErrorUserNotFound from './components/Dashboard/Error/ErrorUserNotFound';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import useStickyState from './components/Dashboard/CustomHooks/useStickyState';
import NotFound from './components/ErrorPage/NotFound';
import HelpHome from './components/HelpPages/HelpHome';
import CampaignSignUpForm from './components/PhishingCampaign/CampaignSignUpForm';
import ThankYou from './components/PhishingCampaign/ThankYou';
import ExtensionWelcomePage from './components/ExtensionWelcomePage';
import PrivateRoute from './components/Dashboard/Routes/PrivateRoute';
import { fetchApiData } from './utils/service';
import RedirectOnNotFoundTopLevel from './components/RedirectOnNotFoundTopLevel';
import EmailLogin from './components/EmailLogin/EmailLogin';
import DuracellLogin from './components/Login/BrandedLogins/DuracellLogin';

const App = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const useStickyStateNoParse = (defaultValue, key) => {
    const [value, setValue] = useState(() => {
      const stickyValue = localStorage.getItem(key);
      return stickyValue !== null ? stickyValue : defaultValue;
    });
    useEffect(() => {
      localStorage.setItem(key, value);
    }, [key, value]);
    return [value, setValue];
  };

  const [darkMode, setDarkMode] = useStickyState(false, 'dark-mode');
  const initialLanguage = 'initialLanguage';
  const [preferredLanguage, setPreferredLanguage] = useStickyStateNoParse(initialLanguage, 'preferred-language');
  const paletteType = darkMode ? 'dark' : 'light';
  //const mainPrimaryColor = darkState ? '#303030' : '#061739';
  //const mainSecondaryColor = darkState ? '#303030' : '#f50057';
  const sideNavColor = darkMode ? '#303030' : '#061739';

  const { user } = useAuth0();
  //console.log(user)

  //const userSettingsData = useQuery('user-settings', async () => {

  //   const token = await getAccessTokenSilently();
  //   console.log(token)
  //   return fetchApiData('https://portalapi-v2.phishcloud.io/api/portalapi-v2', token, 'getUserSettings');
  // });

  // if (!userSettingsData.isLoading) {
  // console.log(userSettingsData.data)
  // console.log("Testing")
  // }

  const theme = createMuiTheme({
    palette: {
      type: paletteType,

      sideNav: {
        main: sideNavColor,
      },
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <RedirectOnNotFoundTopLevel history={props.history} location={props.location} redirectTo="/Dashboard/">
            <Route
              exact
              path="/"
              component={(props) => (
                <LoginPortal
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              exact
              path="/Login"
              component={(props) => (
                <EmailLogin
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              exact
              path="/Duracell"
              component={(props) => (
                <DuracellLogin
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              exact
              path="/Error"
              component={(props) => (
                <Error history={props.history} location={props.location} preferredLanguage={props.preferredLanguage} />
              )}
            />
            <Route
              exact
              path="/ErrorUserNotFound"
              component={(props) => (
                <ErrorUserNotFound
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />
            <Route
              exact
              path="/MoreInfo"
              component={(props) => (
                <MoreInfo
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/PortalSignIn"
              component={(props) => (
                <LoginPortal
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <PrivateRoute
              path="/Dashboard"
              component={(props) => (
                <Dashboard
                  history={props.history}
                  location={props.location}
                  toggleDarkMode={setDarkMode}
                  darkMode={darkMode}
                  preferredLanguage={preferredLanguage}
                  setPreferredLanguage={setPreferredLanguage}
                  user={user}
                />
              )}
            />

            <Route
              path="/LandingPage"
              component={(props) => (
                <LandingPage
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/PhishingSucks"
              component={(props) => (
                <CampaignSignUpForm
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/ThankYou"
              component={(props) => (
                <ThankYou
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/Welcome"
              component={(props) => (
                <ExtensionWelcomePage
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/CheckingOutsideLinkNotice"
              component={(props) => (
                <CheckingOutsideLinkNotice
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/Signup"
              component={(props) => (
                <Signup history={props.history} location={props.location} preferredLanguage={props.preferredLanguage} />
              )}
            />
            <Route
              path="/Register"
              component={(props) => (
                <Register
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/InitialDownloads"
              component={(props) => (
                <InitialDownloads
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />
            <Route
              path="/Help"
              component={(props) => (
                <HelpHome
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />
            <Route
              path="/BrowserConfig"
              component={(props) => (
                <BrowserConfig
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/Support"
              component={(props) => (
                <Support
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />
            <Route
              path="/BrowserCheck"
              component={(props) => (
                <DetectBrowser
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />

            <Route
              path="/DesktopSignIn"
              component={(props) => (
                <LoginDesktop
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />
            <Route
              path="/DesktopSignInResult"
              component={(props) => (
                <Success
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />
            <Route
              path="/Activation"
              component={(props) => (
                <Activation
                  history={props.history}
                  location={props.location}
                  preferredLanguage={props.preferredLanguage}
                />
              )}
            />
          </RedirectOnNotFoundTopLevel>
        </Router>
      </ThemeProvider>
    </div>
  );
};
export default App;

//------------------For Future Release----------------//

//   < Route path = "/Training" component = { props => < TrainingItemView history = { props.history } location = { props.location } />} />
/*


useEffect(() => {
    getToken();
}, [loading]);
*/

//<ListItem button
//     className={isActive('/Alerts')}
//     key="Alerts"
//     component={Link}
//     to={'/Alerts'}>
//     <ListItemIcon className={classes.list}>
//             <FontAwesomeIcon  icon={['fas', 'bell']} size='lg' />
//     </ListItemIcon>
//     <ListItemText> Alerts</ListItemText>
// </ListItem>

//< AdminRoute path = '/Alerts' location = { location } component = { props => < Alerts history = { props.history } location = { props.location } />} />
//    < AdminRoute path = '/AlertsDetail' location = { location } component = { props => < AlertsDetail history = { props.history } location = { props.location } />} />

//--------------------------------------------------------------------------------------//

//if (loading) {
//    <div >
//        Loading...
//        </div>
//    console.log('loading')
//}

//const toggleNavbar = () => {
//    setCollapsed(!collapsed);
//        const elemBody = document.getElementById("mainBody");
//        if (collapsed === true) {
//            elemBody.className = "mainBodyOpen"
//        } else {
//            elemBody.className = "mainBodyClosed"
//        }
//}

//{ renderSideNav(location, history) }
//{ renderMainBody(location, history) }
