import React, { useState, useEffect } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'tippy.js/themes/light.css';

const CompanyInfoWordCloud = (props) => {
  const theme = useTheme();

  const renderWordCloud = () => {
    const options = {
      colors: ['#428BC9', '#87B0D5', '#0055A2', `${theme.palette.text.primary}`, '#0088e3', '#57afeb', '#006ab3'],
      enableTooltip: true,
      deterministic: true,
      fontFamily: 'Montserrat, sans-serif',
      fontSizes: [18, 45],
      fontStyle: 'normal',
      fontWeight: '600',
      padding: 1,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: 'log',
      spiral: 'archimedean',
      //transitionDuration: 1000,

      tooltipOptions: {
        //allowHTML: true,
        //arrow: true,
        placement: 'bottom',
        theme: 'light',
      },
    };

    const data = props.words;

    //------Remove zero values------//
    const removeZeroValues = (number, array) => {
      return array.filter((number) => number.value !== 0);
    };

    const finalWords = removeZeroValues(0, data);

    return <ReactWordcloud words={finalWords} options={options} />;
  };

  return <div style={{ height: '260px', width: '100%' }}>{renderWordCloud()}</div>;
};
export default CompanyInfoWordCloud;
