import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import UnknownLandingPage from './UnknownLandingPage';
import LandingPageBody from './LandingPageBody';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';
import './LandingPage.css';

const LandingPage = (props) => {
  useChangePageTitle('LandingPage');

  return (
    <div>
      <LandingPageBody />
    </div>
  );
};
export default LandingPage;

//? loading === false && linkData.img === null : < UnsafeLandingPage title={linkData.title} desc={linkData.desc}  url={url} />
//
