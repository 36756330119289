import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Container, Col, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './LandingPage.css';

const NewsFeedLinkPreview = (props) => {


  const useStyles = makeStyles({

    regularRoot: {
      minWidth: 275,
      fontFamily: 'Montserrat',
      display: 'flex',
      //padding: '15px',
      width: '100%',
      margin: '15px',
      //backgroundColor: '#26282c',
      //color: 'white',
      borderColor: props.backgroundColor,
      '&:hover': {
        //background: "#191a1c",
        borderColor: '#428BC9',
        boxShadow: '0 0 10px #428BC9',

     },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '10px',
      fontFamily: 'Montserrat',
    },
    title: {
      fontWeight: 600,
      fontFamily: 'Montserrat',
      marginBottom: '10px',
      // backgroundColor: '#c8895d',
      // color: 'black'
    },
    desc: {
      fontFamily: 'Montserrat',
      marginBottom: '10px',
    },
    cover: {
      width: '175px',
      minHeight: '125px',
      objectFit: 'cover',
      backgroundColor: props.clientInfo ? props.clientInfo.styling.linkPreviewCoverColor : '#0055a2',

    },
    pos: {
      //marginBottom: 12,
      fontFamily: 'Montserrat',
      fontStyle: 'italic',
      color: 'white'
    },
  });
  const classes = useStyles();

const preferredLanguage = props.preferredLanguage
const getTranslation = props.getTranslation

  return (
    <div id={"linkPreviewBody"}>

        <a href={props.newsUrl} target="_blank" style={{ width: '100%' }}>
          <Card className={classes.regularRoot} variant="outlined">
            {props.image != null ? (
              <CardMedia
                className={classes.cover}
                component="img"
                alt={props.title}
                image={props.image}
                title={props.title}
              />
            ) : (
              <CardMedia className={classes.cover} component="img" />
            )}
            <div className={classes.details}>
              <Typography className={classes.title} variant="h5" component="h3">
             {getTranslation(props.title, preferredLanguage)}
              </Typography>
              <Typography className={classes.desc} variant="body2" component="p">
              {props.description ? getTranslation(props.description, preferredLanguage)  : getTranslation("There was no description found for this link.", preferredLanguage)} 
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
              {props.newsUrl ? props.newsUrl : null}
 
              </Typography>
            </div>
          </Card>
        </a>
    </div>
  );
};
export default NewsFeedLinkPreview;