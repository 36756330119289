import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from './../../react-auth0-spa';
import phishcloudIcon from './logo.png';
import './FirstLogin.css';
import InstallationDesktopMessage from './InstallationDesktopMessage';
import Auth0SpaLogin from './auth0SpaLogin';
import chromeStoreLink from './../ChromeStoreLinkGlobal.js';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';

const LoginDesktop = (props) => {
  useChangePageTitle('DesktopSignIn');

  const { loading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [userBrowser, setUserBrowser] = useState();
  const [extensionCheck, setExtensionCheck] = useState(null);
  const [token, setToken] = useState(null);
  const [isSignedInResult, setIsSignedInResult] = useState(null);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    //Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    //--To be used when there is something to scan for--//
    if (document.getElementById('PhishCloudExtensionActive') != null) {
      setExtensionCheck(true);
    } else {
      setExtensionCheck(false);
    }
    var signinResult = document.getElementById('PhishCloudDesktopSignResult');
    console.log(signinResult);
    if (signinResult != null) {
      console.log(signinResult.className);
      setIsSignedInResult(signinResult.className);
    }
    //console.log(loading);
    //console.log(isAuthenticated);

    //console.log(extensionCheck);
  }, 2000);

  const extensionBasedOnBrowser = () => {
    if (userBrowser === 'Chrome') {
      return (
        <span>
          <p>
            Download PhishCloud Chrome/Edge extension here:{' '}
            <a href={chromeStoreLink} target="_blank" rel="noopener noreferrer">
              <button>Click Here</button>
            </a>
          </p>{' '}
          <br />
          <br />
          <p>
            ***If you are using Edge, you can still use the Chrome extension! Simply follow the link, click "Allow
            extensions from other stores" and click "Add to Chrome".
          </p>
          <ul>
            <li>
              <div>
                <img alt="edge browser setting" src="https://i.imgur.com/pSXHXgf.png" />
              </div>
            </li>
            <br />
            <br />
            <li>
              <div>
                <img alt="chrome add extension picture" src="https://i.imgur.com/ZRaXXCK.png" />
              </div>
            </li>
          </ul>
        </span>
      );
    } else if (userBrowser === 'Firefox') {
      return (
        <span>
          <p>
            Download the Firefox Add-on here:{' '}
            <a
              href="https://addons.mozilla.org/en-US/firefox/addon/phishcloud/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>Click Here</button>
            </a>
          </p>{' '}
          <br />
          <br />
          <div>
            <img alt="firefox add extension picture" src="https://i.imgur.com/b9A4JTa.png" />
          </div>
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const renderMainBody = () => {
    if (extensionCheck === false) {
      return (
        <div>
          <h3>It looks like you do not have your PhishCloud browser extension installed yet!</h3>
          <br />
          {extensionBasedOnBrowser()}
        </div>
      );
    } else if (extensionCheck === true) {
      if (!isAuthenticated) {
        return (
          <Auth0SpaLogin
            redirect_uri="https://portal.phishcloud.io/DesktopSignIn"
            history={props.history}
            location={props.location}
          />
        );
      } else {
        getAccessTokenSilently().then((result) => {
          setToken(result);
        });
        if (isSignedInResult == 'success') {
          return (
            <div>
              <h1>PhishCloud is signed in and ready to go!</h1>
              <br />
              <br />
              <p>
                You can close this window or go to the portal by clicking{' '}
                <a href="https://portal.phishcloud.io/Dashboard/History">here</a>
              </p>
            </div>
          );
        } else if (isSignedInResult == 'Error') {
          return (
            <div>
              <h1>Something Went Wrong....</h1>
              <br />
              <br />
              <p>
                We are trying to sign you in again... If you are stuck for more than 10 seconds, try refreshing the
                page.
              </p>
              <br />
              <p>
                If this problem persists, please contact us at{' '}
                <a href="mailto:support@phishcloud.com">support@phishcloud.com</a>
              </p>
            </div>
          );
        } else if (isSignedInResult == 'NotActive') {
          return (
            <div>
              <h1>Something Went Wrong....</h1>
              <br />
              <br />
              <p>
                Looks like the PhishCloud Desktop Application is not working right now. Please restart PhishCloud
                Desktop then refresh the page.
              </p>
              <br />
              <p>
                If this problem persists, please contact us at{' '}
                <a href="mailto:support@phishcloud.com">support@phishcloud.com</a>
              </p>
            </div>
          );
        } else {
          return (
            <div>
              <h3>Sucessful Portal Signin...</h3> <p> Now Signing Desktop Agent in...</p>
            </div>
          );
        }
      }
    } else if (extensionCheck === null) {
      return <h1>Loading...</h1>;
    }
  };

  const detectBrowser = () => {
    // var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    // Safari 3.0+ "[object HTMLElementConstructor]"
    // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1 - 79
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1;

    if (isChrome === true) {
      setUserBrowser('Chrome');
    } else if (isFirefox === true) {
      setUserBrowser('Firefox');
    } else if (isIE === true) {
      setUserBrowser('InternetExplorer');
    } else if (isEdge === true) {
      setUserBrowser('Edge');
    } else if (isEdgeChromium === true) {
      setUserBrowser('EdgeChromium');
    }
  };

  useEffect(() => {
    detectBrowser();
  }, [loading, isSignedInResult]);

  return (
    <div id="initial-login-container">
      {!loading ? renderMainBody() : <p>Loading...</p>}

      {token != null ? <div id="PhishCloudAccessToken" className={token}></div> : <div />}
    </div>
  );
};
export default LoginDesktop;

//<PhishCloudAuth0Lock redirectUrl="https://localhost:44348/DesktopSignInResult"  history={props.history} location={props.location}  />
//<PhishCloudAuth0Lock redirectUrl="https://portal.phishcloud.io/DesktopSignInResult"  history={props.history} location={props.location}  />
