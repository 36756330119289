import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flag from 'react-world-flags';
import { Row, Col } from 'reactstrap';

const noCode = 'foo';

export class FlagIcon extends Component {
  render() {
    if (this.props.code === '' || this.props.code === null || this.props.code === undefined) {
      return (
        <Row>
          <Flag code={noCode} fallback={<span></span>} />
          <Col xs="auto">
            <FontAwesomeIcon icon={['fas', 'question']} size="3x" />
          </Col>
        </Row>
      );
    } else {
      return <Flag code={this.props.code} height="40" />;
    }
  }
}
