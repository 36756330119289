import React, { useState, useEffect } from 'react';

//import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Chart } from 'react-google-charts';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const CompanyInfoRecentAlertsChart = (props) => {
  const [dateRange, setDateRange] = useState('sevenDays');

const getTranslation = props.getTranslation
const preferredLanguage = props.preferredLanguage

  const theme = useTheme();

  // const [alertsRecentData, setAlertsRecentData] = useState(nullAlertsRecentChartData);

  const alertsRecentData = props.chartData;

  // --------Google Chart Logic -------- //
  const renderChartData = () => {
    if (props.chartData != null && props.chartData != undefined) {
      //COPY ARRAYS TO ALTER/MANIPULATE
      let alterChartData = props.chartData.map((arr) => arr.slice());
      let parseDateChartData = props.chartData.map((arr) => arr.slice());

      //console.log(alterChartData);

      //REMOVE DATES FROM ARRAY - WILL ADD THEM BACK WHEN THEY ARE FORMATTED
      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 1);
        }
      }

      //PARSE DATES WITH .MAP INTO NEW ARRAY
      if (dateRange === 'today') {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0] + 'Z'));
          //let dateTime = new Date((date.getFullYear()) + "," + (date.getMonth() + 1) + "," + (date.getDate()));
          let dateTime = new Date(date.getTime());

          return {
            dateTime,
          };
        });
      } else {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0]));
          let dateTime = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()));

          return {
            dateTime,
          };
        });
      }

      //CONVERT ARRAY OF OBJECTS TO ARRAY
      let finalFormattedDateTime = formattedChartData.map(({ dateTime }) => dateTime);

      //ADD BACK FORMATTED DATES AND REFORMAT INTO ARRAY OF ARRAYS SO DATA IS USABLE WITH GOOGLE CHARTS

      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 0, finalFormattedDateTime[i]);
        }
      }

      alterChartData.splice(0, 0, ['Date', 'Low', 'Med', 'High']);

      // --------- End of Chart Logic -------- //
      return <AlertInfoChart chartData={alterChartData} ticks={finalFormattedDateTime} />;
    } else {
      return <p> Could not load chart...</p>;
    }
  };

  const AlertInfoChart = (props) => {
    if (dateRange === 'today') {
      return (
        <div>
          <Chart
            chartType="ColumnChart"
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            columns={columns}
            height={'100%'}
            options={{
              chartArea: { width: '60%', height: '50%' },
              isStacked: true,
              colors: ['dodgerblue', '#F1C411', '#EE311E'],
              font: 'Montserrat',
              backgroundColor: { fill: 'transparent' },
              chart: {
                textStyle: { color: theme.palette.text.primary },
              },
              hAxis: {
                // title: 'Total Alerts',
                format: ['MMM DD YYYY'],
                slantedText: true,
                slantedTextAngle: 45,
                showTextEvery: 1,
                maxAlternation: 1,
                showTextEvery: 1,
                minTextSpacing: 12,
                textStyle: { fontSize: 12, color: theme.palette.text.primary },
                ticks: props.ticks,
              },
              vAxis: {
                title: 'Total Alerts',
                minValue: 0,
                format: '0',
                titleTextStyle: { color: theme.palette.text.primary },
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    } else if (dateRange === 'sevenDays') {
      return (
        <div>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={props.chartData}
            columns={columns}
            height={'100%'}
            options={{
              chartArea: { width: '70%', height: '50%' },
              isStacked: true,
              colors: ['dodgerblue', '#F1C411', '#EE311E'],
              font: 'Montserrat',
              backgroundColor: { fill: 'transparent' },
              chart: {
                textStyle: { color: theme.palette.text.primary },
              },
              hAxis: {
                // title: 'Total Alerts',
                format: 'MM/dd',
                slantedText: true,
                slantedTextAngle: 45,
                showTextEvery: 1,
                maxAlternation: 1,
                showTextEvery: 1,
                minTextSpacing: 12,
                ticks: props.ticks,
                textStyle: { color: theme.palette.text.primary },
              },
              vAxis: {
                title: 'Total Alerts',
                minValue: 0,
                format: '0',
                textStyle: { color: theme.palette.text.primary },
                titleTextStyle: { color: theme.palette.text.primary },
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    } else if (dateRange === 'thirtyDays') {
      return (
        <div>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={props.chartData}
            columns={columns}
            height={'100%'}
            options={{
              chartArea: { width: '70%', height: '50%' },
              isStacked: true,
              colors: ['dodgerblue', '#F1C411', '#EE311E'],
              font: 'Montserrat',
              backgroundColor: { fill: 'transparent' },
              chart: {
                textStyle: { color: theme.palette.text.primary },
              },
              hAxis: {
                // title: 'Total Alerts',
                format: 'MM/dd',
                slantedText: true,
                slantedTextAngle: 45,
                //showTextEvery: 1,
                //maxAlternation: 1,
                //showTextEvery: 1,
                minTextSpacing: 8,
                ticks: props.ticks,
                textStyle: { color: theme.palette.text.primary },
              },
              vAxis: {
                title: getTranslation('Total Alerts', preferredLanguage),
                minValue: 0,
                format: '0',
                textStyle: { color: theme.palette.text.primary },
                titleTextStyle: { color: theme.palette.text.primary },
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    }
  };

  const columns = [
    {
      type: 'date',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
  ];

  //-------Chart Title------//

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const useStyles = makeStyles({
    paper: {},
    root: {
      width: '100%',
      fontFamily: 'Montserrat, sans-serif',

      maxWidth: '700',
      minWidth: '400',
    },
    container: {
      height: '40vh',
      maxHeight: '400',
      minHeight: '200',
      width: '100%',
      maxWidth: '700',
      minWidth: '400',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
  });

  const paperClasses = useStyles();

  return <div className={paperClasses.root}>{renderChartData()}</div>;
};
export default CompanyInfoRecentAlertsChart;

//<TitleToolbar />
//    <Chart
//        chartType="AreaChart"
//        width="100%"
//        height="283px"
//        data={props.chartData}
//        columns={columns}
//        //legendToggle
//        //isStacked="true"
//        options={{
//            colors: ["#2CCC72", "#F1C411", "#EE311E"],
//            font: 'Montserrat',
//            backgroundColor: '#f5f5f5',
//            chartArea: { width: '65%', height: '65%' },
//            pointSize: 5,
//            vAxis: {
//                viewWindow: { min: 0 },
//                logScale: true
//            },
//            hAxis: {
//                format: 'MMM dd, yyyy',
//            },

//        }}
//    />
