import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import NewUser from './NewUser';

const NewUserView = (props) => {
  var stripePromise = loadStripe('pk_live_lQFJBBHu11xProHTpcc4SRnE00x9Tc5Eyo');

  return (
    <Elements stripe={stripePromise}>
      <head>
        <script src="https://js.stripe.com/v3/"></script>
      </head>
      <NewUser stripe={stripePromise} history={props.history} />
    </Elements>
  );
};
export default NewUserView;
