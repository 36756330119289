export const generateTrainingReportData = (data) => {
  if (data.errorMessage) {
    return null;
  }
  const reportArr = Object.values(data)[0];
  return reportArr;
};

export const generateChartData = (data, type = undefined) => {
  let chartData;
  if (type) {
    const { totalUsersAssignedTraining, totalCoursesCompleted, totalCoursesInProgress } = data;
    chartData = [
      ['Status', 'Count'],
      ['Course Assigned', totalUsersAssignedTraining || 0],
      ['Completed', totalCoursesCompleted || 0],
      ['In Progress', totalCoursesInProgress || 0],
    ];
  } else {
    chartData = [
      ['Status', 'Count'],
      ["Haven't started", data?.trainingCoursesAssigned?.length - data?.trainingCoursesCompleted?.length || 0],
      ['Completed', data?.trainingCoursesCompleted?.length || 0],
      ['In Progress', data?.trainingCoursesInProgress?.length || 0],
    ];
  }

  return chartData;
};
