import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardContent } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Cookies from 'js-cookie';

const TrainingCard = (props) => {
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [MSSPClientCardDialogueState, setMSSPClientCardDialogueState] = useState(false);
  const toggleMSSPClientCardDialogue = () => setMSSPClientCardDialogueState(!MSSPClientCardDialogueState);
  const { courseDetails } = props;

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const trainingDetailPush = (trainingDetails) => {
    history.push('/Dashboard/Training?pad=&id=' + trainingDetails);
  };
  const trainingQuizPush = (trainingDetails) => {
    history.push('/Dashboard/TrainingQuiz?pad=&id=' + trainingDetails);
  };

  //------Card Styling----//
  const useStyles = makeStyles((theme) => ({

    media: {
      height: 200,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    overlineTypography: {
      color: '#B5B5B5',
      fontFamily: 'Montserrat, sans-serif',
    },
    statusTypography: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
    },
    titleTypography: {
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.4em',
      paddingBottom: 12,
      textAlign: 'start',
    },
    descriptionTypography: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.1em',
      height: 100,
      paddingBottom: 10,
    },
    cardActions: {
      display: 'flex',
      height: '100%',
      justifyContent: 'space-around',
      marginTop: "auto"
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
    circle: {
      backgroundColor: '#0055A2',
      borderRadius: '50%',
      display: 'flex',
      color: 'white',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '200px',
      height: '200px',
      padding: '10px',
      '& p': {
        fontWeight: 'bold',
      },
      '&:hover': {
        transform: 'scale(1.1)',
        cursor: 'pointer',
        backgroundColor: '#0055A2',
      },
    },
    clickActionContainer: {
      border: '#061739',
      padding: '2rem',
    },
    paperBody: {
      marginBottom: '2rem',
      flexDirection: 'column',
      display:'flex', 
      height: '100%'
      
      // '& p': {
      //   textAlign: 'center',
      // },
    },
    chip: {
      marginTop: '10px',
      marginBottom: '10px',
      marginLeft: '0px',
      margin: '0px'

    },

    button: {
     // width: '100%',
     // fontFamily: 'Montserrat, sans-serif',
      height: '50px',
      backgroundColor: '#0055A2',
      marginLeft: '20px',
      // '&:hover': {
      //   color: 'black',
      //   backgroundColor: '#428BC9',
      // },
    },
    titleTypography: {
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.4em',
      paddingBottom: 12,
      textAlign: 'start',
    },
    trainingType: {
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.4em',
      padding: 12,
    },
    gridItem: {
      height: '100%',
      maxHeight: 500,
      minWidth: 400,
      marginTop: '10px'
    },
    listItem: {
      padding: 20,
      paddingLeft: 0
    },
    listText: {
      flex: "none"
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'flex-end'
    },
    lowerCardContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent:'space-between',
      alignItems: 'flex-end',
      paddingBottom: '.5em'
    }
  }));

  const classes = useStyles();

  function getTrainingStatus(trainingId) {
    // Check if the cookie exists
    if (Cookies.get(trainingId)) {
      // If the cookie exists, return its value
      return Cookies.get(trainingId);
    } else {
      // If the cookie does not exist, return the course details status
      return courseDetails.status 
    }
  }

  const trainingStatus = getTrainingStatus(courseDetails.modId).toString() 
  

  const ListContent = () => {
    return (
      <List>
        <Paper className={classes.paper}>
          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar variant="square" src={courseDetails.thumbnail} className={classes.large}></Avatar>
            </ListItemAvatar>
            <ListItemText primary={courseDetails.title} className={classes.listText} />
            <Chip
      className={classes.chip}
        label={getTranslation(trainingStatus, preferredLanguage)}
        color="info"
        style={{ margin: '5px' }}
      />
            <ListItemSecondaryAction>
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => trainingDetailPush(courseDetails.modId)}
          >
           {getTranslation("View Training", preferredLanguage)}
          </Button>
          <Button
            variant="contained"
            //style={{ marginTop: '10px' }}
            color="primary"
            className={classes.button}
           onClick={() => trainingQuizPush(courseDetails.modId)}
          >
           {getTranslation("Take Quiz", preferredLanguage)}
          </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
      </List>
    );
  };

  const GridContent =()=> {
    return(
      <Paper className={classes.paperBody}>
           
      {/* <Grid container spacing={3} className={classes.clickActionContainer}> */}
      <CardMedia
            style={{ width: '100%' }}
            className={classes.media}
            image={courseDetails.thumbnail}
            title="PhishCloud"
            onClick={() => trainingDetailPush(courseDetails.modId)}
          />
        {/* <Grid item xs={12} sm={6} md={4}> */}
      <div className={classes.lowerCardContent}>
      <CardContent>
      <Typography className={classes.titleTypography}  variant="h5" component="h2">
      {courseDetails.title}  
            <Chip
      className={classes.chip}
        label={trainingStatus.charAt(0).toUpperCase()
          + trainingStatus.slice(1)}
        color="info"
        style={{ justifyContent: 'end', margin: '5px' }}
      />
    
          </Typography>
      
      <br/>
      {courseDetails.description}
 </CardContent>
 <div className={"flex-grow"} />
 <CardActions className={classes.cardActions}>
 <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => trainingDetailPush(courseDetails.modId)}
          >
            {getTranslation("View Training", preferredLanguage)}
          </Button>
          <Button
            variant="contained"
            //style={{ marginTop: '10px' }}
            color="primary"
            className={classes.button}
           onClick={() => trainingQuizPush(courseDetails.modId)}
          >
            {getTranslation("Take Quiz", preferredLanguage)}
          </Button>
      </CardActions>
      </div>
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4} className={classes.cardActions}> */}
         
        {/* </Grid> */}
         {/* <Grid item xs={12} sm={6} md={4} ml={5}> */}
          {/* <div className={classes.circle}> */}
          {/* <div>
            <p>Progress</p>
            <h2>{courseDetails.progress + '%'}</h2>
          </div> */}
        {/* </Grid> */}
     
      {/* </Grid> */}

    </Paper>
    )
  }

  return (
    <span>
      {props.listView ? <Grid item xs={12} sm={12} md={12} lg={12}>{ListContent()} </Grid> : <Grid item className={classes.gridItem} xs={12} sm={6} md={6} lg={4}> {GridContent()} </Grid>}
    </span>
  );
};
export default TrainingCard;
