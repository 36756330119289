import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import { useHistory } from 'react-router-dom';
import './Success.css';
import basePCUrl from '../../../Globals';

const EmailVerification = (props) => {
  const { loading } = useAuth0();

  const [error, setError] = useState();

  const [email, setEmail] = useState();

  const [emailVerified, setEmailVerified] = useState(false);

  const searchParams = new URLSearchParams(props.location.search);
  const signUpEmail = searchParams.get('signUpEmail');

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (emailVerified == false) {
      checkEmailVerificationStatus(email);
      //.then(function (data) {
      // if (data.verified == true) {
      //setEmailVerified(true);
      // setAlreadySentSuccess(true);

      //}
      // });
    }
    checkEmailVerificationStatus(email);
    // console.log(signUpEmail);
    // console.log(email);
  }, 5000);

  const checkEmailVerificationStatus = (email) => {
    return new Promise((resolve) => {
      var data = {
        email: `${email}`,
      };

      fetch(basePCUrl + 'Auth/EmailIsVerified', {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'same-origin',
        headers: {
          //Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            //console.log(data);
           //console.log(data.status);
            if (data.status === 'Error') {
              console.log('Error');
              setError(data.status);
              resolve(data);
            } else if (data.status === 'ok' && data.verified === true) {
              setEmailVerified(true);
              //console.log(data);
              setError(false);
              resolve(data);
            } else if (data.status === 'ok' && data.verified === false) {
              setEmailVerified(false);
              setError(false);
              //console.log(data);
              resolve(data);
            }
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    });
  };

  useEffect(() => {
    if (!loading) {
      setEmail(signUpEmail);
    }
  }, [loading]);

  //useEffect(() => {

  //    if (!loading) {
  //    console.log(parsedQuery);

  //    setEmail(signUpEmail);
  //    setError(false);
  //    console.log({ errorIs: error });

  //    if (!alreadySentSuccess && (success === "true" || message === "This URL can be used only once" || message === "This account is already verified.")) {
  //        setEmailVerified(true);

  //    } else {
  //        setError(true);
  //    }
  //      }

  //}, [loading]);

  const SuccessMessage = () => {
    return (
      <div>
        {' '}
        <h2 id="result">Email Successfully Registered!</h2>
        <br/>
        {window.location.replace("https://phishcloud.io/")}
        <a href="https://phishcloud.io/">
          <h4>Phishcloud Portal Login</h4>
        </a>
      </div>
    );
  };

  //console.log(errorMessageRedirect)

  const ErrorMessage = () => {
    if (signUpEmail != undefined) {
      return (
        <div>
          <b>
            <p>
              Something Went Wrong. Please contact <a href="mailto:support@phishcloud.com">suport@phishcloud.com</a> if
              this issue persists.
            </p>
          </b>
          <br></br>
          <br></br>

          <p>
            If you have already installed phishcloud, go to <a href="https://phishcloud.io/">https://phishcloud.io/</a>{' '}
            to see your statistics or manage users(Admins only).
          </p>

          <br></br>
          <br></br>
          <br></br>
        </div>
      );
    }
  };

  console.log(error);

  const BodyRender = () => {
    if (error === true) {
      return ErrorMessage();
    } else if (error == false && emailVerified == true) {
      return SuccessMessage();
    } else if (!loading && !error && emailVerified == false) {
      return <div>Please check your inbox and verify your email.</div>;
    } else if (!error && emailVerified == false && loading == true) {
      return <div>Loading...</div>;
    } else {
      return <div>We're sorry, there was an error.</div>;
    }
  };

  return <div id="resultBody">{BodyRender()}</div>;
};
export default EmailVerification;
