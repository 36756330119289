import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input, Label, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from '../CustomHooks/useForm';

const UserDeleteCheckModal = (props) => {
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //-----Designation Modal close button toggle-----//
  const closeDeleteCheckBtn = (
    <Button type="submit" outline color="white" onClick={props.toggle}>
      <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
    </Button>
  );

  return (
    <div>
      <Dialog
        className={styles.root}
        onClose={props.toggle}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.toggle}></DialogTitle>

        <ModalBody>
          <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
            {getTranslation("Are you sure?", preferredLanguage)}
          </Typography>
          <br></br>

          {props.displayCannotDeleteYourselfMessage()}
          {props.displayCannotDeleteOnlyAdminMessage()}

          <div className="ModalForm">
            <FormGroup check>
              <input
                check
                style={{ marginLeft: '10px' }}
                type="checkbox"
                checked={props.isUserDeleteChecked}
                onClick={() => props.setIsUserDeleteChecked(!props.isUserDeleteChecked)}
              ></input>
              <Label check style={{ fontSize: '18px', marginLeft: '12px', padding: '10px' }} for="confirmDeleteCheck">
                {getTranslation("Yes, I want to delete these users.", preferredLanguage)}
              </Label>
            </FormGroup>
          </div>

          <div className="deleteUserButton">
            <Button
              block
              disabled={!props.isUserDeleteChecked}
              onClick={props.handleDeletion}
              id="deleteAllSelected"
              fullWidth="true"
              variant="contained"
              color="secondary"
            >
              {getTranslation("Delete Selected", preferredLanguage)}
            </Button>
          </div>
        </ModalBody>
      </Dialog>
    </div>
  );
};
export default UserDeleteCheckModal;
