import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import queryString from 'querystring';
import Auth0Lock from 'auth0-lock';
import phishcloudIcon from './logo.png';
import './FirstLogin.css';
import InstallationMessage from './InstallationMessage';
import EmailLogin from '../EmailLogin/EmailLogin';

const Auth0SpaLogin = (props) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const url = window.location.href
  let parsedQuery = queryString.parse(window.location.search);
  let parsedId = parsedQuery.cid;

  useEffect(() => {
    if (!isLoading && !isAuthenticated && parsedId === "DFCYET4FzlXAkJO") {
      props.history.push('/Duracell')
      //loginWithRedirect({ redirect_uri: props.redirect_uri });
    } else if (!isLoading && !isAuthenticated) {
      props.history.push('/Login')

    }
  }, [isLoading]);

  //Need to check if extension is installed to determine what to render
  return <div></div>;
};
export default Auth0SpaLogin;
