import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
//import config from './auth_config.json';
import history from './utils/history';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const intendedRoute = localStorage.getItem('intendedRoute')

function extractEmailFromUrl(url) {
  const urlParams = new URLSearchParams(url.split('?')[1]);
  const email = urlParams.get('email');
  return email ? decodeURIComponent(email) : null;
}

const email = extractEmailFromUrl(window.location.href)

const authorizationParams = {
  redirect_uri: window.location.origin,
};

if (email !== null && email !== undefined) {
  authorizationParams.login_hint = email;
}


ReactDOM.render(
  <Auth0Provider
    domain={"login.phishcloud.io"}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID}
    onRedirectCallback={onRedirectCallback}
    authorizationParams={authorizationParams}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    //useRefreshTokens={true}
    //cacheLocation="localstorage"
  >
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Auth0Provider>,
  document.getElementById('root'),
  document.getElementById('PhishCloudExtenstionActive')
);

serviceWorker.unregister();

//import 'bootstrap/dist/css/bootstrap.css';
//import React from 'react';
//import ReactDOM from 'react-dom';
//import { BrowserRouter } from 'react-router-dom';
//import App from './App';
//import registerServiceWorker from './registerServiceWorker';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');

//ReactDOM.render(
//  <BrowserRouter basename={baseUrl}>
//    <App />
//  </BrowserRouter>,
//  rootElement);

//registerServiceWorker();
