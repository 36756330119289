import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Link, withRouter } from 'react-router-dom';
import { Form, FormGroup, Input, Label, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSVReader from 'react-csv-reader';
import useForm from '../CustomHooks/useForm';
import Error from '../Error/Error';
import basePCUrl from '../../../Globals';
import csvUploadExample from './UserManagementImages/csvUploadExample.PNG';

const AddMultipleUsersModal = (props) => {

  const [loadingAddedUsers, setLoadingAddedUsers] = useState(false)
  const [error, setError] = useState();

  const [errorMessage, setErrorMessage] = useState('');
  const [invalidEmail, setInvalidEmail] = useState('');
  const [invalidRole, setInvalidRole] = useState('');
  const [CSVRequiredMessage, setCSVRequiredMessage] = useState();
  const [refreshUserList, setRefreshUserList] = useState(false);
  const [validEmails, setValidEmails] = useState('');

  const [csvData, setCSVData] = useState();
  const [finalRolesList, setFinalRolesList] = useState();
  const [finalEmailsList, setFinalEmailsList] = useState();

  const [emailTypeToggle, setEmailTypeToggle] = useState(false);
  const [emailType, setEmailType] = useState();

  const { getAccessTokenSilently, logout } = useAuth0();

  //--------Custom Hook - useForm to handle CSV form input - NOT IN USE--------//
  const { values: email, bind: bindEmail, reset: resetEmail } = useForm('');
  const { values: accessLevel, bind: bindAccessLevel, reset: resetAccessLevel } = useForm('');

  //-----------Modal toggle logic----------//
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setErrorMessage('');
    setCSVRequiredMessage('');
    setInvalidEmail('');
    setInvalidRole('');
    setValidEmails('');
    resetEmail();
    resetAccessLevel();
  };

  const toggleClose = () => {
    setModal(!modal);
    setErrorMessage('');
    setCSVRequiredMessage('');
    setInvalidEmail('');
    setInvalidRole('');
    setValidEmails('');
  };

  const changeEmailSendTypeSwitch = () => {
    setEmailTypeToggle(!emailTypeToggle);
  };

  useEffect(() => {
    emailTypeToggle ? setEmailType('gpo') : setEmailType('manual');
  }, [emailTypeToggle]);

  //----------------Duplicate Email Error Message-------------------//
  const displayErrorMessage = () => {
    if (errorMessage != null && errorMessage.toString().includes('duplicate email')) {
      return (
        <p>
          Oops! The following emails are already in our system. Please contact support@phishcloud.com if you think this
          is a mistake.
          <br />
          {errorMessage}
        </p>
      );
    }
  };

//------------------Loading Adding Users Message---------------//
const displayLoadingAddedUsersMessage = () => {
  if (loadingAddedUsers)
    return <Dialog className={styles.root} onClose={toggle} aria-labelledby="customized-dialog-title" open={modal}>
         <DialogTitle id="customized-dialog-title" onClose={toggle}></DialogTitle>

         <ModalBody>
           <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
           New users being added!
                   <br />
                   Check back in a few minutes.
           </Typography>
           </ModalBody>
       </Dialog>;
};

  //----------------Invalid Email Error Message-------------------//
  const displayInvalidEmailMessage = () => {
    if (invalidEmail != '')
      return <h5 style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>{invalidEmail}</h5>;
  };

  //-------------------CSV Required Message----------------------//
  const displayCSVRequiredMessage = () => {
    if (CSVRequiredMessage === true)
      return <h5 style={{ color: 'red', textAlign: 'center' }}>Error: You must choose a CSV file to upload</h5>;
  };

  //-------------------Invalid Role Message----------------------//
  const displayInvalidRoleMessage = () => {
    //ROLES:
    // User, CompanyAdmin, (?)
    if (invalidRole != '')
      return <h5 style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>{invalidRole}</h5>;
  };

  //----------------CSV Error Message-------------------//
  const handleCSVError = () => {
    return (
      <div>
        <p>There was an error while uploading the CSV file.</p>
      </div>
    );
  };

  //-----------Check Status of Add Multiple Users Call-----------//
  useEffect(() => {
    if (errorMessage.includes('Duplicate Emails:')) {
      displayErrorMessage();
    } else if (errorMessage === 'ok') {
      toggleClose();
      setLoadingAddedUsers(false)
      props.retrieveUsers();
    } else if (errorMessage === 'Error') {
      toggleClose();
      Error();
    } else if (errorMessage.includes('not able to verify access token or resolve to a valid user')) {
      logout({
        returnTo: 'https://portal.phishcloud.io/PortalSignIn',
      });
    }
  }, [errorMessage]);

  //----------------Popover---------------//

  //------Popover Styling------//
  const usePopoverStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      backgroundColor: 'rgba(78, 78, 78, 0.9)',
      color: 'white',
      width: '650px',
      //height: '200px',
      padding: '28px',
      overflowWrap: 'break-Word',
      //lineHeight: '1'
    },
    typography: {
      fontWeight: '300',
      fontSize: '16px',
      fontFamily: 'Montserrat, sans-serif',
    },
    icon: {
      padding: '5px',
    },
  }));

  //-------Popover Logic-------//
  const MouseOverPopover = () => {
    const classes = usePopoverStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        Add multiple users with a CSV file
        <InfoIcon
          className={classes.icon}
          fontSize="large"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography className={classes.typography}>
            CSV files should be formatted into two columns, with the new user's email in the first column cell followed
            by the new user's role/access level in the second column cell. The current access levels are "User" and
            "CompanyAdmin".
            <br />
            <br />
            Example:
            <br />
          </Typography>
          <img id="csvUploadExample" src={csvUploadExample} />
        </Popover>
      </div>
    );
  };

  //------------Add Multiple Users by CSV-------------//
  const handleSubmit = async (event) => {
    
    if (finalEmailsList != undefined && finalRolesList != undefined) {
      event.preventDefault();
setLoadingAddedUsers(true)
      const token = await getAccessTokenSilently();

      //-----Check that all emails in the CSV are valid------//
      let check =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let resultsArray = [];
      let resultsArrayCheck = (currentValue) => currentValue === 'valid';

      // if (props.callRoute != '') {
      //     if (props.permissions.includes('AccountAdmin')) {
      var data = {
        clientId: props.clientId,
        installMethod: emailType,
        listOfEmails: finalEmailsList,
        listOfAccessLevels: finalRolesList,
      };

      //     };
      // } else {
      //     var data = {
      //         listOfEmails: finalEmailsList,
      //         listOfAccessLevels: finalRolesList,
      //     };
      // }

      for (var i = 0; i < finalEmailsList.length; i++) {
        if (check.test(finalEmailsList[i]) === true) {
          resultsArray.push('valid');
        } else if (check.test(finalEmailsList[i]) === false) {
          resultsArray.push('invalid');
        }
        //console.log(resultsArray)
      }

      //------Check that all roles/accessLevels in the CSV are valid------//
      let checkEveryRole = (currentValue) => currentValue === 'User' || currentValue === 'CompanyAdmin';

      //-------Make the API call------//
      
      if (resultsArray.every(resultsArrayCheck) === true && finalRolesList.every(checkEveryRole) === true) {
        displayLoadingAddedUsersMessage();
        fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
          method: 'POST',
          headers: {
            action: 'createPhishCloudUser',
            'content-type': 'application/json',
            //testacct: uid,
            id: props.clientId,
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }

            response.json().then((data) => {
              //setLoadingAddedUsers(false);
              setErrorMessage(Object.entries(data));

              // console.log(data.status);
              // setErrorMessage(data.status);
              // if (!data.status.includes("Duplicate Emails")) {
              //     sendActivationsToUsers();
              // }
              //setTimeout(props.retrieveUsers(props.uid, props.clientId), 200);
            });
          })
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
          });
      } else if (resultsArray.every(resultsArrayCheck) === false && finalRolesList.every(checkEveryRole) === true) {
        setInvalidEmail('Error: The CSV file contained and invalid email address.');
        return console.log('That is not a valid email address');
      } else if (resultsArray.every(resultsArrayCheck) === true && finalRolesList.every(checkEveryRole) === false) {
        setInvalidRole('Error: The CSV file contained an invalid access level.');
        return console.log('That is not a valid role/accessLevel');
      } else if (resultsArray.every(resultsArrayCheck) === false && finalRolesList.every(checkEveryRole) === false) {
        setInvalidEmail('Error: The CSV file contained and invalid email address.');
        setInvalidRole('Error: The CSV file contained an invalid access level.');
        console.log('There was an error with the email address and role.');
      }
    } else {
      event.preventDefault();
      setCSVRequiredMessage(true);
    }
  };

  //------------Check for Errors on submission/API call to add users-------------//
  useEffect(() => {

    if (errorMessage.includes('duplicate email')) {
      displayErrorMessage();
    } else if (errorMessage.toString().includes('successfully added')) {
      toggleClose();
      setLoadingAddedUsers(false)
      if (props.clientId !== undefined) {
        setTimeout(props.retrieveUsers(props.clientId), 10000);
      } else {
        setTimeout(props.retrieveUsers(''), 10000);
      }
    } else if (errorMessage.toString().includes('failed to add')) {
      toggleClose();
      Error();
    }
  }, [errorMessage]);

  //------------Handle CSV - Format it for API call-------------//
  const handleCSV = (data) => {
    setCSVData(data);
    getRolesList(data);
    getEmailsList(data);
    setCSVRequiredMessage(false);
    
  };


  //------------Format CSV data into array of user emails for API call-------------//
  const getEmailsList = (data) => {
    if (data != undefined) {
      let emailsList = data.map((arr) => arr.slice());
      if (emailsList != undefined) {
        //-----Make new array of just emails-----//
        emailsList.forEach((array) => array.splice(1, 1));
        var concatEmailsList = [].concat.apply([], emailsList);

        //-----Filter out any blank cells/empty array elements from the CSV-----//
        var filteredConcatEmailsList = concatEmailsList.filter(Boolean);

        //----Set Final Emails List for the API call----//
        setFinalEmailsList(filteredConcatEmailsList);
      }
    }
  };

  //------------Format CSV data into array of user roles for API call-------------//
  const getRolesList = (data) => {
    if (data != undefined) {
      let rolesList = data.map((arr) => arr.slice());
      if (rolesList != undefined) {
        //-----Make new array of just roles-----//
        rolesList.forEach((array) => array.splice(0, 1));
        var concatRolesList = [].concat.apply([], rolesList);

        //-----Make sure that all roles are capitalized-----//
        var capitalizedFinalRolesList = concatRolesList.map((w) => w.charAt(0).toUpperCase() + w.slice(1));

        //-----Account for no capital in the middle of "CompanyAdmin"-----//
        var accountForNoCamelCase = [];

        for (var i = 0; i < capitalizedFinalRolesList.length; i++) {
          if (capitalizedFinalRolesList[i] === 'Companyadmin') {
            accountForNoCamelCase.push(
              capitalizedFinalRolesList[i].slice(0, 7) +
                capitalizedFinalRolesList[i].charAt(7).toUpperCase() +
                capitalizedFinalRolesList[i].slice(8, 12)
            );
          } else {
            accountForNoCamelCase.push(capitalizedFinalRolesList[i]);
          }
        }

        //-----Filter out any blank cells/empty array elements from the CSV-----//
        var filteredCapitalizedFinalRolesList = accountForNoCamelCase.filter(Boolean);

        //----Set Final Roles List for the API call----//
        setFinalRolesList(filteredCapitalizedFinalRolesList);
      }
    }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //----------Template Download Logic---------//

  var csvRows = [
    ['exampleUser@example.com', 'User'],
    ['exampleUser2@example.com', 'User'],
    ['exampleAdmin@example.com', 'CompanyAdmin'],
  ];
  var csvString = csvRows.join('\r\n');

  let csvHref = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csvString);

  //----------User Modal close button toggle---------//
  const closeBtn = (
    <Button outline color="white" onClick={toggleClose}>
      <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
    </Button>
  );

  return (
    <div>
      {/* {!loadingAddedUsers ?  */}
    <div>
      <Button
        color="primary"
        variant="contained"
        style={{ marginLeft: '10px' }}
        startIcon={<FontAwesomeIcon icon={['fas', 'plus-square']} />}
        onClick={toggle}
      >
        Add Multiple Users
      </Button>

      <Dialog className={styles.root} onClose={toggle} aria-labelledby="customized-dialog-title" open={modal}>
        <DialogTitle id="customized-dialog-title" onClose={toggle}></DialogTitle>

        <ModalBody>
          <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
            Add Multiple Users
          </Typography>
          <br></br>
          {displayLoadingAddedUsersMessage()}
          {displayErrorMessage()}
          {displayInvalidEmailMessage()}
          {displayInvalidRoleMessage()}
          {displayCSVRequiredMessage()}
          <Form id="addUserForm" onSubmit={handleSubmit}>
            <div className="ModalForm">
              <Label
                style={{ marginLeft: '25px', marginRight: '25px', width: '90%', textAlign: 'center', fontSize: '20px' }}
                check
                for="addingLink"
              >
                {MouseOverPopover()}

                <CSVReader
                  cssClass="csv-reader-input"
                  //label="Add multiple users with a CSV file "
                  onFileLoaded={handleCSV}
                  onError={handleCSVError}
                  inputId="AddUsers"
                  inputStyle={{ color: 'red' }}
                />
                <a href={csvHref} target="_blank" download="exampleCSV.csv" id="userManagementDownloadCSV">
                  Download example CSV
                </a>
              </Label>
            </div>
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={emailTypeToggle}
                  onChange={changeEmailSendTypeSwitch}
                  name="emailTypeToggle"
                  color="primary"
                />
              }
              label="Use GPO set up for these users"
            />

            <br />

            <div className="addUserButton">
              <Button type="submit" fullWidth="true" variant="contained" size="lg" color="primary" block>
                Add
              </Button>{' '}
            </div>
          </Form>
        </ModalBody>
      </Dialog>
    </div>
    {/* //  : <div>
    //   <Button
    //     color="primary"
    //     variant="contained"
    //     style={{ marginLeft: '10px' }}
    //     startIcon={<FontAwesomeIcon icon={['fas', 'plus-square']} />}
    //     onClick={toggle}
    //   >
    //     Add Multiple Users
    //   </Button>

    //   <Dialog className={styles.root} onClose={toggle} aria-labelledby="customized-dialog-title" open={modal}>
    //     <DialogTitle id="customized-dialog-title" onClose={toggle}></DialogTitle>

    //     <ModalBody>
    //       <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
    //       New users being added!
    //               <br />
    //               Check back in a few minutes.
    //       </Typography>
    //       </ModalBody>
    //   </Dialog>
    //   </div> } */}
    </div>
  );
};
export default AddMultipleUsersModal;

//---------Tooltip was used until replaced by Popover----------//

//----------------Tooltip Styling---------------//
//const useStyles = makeStyles(theme => ({
//    button: {
//        margin: theme.spacing(1)
//    },
//    customWidth: {
//        maxWidth: 650
//    },
//}));

//--------------------Tooltip---------------------//
//const classes = useStyles();

//< Tooltip classes = {{ tooltip: classes.customWidth }}  title = {< span style = {{ }}>
//    <div style={{
//        width: '650px', height: '200px', padding: '18px', overflowWrap: "break-Word",
//        fontWeight: '300', fontSize: '16px', fontFamily: 'Montserrat, sans-serif', lineHeight: '1'
//    }}>
//        CSV files should be formatted with the new user's email in the first cell,
//        followed by the new user's role in the second.

//                                    <br />
//        <br />
//        Example:
//                                    <br />

//        <img id="csvUploadExample" src={csvUploadExample} />

//    </div></span >} arrow >
//        <span style={{ padding: "5px" }}><FontAwesomeIcon icon={['fas', 'info-circle']} size="md" /></span>
//                            </Tooltip >

//----------------Send Activations/Resend Activations---------------//
// const sendActivationsToUsers = async () => {

//     const token = await getAccessTokenSilently();

//     var data = {
//         listOfEmails: finalEmailsList
//     };

//     fetch(basePCUrl + 'Actions/EmailUserActivationInstructions', {
//         credentials: "same-origin",
//         method: 'POST',
//         body: JSON.stringify(data),
//         headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//         }
//     }).then((response) => {

//         if (response.status !== 200) {
//             console.log('Looks like there was a problem. Status Code: ' +
//                 response.status);

//             return;
//         }

//         response.json().then((data) => {
//             console.log(data);
//              if (data.status === "Error") {
//                  setError(true);
//             } else {
//                  setTimeout(props.retrieveUsers(), 200);
//                 return;
//             }

//         });
//     })
//         .catch(function (err) {
//             console.log('Fetch Error :-S', err);
//         });

// }
