import React ,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import AWS from 'aws-sdk'

const S3_BUCKET = 'portal-upload-repository';
const REGION ='us-west-2';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

const ImageUploadS3 = (props) => {


    const handleLinkChange = props.handleLinkChange
    const progress = props.progress
    const setProgress = props.setProgress

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
        uploadFile(e.target.files[0])
    }

    const uploadFile = (file) => {

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };
        handleLinkChange(file)
        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
            })
    }


    const useStyles = makeStyles({
        root: {
          width: '100%',
        },
      });
      
      const classes = useStyles();

    return <div>
        <div>File Upload Progress is {progress}%</div>
        <div className={classes.root}>
            
      <LinearProgress variant="determinate" value={progress} />
    </div>
    <br/>
        <input type="file" onChange={handleFileInput}/>
        {/* <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button> */}
    </div>
}

export default ImageUploadS3;