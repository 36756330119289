import React, { Component } from 'react';
import { Alert, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './BoxWidget.css';

const BoxWidgetTwoIcons = (props) => {
  return (
    <Paper
      className="BoxWidgetRedone"
      style={{
        height: props.height,
        width: props.width,
        padding: props.padding,
        backgroundColor: props.backgroundColor,
        border: props.border,
        boxShadow: props.boxShadow,
      }}
    >
      <div id="boxContainerTwoHeaders">
        <div style={{ backgroundColor: props.circleColor }}>
          <div id="boxIcon" style={{ marginTop: props.iconMarginTop }}>
            {props.icon}
          </div>
          <div id="boxHeader">{props.boxHeader}</div>
        </div>
      </div>
      <div className="boxContent">
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <span id="boxNumberTwoHeaders">{props.number1}</span>
            <br />
            <span id="boxText">{props.text1}</span>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <span id="boxNumberTwoHeaders">{props.number2}</span>
            <br />
            <span id="boxText">{props.text2}</span>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};
export default BoxWidgetTwoIcons;

//<h6 id="boxTextHeader">{props.boxHeader}</h6>
