import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';
import './CampaignSignUpForm.css';
import { List } from '@material-ui/core';

const CampaignSignUpForm =(props)=> {

  useChangePageTitle('PhishingCampaignSignupForm');

    console.log("I should render SOMETHING")

    const handleSubmit =(event)=> { 
      let name = document.getElementById('name').value
      let email = document.getElementById('userEmail').value
      let company = document.getElementById('company').value
      let companySize = document.getElementById('companySize').value
        
        fetch('https://57byeme4be.execute-api.us-west-2.amazonaws.com/prod', {
            method: 'POST',
            body: JSON.stringify({
        
                "name" : name,
                
                "user" : email,
                
                "company" : company,
                
                "userCount" : companySize
                
                }),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
              }
        
              response.json().then((data) => {
                //console.log(data);
                
                
              });
            })
            .catch(function (err) {
              console.log('Fetch Error :-S', err);
            }); 
          
            event.preventDefault();
            props.history.push('/ThankYou');
          
          } 

    return(
      <div className="content">

{/* <html lang="en"><head>
	<base href="https://preview.colorlib.com/theme/bootstrap/contact-form-20/"/><meta charset="utf-8"/><meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
	<link href="https://fonts.googleapis.com/css?family=Roboto:400,700,900&amp;display=swap" rel="stylesheet"/>
	<link href="fonts/icomoon/style.css" rel="stylesheet"/>
	<link href="css/bootstrap.min.css" rel="stylesheet"/>
	<link href="css/style.css" rel="stylesheet"/>
	<title></title>
</head>
<body> */}


<div className="container">
<div className="row align-items-stretch justify-content-center no-gutters">
 
<div >
<div className="form h-100 contact-wrap p-5">
<h3 className="text-center"><img alt="PhishCloud Logo" id="logo" src="https://lp-custom-data.s3.us-west-2.amazonaws.com/quickLogo.png" style={{width: '398px', height: '150px'}}/></h3>
<div >
    <p className="textBox">
    Understand your organizations security posture against a real phishing assessment, not just the simulation. 
    Our experienced team looks at your organization the way an attacker would to create a custom phishing attack to help you understand
    your real vulnerabilities and real actions you can take to protect your company.  Each assessment includes:
    </p>
    <br/>
    <ul style={{marginLeft: '25px'}}>
    <li>Custom Designed Landing Pages</li>
    <li>Open-source Intelligence (OSINT) Overview</li>
    <li>Custom, Hard to Detect Phishing Email + Campaign</li>
    <li>Detailed Result Report & Debrief</li>
    </ul>
    <br/>
    <p className="textBox">Sign Up to get details about your custom phishing assessment today!</p>
  </div>
  <br />

<form onSubmit={handleSubmit} className="mb-5" id="contactForm" name="contactForm"><input type="hidden" value="https://preview.colorlib.com/theme/bootstrap/contact-form-20/"/>
<div className="row">
<div className="col-md-6 form-group mb-3"><label className="col-form-label" >Name *</label> <input className="form-control" id="name" placeholder="Your name" required type="text"/></div>

<div className="col-md-6 form-group mb-3"><label className="col-form-label" >Email *</label> <input className="form-control" id="userEmail" placeholder="Your email" required type="email"/></div>
</div>

<div className="row">
<div className="col-md-12 form-group mb-3"><label className="col-form-label" >Company *</label><input className="form-control" id="company" placeholder="Your company" required type="text"/></div>
</div>

<div className="row mb-5">
<div className="col-md-12 form-group mb-3"><label className="col-form-label" >Company Size *</label><input className="form-control" id="companySize" placeholder="Company Size" required type="text"/></div>
</div>

<div >
<Button style={{ backgroundColor: '#0055A2', color: '#fff',}} fullWidth={true} id="submitButton"  type="submit">Submit</Button>
</div>
</form>
</div>
</div>
</div>
</div>


{/* </body>
<script src="js/popper.min.js+bootstrap.min.js.pagespeed.jc.8DARHb5pmg.js"></script>
<script></script></html> */}

</div>
    )
}; export default CampaignSignUpForm