import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { EmpowerPeopleLogo } from './Assests/Logos/EmpowerPeopleLogo.png';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        {/* <NavMenu navTitle={this.props.navTitle} /> */}
        <img style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: '50%', paddingTop: "60px", paddingBottom: "30px" }} src="https://ecp.yusercontent.com/mail?url=https%3A%2F%2Fstorage.googleapis.com%2Ftopol-io-team-2560%2Fplugin-assets%2F6320%2F2560%2FMicrosoftTeams-image%2520%25282%2529.png&t=1653405958&ymreqid=6313dc59-da47-4302-1c0c-ee000401cc00&sig=.OXGoENnX1NI4K9YX6wJ8A--~D"></img>
        {this.props.children}
      </div>
    );
  }
}
