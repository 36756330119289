import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import basePCUrl from '../../../Globals';
var baseUrl = basePCUrl + 'Training/getTrainingByModId';

export const useGetTrainingByID = (trainingId, videoType, uid) => {
  //const [data, setData] = useState([]);

  const [state, setState] = useState({
    trainingVideo: null,
    trainingGraphic: null,
    trainingTitle: null,
    newsArticles: null,
    trainingTimeEstimate: null,
    trainingStatus: null,
    trainingQuiz: null,
    isLoading: true,
  });

  const { getAccessTokenSilently } = useAuth0();

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  const getUserTraining = async () => {
    const token = await getAccessTokenSilently();
    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
    var data = {
      modId: `${trainingId}`,
    };

    fetch(api, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        id: trainingId,
        Action: 'trainingMod',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          //setError(true);
          // console.log(error);
          return;
        }

        response.json().then((data) => {
        //  console.log(data);
          if (data !== 'access token check failed') {
            if (videoType === 'A') {
              setState({
                trainingVideo: data.anime.fullEpisode,
                trainingGraphic: data.infoGraphic,
                trainingTitle: data.title,
                trainingDescription: data.description,
                newsArticles: data.newsLinks,
                trainingTimeEstimate: data.timeEstimate,
                trainingStatus: data.trainingModuleProgress,
                trainingQuiz: data.quiz,
                isLoading: false,
              });
            } else {
              setState({
                trainingVideo: data.corporate.nanoEpisode,
                trainingGraphic: data.infoGraphic,
                trainingTitle: data.title,
                trainingDescription: data.description,
                newsArticles: data.newsLinks,
                trainingTimeEstimate: data.timeEstimate,
                trainingStatus: data.trainingModuleProgress,
                trainingQuiz: data.quiz,
                isLoading: false,
              });
            }
          } else {
            return;
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    setState({
      trainingVideo: null,
      trainingGraphic: null,
      trainingTitle: null,
      newsArticles: null,
      trainingTimeEstimate: null,
      trainingStatus: null,
      isLoading: null,
    });

    if (isMount) {
      console.log('First Render');
    } else {
      setState({
        trainingVideo: null,
        trainingGraphic: null,
        trainingTitle: null,
        newsArticles: null,
        trainingTimeEstimate: null,
        trainingStatus: null,
        isLoading: true,
      });
      if (videoType !== undefined) {
        getUserTraining();
      }
    }
  }, [trainingId && videoType]);

  return state;
};
export default useGetTrainingByID;

// const getUserTraining = async () => {
//     const token = await getAccessTokenSilently();

//     var data = {
//         "modId": `${trainingId}`
//     };

//     fetch(baseUrl, {
//         credentials: "same-origin",
//         method: 'POST',
//         body: JSON.stringify(data),
//         headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//         }
//     }).then((response) => {

//         if (response.status !== 200) {
//             console.log('Looks like there was a problem. Status Code: ' +
//                 response.status);
//             //setError(true);
//             // console.log(error);
//             return;
//         }

//         response.json().then((data) => {
//             console.log(data)

//             if (videoType === 'A') {
//                 setState({
//                     trainingVideo: data.anime.fullEpisode,
//                     trainingGraphic: data.infographic,
//                     trainingTitle: data.description,
//                     newsArticles: data.newsLinks,
//                     trainingTimeEstimate: data.timeEstimate,
//                     trainingStatus: data.trainingModuleProgress,

//                     isLoading: false
//                 });
//             } else {
//                 setState({
//                     trainingVideo: data.corporate.nanoEpisode,
//                     trainingGraphic: data.infographic,
//                     trainingTitle: data.description,
//                     newsArticles: data.newsLinks,
//                     trainingTimeEstimate: data.timeEstimate,
//                     trainingStatus: data.trainingModuleProgress,

//                     isLoading: false
//                 });
//             }

//         });
//     })
//         .catch(function (err) {
//             console.log('Fetch Error :-S', err);
//         });
// }
