import React from 'react';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import './Error.css';

const Error = (props) => {
  useChangePageTitle('Error');

  return (
    <div className="errorContainer">
      <h2 className="errorHeader">Oops! We're Sorry.</h2>
      <div className="errorBody">
        <p className="errorText">
          There was an error. If this issue persists, please contact{' '}
          <a href="mailto:support@phishcloud.com">support@phishcloud.com</a>
        </p>
        <br />
      </div>
    </div>
  );
};
export default Error;
