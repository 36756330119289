import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {Tabs, Tab } from '@material-ui/core';

const TrainingDashboardTabs =(props)=> {

  const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#0055A2',
    },
  })(Tabs);
  
  const AntTab = withStyles((theme) => ({
    root: {
      backgroundColor: '#0055A2',
      color: 'lightgrey',
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      //marginRight: theme.spacing(4),
      fontFamily: 'Montserrat, sans-serif',
      '&:hover': {
        color: '#40a9ff',
        //opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);
  
  

  
  const useTabStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginBottom: '1rem'

    },
    padding: {
      padding: theme.spacing(3),
    },
    demo1: {
      backgroundColor: theme.palette.background.paper,
    },
  }));
      
  const history = useHistory();

  const [value, setValue] = useState("/Dashboard/TrainingDashboard");

  useEffect(() => {
    setValue(history.location.pathname);
  }, [history]);

      function CustomizedTabs() {
        const tabClasses = useTabStyles();
      
        const isActive = (value) => {
          if (history.location.pathname === value) {
            return 'activeTab';
          } else {
            return 'Tab';
          }
        };
      
        const handleChange = (event, newValue) => {
          setValue(newValue)
        };

        return (
          <div className={tabClasses.root}>
        <div className={tabClasses.demo1}>
              <AntTabs value={value} onChange={handleChange}>
                <AntTab label="Admin View" value="/Dashboard/AdminTrainingDashboard" component={Link}
                to={"/Dashboard/AdminTrainingDashboard"} className={isActive("/Dashboard/AdminTrainingDashboard")}/>
                <AntTab label="User View"  value="/Dashboard/TrainingDashboard" component={Link}
                to={"/Dashboard/TrainingDashboard"} className={isActive("/Dashboard/TrainingDashboard")}/>
              </AntTabs>
              </div>
            </div>
        );
      }

        return(
          <div>
          {!history.location.pathname ? null :  <CustomizedTabs />}
          {history.pathname}
          </div>
        )
}; export default TrainingDashboardTabs