import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
//------Material UI------//
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchApiData, fetchApiDataWithId } from '../../../utils/service';
//import logoMobile from './logo/phishLogoForMobile.png';
import { NavLink } from 'reactstrap';
import basePCUrl from '../../../Globals';
import { useAuth0 } from "@auth0/auth0-react";
import useScript from '../CustomHooks/useScript';
import MSSPHeader from '../MSSP/MSSPHeader';
import LanguageDropdown from './LanguageDropdown';
import logo from './logo/phishLogo.png';
import darkModeLogo from './logo/phishCloudDarkModeLogo.png';
import Cookies from 'js-cookie';
import './NavBar.css';

const NavBar = (props) => {
  //useScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');

  const { isAuthenticated, loginWithRedirect, logout, loading, getAccessTokenSilently } = useAuth0();

  let isNotLoading = props.isNotLoading;
  let translationData = props.translationData.data;

  //const alertBadgeNumber = props.alertAmount
  const [alertBadgeNumber, setAlertBadgeNumber] = useState();
  const toggleDarkMode = props.toggleDarkMode;
  const darkMode = props.darkMode;

  //const [preferredLanguage, setPreferredLanguage] = useState();
  //const [customerPortalUrl, setCustomerPortalUrl] = useState();

  //const toggleNavbar = () => setCollapsed(!collapsed);

  //-----AppBar State----//

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isInfoMenuOpen = Boolean(infoAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const clientId = props.selectedClient;

  //const alertBadgeNumber = props.alertBadgeNumber
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  //Google Widget Logic
  // function googleTranslateElementInit() {
  //   window.google.translate.TranslateElement(
  //     { pageLanguage: 'en', includedLanguages: 'en,ja,de,es,fr,it,tr' },
  //     'google_translate_element'
  //   );

  //   var removePopup = document.getElementById('goog-gt-tt');
  //   removePopup.parentNode.removeChild(removePopup);
  //   $('.goog-logo-link').empty();
  //   $('.goog-te-gadget').html($('.goog-te-gadget').children());
  // }

  //const theme = useTheme();

  //--------AppBar styles------//
  const useStyles = makeStyles((theme) => ({
    navContainer: {
      //flexGrow: 1,
      //backgroundColor: '#fafafa',
      // backgroundColor: theme.palette.background.default,
      //height: '100%'
    },
    grow: {
      flexGrow: 1,
      //backgroundColor: '#fafafa',
      backgroundColor: theme.palette.background.default,
      height: '100%',
    },
    appBar: {
      //backgroundColor: '#fafafa',
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default,
      zIndex: 200 + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      //backgroundColor: '#fafafa',
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    buttons: {
      //color: '#00000080',
      padding: 20,
      fontFamily: 'Montserrat, sans-serif',
    },
    navLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
  }));

  const classes = useStyles();

  //-----AppBar Logic-----//

  const onLogOut = (props) => {
    props.setselectedClient([{ clientId: props.defaultClient, industry: '', name: 'No clients', numberOfUsers: 0 }]);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoMenuOpen = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleInfoMenuClose = () => {
    setInfoAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const openCustomerPortal = () => {
    getCustomerPortalLink().then(function (result) {
      if (result.status === 'Error') {
        window.open('https://portal.phishcloud.io/Dashboard/Error', 'newwindow', 'width=600,height=600');
      } else {
        window.open(result.url, 'newwindow', 'width=600,height=600');
      }
    });
  };

  const getAlertNumber = useQuery(
    ['alertCount'],
    async () => {
      if (props.accessLevel !== 'User') {
        if (localStorage.getItem('usid')) {
          let usid = localStorage.getItem('usid');
          return fetchApiDataWithId(
            'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
            usid,
            'alertCount',
            props.accessLevel,
            clientId
          );
        } else if (!localStorage.getItem('usid')) {
          const token = await getAccessTokenSilently();
          return fetchApiData(
            'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
            token,
            'alertCount',
            props.accessLevel,
            clientId
          );
        }
      }

      //   const token = await getAccessTokenSilently();
      //   setToken(token);
      //   return fetchApiData('https://portalapi-v2.phishcloud.io/api/portalapi-v2', token, 'initialTranslator');
    }
    //  { enabled: isNotLoading, selectedClient }
  );

  let alertNumber = getAlertNumber?.data?.newAlertCount;

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <NavLink tag={Link} className={classes.navLink} to="/Dashboard/account-info"></NavLink>
      </MenuItem>
      {(props.accessLevel !== undefined && props.accessLevel === 'CompanyAdmin') ||
      props.accessLevel === 'AccountAdmin' ? (
        <MenuItem onClick={handleMenuClose}>
          <NavLink onClick={openCustomerPortal}>{props.getTranslation('billing', props.preferredLanguage)}</NavLink>
        </MenuItem>
      ) : null}
      <MenuItem onClick={handleMenuClose}>
        <NavLink
          className={classes.navLink}
          onClick={() =>
            logout({
              returnTo: 'https://portal.phishcloud.io/PortalSignIn',
            })
          }
        >
          {props.getTranslation('log out', props.preferredLanguage)}
        </NavLink>
      </MenuItem>
    </Menu>
  );

  const renderInfoMenu = (
    <Menu
      anchorEl={infoAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isInfoMenuOpen}
      onClose={handleInfoMenuClose}
    >
      <MenuItem onClick={handleInfoMenuClose}>
        <NavLink tag={Link} className={classes.navLink} to="/Dashboard/about-us">
          {props.getTranslation('about us', props.preferredLanguage)}
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleInfoMenuClose}>
        <NavLink tag={Link} className={classes.navLink} to="/Dashboard/contact-us">
          {props.getTranslation('contact', props.preferredLanguage)}
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleInfoMenuClose}>
        <NavLink tag={Link} className={classes.navLink} to="/Dashboard/Downloads">
          {props.getTranslation('downloads', props.preferredLanguage)}
        </NavLink>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="new alerts" color="inherit">
          <Badge badgeContent={alertNumber} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p> {props.getTranslation('new alerts', props.preferredLanguage)}</p>
      </MenuItem>
      <MenuItem onClick={handleInfoMenuOpen}>
        <IconButton aria-label="new alerts" color="inherit">
          <InfoIcon />
        </IconButton>
        <p> {props.getTranslation('info', props.preferredLanguage)}</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{props.getTranslation('profile', props.preferredLanguage)}</p>
      </MenuItem>
    </Menu>
  );

  //-----------Get Customer Portal Link------------//

  const getCustomerPortalLink = async () => {
    return new Promise(async (resolve, reject) => {
      const token = await getAccessTokenSilently();

      fetch(basePCUrl + 'StripeClient/GetCustomerPortalUrl', {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            if (data.status === 'Error') {
              console.log(data.status);
              //setCustomerPortalUrl("https://portal.phishcloud.io/Dashboard/Error")
              resolve(data);
              //console.log(token)
            } else {
              //setCustomerPortalUrl(data.url);
              resolve(data);
            }
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    });
  };

  function setCookie(cname, cvalue, exdays, cpath) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();

    document.cookie = cname + '=' + cvalue + ';' + expires + ';' + cpath;
  }

  //Google Widget Logic
  // useEffect(() => {
  //   if (preferredLanguage !== undefined) {
  //     //let lowerCasePreferredLanguage = preferredLanguage.toLowerCase();
  //     if (lowerCasePreferredLanguage === 'jp') {
  //       setCookie('googtrans', '/en/' + 'ja', 1, 'path=/;');
  //     } else {
  //       setCookie('googtrans', '/en/' + lowerCasePreferredLanguage, 1, 'path=/;');
  //     }
  //     //googleTranslateElementInit();
  //   }
  // }, [preferredLanguage]);

  useEffect(() => {
    if (window.location.search.includes('error=')) {
      props.history.push('/Error');
    }
    const intervalCall = setInterval(() => {
      getAlertNumber.refetch();
    }, 60 * 1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [clientId]);

  // function getCookie(name) {
  //   function escape(s) {
  //     return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
  //   }
  //   var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
  //   return match ? match[1] : null;
  // }

  // useEffect(() => {
  //   if (languageLocalStorageChanged && !loading) {
  //     savePreferredLanguage(props.preferredLanguage);
  //     console.log(props.preferredLanguage);
  //   }
  // }, [languageLocalStorageChanged]);

  let auth0Cookie = Cookies.get('auth0.is.authenticated');

  return (
    <React.Fragment>
      {isAuthenticated && (
        <React.Fragment>
          <Toolbar className={classes.appBar} style={{ position: 'fixed', width: '100%' }}>
            <Typography className={classes.title} variant="h6" noWrap>
              {!darkMode ? (
                <img id="navbarLogo" src={logo} alt="Logo" />
              ) : (
                <img id="navbarLogo" src={darkModeLogo} alt="Logo" />
              )}
            </Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div id="google_translate_element"></div>
              {/* {!loadingUserSettings ?  */}

              <LanguageDropdown
                history={props.history}
                location={props.location}
                userSettings={props.userSettings}
                isNotLoading={isNotLoading}
                preferredLanguage={props.preferredLanguage}
                setPreferredLanguage={props.setPreferredLanguage}
              />

              <Tooltip title={props.getTranslation('Toggle Dark Mode', props.preferredLanguage)} placement="bottom">
                <FormControlLabel
                  id="label"
                  control={
                    <Switch
                      color="primary"
                      checked={darkMode}
                      onChange={() => {
                        toggleDarkMode(!darkMode);
                      }}
                    />
                  }
                  style={{ marginLeft: '30px' }}
                  label={<Brightness4Icon />}
                  labelPlacement="end"
                />
              </Tooltip>
              {/* style={{ color: theme.palette.action.active }} */}
              {/* : <span></span> } */}
              {props.accessLevel !== 'User' ? (
                <NavLink tag={Link} className="text-dark" to="/Dashboard/Alerts">
                  <IconButton aria-label="new alerts">
                    <Badge badgeContent={alertNumber} color="secondary" style={{ alignItems: 'center' }}>
                      <span id="alertsBadge">
                        {' '}
                        {alertNumber === 0 || alertNumber === undefined
                          ? props.getTranslation('no alerts', props.preferredLanguage)
                          : props.getTranslation('new alerts', props.preferredLanguage)}{' '}
                      </span>{' '}
                      <NotificationsIcon fontSize="large" />
                    </Badge>
                  </IconButton>
                </NavLink>
              ) : null}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleInfoMenuOpen}
                className={classes.buttons}
              >
                <InfoIcon fontSize="large" />
                <span id="alertsBadge">{props.getTranslation('info', props.preferredLanguage)} </span>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                className={classes.buttons}
              >
                <AccountCircle fontSize="large" />
                <span id="alertsBadge">{props.getTranslation('user', props.preferredLanguage)} </span>
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                className={classes.buttons}
              >
                <MoreIcon fontSize="large" />
              </IconButton>
            </div>
          </Toolbar>
          {props.accessLevel != undefined && props.accessLevel === 'AccountAdmin' ? (
            <MSSPHeader
              selectedClient={props.selectedClient}
              defaultClient={props.defaultClient}
              accountAdminData={props.accountAdminData}
              getTranslation={props.getTranslation}
              preferredLanguage={props.preferredLanguage}
            />
          ) : null}
          {renderMobileMenu}
          {renderInfoMenu}
          {renderMenu}
        </React.Fragment>
      )}
      {!isAuthenticated && (
        <div className={classes.grow}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <NavLink tag={Link} className="text-dark" to="/PortalSignIn">
                  <Button>{props.getTranslation('log in', props.preferredLanguage)} </Button>
                </NavLink>
              </div>
              <div className={classes.sectionMobile}>
                <NavLink tag={Link} className="text-dark" to="/PortalSignIn">
                  <Button>{props.getTranslation('log in', props.preferredLanguage)} </Button>
                </NavLink>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </React.Fragment>
  );
};
export default NavBar;

//"react-dom": "16.7.0-alpha.0",
