import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Form, FormGroup, Input, Label, ModalBody } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTour } from '@reactour/tour';
import useForm from '../CustomHooks/useForm';
import basePCUrl from '../../../Globals';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import CircularProgress from '@material-ui/core/CircularProgress';
var api = 'https://zsvz1vz9rg.execute-api.us-west-2.amazonaws.com/prod';

const LinkSafetyRulesModal = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [regexErrorMessage, setRegexErrorMessage] = useState();
  const [isLoading, setLoading] = useState(null);
  const [modal, setModal] = useState(false);

  const [suggestedDomain, setSuggestedDomain] = useState();
  const [suggestedSubdomain, setSuggestedSubdomain] = useState();
  const [finalEntry, setFinalEntry] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  const [entry, setEntry] = useState(props.alertUrl);
  // const resetEntry =()=>{
  //   setEntry('')
  // }

  //const { values: entry, bind: bindEntry, reset: resetEntry } = useForm('');
  const { values: safety, bind: bindSafety, reset: resetSafety } = useForm('');
  const { values: type, bind: bindType, reset: resetType } = useForm('');

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  var suggestedTopLevelDomain

  const handleTextInputChange =(event)=> {
    setEntry(event.target.value)
  }

  const toggle = () => {
    //setModal(!modal);
    props.setIsOpen(!props.isOpen);
    setErrorMessage('');
    setFinalEntry('');
    setSuggestedDomain('');
    //resetEntry();
    resetSafety();
    resetType();
  };

  const toggleClose = () => {
    props.setIsOpen(!props.isOpen);
  };


  const checkDomainAndSubdomain = async () => {
    return new Promise(async (resolve, reject) => {
    //let Regex = /^(?:https?:\/\/)?(?:(?<subdomain>[a-z0-9.-]+)\.)?(?<domain>[a-z0-9.-]+\.[a-z]{2,})(?::(?<port>\d+))?\/?(?<path>[^\s]*)?(?:\?(?<query>[^#\s]*))?(?:#(?<fragment>[^\s]*))?$/
      let Regex = /^(?:(?<protocol>https?:\/\/)|www\.)?(?=(?<fqdn>[^:/]+))(?:(?<service>www|ww\d|cdn|ftp|mail|pop\d?|ns\d?|git)\.)?(?:(?<subdomain>[^:/]+)\.)*(?<domain>[^:/]+\.[a-z0-9]+)(?::(?<port>\d+))?(?<path>\/[^?]*)?(?:\?(?<query>[^#]*))?(?:#(?<hash>.*))?/i


    let regexResult = entry.toString().match(Regex);
      
    if (regexResult != null) {
      let startDomain = regexResult?.groups?.domain?.toString()
      var startSubDomain = regexResult?.groups?.subdomain?.toString()
     //if(regexResult.groups.subdomain != undefined){ var startSubDomain = regexResult.groups.subdomain.toString()}
      if (type === "domain") {
        setSuggestedDomain(startDomain)
        resolve(suggestedTopLevelDomain)
      } else if (type === "subdomain") {
        if(startSubDomain){setSuggestedSubdomain(startSubDomain + "." + startDomain)} else {
          setSuggestedSubdomain(startDomain)
        }
        resolve(suggestedSubdomain)
      } else if (type === "url") {
        resolve(entry)
      }
    } else {
      setRegexErrorMessage("The input you provided is not a valid domain.")
    }
  });
  };

  function getDomainInfo(url) {
    // Use regex to parse the URL
    const matches = /^(https|http|ftp):\/\/(.*?)\//.exec(url + '/');

    if (matches) {
        const parts = matches[2].split('.');
        const tld = parts.pop();
        let host = parts.pop();

        if (tld.length === 2 && host.length <= 3) {
            tld = `${host}.${tld}`;
            host = parts.pop();
        }

        return {
            protocol: matches[1],
            subdomain: parts.join('.'),
            domain: `${host}.${tld}`,
            host,
            tld,
        };
    } else {
        // Handle invalid URL or no match
        return url;
    }
}
  const handleSubmit = async (inputEntry) => {
    return new Promise(async (resolve, reject) => {
      setLoading(true)
      const token = await getAccessTokenSilently();

      if (type === 'domain'|| type === "subdomain") {
        var data = {
          domain: inputEntry,
          safety: safety,
          type: "domain",
        };
      } else if (type === 'url') {
        var data = {
          url: entry,
          safety: safety,
          type: type,
        };
      } 

      if (localStorage.getItem('usid')) {
        let usid = localStorage.getItem('usid');
        var headers = {
          id: props.clientId,
          action: 'addLDR',
          user_id: usid,
          'Content-Type': 'application/json',
        }
      } else {
        var headers = {
          id: props.clientId,
          action: 'addLDR',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }

      fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers,
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            setLoading(false);
            setErrorMessage(data.status);
            resolve(data.status);
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    });
    setFinalEntry('');
  };

  const checkBeforeSubmit = async (event) => {
    event.preventDefault();
    setFinalEntry(entry);
    checkDomainAndSubdomain(entry).then(function (result) {
      if (type === 'domain') {
        if (entry !== '' && result !== '' && entry.toString() !== result) {
          displayErrorMessage();
        } else if (entry === result) {
          handleSubmit(entry);
        } else if (result === '') {
          //console.log('I made it all the way here.');
        }
      } else if (type === 'subdomain') {
        if (entry !== '' && result !== '' && entry.toString() !== result) {
          displayErrorMessage();
        } else if (entry === result) {
          handleSubmit(entry);
        } else if (result === '') {
          //console.log('I made it all the way here.');
        }
      } else if (type === 'url') {
        handleSubmit(entry);
      }

    });
  };

  // useEffect(() => {
  const handleErrorMessage = () => {
    if (errorMessage.includes('AlreadyExists') || entry.length > 1024) {
      displayErrorMessage();
    } else if (type === 'domain' && entry != suggestedDomain) {
      displayErrorMessage();
    } else if (type === 'subdomain' && entry != suggestedSubdomain) {
      displayErrorMessage();
    } else if (errorMessage === 'ok' && type === 'url') {
      toggle();
    } else if (errorMessage === 'ok' && type === 'domain' && finalEntry === suggestedDomain  || errorMessage === 'ok' && type === 'subdomain') {
      toggle();
    } else if (errorMessage === '') {
      return;
    }
  };


  //!!!!----Account for "Not Valid Domain" ?---!!!!!//

  const displayErrorMessage = () => {
    if (errorMessage.includes('AlreadyExists')) {
      return <h5 style={{ color: 'red' }}> {getTranslation("That rule already exists.", preferredLanguage)} </h5>;
    }
    if (entry.length > 1024) {
      return <h5 style={{ color: 'red' }}>{getTranslation("That rule exceeds the 1024 character limit.", preferredLanguage)}</h5>;
    } if (type === 'domain' && finalEntry != '' && suggestedDomain != '' && finalEntry != suggestedDomain) {
      return (
        <div id="confirmDomainBox" style={{ textAlign: 'center', display: !isLoading ? "block" : "none" }}>
          <h6>
          {getTranslation("Did you want to flag everything within this domain", preferredLanguage)}
            : <br />{' '}
            <div style={{ textAlign: 'center' }}>
              <h5>{suggestedDomain} ?</h5>
            </div>{' '}
          </h6>
          <Button color="primary" variant="contained" style={{ width: '55px', marginRight: '20px' }} onClick={() => confirmDomainCorrection()}>
          {getTranslation("Yes", preferredLanguage)}
          </Button>
          <Button color="primary" variant="contained" style={{ width: '55px' }} onClick={() => setSuggestedDomain('')}>
          {getTranslation("No", preferredLanguage)} 
          </Button>
        </div>
      );
    } if (type === 'subdomain' && finalEntry != '' && suggestedSubdomain != '' && finalEntry != suggestedSubdomain) {
      return (
        <div id="confirmDomainBox" style={{ textAlign: 'center', display: !isLoading ? "block" : "none" }}>
          <h6>
          {getTranslation("Did you want to flag everything within this subdomain", preferredLanguage)}
            : <br />{' '}
            <div style={{ textAlign: 'center' }}>
              <h5>{suggestedSubdomain} ?</h5>
            </div>{' '}
          </h6>
          <Button color="primary" variant="contained" style={{ width: '55px', marginRight: '20px' }} onClick={() => confirmDomainCorrection()}>
          {getTranslation("Yes", preferredLanguage)}
          </Button>
          <Button color="primary" variant="contained" style={{ width: '55px' }} onClick={() => setSuggestedSubdomain('')}>
          {getTranslation("No", preferredLanguage)} 
          </Button>
        </div>
      );
    }
  };

  const displayLoader =()=> {
    if (isLoading){
    return( <div style={{ textAlign: 'center', }}>
      <CircularProgress style={{color: "black"}} size={80}/>
      </div>
      )
    }
  }

  //--------Autofill with suggested domain--------//

  const confirmDomainCorrection = () => {
    if (type === "domain") {
    handleSubmit(suggestedDomain).then(function (result) {

      setFinalEntry(suggestedDomain);
      
      if(!result) {
        displayLoader();
      } if (result === 'AlreadyExists') {
        displayErrorMessage();
      } else if (result === 'ok') {
        toggle();
      }
    });
  } else if (type === "subdomain") {
    handleSubmit(suggestedSubdomain).then(function (result) {

      setFinalEntry(suggestedSubdomain);
      
      if(!result) {
        displayLoader();
      } if (result === 'AlreadyExists') {
        displayErrorMessage();
      } else if (result === 'ok') {
        toggle();
      }
    });
  }
  };


  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //-----Designation Modal close button toggle-----//
  const closeBtn = (
    <Button type="submit" outline color="white" onClick={toggleClose}>
      <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
    </Button>
  );

  return (
    <div>

      <Dialog className={styles.root} onClose={toggle} aria-labelledby="customized-dialog-title" open={props.isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={toggle}></DialogTitle>

        <ModalBody>
          <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
          {getTranslation("Add a Rule", preferredLanguage)} 
 
          </Typography>
          <br></br>
          {regexErrorMessage}
          {displayErrorMessage()}
          {displayLoader()}
          <Form id="addSafetyRulesForm" onSubmit={checkBeforeSubmit}>
            <div className="ModalForm">
              <FormGroup
                className="designationRadioButtons"
                check
                style={{ fontSize: '18px', marginLeft: '12px', padding: '20px' }}
              >
                <Input
                  required
                  type="radio"
                  id="addLink"
                  name="type"
                  checked={type === 'url'}
                  {...bindType}
                  value="url"
                  
                />{' '}
                <Label style={{ marginRight: '30px' }} check for="addingLink">
                  URL
                  <Tooltip
                    placement="left"
                    title={
                      <span style={{}}>
                        <div
                          style={{
                            maxWidth: '450px',
                            height: '200px',
                            padding: '18px',
                            overflowWrap: 'break-Word',
                            fontWeight: '300',
                            fontSize: '16px',
                            fontFamily: 'Montserrat, sans-serif',
                            lineHeight: '1',
                          }}
                        >    
                        {getTranslation("Entering a specific URL will determine the safety reading your users will see for that specific url", preferredLanguage)} 

                          .
                          <br />
                          <br />
                          {getTranslation("e.g. if you mark 'https://mail.google.com/' as safe, that won't affect the safety reading for'https://www.google.com/'", preferredLanguage)}
                          
                        </div>
                      </span>
                    }
                    arrow
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'info-circle']} size="xs" />
                    </span>
                  </Tooltip>
                </Label>
                <Input
                  required
                  type="radio"
                  id="addDomain"
                  name="type"
                  checked={type === 'domain'}
                  {...bindType}
                  value="domain"
                  style={{ marginLeft: '80px' }}
                />{' '}
                <Label check for="addingDomain" style={{ marginLeft: '95px' }}>
                {getTranslation("Domain", preferredLanguage)} 
 
                  <Tooltip
                    placement="right"
                    title={
                      <span style={{}}>
                        <div
                          style={{
                            maxWidth: '450px',
                            height: '300px',
                            padding: '18px',
                            overflowWrap: 'break-Word',
                            fontWeight: '300',
                            fontSize: '16px',
                            fontFamily: 'Montserrat, sans-serif',
                            lineHeight: '1',
                          }}
                        >
                          {getTranslation("Entering a domain will determine the safety reading for all links under the entire domain", preferredLanguage)} 
                          .
                          <br />
                          <br />
                          {getTranslation("e.g. if you mark 'reddit.com' as unsafe, then 'https://reddit.com/r/phishing' will aslo be unsafe", preferredLanguage)} 

                           .
                          <br />
                          <br />               
                          {getTranslation(" Domain should be formated with just the host and tld e.g. 'phishcloud.com'", preferredLanguage)} 
{' '}
                        </div>
                      </span>
                    }
                    arrow
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'info-circle']} size="xs" />
                    </span>
                  </Tooltip>
                </Label>
                <Input
                  required
                  type="radio"
                  id="addSubDomain"
                  name="type"
                  checked={type === 'subdomain'}
                  {...bindType}
                  value="subdomain"
                  style={{ marginLeft: '80px' }}
                />{' '}
                <Label check for="addingDomain" style={{ marginLeft: '95px' }}>
                {getTranslation("Subdomain", preferredLanguage)} 

                  <Tooltip
                    placement="right"
                    title={
                      <span style={{}}>
                        <div
                          style={{
                            maxWidth: '450px',
                            height: '300px',
                            padding: '18px',
                            overflowWrap: 'break-Word',
                            fontWeight: '300',
                            fontSize: '16px',
                            fontFamily: 'Montserrat, sans-serif',
                            lineHeight: '1',
                          }}
                        >
                        {getTranslation(" Entering a subdomain will determine the safety reading for all links under only that subdomain", preferredLanguage)} 
                        .
                         
                          <br />
                          <br />
                          {getTranslation("e.g. if you mark 'https://accounts.reddit.com/' as unsafe, then 'reddit.com' will not be affected, only 'accounts.reddit.com' will show as unsafe", preferredLanguage)} 
                          .
                          <br />
                          <br /> 
                          {getTranslation("Subdomains should be formated with the host, tld, and the subdomain(s) e.g. 'portal.phishcloud.com'", preferredLanguage)} 

                          {' '}
                        </div>
                      </span>
                    }
                    arrow
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'info-circle']} size="xs" />
                    </span>
                  </Tooltip>
                </Label>
              </FormGroup>

              <FormGroup style={{ fontSize: '12px', alignContent: 'center', alignItems: 'center' }}>
                <FormControl variant="outlined" style={{ marginBottom: '10px', width: '100%' }}>
                  <TextField
                    //className={classes.root}
                    label="Enter URL/Domain"
                    type="text"
                    required
                    name="entry"
                    id="entry"
                    //multiline
                    //rows={3}
                    value={entry}
                    onChange={(event)=> handleTextInputChange(event)}
                    variant="outlined"
                  />
                </FormControl>
              </FormGroup>

              <FormGroup style={{ fontSize: '12px', marginLeft: '0px', alignContent: 'center', alignItems: 'center' }}>
                <FormControl variant="outlined" style={{ marginBottom: '10px', width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">{getTranslation("Safety Status", preferredLanguage)} 
</InputLabel>
                  <Select
                    {...bindSafety}
                    id="demo-simple-select-outlined"
                    //value={capitalizeSeverity(props.alertDetails.baseAlert.severity)}
                    //onChange={handleChange}
                    required
                    name="safety"
                    label="Safety Status"
                  >
                    <MenuItem value="">
                      <em>{getTranslation("Safety Status", preferredLanguage)}</em>
                    </MenuItem>
                    <MenuItem id="safeSelect" value="safe">
                    {getTranslation("Safe", preferredLanguage)}
                    </MenuItem>
                    <MenuItem id="unknownSelect" value="unknown">
                    {getTranslation("Unknown", preferredLanguage)}
                    </MenuItem>
                    <MenuItem id="unsafeSelect" value="unsafe">
                    {getTranslation("Unsafe", preferredLanguage)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </div>

            <div className="addDesignationButton">
              <Button type="submit" size="lg" color="primary" variant="contained" fullWidth="true">
              {getTranslation("Add", preferredLanguage)}
              </Button>{' '}
            </div>
          </Form>
        </ModalBody>
      </Dialog>
      {handleErrorMessage()}
    </div>
  );
};
export default LinkSafetyRulesModal;