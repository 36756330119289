import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faUser, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckSquare,
  faCoffee,
  faCog,
  faTachometerAlt,
  faBell,
  faChartLine,
  faUserFriends,
  faSlidersH,
  faQuestionCircle,
  faQuestion,
  faLock,
  faCheck,
  faUsers,
  faLink,
  faBars,
  faPlus,
  faSearch,
  faEnvelope,
  faFrownOpen,
  faInfoCircle,
  faFilter,
  faStar,
  faStarHalfAlt,
  faExclamationTriangle,
  faHistory,
  faTrafficLight,
  faEye,
  faMousePointer,
  faFlag,
  faUserCircle,
  faPlusSquare,
  faTimes,
  faUserClock,
  faExclamation,
  faExternalLinkAlt,
  faChartBar,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';

function initFontAwesome() {
  library.add(
    fab,
    faCheckSquare,
    faCoffee,
    faUserCircle,
    faCog,
    faTachometerAlt,
    faBell,
    faChartLine,
    faUserFriends,
    faSlidersH,
    faUser,
    faQuestionCircle,
    faQuestion,
    faLock,
    faCheck,
    faUsers,
    faLink,
    faBars,
    faPlus,
    faSearch,
    faEnvelope,
    faFrownOpen,
    faCircle,
    faInfoCircle,
    faFilter,
    faStar,
    faStarHalfAlt,
    faStarRegular,
    faExclamationTriangle,
    faHistory,
    faTrafficLight,
    faMousePointer,
    faEye,
    faFlag,
    faPlusSquare,
    faTimes,
    faUserClock,
    faExclamation,
    faExternalLinkAlt,
    faChartBar,
    faArrowLeft
  );
}

export default initFontAwesome;
