import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { DateCreateTimeStamp } from './../DateCreate/DateCreateTimeStamp';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RefreshIcon from '@material-ui/icons/Refresh';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Row, Col, Button } from 'reactstrap';
import AlertsMockData from './AlertsMockData';
import DeleteAlertModal from './DeleteAlertModal';
import basePCUrl from '../../../Globals';
var baseUrl = basePCUrl + 'Alerts/GetCompanyAlerts';

const EmptyAlertsTable = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const [selected, setSelected] = React.useState([]);

  const [alertDeleteCheckModal, setAlertDeleteCheckModal] = useState(false);
  const toggleAlertDeleteCheck = () => setAlertDeleteCheckModal(!alertDeleteCheckModal);

  const refreshTable = () => {
    props.refreshAlerts();
    console.log('Refreshing table!');
  };

  //console.log(props.alertData);

  ////------Normal Sorting Logic------////
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const headCells = [
    //{ id: 'priorityFill', numeric: false, disablePadding: true, label: '' },
    //{ id: 'dateCreated', numeric: false, disablePadding: false, label: 'Date Created' },
    { id: 'severity', numeric: false, disablePadding: false, label: 'Severity' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'lastUpdated', numeric: false, disablePadding: false, label: 'Last Updated' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'incident', numeric: false, disablePadding: false, label: 'Incident' },
    { id: 'user', numeric: false, disablePadding: false, label: 'User' },
    { id: 'url', numeric: false, disablePadding: false, label: 'URL' },
    //{ id: 'alertsDetail', numeric: false, disablePadding: false, label: 'Details' },
  ];

  const useHeaderStyles = makeStyles((theme) => ({
    tableHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 80,

      '@media(max-width:830px)': {
        fontSize: 10,
        paddingTop: 0,
        paddingBottom: 0,
        width: 50,
      },
    },
    detailsHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 50,
      textAlign: 'right',
      '@media(max-width:830px)': {
        fontSize: 10,
        width: 45,
        padding: 5,
      },
    },
    colorHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 20,
      '@media(max-width:820px)': {
        width: 15,
      },
      padding: 0,
    },
    checkboxHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 30,
      '@media(max-width:820px)': {
        width: 25,
      },
      padding: 0,
    },
    zeroHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      width: 0,
      padding: 0,
    },
  }));

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headerClasses = useHeaderStyles();

    return (
      <TableHead>
        <TableRow>
          <TableCell
            className={headerClasses.colorHeader}
            key={headCells[0].id}
            align={headCells[0].numeric ? 'right' : 'left'}
            //padding={headCells[0].disablePadding ? 'none' : 'default'}
            //sortDirection={orderBy === headCells[0].id ? order : false}
          ></TableCell>{' '}
          <TableCell
            className={headerClasses.zeroHeader}
            key={headCells[0].id}
            align={headCells[0].numeric ? 'right' : 'left'}
            //padding={headCells[0].disablePadding ? 'none' : 'default'}
            //sortDirection={orderBy === headCells[0].id ? order : false}
          ></TableCell>
          <TableCell className={headerClasses.checkboxHeader} padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rules' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className={headerClasses.tableHeader}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell className={headerClasses.detailsHeader}>Details</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      //paddingLeft: theme.spacing(2),
      //paddingRight: theme.spacing(1),
      '@media(max-width:830px)': {
        height: '25px',
      },
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
    icons: {
      '@media(max-width:830px)': {
        fontSize: 25,
      },
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle"></Typography>
        )}
        <Tooltip title="Refresh">
          <IconButton className={classes.icons} onClick={refreshTable} aria-label="delete">
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>

        {numSelected === 0 ? (
          <IconButton className={classes.icons} disabled aria-label="delete">
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className={classes.icons} onClick={toggleAlertDeleteCheck} aria-label="delete">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '@media(max-width:600px)': {
        width: '80%',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      fontFamily: 'Montserrat, sans-serif',
    },
    container: {
      maxHeight: 500,
      '@media(max-width:600px)': {
        maxHeight: '200px',
      },
    },
    table: {
      minWidth: 750,
      '@media(max-width:820px)': {
        minWidth: 550,
      },
    },
    tableCell: {
      fontFamily: 'Montserrat, sans-serif',
      paddingLeft: 6,
      paddingRight: 6,
      '@media(max-width:820px)': {
        fontSize: '.75rem',
      },
    },
    tableRow: {
      width: '100%',
    },
    detailsCell: {
      fontFamily: 'Montserrat, sans-serif',
      width: 80,
    },
    alertsHIGH: {
      color: 'red',
      fontWeight: 600,
    },
    alertsMED: {
      color: '#F1C411',
      fontWeight: 600,
    },
    alertsLOW: {
      color: 'dodgerblue',
      fontWeight: 600,
    },
    priorityFillHigh: {
      backgroundColor: 'red',
      width: 15,
      height: 'auto',
      marginLeft: 0,
      paddingLeft: 0,
    },
    priorityFillMed: {
      backgroundColor: '#F1C411',
      width: 15,
      height: 'auto',
      marginLeft: 0,
      paddingLeft: 0,
    },
    priorityFillLow: {
      backgroundColor: 'dodgerblue',
      width: 15,
      height: 'auto',
      marginLeft: 0,
      paddingLeft: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('lastUpdated');

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="Alert Table"
            style={{ tableLayout: 'auto' }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              //onRequestSort={handleRequestSort}
              rowCount={1}
            />

            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell padding="checkbox"></TableCell>

                <TableCell align="left" className={classes.tableCell}></TableCell>
                <TableCell align="left" className={classes.tableCell}></TableCell>
                <TableCell align="left" className={classes.tableCell}></TableCell>
                <TableCell align="left" className={classes.tableCell}></TableCell>
                <TableCell align="left" className={classes.tableCell}></TableCell>
                <TableCell align="left" className={classes.tableCell}></TableCell>
                <TableCell align="left" className={classes.tableCell}></TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {' '}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          //rowsPerPageOptions={[10]}
          component="div"
          count={1}
          rowsPerPage={1}
          page={0}
          // onChangePage={handleChangePage}
          //onChangeRowsPerPage={handleChangeRowsPerPage}
          //className={classes.tableCell}
        />
      </Paper>
    </div>
  );
};
export default EmptyAlertsTable;
