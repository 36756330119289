import { useState, useEffect } from 'react';

const useCheckPermissionsForAPICalls = (accessLevel) => {
  const [permissionsForAPICallsValues, setPermissionsForAPICallsValues] = useState({
    requestType: '',
    callRoute: '',
    body: 'body: JSON.stringify(data),',
  });

  useEffect(() => {
    if (accessLevel === 'AccountAdmin') {
      setPermissionsForAPICallsValues({
        requestType: 'POST',
        callRoute: 'AccountClient',
      });
    } else if (accessLevel === 'CompanyAdmin') {
      setPermissionsForAPICallsValues({
        requestType: 'GET',
        callRoute: '',
      });
    }
  }, [accessLevel]);

  return permissionsForAPICallsValues;
};
export default useCheckPermissionsForAPICalls;
