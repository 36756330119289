import { get } from 'jquery';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import translateText from './translateText';

function TranslationModule(props) {
  const [translatedText, setTranslatedText] = useState('');
  const { getAccessTokenSilently } = useAuth0();


//console.log(props)


  useEffect(() => {
   //console.log(props.targetLanguage)
    const fetchTranslation = async () => {
        //console.log(props.targetLanguage)
        
        if (props.targetLanguage === "EN-US" || !props.targetLanguage) {
            setTranslatedText(props.text)
        } else {
      try {
        const token = await getAccessTokenSilently();
        const translation = await translateText(props.text, props.targetLanguage, token);
        //console.log(translation)
        setTranslatedText(translation);
      } catch (error) {
        console.error('Translation error:', error);
        setTranslatedText(props.text); // Display the original text if translation fails
      }
    }
    };

    fetchTranslation();
  }, [getAccessTokenSilently != undefined]);

//console.log(translatedText)

  return (
      <React.Fragment>
   {!translatedText ? <span></span> : <span>{translatedText}</span>}
   </React.Fragment>
  );
}

export default TranslationModule;