import { useEffect } from 'react';

const useChangePageTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = title;
    };
  }, []);
};

export default useChangePageTitle;
