import React, { useState, useEffect } from 'react';
import AutocompleteCheckboxes from './AutoCompleteCheckboxes';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const FilteredSelection = ({setList, clearFields, list, data}) => {
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [manualSearch, setManualSearch] = useState('');

  const deptValues = data?.map(item => item.dept);
  const uniqueDeptValues = [...new Set(deptValues.filter(value => value !== 'not available'))];
  const isDeptDisabled = uniqueDeptValues.length === 0;

  useEffect(() => {
    if(clearFields) {
      setSelectedKey('')
      setSelectedValue('')
      setManualSearch('')
    } 
  }, [clearFields]);

  // console.log(selectedKey)
  // console.log(selectedValue)

  const uniqueKeys = Array.from(new Set(data?.map(item => item[selectedKey])));

  //console.log(uniqueDeptValues)
  //console.log(uniqueKeys)


  const keyLabels = {
    id: 'Email',
    dept: 'Department',
    geo: 'Location',
  };



  const handleKeyChange = (e) => {
    setSelectedKey(e.target.value);
    setSelectedValue('');
  };

  const handleValueChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleManualSearch = (e) => {
    setManualSearch(e.target.value);
  };

  if(selectedKey !== "All" && selectedKey !== "id") { 
    var filteredOptions = data.filter(
    item =>
      item[selectedKey] === selectedValue //||
      //item.id.toLowerCase().includes(manualSearch.toLowerCase())
  ); }  else {
    var filteredOptions = data
  }


  return (
    <div>
        <FormControl style={{margin: "20px"}} variant="outlined">
        <InputLabel>Category</InputLabel>
        <Select
          value={selectedKey} onChange={handleKeyChange}
          label="Category"
          style={{ width: 500 }}
        >
        {!isDeptDisabled ? Object.keys(data[0]).map(key => (
            <MenuItem key={key} value={key}>
              {keyLabels[key] || key}
            </MenuItem>
          )) : Object.keys(data[0]).map(key => (
            key !== "dept" ? <MenuItem key={key} value={key}>
              {keyLabels[key] || key}
            </MenuItem>: null
          ))}
        </Select>
      </FormControl>

      {/* <label>
        Select {keyLabels[selectedKey]}:
        <select value={selectedValue} onChange={handleValueChange}>
          <option value="">All</option>
          {uniqueKeys.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </label> */}
      <br/>

{selectedKey !== "id" && selectedKey !== "All" ?
        <FormControl style={{margin: "20px"}} variant="outlined">
        <InputLabel>Select {keyLabels[selectedKey]}</InputLabel>
        <Select  
          value={selectedValue} onChange={handleValueChange}
          label={"Select " + keyLabels[selectedKey]}
          style={{ width: 500 }}>


          {uniqueKeys.map(value => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
    </Select>
      </FormControl>
       : null }
<br/>

        <AutocompleteCheckboxes setList={setList} filteredOptions={filteredOptions} />

    </div>
  );
};

export default FilteredSelection;