import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import './SignUp.css';



const SignUpPage = (props) => {
  const [code, setCode] = useState("no code yet");

  const [email, setEmail] = useState();
  const [companyName, setCompanyName] = useState();
  const [installType, setInstallType] = useState();
  const [status, setStatus] = useState();
  const [errorMessage, setErrorMessage] = useState('errorMessage');
  const [password, setPassword] = useState();

  const [errorPasswordList, setErrorPasswordList] = useState([]);
  const [errorPassword, setErrorPassword] = useState(null);

  const [digits, setDigits] = useState();
  const [min, setMin] = useState();
  const [uppercase, setUppercase] = useState();
  const [lowercase, setLowercase] = useState();
  const [spaces, setSpaces] = useState();
  const [symbols, setSymbols] = useState();

  //const [formState, setFormState] = React.useState({

  //    password: '',
  //    verifyPassword: ''
  //});

  //const {
  //    password,
  //    verifyPassword
  //} = formState;

  var passwordValidator = require('password-validator');
  var schema = new passwordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 digits
    .has()
    .not()
    .spaces() // Should not have spaces
    .has()
    .symbols(1);
  //.is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values

  const handlePasswordChange = (event) => {
    if (
      event.target.value != undefined &&
      event.target.value != null &&
      event.target.value != ' ' &&
      event.target.name === 'password'
    ) {
      let enteredPassword = event.target.value;

      if (enteredPassword != undefined && schema.validate(enteredPassword)) {
        setPassword(event.target.value);
        setErrorPassword(false);
        setErrorPasswordList([]);
      } else if (enteredPassword != undefined && !schema.validate(enteredPassword)) {
        //console.log(schema.validate(enteredPassword, { list: true }))
        setErrorPasswordList(schema.validate(enteredPassword, { list: true }));

        setErrorPassword(true);
      } else if (event.target.name === 'password' && event.target.value.length === 0) {
        setErrorPassword(false);
        setErrorPasswordList([]);
        setSpaces(false);
      }
    }
  };

  const showPasswordRulesNotMet = [];

  // --------Password Rules Tooltip--------//
  useEffect(() => {
    {
      if (errorPasswordList != [] && !errorPasswordList.includes('digits')) {
        setDigits(true);
      } else if (errorPasswordList.includes('digits')) {
        setDigits(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('min')) {
        setMin(true);
      } else if (errorPasswordList.includes('min')) {
        setMin(false);
      }
    }
    {
      if (errorPasswordList != [] && !errorPasswordList.includes('uppercase')) {
        setUppercase(true);
      } else if (errorPasswordList.includes('uppercase')) {
        setUppercase(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('lowercase')) {
        setLowercase(true);
      } else if (errorPasswordList.includes('lowercase')) {
        setLowercase(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('spaces')) {
        setSpaces(true);
      } else if (errorPasswordList.includes('spaces')) {
        setSpaces(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('symbols')) {
        setSymbols(true);
      } else if (errorPasswordList.includes('symbols')) {
        setSymbols(false);
      }
    }
  }, [errorPasswordList.length]);

  var finalPasswordList = (
    <List>
      {digits === false ? (
        <ListItem style={{ backgroundColor: '#C00118' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain as least one number
        </ListItem>
      ) : digits === true ? (
        <ListItem style={{ backgroundColor: '#2ECC40' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" /> must contain as least one number
        </ListItem>
      ) : (
        <ListItem>must contain as least one number</ListItem>
      )}
      {min === false ? (
        <ListItem style={{ backgroundColor: '#C00118' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain least eight characters
        </ListItem>
      ) : min === true ? (
        <ListItem style={{ backgroundColor: '#2ECC40' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain least eight characters
        </ListItem>
      ) : (
        <ListItem>must contain least eight characters</ListItem>
      )}
      {uppercase == false ? (
        <ListItem style={{ backgroundColor: '#C00118' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain at least one uppercase letter
        </ListItem>
      ) : uppercase === true ? (
        <ListItem style={{ backgroundColor: '#2ECC40' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain at least one uppercase letter
        </ListItem>
      ) : (
        <ListItem>must contain at least one uppercase letter</ListItem>
      )}
      {lowercase === false ? (
        <ListItem style={{ backgroundColor: '#C00118' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain at least one lowercase letter
        </ListItem>
      ) : lowercase === true ? (
        <ListItem style={{ backgroundColor: '#2ECC40' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain at least one lowercase letter
        </ListItem>
      ) : (
        <ListItem>must contain at least one lowercase letter</ListItem>
      )}
      {symbols === false ? (
        <ListItem style={{ backgroundColor: '#C00118' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain at least one special character
        </ListItem>
      ) : symbols === true ? (
        <ListItem style={{ backgroundColor: '#2ECC40' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain at least one special character
        </ListItem>
      ) : (
        <ListItem>must contain at least one special character</ListItem>
      )}
      {spaces === false ? (
        <ListItem style={{ backgroundColor: '#C00118' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain no spaces
        </ListItem>
      ) : spaces === true ? (
        <ListItem style={{ backgroundColor: '#2ECC40' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain no spaces
        </ListItem>
      ) : (
        <ListItem>must contain no spaces</ListItem>
      )}
    </List>
  );

  const [openTooltip, setOpenTooltip] = React.useState(false);
  var activeTextarea = document.activeElement;

  const checkPasswordActiveField = () => {
    if (activeTextarea.id === 'password' && errorPassword === true && openTooltip === false) {
      setOpenTooltip(true);
    } else if (activeTextarea.id === 'password' && errorPassword === false) {
      setOpenTooltip(false);
    }
  };
  
  useEffect(() => {
    checkPasswordActiveField();
  }, [errorPasswordList.length]);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      //backgroundColor: '#f5f5f9',
      //backgroundColor: theme.palette.action.disabledBackground,
      //color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const [termsCheckbox, setTermsCheckbox] = useState(false);

  const onCheckboxChange = () => {
    setTermsCheckbox(!termsCheckbox);
  };

  const pushToSuccessPage = () => {
    props.history.push({
      pathname: '/SignUp/Verify',
      search: '?pad=&signUpEmail=' + email,
    });
    //window.location.reload()
  };

  const pushToErrorPage = () => {
    props.history.push('/Error');
  };

  const updatePasswordFromCode = (event) => {
    // const token = await getAccessTokenSilently();
    event.preventDefault();

    var data = {
      email: `${email}`,
      password: `${password}`,
    };

    fetch('https://zsvz1vz9rg.execute-api.us-west-2.amazonaws.com/prod', {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        action: 'createSecurityUser',
        'content-type': 'application/json',
        //testacct: uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          if (data === true) {
            setStatus(data.status);
            pushToSuccessPage();
          }
          // else if (data.status === null && data.password === null && data.code === null) {
          //     pushToSuccessPage();
          // } else if (data.status === null) {
          //     setErrorMessage(null)
          // }
          else if (data !== true) {
            setErrorMessage(data.status);
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  const retrieveEmail = () => {
    // const token = await getAccessTokenSilently();

    var data = {
      code: `${code}`,
    };

    fetch('https://zsvz1vz9rg.execute-api.us-west-2.amazonaws.com/prod', {
      method: 'POST',
      body: JSON.stringify(data),
      //credentials: "same-origin",
      headers: {
        //Authorization: `Bearer ${token}`,
        testacct: '00000-BNzB446fHiQbew5',
        action: 'transformCode',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          if (data.accessLevel === 'Error') {
            console.log('Error');
          } else {
            setEmail(data.email);
            setCompanyName(data.companyName);
            setInstallType(data.installType);
            setStatus(data.status);
            //console.log(data);

            //console.log("I set the email!")
            if (data.status === "this code doesn't exist") {
              setTimeout(function () {
                var errorDiv = document.getElementById('Error');
                if (errorDiv != null) errorDiv.style = '';
              }, 2000);
            }
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const sendResetPasswordEmail = () => {
    var options = {
      method: 'POST',
      //url: 'https://phishcloud.auth0.com/dbconnections/change_password',
      headers: { 'content-type': 'application/json' },
      data: {
        client_id: 'Nrhxmnw0IvHkbgbGHKO6KUslAkTyhLNZ',
        email: 'leese_38@yahoo.com',
        connection: 'Username-Password-Authentication',
      },
    };

    fetch('https://phishcloud.auth0.com/dbconnections/change_password', options).then((response) => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' + response.status);
        return;
      }

      response.json().then((data) => {
        return;
      });
    });
  };

  const setEmailAgain = () => {
    setTimeout(function () {
      if (document.getElementById('Success') === null) {
        if (code !== undefined && code != null) {
          retrieveEmail();
        } else {
          setEmailAgain();
        }
      }
    }, 2000);
  };

  useEffect(() => {
    let parsedQuery = queryString.parse(props.location.search);
    let parsedCode = parsedQuery.code;
    setCode(parsedCode);
    //setEmailAgain();
  }, []);

  useEffect(() => {
    if (code != "no code yet"){
    retrieveEmail();
//console.log(code)
    //setEmailAgain();
    //lock.show();
    }
  }, [code != "no code yet"]);

  useEffect(() => {
    if (installType !== undefined) {
      setCookie('installType', installType, 3);
      localStorage.setItem('installType', installType);
    }
  }, [installType]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const ErrorMessageAlert = () => {
    const classes = useStyles();
    if (errorMessage != undefined && errorMessage.includes('link already used')) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorMessage('errorMessage')}>
            {errorMessage} <a href="https://portal.phishcloud.io">Sign in here</a>{' '}
          </Alert>
        </div>
      );
    } else if (errorMessage != undefined && errorMessage.includes('link expired')) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorMessage('errorMessage')}>
            {errorMessage} . Please contact your administrator.
          </Alert>
        </div>
      );
    } else if (errorMessage != undefined && errorMessage.includes('user not found')) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorMessage('errorMessage')}>
            {errorMessage} . Please contact your administrator.
          </Alert>
        </div>
      );
    } else if (errorMessage != undefined && errorMessage.includes('Error: Generic')) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorMessage('errorMessage')}>
            Oops! We're sorry. There was an error.
          </Alert>
        </div>
      );
    } else if (errorMessage === undefined || errorMessage === null) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorMessage('errorMessage')}>
            Oops! We're sorry. There was an error.
          </Alert>
        </div>
      );
    } else if (errorMessage === 'errorMessage') {
      return null;
    }
  };

  return (
    <div id="SignUpBody">
      {email !== undefined ? <h2 id="Success">Welcome {email}</h2> : <h2 id="loading">Welcome ...</h2>}

      <p>You have been registered for PhishCloud with the {companyName} account.</p>

      {installType === 'installed' ? (
        <p>It looks like you've already set up and used your account. Please reset your password and login.</p>
      ) : null}

      {installType !== 'installed' ? (
        <div>
          <p>
            {' '}
            Please create an account password and agree to the PhishCloud{' '}
            <a style={{ marginLeft: '5px' }} target="_blank" href="https://www.phishcloud.com/terms-of-service/">
              Terms and Conditions
            </a>
            .
          </p>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <form id="createPasswordForm" onSubmit={updatePasswordFromCode}>
            <Paper id="signupFieldset">
             {installType != "manualSSO" ? <label>
                <HtmlTooltip
                  title={finalPasswordList}
                  open={openTooltip}
                  onOpen={() => setOpenTooltip(true)}
                  onClose={() => setOpenTooltip(false)}
                  disableHoverListener
                  placement="left"
                  arrow
                >
                  {errorPassword === true ? (
                    <span id="textfieldTitlePassword">
                      Password
                      <span style={{ color: 'red', padding: '5px' }}>
                        <LockOutlinedIcon />
                      </span>
                    </span>
                  ) : errorPassword === false ? (
                    <span id="textfieldTitlePassword">
                      Password
                      <span style={{ color: 'green', padding: '5px' }}>
                        <LockOutlinedIcon />
                      </span>
                    </span>
                  ) : errorPassword === null ? (
                    <span id="textfieldTitlePassword">
                      Password
                      <span style={{ padding: '5px' }}>
                        <LockOutlinedIcon />
                      </span>
                    </span>
                  ) : (
                    <span></span>
                  )}
                </HtmlTooltip>

                <TextField
                  name="password"
                  id="password"
                  class="field"
                  type="password"
                  placeholder="Create Password"
                  className="signupInputPassword"
                  onChange={handlePasswordChange}
                  //InputProps={{ disableUnderline: true }}
                  required
                />
              </label>
              : null}

              <label>
                <input
                  name="termsCheckbox"
                  style={{ marginRight: '15px', marginTop: '5px' }}
                  class="field"
                  type="checkbox"
                  //placeholder="Password"
                  value={termsCheckbox}
                  onChange={onCheckboxChange}
                  required
                />
                Yes, I agree to PhishCloud{' '}
                <a style={{ marginLeft: '5px' }} target="_blank" href="https://www.phishcloud.com/terms-of-service/">
                  {' '}
                  Terms and Conditions
                </a>
                .
              </label>
            </Paper>

            {schema.validate(password) && termsCheckbox && password != '' || termsCheckbox && installType === "manualSSO" ? (
              <Button style={{ backgroundColor: '#0055A2', color: '#fff' }} id="signupButton" type="submit">
                Finish Registration
              </Button>
            ) : schema.validate(password) && !termsCheckbox && password != '' || installType === "manualSSO" ? (
              <Button disabled style={{ backgroundColor: '#b5b5b5', color: '#fff' }} id="signupButton">
                Accept Terms and Conditions
              </Button>
            ) : (
              <Button disabled style={{ backgroundColor: '#b5b5b5', color: '#fff' }} id="signupButton">
                Create New Password
              </Button>
            )}
          </form>
        </div>
      ) : null}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ErrorMessageAlert />
      <br />
      <br />
      <br />

      {installType === 'installed' ? null : (
        <p style={{ fontSize: '12px' }}>
          {/* Already registered? Log in to the user Portal <a href="https://portal.phishcloud.io/">here</a>. */}
        </p>
      )}
    </div>
  );
};
export default SignUpPage;

//    < HtmlTooltip title = { finalPasswordList }
//open = { openTooltip }
//onOpen = {() => setOpenTooltip(true)}
//onClose = {() => setOpenTooltip(false)}
//disableHoverListener
//placement = "left"
//arrow >
//    { errorPassword === true ? <span id="textfieldTitle">Password<span style={{ color: 'red', padding: '5px' }}><LockOutlinedIcon /></span></span> : errorPassword === false ? <span id="textfieldTitle">Password<span style={{ color: 'green', padding: '5px' }}><LockOutlinedIcon /></span></span> : <span id="textfieldTitle">Password<span style={{ padding: '5px' }}><LockOutlinedIcon /></span></span>}
//                    </HtmlTooltip >

//    < HtmlTooltip title = { finalVerifyPasswordList }
//open = { openVerifyTooltip }
//onOpen = {() => setOpenVerifyTooltip(true)}
//onClose = {() => setOpenVerifyTooltip(false)}
//disableHoverListener
//placement = "left"
//arrow >
//    { errorVerifyPassword === true ? <span id="textfieldTitle">Verify Password<span style={{ color: 'red', padding: '5px' }}><LockOutlinedIcon /></span></span> : errorVerifyPassword === false ? <span id="textfieldTitle">Verify Password<span style={{ color: 'green', padding: '5px' }}><LockOutlinedIcon /></span></span> : <span id="textfieldTitle">Verify Password<span style={{ padding: '5px' }}><LockOutlinedIcon /></span></span>}
//                    </HtmlTooltip >

// <p>You may currently sign up with your <FontAwesomeIcon id="BrandLogos" icon={faGoogle} /> Google or <FontAwesomeIcon id="BrandLogos" icon={faMicrosoft} /> Microsoft accounts.</p>
//onClick = {() => loginWithRedirect({})}
//and < a target = "_blank" href = "https://www.phishcloud.com/privacy-policy/" > privacy policy</a >.

//{ status === "ok" ? lock.show() : <div id="Error" style={{ display: "none" }}><br /><br />Something Went Wrong... If you are the account admin, please contact < a href="mailto: support@phishcloud.com" > support@phishcloud.com</a >. <br /> If you are a user, please ask your admin to send another activation email. </div> }
//<div id='login-container'></div>

//var options = {
//    allowForgotPassword: false,
//    allowShowPassword: true,
//    allowSignUp: true,
//    allowLogin: false,
//    mustAcceptTerms: true,
//    rememberLastLogin: false,
//    container: 'login-container',

//    languageDictionary: {
//        emailInputPlaceholder: "your email",
//        title: "Log in to PhishCloud",
//        signUpTerms: 'Yes, I agree to PhishCloud <a target="_blank" href="https://www.phishcloud.com/terms-of-service/"> Terms and Conditions</a>. ',
//        //error: {
//        //    signUp: {
//        //        "invalid_password": "Custom message indicating a password was invalid",
//        //        "user_exists": "Custom message indicating that a user already exists"
//        //    }
//        //}
//    },
//    prefill: {
//        email: email
//    },
//    theme: {
//        logo: phishcloudIcon,
//        primaryColor: '#31324F',

//    },
//    auth: {
//        audience: 'Nrhxmnw0IvHkbgbGHKO6KUslAkTyhLNZ',
//        autoParseHash: true,
//        redirectUrl: 'https://portal.phishcloud.io/Signup/Result?pad=&signUpEmail=' + email + '&code=' + code,
//        responseType: 'token',
//        params: {
//            scope: 'openid email' // Learn about scopes: https://auth0.com/docs/scopes
//        },
//        sso: true
//    },
//};

//var lock = new Auth0Lock(
//    'Nrhxmnw0IvHkbgbGHKO6KUslAkTyhLNZ',
//    'login.phishcloud.io',
//    options
//);
