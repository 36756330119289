import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Form, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, DatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'querystring';
import useForm from '../CustomHooks/useForm';
import './Alerts.css';
import basePCUrl from '../../../Globals';
const baseUrl = basePCUrl + 'alerts/EditAlert';

const EditAlertModal = (props) => {
  const { values: id, bind: bindId, reset: resetId } = useForm('');
  const { values: status, bind: bindStatus, reset: resetStatus } = useForm('');
  const { values: owner, bind: bindOwner, reset: resetOwner } = useForm('');
  const { values: severity, bind: bindSeverity, reset: resetSeverity } = useForm('');
  const { values: comments, bind: bindComments, reset: resetComment } = useForm('');
  //const { values: sla, bind: bindSla, reset: resetSla } = useForm('');

  const { getAccessTokenSilently } = useAuth0();
  const [modal, setModal] = useState(false);
  const [finalSla, setFinalSla] = useState();
  const toggle = () => {
    setModal(!modal);
  };

  let preferredLanguage = props.preferredLanguage
  let getTranslation = props.getTranslation

  const alertDetailPush = (alertId) => {
    props.history.push('?pad=&id=' + alertId);
  };

  let alertID = props.id;

  const alertTablePush = () => {
    if (status === 'Closed') {
      props.history.push('/Dashboard/Alerts');
    } else if (status != 'Closed') {
      alertDetailPush(alertID);
    }
  };

  //----------Edit Alert API Call----------//
  const saveAlertEdits = async (event) => {
    event.preventDefault();

    const token = await getAccessTokenSilently();

    var data = {
      id: props.id,
      status: status,
      owner: owner,
      severity: severity,
      comments: comments,
      sla: sla,
    };

    fetch(baseUrl, {
      credentials: 'same-origin',
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then((data) => {});
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
    props.toggle();
    setTimeout(() => {
      alertTablePush();
    }, 2000);
  };

  //-------Styling for Severity Input Placeholder------//

  const priorityColorCheck = (value) => {
    if (value === '3: high') {
      return classes.alertsHIGH;
    } else if (value === '2: med') {
      return classes.alertsMED;
    } else if (value === '1: low') {
      return classes.alertsLOW;
    }
  };

  const capitalizeSeverity = (value) => {
    return value.toUpperCase();
  };

  //--------SLA Date Picker Logic-------//
  const [sla, setSla] = React.useState(new Date());
  const handleDateChange = (date) => {
    setSla(date);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      //width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //---------Material UI Text Field Styling--------//
  const useStyles = makeStyles((theme) => ({
    root: {
      //margin: theme.spacing(1),
      width: '100%',
    },
  }));

  const classes = useStyles();

  //--------Modal Close Button--------//

  return (
    <Paper id="editAlertModalContainer">
      <Dialog
        contentClassName="editAlertModal"
        maxWidth={'sm'}
        isOpen={props.isOpen}
        toggle={props.toggle}
        open={props.isOpen}
      >
        <DialogTitle onClose={props.toggle} />
        <ModalBody>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
          {getTranslation("Edit Alert", preferredLanguage)}
          </Typography>
          <br />
          <Form id="addUserForm" onSubmit={saveAlertEdits}>
            <Grid containter spacing={3} container direction="row" justify="center" alignItems="center">
              <Grid item xs={6}>
                <h5>{getTranslation("Current status", preferredLanguage)} :</h5>
                <h5> {getTranslation(props.alertDetails.status, preferredLanguage)}</h5>
                <br />
                <FormControl
                  variant="outlined"
                  style={{ marginBottom: '10px', width: '100%' }}
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">{getTranslation("Status Options", preferredLanguage)}</InputLabel>
                  <Select
                    {...bindStatus}
                    id="demo-simple-select-outlined"
                    //value={capitalizeSeverity(props.alertDetails.baseAlert.severity)}
                    //onChange={handleChange}
                    required
                    name="alertSeverity"
                    label="Alert Severity"
                  >
                    <MenuItem value="">
                      <em>{getTranslation("None", preferredLanguage)}</em>
                    </MenuItem>
                    <MenuItem value="OnHold">{getTranslation("On Hold", preferredLanguage)}</MenuItem>
                    <MenuItem value="Acknowledged">{getTranslation("Acknowledged", preferredLanguage)}</MenuItem>
                    <MenuItem value="InProgress">{getTranslation("In Progress", preferredLanguage)}</MenuItem>
                    <MenuItem value="Closed">{getTranslation("Closed", preferredLanguage)}</MenuItem>
                    <MenuItem value="Archived">{getTranslation("Archived", preferredLanguage)}</MenuItem>
                  </Select>
                </FormControl>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    allowKeyboardControl
                    value={sla}
                    placeholder={new Date()}
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="SLA"
                    variant="inline"
                    onChange={(date) => handleDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ marginBottom: '10px', width: '100%' }}>
                  <TextField
                    className={classes.root}
                    id="outlined-multiline-static"
                    label={getTranslation("Owner", preferredLanguage)}
                    multiline
                    rows={1}
                    placeholder={props.alertDetails.userName}
                    variant="outlined"
                    {...bindOwner}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: '10px', width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">{getTranslation("Severity", preferredLanguage)}</InputLabel>
                  <Select
                    {...bindSeverity}
                    id="demo-simple-select-outlined"
                    //value={capitalizeSeverity(props.alertDetails.baseAlert.severity)}
                    //onChange={handleChange}
                    required
                    name="alertSeverity"
                    label="Severity"
                  >
                    <MenuItem value="">
                      <em>{getTranslation("None", preferredLanguage)}</em>
                    </MenuItem>
                    <MenuItem value="1: low" style={{ color: 'dodgerblue' }}>
                      1: {getTranslation("LOW", preferredLanguage)}
                    </MenuItem>
                    <MenuItem value="2: med" style={{ color: '#F1C411' }}>
                      2: {getTranslation("MEDIUM", preferredLanguage)}
                    </MenuItem>
                    <MenuItem value="3: high" style={{ color: 'red' }}>
                      3: {getTranslation("HIGH", preferredLanguage)}
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: '10px', width: '100%' }}
                >
                  <TextField
                    className={classes.root}
                    id="outlined-multiline-static"
                    label={getTranslation("Comments", preferredLanguage)}
                    multiline
                    rows={3}
                    //defaultValue="Default Value"
                    variant="outlined"
                    {...bindComments}
                  />
                </FormControl>
              </Grid>
              <Grid container item xs={12} justify="flex-end">
                <Button variant="contained" onClick={props.toggle} size="lg" style={{ margin: '5px' }}>
                {getTranslation("Cancel", preferredLanguage)}
                </Button>
                <Button variant="contained" type="submit" size="lg" color="primary" style={{ margin: '5px' }}>
                {getTranslation("Save Edits", preferredLanguage)}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </ModalBody>
      </Dialog>
    </Paper>
  );
};
export default EditAlertModal;

//< FormGroup style = {{ fontSize: '12px', alignContent: 'center', alignItems: 'center' }} >
//    <Input type="select" required name="alertStatus" {...bindStatus} style={{ marginLeft: '0px' }}>
//        <option disabled selected value="">Status Options</option>
//        <option value="OnHold">On Hold</option>
//        <option value="Acknowledged">Acknowledged</option>
//        <option value="InProgress">In Progress</option>
//        <option value="Closed">Closed</option>
//        <option value="Archived">Archived</option>
//    </Input>
//                            </FormGroup >

//< FormGroup style = {{ fontSize: '12px', alignContent: 'center', alignItems: 'center' }} >
//    <Input type="select" required name="alertSeverity" {...bindSeverity} style={{ marginLeft: '5px', marginRight: '10px' }}>
//        <option className={priorityColorCheck(props.alertDetails.baseAlert.severity)} disabled selected value="">{capitalizeSeverity(props.alertDetails.baseAlert.severity)}</option>
//        <option value="1: low" style={{ color: 'dodgerblue' }}>1: LOW</option>
//        <option value="2: med" style={{ color: '#F1C411' }}>2: MEDIUM</option>
//        <option value="3: high" style={{ color: 'red' }}>3: HIGH</option>
//    </Input>
//                            </FormGroup >
