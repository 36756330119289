import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

//--------------Material UI Imports--------------//
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './MSSP.css';

const MSSPSparkLineChart = (props) => {
  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

  const theme = useTheme();

  return (
    <Chart
      width={'auto'}
      height={'60px'}
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={props.data}
      options={{
        colors: [props.color, props.color2],
        legend: 'none',
        backgroundColor: { fill: 'transparent' },
        hAxis: {
          textStyle: { color: theme.palette.text.primary },
        },
        vAxis: {
          textStyle: { color: theme.palette.text.primary },
          titleTextStyle: { color: theme.palette.text.primary },
        },
        // tooltip: {
        //     ignoreBounds: true,
        //     textStyle: { fontSize: '12px' },
        //     isHtml: true
        // },
        annotations: {
          textStyle: { color: theme.palette.text.primary },
          backgroundColor: theme.palette.background.paper,
        },
        // legend: {
        //     textStyle: { color: theme.palette.text.primary, },
        //     backgroundColor: theme.palette.background.paper
        // }
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};
export default MSSPSparkLineChart;
