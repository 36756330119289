import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import basePCUrl from '../../../Globals';
import { fetchApiData } from '../../../utils/service';
import { useQuery } from 'react-query';

export const useGetAlertDetails = (alertId, accessLevel, clientId) => {
  //const [data, setData] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, data, isError, error } = useQuery(`alertId=${alertId}`, async () => {
    const token = await getAccessTokenSilently();
    return fetchApiData(
      'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
      token,
      'getAlertDetails',
      accessLevel,
      clientId
    );
  });
  let alertDetails;
  if (data) {
    alertDetails = data.find((alert) => alert.alertId === alertId);
  }

  return { isLoading, alertDetails, isError, error };
};
export default useGetAlertDetails;
