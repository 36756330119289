import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import SchoolIcon from '@material-ui/icons/School';
import Paper from '@material-ui/core/Paper';
//------Material UI-----/
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import queryString from 'querystring';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useAuth0 } from "@auth0/auth0-react";

import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import LinkPreview from '../../LandingPages/LinkPreview';
import LinearProgress from '@material-ui/core/LinearProgress';


//----CSS/Styling Sheet----//
import './TrainingSuite.css';
import useGetTrainingByID from './useGetTrainingByID';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const courseStatsAPI = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

const TrainingSuite = (props) => {
  const reactPlayerRef = useRef();

  const [trainingId, setTrainingId] = useState('');
  const [infographicOpen, setInfographicOpen] = useState(false)

  //console.log(props.trainingData)

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const [trainingState, setTrainingState] = useState({
    trainingFlag: false,
    videoType: null,
    frequency: null,
    completionIndicator: null,
    video: false,
    pdf: false,
    quiz: false,
    yellowClick: false,
    redClick: false,
  });

  const { trainingFlag, videoType, frequency, completionIndicator, video, pdf, quiz, yellowClick, redClick } =
    trainingState;

  const {
    data,
    trainingVideo,
    trainingGraphic,
    trainingTitle,
    newsArticles,
    trainingTimeEstimate,
    trainingStatus,
    trainingQuiz,
    isLoading,
  } = useGetTrainingByID(trainingId, videoType);

  const [isLoadingTraining, setIsLoadingTraining] = useState(true);
  const { getAccessTokenSilently } = useAuth0();


  if(trainingTitle) {
    document.title = 'Training Module ' + trainingTitle
  }


  const analyzeLink = () => {
    let parsedQuery = queryString.parse(props.location.search);
    let parsedId = parsedQuery.id;

    if (parsedId !== undefined) {
      let parsedId = parsedQuery.id;
      //var decodedLink = decodeURIComponent(parsedId);
      setTrainingId(parsedId);
    }
  };

  useEffect(() => {
    analyzeLink();
    listeningVideo();
  }, [trainingId, videoType]);


  const listeningVideo = () => {
    if (video) {
      var trainingVideoPlayer = document.getElementById('trainingVideo');


      trainingVideoPlayer.addEventListener('ended', function (e) {
        saveTrainingProgress();
        console.log('video finished');
      });
    }
  };

  const getAdminConfigSettings = async () => {
    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
    const token = await getAccessTokenSilently();

    return new Promise(async (resolve, reject) => {
      setIsLoadingTraining(true);

      fetch(api, {
        method: 'get',
        headers: {
          Action: 'adminConfigs',
          //testacct: uid,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            if (data !== 'access token check failed') {
              setTrainingState({
                trainingFlag: data.settings.trainingSettings.trainingFlag,
                videoType: data.settings.trainingSettings.videoType,
                frequency: data.settings.trainingSettings.trainingFrequency,
                completionIndicator: data.settings.trainingSettings.completionIndicator,
                yellowClick: data.settings.trainingSettings.ongoingTraining.yellowClick,
                redClick: data.settings.trainingSettings.ongoingTraining.redClick,
              });
              setIsLoadingTraining(false);
              resolve(data);
            } else {
              resolve(data);
              return;
            }
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    });
  };

  const saveTrainingProgress = async (event) => {
    //event.preventDefault();
    const token = await getAccessTokenSilently();
    var api = 'https://zsvz1vz9rg.execute-api.us-west-2.amazonaws.com/prod';
    const videoProgress = reactPlayerRef.current.getCurrentTime() / reactPlayerRef.current.getDuration();
    const date = new Date();
    var data = {
      trainingModule: trainingId,
      status: 'required',
      startDate: date,
      //finishedDate: '',
      //progress: '',
      videoProgress: videoProgress ? Math.floor(videoProgress * 100) : 0,
    };

    fetch(courseStatsAPI, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        Action: 'updateUserTraining',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          //setError(true);
          // console.log(error);
          return;
        }

        response.json().then((data) => {
          return;
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    getAdminConfigSettings();
  }, []);

  useEffect(() => {
    if (pdf) {
      saveTrainingProgress();
    }
  }, [pdf != null]);


  //-----Grid Styling-----//
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    headerPaper: {
      padding: theme.spacing(6),
      textAlign: 'center',
      alignContent: 'center',
      height: '100%',
      //color: theme.palette.text.secondary,
    },
    video: {
      width: '70%',
      minWidth: 500,
    },
  }));

  const classes = useStyles();

  const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
  };

  const getTimeEstimate = (timeEstimate) => {
    return Math.floor(timeEstimate / 60) + getTranslation(' minutes', preferredLanguage);
  };

  const pushToQuiz = () => {
    props.history.push(
   '/Dashboard/TrainingQuiz?pad=&id=' + trainingId,
    );
    window.scrollTo({ top: 110, left: 100, behavior: 'smooth' });
  };



  return (
    <span>
  {!isLoadingTraining ?  <div className="traininig-suite-container">
      <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          {/* <Paper elevation={0} className={classes.headerPaper}> */}
            <Typography variant="h4" component="h4">
              {trainingTitle !== null ? trainingTitle : ''}
            </Typography>
<br></br>
            <Grid container direction="row" justify="space-between">
              {/* <Grid item>
                <Typography className={classes.statusTypography} align="left" gutterBottom variant="overline">
                  {trainingStatus !== null ? trainingStatus?.status : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.overlineTypography} align="right" gutterBottom variant="overline">
                  {trainingTimeEstimate !== null ? getTimeEstimate(trainingTimeEstimate) : ''}{' '}
                  <AccessTimeIcon fontSize="inherit" />
                </Typography>
              </Grid> */}
              <Grid item>
              <Typography  align="center" variant="subtitle1">
                  {props.trainingData.completionIndicator === "Q" ? 
"To successfully complete this training, passing the quiz is required." : props.trainingData.completionIndicator === "V" ? 
"To successfully complete this training, viewing the entire training video is required." : ''}
                </Typography>
              </Grid> 
            </Grid>
            {/* <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography className={classes.statusTypography} align="left" gutterBottom variant="overline">
                  {getTranslation("Current progress", preferredLanguage)}: {trainingStatus !== null ? trainingStatus.progress : ''}
                </Typography>
              </Grid>
            </Grid> */}
            {quiz !== null && trainingQuiz !== null && !isLoadingTraining ? (
              <Grid container direction="row" justify="center" alignItems="end">
              <Button variant="contained" color="primary" onClick={pushToQuiz}>
                {getTranslation("Take Quiz", preferredLanguage)}
              </Button>
              </Grid>
            ) : null}
</Grid>
            <br />
            <br />
            <Grid item >
            <ReactPlayer
              url={trainingVideo}
              ref={reactPlayerRef}
              className="react-player"
              width="100%"
              height="100%"
              style={{maxWidth: "1000px", justifyContent: "center"}}
              controls={true}
              onPause={(progress) => {
                console.log(
                  'progress1',
                  progress,
                  reactPlayerRef.current.getDuration(),
                  reactPlayerRef.current.getCurrentTime()
                );
                saveTrainingProgress();
              }}
              onEnded={() => saveTrainingProgress()}
            />
            </Grid>
<Grid container direction="row" justify="center" alignItems="center" spacing={4}>
            <div elevation={0} className={classes.headerPaper}>
            <br />
            <br />
            <br />
            {trainingGraphic !== null ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button variant="contained" color="primary" onClick={()=> setInfographicOpen(!infographicOpen)}>{!infographicOpen ? getTranslation("View Infographic", preferredLanguage) : getTranslation("Close Infographic", preferredLanguage) }</Button>  
{infographicOpen ? <iframe style={{width: '75vw', height: '75vh'}}  src={trainingGraphic} /> : null }
</Grid>
            ) : (
              null
            )}

</div>
</Grid>
            <br />
            <br />
            <br />
            {newsArticles !== null && newsArticles.length !== 0
          ? 
<Paper elevation={0} className={classes.headerPaper}>

            <Typography align="left" variant="h5" component="h4">
              {getTranslation("Related Reading", preferredLanguage)}:
            </Typography> 
            {newsArticles !== null
          ? newsArticles.map((link, index) => (
              <span key={index}>
                <LinkPreview linkForPreview={link} />
                <br />
              </span>
            ))
          : null}
            {/* {newsArticles !== null ? (
              <List aria-label="main mailbox folders">
                <ListItemLink target="_blank" href={newsArticles[0]}>
                  <ListItemText primary={newsArticles[0]} />
                </ListItemLink>
                <ListItemLink target="_blank" href={newsArticles[1]}>
                  <ListItemText primary={newsArticles[1]} />
                </ListItemLink>
                <ListItemLink target="_blank" href={newsArticles[2]}>
                  <ListItemText primary={newsArticles[2]} />
                </ListItemLink>
              </List>
            )
             : (
              <span></span>
            )} */}
            <br />


            <br />
          </Paper> : null}
            <br/>
        
      </Grid>
      <br />
      <br />
    </div> : <div style={{width: '60vw', height: '80vh', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{marginTop: '25%', marginLeft: '10%'}}>
            <h3> {getTranslation('Loading Your Training Content...', preferredLanguage)}</h3>
            <LinearProgress style={{ display: 'block'}}/>
            </div>
            </div>}
    </span>
  );
};
export default TrainingSuite;

//< Button className = { classes.button } size = "small" color = "primary" >
//    I have completed this training
//                    </Button >

//< Grid item xs = { 12} >
//    <Paper className={classes.headerPaper}>
//        <Typography variant="h4" component="h4">
//            Lesson Title
//                    </Typography>
//    </Paper>
//            </Grid >
