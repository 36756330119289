import PhishCloudLogo from '../../Assets/Logos/FullNameLogoWhite.png'


export const landingPageCodes = {  
    RvEHcO0hUH: {
        clientName: "PhishCloud",
        logo: PhishCloudLogo,
        styling: {
            bannerColor: '#0055a2',
            clickedLinkFontColor: '#ffffff',
            returnToPortalButtonBackgroundColor: '#0055a2', 
            linkPreviewCoverColor: '#0055a2',
            linkPreviewBorderColor: '#0055a2',
        },
        messages: {
            unknownMessage: 'The PhishCloud security team has reason to believe that the URL you clicked on is unknown and you should proceed with caution.  If you know that this URL is not malicious, please hit the greeen "thumbs up" button to let us know and we will address it.',
            unsafeMessage: "The PhishCloud security team has reason to believe that the URL you clicked on is malicious. If you know that this URL is not malicious, please hit the green 'thumbs up' button to let us know and we will address it."
        }
    },
    aJeOfUWsGg: {
        clientName: "Duracell",
        logo: 'https://www.duracell.com/wp-content/themes/duramotion/images/logo-black.svg',
        styling: {
            bannerColor: '#c8895d',
            clickedLinkFontColor: '#c8895d',
            returnToPortalButtonBackgroundColor: '#c8895d', 
            linkPreviewCoverColor: '#c8895d',
            linkPreviewBorderColor: '#c8895d',
        },
        messages: {
            unknownMessage: "The Duracell security team has reason to believe that the URL you clicked on is unknown and you should proceed with caution.  If you know that this URL is not malicious, please hit the greeen 'thumbs up' button to let us know and we will address it.",
            unsafeMessage: "The Duracell Security Team thinks the web address you clicked might be unsafe. Please be careful. If you're sure it's safe, you can click the green 'thumbs-up' icon at the bottom of your screen to let us know it's safe—the Security Team will investigate. If you still want to proceed to the web address, you can do so by clicking 'Proceed to Link'."
        }
    }
}

export default landingPageCodes;