import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useFetchLinkAnalysisAdmin from './useFetchLinkAnalysisAdmin';
import useFetchGlobalAndCompanyStatistics from './useFetchGlobalAndCompanyStatistics';
import useForm from '../CustomHooks/useForm';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import LinkAnalysisAdminResults from './LinkAnalysisAdminResults';
import queryString from 'querystring';
import ReturnToAlertDetailsButton from './ReturnToAlertDetailsButton';
import './LinkAnalysis.css';
import { useQuery, useQueryClient } from 'react-query';
import { fetchLinkAnalysisData } from '../../../utils/service';

const LinkAnalysisAdmin = (props) => {
  useChangePageTitle('LinkAnalysis');

  const { loading } = useAuth0();

  const { values: inputUrl, bind: bindInputUrl, reset: resetInputUrl } = useForm('');

  const [finalUrl, setFinalUrl] = useState('');

  const { data, domainDetails, isLoading } = useFetchLinkAnalysisAdmin(finalUrl);

  const { companyStatistics, globalStatistics, isLoadingGC } = useFetchGlobalAndCompanyStatistics(finalUrl);

  const [returnToAlertDetails, setReturnToAlertDetails] = useState(false);

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

 // console.log(props.location.search)
  const analyzeLink = () => {
    let parsedQuery = queryString.parse(props.location.search);
    let parsedLink = parsedQuery.link;

    if (parsedLink !== undefined && finalUrl === '') {
      var decodedLink = decodeURIComponent(parsedLink);
      setFinalUrl(decodedLink);
    }
  };

  //console.log(finalUrl);

  useEffect(() => {
    analyzeLink();
  }, [finalUrl]);

  const submitOnEnterKey = (event) => {
    if (event.keyCode === 13) {
      setFinalUrl(inputUrl);
    }
  };

  let history = useHistory();

  useEffect(() => {
    if (history.location.state != undefined) {
      if (history.location.state.from != undefined) {
        setReturnToAlertDetails(true);
        console.log(history.location.state);
      } else {
        setReturnToAlertDetails(false);
      }
    }
  }, []);

  const returnToAlertDetailsOnClick = () => {
    history.goBack();
  };

  return (
    <div>
      <div id="analyzeInit">
        <Grid container direction="row" alignItems="flex-end">
          <Grid item>
            <div className="link-wrap">
              <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
              {getTranslation("Analyze Link", preferredLanguage)}
              </Typography>

              <InputBase
                name="inputUrl"
                {...bindInputUrl}
                className="inputLink"
                placeholder="Enter URL"
                onKeyDown={(event) => submitOnEnterKey(event)}
              />
              <Button className="search" onClick={() => setFinalUrl(inputUrl)} outline color="white">
                <FontAwesomeIcon icon={['fas', 'search']} />
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div style={{ paddingBottom: '30px', marginLeft: '20px' }}>
              {returnToAlertDetails ? (
                <ReturnToAlertDetailsButton history={history} goBack={returnToAlertDetailsOnClick} preferredLanguage={preferredLanguage} />
              ) : (
                <span></span>
              )}
            </div>
          </Grid>
        </Grid>
      </div>

      {!isLoading && !isLoadingGC ? '' : <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />}

      {data === null || domainDetails === null || companyStatistics === null || globalStatistics === null ? (
        <div></div>
      ) : (
        data &&
        domainDetails && (
          <LinkAnalysisAdminResults
            alertSafety={data.safety}
            link={data.link}
            safety={data.safety}
            history={props.history}
            location={props.location}
            confidenceLevel={data.confidenceLevel}
            timesClicked={data.timesClicked}
            timesClickedCompany={data.timesClickedCompany}
            timesSeen={data.timesSeen}
            timesSeenCompany={data.timesSeenCompany}
            linkChain={data.linkChain}
            usersSeen={data.lastUsersSeen}
            usersClicked={data.lastUsersClicked}
            countryName={domainDetails?.countryCode}
            code={domainDetails.countryCode}
            domain={data.domain}
            created={domainDetails.created}
            companyStatistics={companyStatistics}
            globalStatistics={globalStatistics}
            globalSeens={globalStatistics.globalSeens}
            getTranslation={getTranslation}
            preferredLanguage={preferredLanguage}
          />
        )
      )}
    </div>
  );
};
export default LinkAnalysisAdmin;
