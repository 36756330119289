import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import CompanyInfoGlobalSafeUnsafe from './CompanyInfoGlobalSafeUnsafe';
import CompanyInfoRecentAlertsChart from './CompanyInfoRecentAlertsChart';
import CompanyInfoSafeUnsafe from './CompanyInfoSafeUnsafe';
import CompanyInfoSeenLinksTable from './CompanyInfoSeenLinksTable';
import CompanyInfoStatisticsBoxWidgets from './CompanyInfoStatisticsBoxWidgets/CompanyInfoStatisticsBoxWidgets';
import CompanyInfoUserClickChart from './CompanyInfoUserClickChart';
import CompanyInfoUserRiskTable from './CompanyInfoUserRiskTable';
import CompanyInfoWordCloud from './CompanyInfoWordCloud';
import './CompanyOverview.css';
import CompanyOverviewThreatModelChart from './CompanyOverviewThreatModelChart';

var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

const CompanyOverview = (props) => {
  useChangePageTitle('CompanyOverview');

  const isLoading = props.isLoading
  const companyAlertStats = props.companyAlertStats;
  const companyBoxStats = props.companyBoxStats;
  const mostRecentCompanySafeUnsafeStats = props.mostRecentCompanySafeUnsafeStats;
  const mostRecentGlobalSafeUnsafeStats = props.mostRecentGlobalSafeUnsafeStats;
  const threatModel = props.threatModel;
  const topGlobalBadLinks = props.topGlobalBadLinks;
  const userClickActivity = props.userClickActivity;
  const userClickOverview = props.userClickOverviewData;
  const wordCloud = props.wordCloud;

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  console.log(userClickActivity)

  //--------Date Ranges for Charts--------//

  const [dateRangeAlertChart, setDateRangeAlertChart] = useState('sevenDays');
  const [dateRangeUserClickActivityChart, setDateRangeUserClickActivityChart] = useState('sevenDays');
  const [dateRangeUserOverview, setDateRangeUserOverview] = useState('sevenDays');
  const [dateRangeSafeUnsafe, setDateRangeSafeUnsafe] = useState('sevenDays');
  const [dateRangeSafeUnsafeGlobal, setDateRangeSafeUnsafeGlobal] = useState('sevenDays');
  const [dateRangeThreatModel, setDateRangeThreatModel] = useState('sevenDays');

  //--------Rows For Chart Data-------//
  const [userClickRows, setUserClickRows] = useState();
  const [recentAlertDataChartRows, setRecentAlertDataChartRows] = useState();
  const [userClickChartRows, setUserClickChartRows] = useState({
    data: [[new Date().toISOString(), 0]],
    emails: ['no data found'],
  });
  const [globalSafeUnsafeRows, setGlobalSafeUnsafeRows] = useState();
  const [safeUnsafeRows, setSafeUnsafeRows] = useState();
  const [threatModelRows, setThreatModelRows] = useState();

  //--------Null Charts -------//
  const accountForNullChartData = [[new Date(), 0, 0, 0]];

  //--------Null Recent Alerts Data ---------//
  const nullAlertsRecentChartData = {
    today: [new Date(), 0, 0, 0],
  };

  //const [alertsRecentData, setAlertsRecentData] = useState(nullAlertsRecentChartData);

  //--------Null Most Seen Domains------//

  const nullMostSeenDomains = [{ hostRoot: '', safety: '', seenCount: '', clickCount: '' }];

  //------Null User Click Overview-----//
  const nullUserClickOverview = [
    {
      user: '',
      clicks: '',
      unknown: '',
      unsafe: '',
    },
  ];

  //------Null User Click Activity-----//

  const nullUserClickActivity = { data: [[new Date().toISOString(), 0]], emails: ['no data found'] };

  //-----------Alerts Chart "If" Date Range logic------------//
  const alertsRecentData = companyAlertStats

  useEffect(() => {
    if (!isLoading && alertsRecentData != undefined && alertsRecentData != null) {
      if (
        dateRangeAlertChart === 'today' &&
        alertsRecentData.today != undefined &&
        alertsRecentData.today.length != 0
      ) {
        setRecentAlertDataChartRows(alertsRecentData.today);
      } else if (
        (dateRangeAlertChart === 'today' && alertsRecentData.today === undefined) ||
        (dateRangeAlertChart === 'today' && alertsRecentData.today.length === 0)
      ) {
        setRecentAlertDataChartRows(nullAlertsRecentChartData);
        //setRecentAlertDataChartRows(alertsRecentData.sevenDays);
      } else if (
        dateRangeAlertChart === 'sevenDays' &&
        alertsRecentData.sevenDays != undefined &&
        alertsRecentData.sevenDays.length != 0
      ) {
        setRecentAlertDataChartRows(alertsRecentData.sevenDays);
      } else if (
        (dateRangeAlertChart === 'sevenDays' && alertsRecentData.sevenDays === undefined) ||
        (dateRangeAlertChart === 'sevenDays' && alertsRecentData.sevenDays.length === 0)
      ) {
        setRecentAlertDataChartRows(nullAlertsRecentChartData);
        //setRecentAlertDataChartRows(alertsRecentData.sevenDays);
      } else if (
        dateRangeAlertChart === 'thirtyDays' &&
        alertsRecentData.thirtyDays != undefined &&
        alertsRecentData.thirtyDays.length != 0
      ) {
        setRecentAlertDataChartRows(alertsRecentData.thirtyDays);
      } else if (
        (dateRangeAlertChart === 'thirtyDays' && alertsRecentData.thirtyDays === undefined) ||
        (dateRangeAlertChart === 'thirtyDays' && alertsRecentData.thirtyDays.length === 0)
      ) {
        setRecentAlertDataChartRows(nullAlertsRecentChartData);
        //setRecentAlertDataChartRows(alertsRecentData.sevenDays);
      }
    } else if (alertsRecentData === undefined || alertsRecentData === null) {
      setRecentAlertDataChartRows(nullAlertsRecentChartData);
    }
  }, [alertsRecentData, dateRangeAlertChart]);

  //-----------User Risk "If" Date Range Logic------------//
  useEffect(() => {
    if (userClickOverview != undefined && userClickOverview != null) {
      if (
        dateRangeUserOverview === 'today' &&
        userClickOverview.today != undefined &&
        userClickOverview.today.length != 0
      ) {
        setUserClickRows(userClickOverview.today);
      } else if (
        (dateRangeUserOverview === 'today' && userClickOverview.today === undefined) ||
        (dateRangeUserOverview === 'today' && userClickOverview.today.length === 0)
      ) {
        setUserClickRows(nullUserClickOverview);
      } else if (
        dateRangeUserOverview === 'sevenDays' &&
        userClickOverview.sevenDays != undefined &&
        userClickOverview.sevenDays.length != 0
      ) {
        setUserClickRows(userClickOverview.sevenDays);
      } else if (
        (dateRangeUserOverview === 'sevenDays' && userClickOverview.sevenDays === undefined) ||
        (dateRangeUserOverview === 'sevenDays' && userClickOverview.sevenDays.length === 0)
      ) {
        setUserClickRows(nullUserClickOverview);
      } else if (
        dateRangeUserOverview === 'thirtyDays' &&
        userClickOverview.thirtyDays != undefined &&
        userClickOverview.thirtyDays.length != 0
      ) {
        setUserClickRows(userClickOverview.thirtyDays);
      } else if (
        (dateRangeUserOverview === 'thirtyDays' && userClickOverview.thirtyDays === undefined) ||
        (dateRangeUserOverview === 'thirtyDays' && userClickOverview.thirtyDays.length === 0)
      ) {
        setUserClickRows(nullUserClickOverview);
      }
    } else if (userClickOverview === undefined || userClickOverview === null) {
      setUserClickRows(nullUserClickOverview);
    }
  }, [userClickOverview, dateRangeUserOverview]);

  //--------------Unknown/Unsafe Activity "If" Date Range Logic----------------//

  const safeUnsafe = mostRecentCompanySafeUnsafeStats

  useEffect(() => {
    if (safeUnsafe != undefined && safeUnsafe != null) {
      if (dateRangeSafeUnsafe === 'today' && safeUnsafe.today != undefined && safeUnsafe.today.length != 0) {
        setSafeUnsafeRows(safeUnsafe.today);
      } else if (
        (dateRangeSafeUnsafe === 'today' && safeUnsafe.today === undefined) ||
        (dateRangeSafeUnsafe === 'today' && safeUnsafe.today.length === 0)
      ) {
        setSafeUnsafeRows(accountForNullChartData);
      } else if (
        dateRangeSafeUnsafe === 'sevenDays' &&
        safeUnsafe.sevenDays != undefined &&
        safeUnsafe.sevenDays.length != 0
      ) {
        setSafeUnsafeRows(safeUnsafe.sevenDays);
      } else if (
        (dateRangeSafeUnsafe === 'sevenDays' && safeUnsafe.sevenDays === undefined) ||
        (dateRangeSafeUnsafe === 'sevenDays' && safeUnsafe.sevenDays.length === 0)
      ) {
        setSafeUnsafeRows(accountForNullChartData);
      } else if (
        dateRangeSafeUnsafe === 'thirtyDays' &&
        safeUnsafe.thirtyDays != undefined &&
        safeUnsafe.thirtyDays.length != 0
      ) {
        setSafeUnsafeRows(safeUnsafe.thirtyDays);
      } else if (
        (dateRangeSafeUnsafe === 'thirtyDays' && safeUnsafe.thirtyDays === undefined) ||
        (dateRangeSafeUnsafe === 'thirtyDays' && safeUnsafe.thirtyDays.length === 0)
      ) {
        setSafeUnsafeRows(accountForNullChartData);
      }
    } else if (safeUnsafe === undefined || safeUnsafe === null) {
      setSafeUnsafeRows(accountForNullChartData);
    }
  }, [safeUnsafe, dateRangeSafeUnsafe]);

  //----------------Global Uknown/Unsafe Activity "If" Date Range Logic------------//

  const globalSafeUnsafe = mostRecentGlobalSafeUnsafeStats
  useEffect(() => {
    if (globalSafeUnsafe != undefined && globalSafeUnsafe != null) {
      if (
        dateRangeSafeUnsafeGlobal === 'today' &&
        globalSafeUnsafe.today != undefined &&
        globalSafeUnsafe.today.length != 0
      ) {
        setGlobalSafeUnsafeRows(globalSafeUnsafe.today);
      } else if (
        (dateRangeSafeUnsafeGlobal === 'today' && globalSafeUnsafe.today === undefined) ||
        (dateRangeSafeUnsafeGlobal === 'today' && globalSafeUnsafe.today.length === 0)
      ) {
        setGlobalSafeUnsafeRows(accountForNullChartData);
      } else if (
        dateRangeSafeUnsafeGlobal === 'sevenDays' &&
        globalSafeUnsafe.sevenDays != undefined &&
        globalSafeUnsafe.sevenDays.length != 0
      ) {
        setGlobalSafeUnsafeRows(globalSafeUnsafe.sevenDays);
      } else if (
        (dateRangeSafeUnsafeGlobal === 'sevenDays' && globalSafeUnsafe.sevenDays === undefined) ||
        (dateRangeSafeUnsafeGlobal === 'sevenDays' && globalSafeUnsafe.sevenDays.length === 0)
      ) {
        setGlobalSafeUnsafeRows(accountForNullChartData);
      } else if (
        dateRangeSafeUnsafeGlobal === 'thirtyDays' &&
        globalSafeUnsafe.thirtyDays != undefined &&
        globalSafeUnsafe.thirtyDays.length != 0
      ) {
        setGlobalSafeUnsafeRows(globalSafeUnsafe.thirtyDays);
      } else if (
        (dateRangeSafeUnsafeGlobal === 'thirtyDays' && globalSafeUnsafe.thirtyDays === undefined) ||
        (dateRangeSafeUnsafeGlobal === 'thirtyDays' && globalSafeUnsafe.thirtyDays.length === 0)
      ) {
        setGlobalSafeUnsafeRows(accountForNullChartData);
      }
    } else if (globalSafeUnsafe === undefined || globalSafeUnsafe === null) {
      setRecentAlertDataChartRows(accountForNullChartData);
    }
  }, [globalSafeUnsafe, dateRangeSafeUnsafeGlobal]);

  //------------User Activity "If" Date Range Logic------------//
  const userClickChart = userClickActivity
  useEffect(() => {
    if (userClickChart != undefined && userClickChart != null) {
      if (
        dateRangeUserClickActivityChart === 'today' &&
        userClickChart.today != undefined &&
        userClickChart.today.length != 0
      ) {
        setUserClickChartRows(userClickChart.today);
      } else if (
        (dateRangeUserClickActivityChart === 'today' && userClickChart.today === undefined) ||
        (dateRangeUserClickActivityChart === 'today' && userClickChart.today.length === 0)
      ) {
        setUserClickChartRows(nullUserClickActivity);
      } else if (
        dateRangeUserClickActivityChart === 'sevenDays' &&
        userClickChart.sevenDays != undefined &&
        userClickChart.sevenDays.length != 0
      ) {
        setUserClickChartRows(userClickChart.sevenDays);
      } else if (
        (dateRangeUserClickActivityChart === 'sevenDays' && userClickChart.sevenDays === undefined) ||
        (dateRangeUserClickActivityChart === 'sevenDays' && userClickChart.sevenDays.length === 0)
      ) {
        setUserClickChartRows(nullUserClickActivity);
      } else if (
        dateRangeUserClickActivityChart === 'thirtyDays' &&
        userClickChart.thirtyDays != undefined &&
        userClickChart.thirtyDays.length != 0
      ) {
        setUserClickChartRows(userClickChart.thirtyDays);
      } else if (
        (dateRangeUserClickActivityChart === 'thirtyDays' && userClickChart.thirtyDays === undefined) ||
        (dateRangeUserClickActivityChart === 'thirtyDays' && userClickChart.thirtyDays.length === 0)
      ) {
        setUserClickChartRows(nullUserClickActivity);
      }
    } else if (userClickChart === undefined || userClickChart === null) {
      setUserClickChartRows(nullUserClickActivity);
    }
  }, [userClickChart, dateRangeUserClickActivityChart]);

  //----------------Threat Model Chart "If" Date Range Logic------------//
  const threatModelData = threatModel

  useEffect(() => {
    if (threatModelData != undefined && threatModelData != null) {
      if (dateRangeThreatModel === 'today' && 
      threatModelData.today != undefined && 
      threatModelData.today.length != 0) {
        setThreatModelRows(threatModelData.today);
      } else if (
        (dateRangeThreatModel === 'today' && 
        threatModelData.today === undefined) ||
        (dateRangeThreatModel === 'today' && 
        threatModelData.today.length === 0)
      ) {
        setThreatModelRows(nullUserClickOverview);
      } else if (
        dateRangeThreatModel === 'sevenDays' &&
        threatModelData.sevenDays != undefined &&
        threatModelData.sevenDays.length != 0
      ) {
        setThreatModelRows(threatModelData.sevenDays);
      } else if (
        (dateRangeThreatModel === 'sevenDays' && threatModelData.sevenDays === undefined) ||
        (dateRangeThreatModel === 'sevenDays' && threatModelData.sevenDays.length === 0)
      ) {
        setThreatModelRows(nullUserClickOverview);
      } else if (
        dateRangeThreatModel === 'thirtyDays' &&
        threatModelData.thirtyDays != undefined &&
        threatModelData.thirtyDays.length != 0
      ) {
        setThreatModelRows(threatModelData.thirtyDays);
      } else if (
        (dateRangeThreatModel === 'thirtyDays' && threatModelData.thirtyDays === undefined) ||
        (dateRangeThreatModel === 'thirtyDays' && threatModelData.thirtyDays.length === 0)
      ) {
        setThreatModelRows(nullUserClickOverview);
      }
    } else if (threatModelData === undefined || threatModelData === null) {
      setThreatModelRows(nullUserClickOverview);
    }
  }, [threatModelData, dateRangeThreatModel]);

  //------------------------------------------//

  //----------Dates for Dummy Table Data-------------//

  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  const thirdDay = new Date(today.getTime() + 48 * 60 * 60 * 1000);
  const fourthDay = new Date(today.getTime() + 72 * 60 * 60 * 1000);
  const fifthDay = new Date(today.getTime() + 96 * 60 * 60 * 1000);

  //---------------Table Title Toolbar-------------//

  //----Toolbar Material UI Styles----//
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
      zIndex: 1,
    },
    paper: {
      height: '346px',
      maxWidth: '1400px',
      //width: '100%',
      padding: '10px',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '15px',
    },
    skeletonBoxWidgetPaper: {
      height: '346px',
      maxWidth: '1400px',
      minWidth: '900px',
      padding: '10px',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '15px',
    },
    skeletonPaper: {
      height: '346px',
      maxWidth: '1400px',
      minWidth: '300px',
      width: '38vw',
      padding: '10px',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '15px',
    },
    loadingCircle: {
      marginLeft: '40%',
      marginTop: '50px',
    },
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  }));

  const classes = useToolbarStyles();

  //----------------Chart Titles---------------//
  const TitleToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
      <Toolbar className={classes.root}>
        <Typography className={classes.title} variant="h6" id="tableTitle">
          <Link tag={Link} to={props.link} style={{ textDecoration: 'none' }}>
            {props.title}
          </Link>
        </Typography>
      </Toolbar>
    );
  };

  //-----------Toggle Dropdown & Table Title Toolbar-----------//

  const TitleToolbarWithDateToggle = (props) => {
    const classes = useToolbarStyles();

    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle">
          <Link tag={Link} to={props.link} style={{ textDecoration: 'none' }}>
            {props.title}
          </Link>
        </Typography>
        <Select
          variant="outlined"
          value={props.value}
          onChange={props.setDateRange}
          style={{ marginLeft: '0px', width: '200px' }}
        >
          <option value="today">{getTranslation('Today', props.preferredLanguage)}</option>
          <option value="sevenDays">{getTranslation('7 Days', props.preferredLanguage)} </option>
          <option value="thirtyDays">{getTranslation('30 Days', props.preferredLanguage)}</option>
        </Select>
      </Toolbar>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        style={{ overflowX: 'hidden', overflowY: 'hidden', width: '100%', maxWidth: '1400px' }}
      >
        {!isLoading && props.companyBoxStats != null && props.companyBoxStats != undefined ? (
          <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
            {props.companyBoxStats.companyName}
          </Typography>
        ) : null}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!isLoading &&
          props.companyBoxStats != null &&
          props.companyBoxStats != undefined ? (
            <span className="selector-box-statistics">
              <CompanyInfoStatisticsBoxWidgets
                boxWidgetsData={props.companyBoxStats}
                preferredLanguage={props.preferredLanguage}
                getTranslation={getTranslation}
              />
            </span>
          ) : (
            <span className="selector-box-statistics">
              <Skeleton className={classes.skeletonBoxWidgetPaper} height={340} />
            </span>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          style={{ overflowX: 'hidden', overflowY: 'hidden' }}
          //spacing={2}
        >
          {!isLoading && recentAlertDataChartRows != null && recentAlertDataChartRows != undefined ? (
            <Grid className="selector-alert-chart" item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbarWithDateToggle
                  value={dateRangeAlertChart}
                  setDateRange={(e) => setDateRangeAlertChart(e.target.value)}
                  title={getTranslation('Alerts', preferredLanguage)} 
                  link={'/Dashboard/Alerts'}
                />
                <CompanyInfoRecentAlertsChart
                  chartData={recentAlertDataChartRows}
                  dateRange={dateRangeAlertChart}
                  preferredLanguage={props.preferredLanguage}
                  getTranslation={getTranslation}

                />
              </Paper>
            </Grid>
          ) : (
            <Grid className="selector-alert-chart" item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}
          {!isLoading && userClickChartRows != null && userClickChartRows != undefined ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbarWithDateToggle
                  value={dateRangeUserClickActivityChart}
                  setDateRange={(e) => setDateRangeUserClickActivityChart(e.target.value)}
                  title={
                    getTranslation("User Click Activity (Top 25)", preferredLanguage)
                  }
                  link={'Dashboard/UserManagement'}
                />
                <CompanyInfoUserClickChart
                  chartData={userClickChartRows.data}
                  chartEmails={userClickChartRows.emails}
                  dateRange={dateRangeUserClickActivityChart}
                  preferredLanguage={props.preferredLanguage}
                  getTranslation={getTranslation}

                />{' '}
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}

          {!isLoading && threatModelRows != null && threatModelRows != undefined ? (
            <Grid className="selector-threat-model" item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbarWithDateToggle
                  value={dateRangeThreatModel}
                  setDateRange={(e) => setDateRangeThreatModel(e.target.value)}
                  title={getTranslation('Threat Model', preferredLanguage)}
                  link={'/Dashboard/AccountManagement'}
                />
                <CompanyOverviewThreatModelChart
                  chartData={threatModelRows.data}
                  chartEmails={threatModelRows.labels}
                  dateRange={dateRangeThreatModel}
                  history={props.history}
                  preferredLanguage={props.preferredLanguage}
                  getTranslation={getTranslation}

                />
              </Paper>
            </Grid>
          ) : (
            <Grid className="selector-threat-model" item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}
          {!isLoading && userClickRows != undefined ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbarWithDateToggle
                  value={dateRangeUserOverview}
                  setDateRange={(e) => setDateRangeUserOverview(e.target.value)}
                  title={
                    getTranslation("User Yellow/Red Click Tracking (Top 100)", preferredLanguage)
                  }
                  link={'Dashboard/UserManagement'}
                />
                <CompanyInfoUserRiskTable atRiskUsers={userClickRows} preferredLanguage={props.preferredLanguage} getTranslation={getTranslation}
 />
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}

          {!isLoading && safeUnsafeRows != null && safeUnsafeRows != undefined ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbarWithDateToggle
                  value={dateRangeSafeUnsafe}
                  setDateRange={(e) => setDateRangeSafeUnsafe(e.target.value)}
                  title={
                    getTranslation('Unknown/Unsafe Activity', preferredLanguage)
                  }
                  link={'/Dashboard/History'}
                />
                <CompanyInfoSafeUnsafe
                  chartData={safeUnsafeRows}
                  dateRange={dateRangeSafeUnsafe}
                  preferredLanguage={props.preferredLanguage}
                  getTranslation={getTranslation}

                />
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}
          {!isLoading && wordCloud != null && wordCloud != undefined ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbar
                  title={
                    getTranslation('Most Seen Unsafe/Unknown Domains', preferredLanguage)
                  }
                />
                <CompanyInfoWordCloud words={wordCloud.wordValues} preferredLanguage={props.preferredLanguage} getTranslation={getTranslation}
/>
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}

          {!isLoading &&
          globalSafeUnsafeRows != null &&
          globalSafeUnsafeRows != undefined ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbarWithDateToggle
                  value={dateRangeSafeUnsafeGlobal}
                  setDateRange={(e) => setDateRangeSafeUnsafeGlobal(e.target.value)}
                  title={
                    getTranslation('Links Seen by Rating (PhishCloud Global)', preferredLanguage)
                  }
                />
                <CompanyInfoGlobalSafeUnsafe
                  chartData={globalSafeUnsafeRows}
                  dateRange={dateRangeSafeUnsafeGlobal}
                  preferredLanguage={props.preferredLanguage}
                  getTranslation={getTranslation}

                />
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}
          {!isLoading && topGlobalBadLinks != null && topGlobalBadLinks != undefined ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Paper className={classes.paper}>
                <TitleToolbar
                  title={
                    getTranslation('Top Yellow/Red Domains (PhishCloud Global)', preferredLanguage)
                  }
                />
                <CompanyInfoSeenLinksTable
                  lastBadLinks={props.topGlobalBadLinks.linkInfoList}
                  preferredLanguage={props.preferredLanguage}
                  getTranslation={getTranslation}

                />
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Skeleton className={classes.skeletonPaper} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default CompanyOverview;

//<img id="companyLogo" src={companyInfo.companyLogo} alt="Company Logo" />

//--------Null Most Recent Alerts -------//
//  const nullAlertsMostRecent = {
//     "portalAlerts": [
//         {
//             "severity": "", "status": '', "lastUpdated": "", "type": '', "incident": '', "detailLink": ''
//         },
//     ]
// }

// const [alertsMostRecent, setAlertsMostRecent] = useState(nullAlertsMostRecent);

// const getMostRecentAlerts = async () => {

//     return new Promise(async (resolve, reject) => {
//         let time1 = performance.now();

//         const token = await getAccessTokenSilently();

//         var data = {

//             clientId: clientId

//         };

//         fetch(basePCUrl + 'AccountClientInfo/getMostRecentAlerts', {
//             credentials: "same-origin",
//             method: 'POST',
//             body: JSON.stringify(data),
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             }
//         }).then((response) => {

//             if (response.status !== 200) {
//                 console.log('Looks like there was a problem. Status Code: ' +
//                     response.status);
//                 //setError(true);
//                 // console.log(error);
//                 setAlertsMostRecent(null);
//                 setIsLoadingAlertsRecent(false);
//                 resolve(response)

//                 return;
//             }

//             response.json().then((data) => {

//                 if (data.status === "Error") {
//                     console.log("Most Recent Alert Fetch Error")
//                     setAlertsMostRecent(null);
//                     setIsLoadingAlertsRecent(false);
//                     resolve(data);
//                 } else if (data === null && data.status === "ok" || data === null && data.status === null) {
//                     setAlertsMostRecent(nullAlertsMostRecent);
//                     setIsLoadingAlertsRecent(false);
//                     resolve(data);
//                 } else {
//                     //setAlertsMostRecent(data);

//                     // console.log(data);
//                     resolve(data);
//                 }
//             });
//         })
//             .catch(function (err) {
//                 console.log('Fetch Error :-S', err);
//             });
//     })
// }
