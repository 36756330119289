import React, { useState, useEffect, useRef, createContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Auth0Lock from 'auth0-lock';

//------------Material UI------------//
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';

import NotificationsIcon from '@material-ui/icons/Notifications';
import SecurityIcon from '@material-ui/icons/Security';
import SchoolIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Dashboard.css';

const SideNav = (props, location, history) => {
  const isActive = (value) => {
    if (props.location.pathname === value) {
      return 'activeSideNavLink';
    } else {
      return 'sideNavLink';
    }
  };

  //----SideNav Styles----//

  const drawerWidth = 240;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'inline',
      height: '100vw',
      marginTop: '25px',
    },
    appBar: {
      backgroundColor: '#F4F7FC',
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'none',
      border: 'none',
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      minWidth: 100,
      maxWidth: 240,
      flexShrink: 0,
      //whiteSpace: 'nowrap',
      color: 'white',
      zIndex: 1,
    },
    drawerPaper: {
      marginTop: 45,
      width: drawerWidth,
      //backgroundColor: theme.overrides.MuiDrawer.paper.backgroundColor,
      backgroundColor: theme.palette.sideNav.main,
      position: 'absolute',
      height: '100rem',
      overflow: 'hidden',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      // backgroundColor: theme.overrides.MuiDrawer.paper.backgroundColor,
      padding: theme.spacing(2),
    },
    list: {
      color: 'lightgrey',
      border: '1px, #061739',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '12px',
      marginRight: '0px',
      textDecoration: 'none',
      hover: {
        textDecoration: 'none',
      },
    },
  }));

  const classes = useStyles();

  const menuTitleTranslations = {
    MenuTitles: {
      SideNav: {
        AccountManagement: {
          EN: 'Account Management',
          JA: 'アカウント管理',
          FR: 'Gestion du compte',
          DE: 'Konto Verwaltung',
          ES: 'Gestión de la cuenta',
          PT: 'Gestão de Conta',
          ZH: '帐户管理',
          NL: 'Accountbeheer',
          IT: 'Gestione account',
          NB: 'Kontohåndtering',
          AR: 'إدارة الحساب'
        },
        CompanyOverview: {
          EN: 'Company Overview',
          JA: '会社概要',
          FR: "Aperçu de l'entreprise",
          DE: 'Unternehmensübersicht',
          ES: 'Descripción de la empresa',
          PT: 'Visão Geral da Empresa',
          ZH: '公司概览',
          NL: 'Bedrijfsoverzicht',
          IT: 'Panoramica aziendale',
          NB: 'Bedriftsoversikt',
          AR: 'نظرة عامة على الشركة'
        },
        History: {
          EN: 'History',
          JA: '履歴',
          FR: 'Histoire',
          DE: 'Geschichte',
          ES: 'Historia',
          PT: 'História',
          ZH: '历史',
          NL: 'Geschiedenis',
          IT: 'Storia',
          NB: 'Historie',
          AR: 'التاريخ'
        },
        Training: {
          EN: 'Training',
          JA: 'トレーニング',
          FR: 'Formation',
          DE: 'Ausbildung',
          ES: 'Entrenamiento',
          PT: 'Treinamento',
          ZH: '培训',
          NL: 'Opleiding',
          IT: 'Formazione',
          NB: 'Opplæring',
          AR: 'تدريب'
        },
        Alerts: {
          EN: 'Alerts',
          JA: 'アラート',
          FR: 'Alertes',
          DE: 'Warnungen',
          ES: 'Alertas',
          PT: 'Alertas',
          ZH: '警报',
          NL: 'Meldingen',
          IT: 'Allarmi',
          NB: 'Varsler',
          AR: 'تنبيهات'
        },
        LinkAnalysis: {
          EN: 'Link Analysis',
          JA: 'リンク分析',
          FR: 'Analyse de lien',
          DE: 'Linkanalyse',
          ES: 'Análisis de enlaces',
          PT: 'Análise de Links',
          ZH: '链接分析',
          NL: 'Linkanalyse',
          IT: 'Analisi dei link',
          NB: 'Lenkeanalyse',
          AR: 'تحليل الروابط'
        },
        AdminConfiguration: {
          EN: 'Admin Configuration',
          JA: '管理者設定',
          FR: "Configuration d'administration",
          DE: 'Admin-Konfiguration',
          ES: 'Configuración de administrador',
          PT: 'Configuração de Administração',
          ZH: '管理员配置',
          NL: 'Beheerdersconfiguratie',
          IT: 'Configurazione amministratore',
          NB: 'Admin-konfigurasjon',
          AR: 'تكوين المسؤول'
        },
        UserConfiguration: {
          EN: 'User Configuration',
          JA: 'ユーザー設定',
          FR: "Configuration de l'utilisateur",
          DE: 'Benutzerkonfiguration',
          ES: 'Configuración de usuario',
          PT: 'Configuração de Usuário',
          ZH: '用户配置',
          NL: 'Gebruikersconfiguratie',
          IT: 'Configurazione utente',
          NB: 'Brukerkonfigurasjon',
          AR: 'تكوين المستخدم'
        },
        LinkSafetyRules: {
          EN: 'Link Safety Rules',
          JA: 'リンク安全規則',
          FR: 'Règles de sécurité des liens',
          DE: 'Link-Sicherheitsregeln',
          ES: 'Reglas de seguridad de enlaces',
          PT: 'Regras de Segurança de Links',
          ZH: '链接安全规则',
          NL: 'Linkveiligheidsregels',
          IT: 'Regole di sicurezza dei link',
          NB: 'Link sikkerhetsregler',
          AR: 'قواعد سلامة الرابط'
        },
        UserManagement: {
          EN: 'User Management',
          JA: 'ユーザー管理',
          FR: 'Gestion des utilisateurs',
          DE: 'Benutzerverwaltung',
          ES: 'Gestión de usuarios',
          PT: 'Gestão de Usuários',
          ZH: '用户管理',
          NL: 'Gebruikersbeheer',
          IT: 'Gestione utenti',
          NB: 'Brukerbehandling',
          AR: 'إدارة المستخدمين'
        },
      },
      TopNav: {
        NoAlerts: {
          EN: 'No Alerts',
          JA: 'アラートなし',
          FR: "Pas d'alertes",
          DE: 'Keine Warnungen',
          ES: 'Sin alertas',
          PT: 'Sem Alertas',
          ZH: '没有警报',
          NL: 'Geen meldingen',
          IT: 'Nessun avviso',
          NB: 'Ingen varsler',
          AR: 'لا تنبيهات'
        },
        Info: {
          EN: 'Info',
          JA: '情報',
          FR: 'Info',
          DE: 'Info',
          ES: 'Información',
          PT: 'Informações',
          ZH: '信息',
          NL: 'Info',
          IT: 'Informazioni',
          NB: 'Info',
          AR: 'معلومات'
        },
        AboutUs: {
          EN: 'About Us',
          JA: '会社概要',
          FR: 'À propos de nous',
          DE: 'Über uns',
          ES: 'Acerca de nosotros',
          PT: 'Sobre Nós',
          ZH: '关于我们',
          NL: 'Over ons',
          IT: 'Chi siamo',
          NB: 'Om oss',
          AR: 'معلومات عنا'
        },
        Contact: {
          EN: 'Contact',
          JA: 'お問い合わせ',
          FR: 'Contact',
          DE: 'Kontakt',
          ES: 'Contacto',
          PT: 'Contato',
          ZH: '联系方式',
          NL: 'Contact',
          IT: 'Contatto',
          NB: 'Kontakt',
          AR: 'اتصل'
        },
        Downloads: {
          EN: 'Downloads',
          JA: 'ダウンロード',
          FR: 'Téléchargements',
          DE: 'Downloads',
          ES: 'Descargas',
          PT: 'Downloads',
          ZH: '下载',
          NL: 'Downloads',
          IT: 'Download',
          NB: 'Nedlastinger',
          AR: 'تحميل'
        },
        User: {
          EN: 'User',
          JA: 'ユーザー',
          FR: 'Utilisateur',
          DE: 'Benutzer',
          ES: 'Usuario',
          PT: 'Usuário',
          ZH: '用户',
          NL: 'Gebruiker',
          IT: 'Utente',
          NB: 'Bruker',
          AR: 'المستخدم'
        },
        Account: {
          EN: 'Account',
          JA: 'アカウント',
          FR: 'Compte',
          DE: 'Konto',
          ES: 'Cuenta',
          PT: 'Conta',
          ZH: '账户',
          NL: 'Account',
          IT: 'Account',
          NB: 'Konto',
          AR: 'الحساب'
        },
        Billing: {
          EN: 'Billing',
          JA: '請求',
          FR: 'Facturation',
          DE: 'Abrechnung',
          ES: 'Facturación',
          PT: 'Faturação',
          ZH: '账单',
          NL: 'Facturatie',
          IT: 'Fatturazione',
          NB: 'Fakturering',
          AR: 'الفواتير'
        },
        Logout: {
          EN: 'Logout',
          JA: 'ログアウト',
          FR: 'Déconnexion',
          DE: 'Abmelden',
          ES: 'Cerrar sesión',
          PT: 'Sair',
          ZH: '注销',
          NL: 'Afmelden',
          IT: 'Esci',
          NB: 'Logg ut',
          AR: 'تسجيل الخروج'
        },
      },
    },
  };

  // Define a function to translate text based on the provided text and language code.
  function translateText(inputText, languageCode) {
    // Check if the input text exists as an object key.
    let finalInputText = inputText.replace(/\s/g, '');
    //console.log(menuTitleTranslations.MenuTitles.SideNav[finalInputText])
    if (menuTitleTranslations.MenuTitles.SideNav[finalInputText]) {
      const translation = menuTitleTranslations.MenuTitles.SideNav[finalInputText][languageCode];
      //console.log(translation)
      if (translation) {
        return translation;
      }
    }

    // If the input text doesn't exist or there's no translation, return the original text.
    return inputText;
  }

  //console.log(props.preferredLanguage)

  return (
    <div id="sideNav" className={classes.root}>
      <CssBaseline />

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Divider />
        <div style={{ paddingTop: '80px' }}>
          <List className={classes.list} style={{ textDecoration: 'none' }}>
            {props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/AccountManagement')}
                key="AccountManagement"
                component={Link}
                to={'/Dashboard/AccountManagement'}
              >
                <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                  <SecurityIcon />
                </ListItemIcon>
                <ListItemText>{translateText('Account Management', props.preferredLanguage)}</ListItemText>
              </ListItem>
            ) : null}

            {props.accessLevel === 'AccountAdmin' || props.accessLevel === 'CompanyAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/CompanyOverview')}
                key="CompanyOverview"
                component={Link}
                to={'/Dashboard/CompanyOverview'}
              >
                <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                  <FontAwesomeIcon icon={['fa', 'chart-bar']} size="lg" />
                </ListItemIcon>
                <ListItemText>{translateText('Company Overview', props.preferredLanguage)}</ListItemText>
              </ListItem>
            ) : null}

            <ListItem
              button
              data-tut={props.tourSideNavSelector}
              className={isActive('/Dashboard/History')}
              key="History"
              component={Link}
              to={'/Dashboard/History'}
            >
              <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                <FontAwesomeIcon icon={['fas', 'history']} size="lg" />
              </ListItemIcon>
              <ListItemText>{translateText('History', props.preferredLanguage)}</ListItemText>
            </ListItem>

            {props.training && (
              <ListItem
                button
                className={
                  props.accessLevel === 'AccountAdmin'
                    ? isActive('/Dashboard/AdminTrainingDashboard')
                    : isActive('/Dashboard/TrainingDashboard')
                }
                key="Training"
                component={Link}
                to={
                  props.accessLevel === 'AccountAdmin'
                    ? '/Dashboard/AdminTrainingDashboard'
                    : '/Dashboard/TrainingDashboard'
                }
              >
                <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText>{translateText('Training', props.preferredLanguage)}</ListItemText>
              </ListItem>
            )}

            {props.accessLevel === 'AccountAdmin' || props.accessLevel === 'CompanyAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/Alerts')}
                key="Alerts"
                component={Link}
                to={'/Dashboard/Alerts'}
              >
                <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText>{translateText('Alerts', props.preferredLanguage)}</ListItemText>
              </ListItem>
            ) : null}

            <ListItem
              button
              className={
                props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin'
                  ? isActive('/Dashboard/AnalyzeLinkAdmin')
                  : isActive('/Dashboard/AnalyzeLink')
              }
              key={
                props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin'
                  ? 'AnalyzeLinkAdmin'
                  : 'AnalyzeLink'
              }
              component={Link}
              to={
                props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin'
                  ? '/Dashboard/AnalyzeLinkAdmin'
                  : '/Dashboard/AnalyzeLink'
              }
            >
              <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                <FontAwesomeIcon icon={['fas', 'link']} size="lg" />
              </ListItemIcon>
              <ListItemText>{translateText('Link Analysis', props.preferredLanguage)}</ListItemText>
            </ListItem>

            {props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/AdminConfiguration')}
                key="AdminConfiguration"
                component={Link}
                to={'/Dashboard/AdminConfiguration'}
              >
                <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                  <FontAwesomeIcon icon={['fas', 'cog']} size="lg" />
                </ListItemIcon>
                <ListItemText>{translateText('Admin Configuration', props.preferredLanguage)}</ListItemText>
              </ListItem>
            ) : null}

            {/* <ListItem
              button
              className={isActive('/Dashboard/Configuration')}
              key="Configuration"
              component={Link}
              to={'/Dashboard/Configuration'}
            >
              <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                <FontAwesomeIcon icon={['fas', 'sliders-h']} size="lg" />
              </ListItemIcon>
              <ListItemText>{translateText('User Configuration', props.preferredLanguage)}</ListItemText>
            </ListItem> */}

            {props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/LinkSafetyRules')}
                key="LinkSafetyRules"
                component={Link}
                to={'/Dashboard/LinkSafetyRules'}
              >
                <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                  <FontAwesomeIcon icon={['fa', 'traffic-light']} size="lg" />
                </ListItemIcon>
                <ListItemText>{translateText('Link Safety Rules', props.preferredLanguage)}</ListItemText>
              </ListItem>
            ) : null}
            {props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/UserManagement')}
                key="UserManagement"
                component={Link}
                to={'/Dashboard/UserManagement'}
              >
                <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
                  <FontAwesomeIcon icon={['fas', 'users']} size="lg" />
                </ListItemIcon>
                <ListItemText>{translateText('User Management', props.preferredLanguage)}</ListItemText>
              </ListItem>
            ) : null}
          </List>
        </div>
      </Drawer>

      <div />
    </div>
  );
};
export default SideNav;
