import React from 'react';
import { Link } from 'react-router-dom';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SchoolIcon from '@material-ui/icons/School';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import './TrainingColumn.css';

const TrainingColumn = (props) => {
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
      textDecoration: 'none',
      //color: 'black',
      '&:link': {
        //color: 'black',
        textDecoration: 'none',
      },
      '&:hover': {
        //color: 'black',
        textDecoration: 'none',
      },
    },
    button: {
      fontFamily: 'Montserrat, sans-serif',
    },
    columnTitle: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  function findObjectsWithKeyValue(array, key, value) {
    return array.filter(obj => obj[key] === value);
  }

function findObjectsWithKeyValueAndCookie(array, key, value, cookieName) {
  const matchingObjects = array.filter(obj => obj[key] === value);
  const values = matchingObjects.map(obj => obj[key]);

  const cookieValue = Cookies.get(cookieName);

  return {
    matchingObjects,
    values,
    cookieValue,
  };
}

const handleFilterTraining = (filterType) => {
  let type = filterType

  const keyToSearch = 'status'; // Modify this key based on your data structure

  // Check if the value exists in the cookie
  const { matchingObjects, values, cookieValue } = findObjectsWithKeyValueAndCookie(
    props.trainingData,
    keyToSearch,
    type,
  );

  let filteredCourses = [];


  function checkModIdForKeyInArray(arrayOfObjects) {
    const initialFilter = props.trainingData.filter(course => course.progress === 100)
  
    arrayOfObjects.forEach(obj => {
      if (obj.modId) {
        const modIdValue = obj.modId;
        const cookieValue = Cookies.get(modIdValue);
  
        if (cookieValue) {
          initialFilter.push(obj);
        }
      }
    });
  
    return initialFilter;
  }
  

    filteredCourses = checkModIdForKeyInArray(props.trainingData)


  return filteredCourses
};



const completedTraining = handleFilterTraining('Completed')
const requiredTraining = findObjectsWithKeyValue(props.trainingData, 'status', 'required');
const suggestedTraining = findObjectsWithKeyValue(props.trainingData, 'status', 'suggested');
const preferredLanguage = props.preferredLanguage

  const trainingDetailPush = (trainingDetails) => {
    props.history.push('/Dashboard/Training?pad=&id=' + trainingDetails);

    window.scrollTo(0, 0);
  };

  const classes = useToolbarStyles();
  //----------Table Title Toolbar-------//
  const TitleToolbar = (props) => {
    return (
      <Toolbar>
        <Typography className={classes.columnTitle} variant="h6" id="tableTitle">
          {props.titleText}
        
          <Link style={{ float: 'right' }} to={'/Dashboard/TrainingSuite'}>
            <Button variant="contained" color="primary" width="25%" style={{ fontSize: '.8rem' }}>
              <SchoolIcon style={{ padding: '5px' }} />
              {props.text}
              
            </Button>
          </Link>
        </Typography>
      </Toolbar>
    );
  };

  
  return (
    <Paper id="training-rectangle">
      <TitleToolbar titleText={props.getTranslation("training", preferredLanguage)} text={props.getTranslation("go to training", preferredLanguage)}
/>
      <div id="training-sections">
        <p id="training-section-header">      {props.getTranslation("required", preferredLanguage)}
</p>
        <hr />
        {requiredTraining != undefined ? (
          requiredTraining.map((training, index) => (
            <span key={index}>
              <Button
                className={classes.button}
                size="small"
                color="primary"
                onClick={() => trainingDetailPush(training.modId)}
              >
                    {training.title}

              </Button>
              <br />
            </span>
          ))
        ) : (
          <p>      {props.getTranslation("no required training found.", preferredLanguage)}
 
           </p>
        )}
        <br />

        <br />
        <p id="training-section-header">      {props.getTranslation("suggested", preferredLanguage)}
</p>
        <hr />
        {suggestedTraining != undefined && suggestedTraining.length >= 1 ? (
          suggestedTraining.map((training, index) => (
            <span>
              <Button
                className={classes.button}
                key={index}
                size="small"
                color="primary"
                onClick={() => trainingDetailPush(training.modId)}
              >
                  {training.title}
 
              </Button>
              <br />
            </span>
          ))
        ) : (
          <p>      {props.getTranslation("no suggested training found", preferredLanguage)}
          </p>
        )}
        <br />
        <br />
        <p id="training-section-header">      {props.getTranslation("completed", preferredLanguage)}
</p>
        <hr />
        {completedTraining != undefined ? (
          completedTraining.map((training, index) => (
            <span key={index}>
              <Button
                className={classes.button}
                size="small"
                color="primary"
                onClick={() => trainingDetailPush(training.modId)}
              >
                     {training.title}

              </Button>
              <br />
            </span>
          ))
        ) : (
          <p>      {props.getTranslation("no past training found", preferredLanguage)}
          </p>
        )}
        <br />
        <br />
      </div>
    </Paper>
  );
};
export default TrainingColumn;
