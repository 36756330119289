export const EmailLoginBrandingStyles = {  
    RvEHcO0hUH: {
        clientName: "PhishCloud",
        //logo: PhishCloudLogo,
        styling: {
            bannerColor: '#0055a2',
            clickedLinkFontColor: '#ffffff',
            returnToPortalButtonBackgroundColor: '#0055a2', 
            linkPreviewCoverColor: '#0055a2',
            linkPreviewBorderColor: '#0055a2',
        },
        // messages: {
        // }
    },
    Duracell: {
        clientName: "Duracell",
        logo: 'https://www.duracell.com/wp-content/themes/duramotion/images/logo-black.svg',
        logoDarkMode: 'https://www.duracell.com/wp-content/themes/duramotion/images/logo-white.svg',
        styling: {
            backgroundColor: '#191a1c',
            cardColor: '#26282c',
            buttonColor: '#c8895d',
        },
        // messages: {
        // }
    }
}

export default EmailLoginBrandingStyles;