import React from "react";
import { matchPath } from 'react-router-dom';

const Callback =(props)=> {

    const intendedRoute = localStorage.getItem('intendedRoute');
    localStorage.setItem('intendedRoute', '');
    return(
        <div>
{intendedRoute !== "/" && intendedRoute !== "" && intendedRoute !== "/Login" && intendedRoute !== "/PortalSignIn" && intendedRoute !== "/Dashboard/Callback" && intendedRoute !== null ? props.history.push(intendedRoute) : props.history.push("/Dashboard/History")}

</div>
    )
}; export default Callback