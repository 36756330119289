import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";


//-----Material Ui imports-----//
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaExclamation } from 'react-icons/fa';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import CompanyInfoSeenLinksMockData from './CompanyInfoSeenLinksMockData';

const CompanyInfoSeenLinksTable = (props) => {
  //-------Logic/Render-------//

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const accountForLinkLength = (linkForCheck) => {
    if (linkForCheck.length >= 60) {
      return <span style={{ fontSize: '12px' }}>{linkForCheck.slice(0, 50)}...</span>;
    } else {
      return <span style={{ fontSize: '14px', wordWrap: 'break-word' }}>{linkForCheck}</span>;
    }
  };

  const linkDetailPush = (linkDetails) => {
    props.history.push(linkDetails);
  };

  const checkStatus = (value) => {
    if (value === 'unknown') {
      return (
        <FontAwesomeIcon
          style={{ paddingRight: '5px', fontSize: '25px', color: '#F1C411' }}
          icon={['fas', 'exclamation-triangle']}
        />
      );
    } else if (value === 'safe') {
      return (
        <FontAwesomeIcon style={{ paddingRight: '5px', fontSize: '25px', color: '#2CCC72' }} icon={['fas', 'check']} />
      );
    } else if (value === 'unsafe') {
      return <FaExclamation style={{ paddingRight: '5px', fontSize: '25px', color: '#EE311E' }} />;
    }
  };

  const columns = [
    {
      id: 'hostRoot',

      label: getTranslation('Link', preferredLanguage),
      minWidth: 100,
      maxWidth: 100,
      align: 'left',
      format: (value) => value.toString(),
    },
    {
      id: 'safety',

      label: getTranslation('Safety', preferredLanguage),
      minWidth: 80,
      maxWidth: 80,
      align: 'left',
      format: (value) => value.toString(),
    },
    {
      id: 'seenCount',

      label: getTranslation('Times Seen', preferredLanguage),
      minWidth: 80,
      maxWidth: 80,
      align: 'left',
      format: (value) => value.toString(),
    },
    {
      id: 'clickCount',
      label: getTranslation('Times Clicked', preferredLanguage),

      minWidth: 80,
      maxWidth: 80,
      align: 'left',
      format: (value) => value.toString(),
    },
  ];

  const rows = props.lastBadLinks;

  const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '100%',
      fontFamily: 'Montserrat, sans-serif',
    },
    container: {
      height: 200,

      width: '100%',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
    tableHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '10px',
      padding: '10px',
    },
    tableCell: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '12px',
    },
  });

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderTable = () => {
    return (
      <div>
        <TableContainer className={classes.container}>
          <Table stickyHeader label="sticky table" style={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className={classes.tableHeader}
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      fontWeight: '600',
                      fontFamily: 'Montserrat, sans-serif',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.hostRoot}>
                    <TableCell align="left" className={classes.tableCell}>
                      {row.hostRoot}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {checkStatus(row.safety)}
                      {row.safety}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {row.seenCount}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {row.clickCount}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}
        />
      </div>
    );
  };
  return <div className={classes.root}>{renderTable()}</div>;
};
export default CompanyInfoSeenLinksTable;
