import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import basePCUrl from '../../../Globals';
var baseUrl = basePCUrl + 'AnalyzeLink/GetLinkDetailAdmin';

export const useFetchGlobalAndCompanyStatistics = (finalUrl) => {
  const { getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState({
    companyStatistics: [],
    globalStatistics: [],
    isLoadingGC: true,
  });

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  ////////MAKE NOTE - LAST 30 DAYS////////
  //console.log(finalUrl);

  const fetchAdditionalLinkAnalysis = async () => {
    // setState({ data: null, domainDetails: null, isLoading: true });

    const token = await getAccessTokenSilently();
    if (localStorage.getItem('usid')) {
      var usid = localStorage.getItem('usid');
    var headers = {
      user_id: usid,
      Action: 'linkAnalysis',
       link: finalUrl,
       //...(queryId && { id: queryId }),
       'Content-Type': 'application/json',
     }
    } else {
      var headers = {
        Authorization: `Bearer ${token}`,
        Action: 'linkAnalysis',
         link: finalUrl,
         'Content-Type': 'application/json',
       }
    }

    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
    fetch(api, {
      method: 'get',
      headers: headers,
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          if (data.errorMessage != undefined) {
            setState({
              companyStatistics: { companySeens: 0, companyClicks: 0 },
              globalStatistics: { globalSeens: 0, globalClicks: 0 },
              isLoadingGC: false,
            });
          } else {
            setState({
              companyStatistics: data.companyStatistics,
              globalStatistics: data.globalStatistics,
              isLoadingGC: false,
            });
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    setState({ companyStatistics: null, globalStatistics: null, isLoadingGC: null });

    if (!isMount)  {
      fetchAdditionalLinkAnalysis();
    }
  }, [finalUrl]);

  return state;
};
export default useFetchGlobalAndCompanyStatistics;
