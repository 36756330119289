import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FilteredSelection from './FilteredSelection';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import ImageUploadS3 from '../S3/ImageUploadS3';
import { useQuery } from 'react-query';
import { postApiData } from '../../../utils/service';
import './AdminMessages.css';

const AdminMessageForm = (props) => {

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const [deliveryDetails, setDeliveryDetails] = useState('')
  const [messageType, setMessageType] = useState('');
  const [messageSubject, setMessageSubject] = useState('');
  const [messageText, setMessageText] = useState('');
  const [messageLink, setMessageLink] = useState('');
  const [progress, setProgress] = useState('');
  const [clearFields, setClearFields] = useState('');

  const [list, setList] = useState([])

  const { getAccessTokenSilently } = useAuth0();


  let usid = localStorage.getItem('usid');
  const portalKeyData = props.portalKeyData


  let finalList = list?.map((item) => item.id)
  
  const handleDeliveryDetailsChange = (e) => {
    setDeliveryDetails(e.target.value);
    if (e.target.value === "all") {
      setList(portalKeyData)
    }
  };

  const handleTypeChange = (e) => {
    setMessageType(e.target.value);
    if (e.target.value === "text") {
      setMessageLink('')
    }
  };

  const handleSubjectChange = (e) => {
    setMessageSubject(e.target.value);
  };

  const handleTextChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleLinkChange = (e) => {
    setMessageLink("https://portal-upload-repository.s3.us-west-2.amazonaws.com/"+ e.name.replace(/ /g, '+'));
  };

  //console.log(messageLink)
  // console.log("The list that will be sent the message is: ")
  // console.log(finalList)

    const submitMessage = useQuery(
    ['admin_msg'],
    async () => {
      const token = await getAccessTokenSilently();
      const data = {
        deliveryDetails : deliveryDetails,   //"list", #or "all"
        userList : finalList, //list of emails or one client_id, send as an array
        messageType : messageType,
        messageSubject : messageSubject,
        messageText : messageText,
        videoLink : messageLink

    }



      return postApiData(
        'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
        token,
        data,
        'admin_msg'
      );
    },
    {
      enabled: false,
      onSuccess: () => {
        ShowMessage("Message Sent Successfully")
        setDeliveryDetails('')
        setMessageType('')
        setMessageSubject('')
        setMessageText('')
        setMessageLink('')
        setProgress(0)
        setClearFields(true)
        //console.log("I have made the save settings call.")
        //window.location.reload();
      },
    }
  );


  // console.log(
  //   {
  //     deliveryDetails : "list",   //"list", #or "all"
  //     userList : finalList, //list of emails or one client_id
  //     messageType : messageType,
  //     messageSubject : messageSubject,
  //     messageText : messageText,
  //     videoLink : messageLink

  // }
  // )

  const handleSubmit = () => {
    // Handle form submission here
   submitMessage.refetch();
  };

  const ShowMessage = (msg) => {
    var snackBar = document.getElementById("snackbar");
    snackBar.innerHTML = msg;
    snackBar.className = "show";
    setTimeout(function () { snackBar.className = snackBar.className.replace("show", ""); }, 5000);
}

const buttonDisabled = messageType === "video" && parseInt(progress) != 100 ? true : false
//console.log(progress)

  return (
   <div>
    <form onSubmit={handleSubmit}>
      <div>
      <FormControl style={{margin: "20px"}} component="fieldset">
      <FormLabel component="legend">{getTranslation("Who to send this message to", preferredLanguage)}:</FormLabel>
      <RadioGroup aria-label="Who to send this message to"  value={deliveryDetails} onChange={handleDeliveryDetailsChange}>
        <FormControlLabel value="all" control={<Radio required  color="primary"/>} label={getTranslation("Entire Organization", preferredLanguage)} />
        <FormControlLabel value="list" control={<Radio required  color="primary"/>} label={getTranslation("Choose from a List", preferredLanguage)} />
      </RadioGroup>
    </FormControl>
    <br/>
       { deliveryDetails === "list" ? <FilteredSelection clearFields={clearFields} data={portalKeyData} list={list} setList={setList}/> : null }
          <FormControl style={{margin: "20px"}} component="fieldset">
      <FormLabel component="legend">{getTranslation("Message Type", preferredLanguage)}:</FormLabel>
      <RadioGroup  aria-label="message type"  value={messageType} onChange={handleTypeChange}>
        <FormControlLabel value="text" control={<Radio required color="primary"/>} label={getTranslation("Text", preferredLanguage)} />
        <FormControlLabel value="video" control={<Radio required color="primary"/>} label={getTranslation("Video", preferredLanguage)} />
      </RadioGroup>
    </FormControl>
      </div>
      <div>
          <TextField id="outlined-basic" style={{margin: "20px", width: 500}} required label={getTranslation("Message Subject", preferredLanguage)} variant="outlined" value={messageSubject}
            onChange={handleSubjectChange} />         
      </div>
      <div>
      <TextField id="outlined-basic" style={{margin: "20px", width: 500}}  required label={getTranslation("Message Text", preferredLanguage)} variant="outlined" multiline
          rows={4} value={messageText}
            onChange={handleTextChange} />  
      </div>
      <div style={{margin: "20px",}}>
      
      {/* {messageType === "video" ? <TextField id="outlined-basic" style={{margin: "20px", width: 500}} label="Video Link" variant="outlined" multiline
           value={messageLink}
          onChange={handleLinkChange} /> : null } */}
               {messageType === "video" ? <ImageUploadS3 style={{margin: "20px",}} progress={progress} setProgress={setProgress} handleLinkChange={handleLinkChange}/> : null }
      </div>
      <Button style={{margin: "20px",}} disabled={buttonDisabled} type="submit" variant="contained" color="primary" >
      {getTranslation("Send Message", preferredLanguage)}
        {/* Send Message Needs translation */}
        </Button>
        </form>
        <div id="snackbar" />
        </div>
  );
};

export default AdminMessageForm;