import React, { useState, useEffect, useRef } from 'react';
import InstallationMessage from './InstallationMessage';
import { useAuth0 } from './../../react-auth0-spa';
import Auth0Lock from 'auth0-lock';
import phishcloudIcon from './logo.png';
import './FirstLogin.css';
import chromeStoreLink from './../ChromeStoreLinkGlobal.js';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';

const Success = () => {
  useChangePageTitle('Success');

  const [extensionCheck, setExtensionCheck] = useState(null);
  const [desktopCheck, setDesktopCheck] = useState();

  const [token, setToken] = useState('');

  const { loading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userBrowser, setUserBrowser] = useState();

  useEffect(() => {
    detectBrowser();
    if (!loading && isAuthenticated) {
      getToken();
    }
  }, [loading]);

  const getToken = async () => {
    var token = await getAccessTokenSilently();
    setToken(token);
  };

  useInterval(() => {
    //--To be used when there is something to scan for--//
    //if (document.getElementById("PhishCloudExtensionActive") != null) {
    //    setExtensionCheck(true)
    //} else {
    //    setExtensionCheck(false)
    //}
    var div = document.getElementById('PhishCloudExtensionActive');
    if (div == null) {
      setExtensionCheck(false);
    } else {
      setExtensionCheck(true);
    }

    //console.log(extensionCheck);
  }, 4000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    //Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const detectBrowser = () => {
    // var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    // Safari 3.0+ "[object HTMLElementConstructor]"
    // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1 - 79
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1;

    if (isChrome === true) {
      setUserBrowser('Chrome');
    } else if (isFirefox === true) {
      setUserBrowser('Firefox');
    } else if (isIE === true) {
      setUserBrowser('InternetExplorer');
    } else if (isEdge === true) {
      setUserBrowser('Edge');
    } else if (isEdgeChromium === true) {
      setUserBrowser('EdgeChromium');
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const extensionBasedOnBrowser = () => {
    if (userBrowser === 'Chrome') {
      return (
        <span>
          <h4>
            <a href={chromeStoreLink}>Download the extension here.</a> If you are using Edge, you can still use the
            Chrome extension! Simply follow the link and click "Add to Chrome".
          </h4>
          <h4>
            After installing the extension, <button onClick={() => reloadPage()}>click here</button>
          </h4>
        </span>
      );
    } else if (userBrowser === 'Firefox') {
      return (
        <span>
          <h4>
            <a href=" https://addons.mozilla.org/en-US/firefox/addon/phishcloud/">
              Download the Firefox extension here
            </a>
          </h4>
          <h4>
            After installing the extension, <button onClick={() => reloadPage()}>click here</button> or refresh the
            page.
          </h4>
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const insertTokenHtml = () => {
    if (token != '') {
      return <div id="PhishCloudAccessToken" className={token}></div>;
    } else {
      return <div />;
    }
  };

  const renderMainBody = () => {
    if (extensionCheck === false) {
      return (
        <div>
          <h4>It looks like you do not have the extension installed yet.</h4>
          {extensionBasedOnBrowser()}
        </div>
      );
    } else if (extensionCheck === true) {
      return (
        <div>
          <h1>PhishCloud is signed in and ready to go!</h1>
          <br />
          <br />
          <p>
            You can close this window or go to the portal by clicking{' '}
            <a href="https://portal.phishcloud.io/Dashboard">here</a>
          </p>
        </div>
      );
    } else if (extensionCheck === null) {
      return <h1>Loading...</h1>;
    }
  };

  return (
    <div id="initial-login-container">
      {!loading ? renderMainBody() : <p>Loading...</p>}
      {insertTokenHtml()}
    </div>
  );
};
export default Success;
