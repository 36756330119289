import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';
import {NameAndShieldLogo} from '../../Assets/Logos/NameAndShieldLogo.png';
import {Shield} from '../../Assets/Logos/Shield.png';
import './CheckingOutsideLinkNotice.css';

const CheckingOutsideLinkNotice = (props) => {
  useChangePageTitle('CheckingOutsideLinkNotice');

  return (
      <div class="CheckingOutsideLinkNoticeBody">
      <Grid container direction="column" justifyContent='center' alignItems="center" >
          <Grid item xs={12} sm={12} md={12} lg={12}>
          <p class="CheckingOutsideLinkMessage">PhishCloud is checking the safety of the link you clicked.</p>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12}>
    <div class="CheckingOutsideLinkLogoWrapper">
        
        <div class="CheckingOutsideLinkLoader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div id="CheckingOutsideLinkLogoContainer">
    <img src={require('../../Assets/Logos/Shield.png')}/>
    </div>
    
        {/* <img src={NameAndShieldLogo} /> */}
      {/* <p>Phishcloud is currently checking the safety link you just clicked.</p> */}
    </div>
    </Grid>
    </Grid>
    </div>
  );
};
export default CheckingOutsideLinkNotice;