import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useForm from '../CustomHooks/useForm';

//------------Follow Up Message Dialogue-------------//

const MSSPNewFeatureDialogue = (props) => {
  const [finalMessage, setFinalMessage] = useState();

  //console.log(message);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      //maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    dialogue: {
      //width: '90vw',
      alignItems: 'center',
    },
    dialogueContent: {
      //width: 600,
    },
    dialogueText: {
      marginBottom: 20,
    },
  }));

  const classes = useStyles();

  const submitFinalMessage = (event) => {
    setFinalMessage(props.message);
    event.preventDefault();
    console.log(finalMessage);
  };

  //console.log(props.message);

  return (
    <Dialog open={props.isOpen} onClose={props.toggle} fullWidth={true} maxWidth={'sm'} className={classes.dialogue}>
      <DialogContent id={props.userRiskBorder} className={classes.dialogueContent}>
        {props.content}
      </DialogContent>
    </Dialog>
  );
};
export default MSSPNewFeatureDialogue;
