import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TextsmsIcon from '@material-ui/icons/Textsms';
import parsePhoneNumber from 'libphonenumber-js/max';
import React, { useEffect, useState } from 'react';
import Flag from 'react-world-flags';
import { Row } from 'reactstrap';
import { useAuth0 } from "@auth0/auth0-react";

import useChangePageTitle from '../CustomHooks/useChangePageTitle';
//import codes from 'country-calling-code';
import './Configuration.css';
import { TabPanel } from './TabPanel';
import TranslationModule from '../../../Translate/TranslationModule';
import AdminMessageForm from '../AdminMessages/AdminMessageForm';
import ImageUploadS3 from '../S3/ImageUploadS3';

const CompanyConfiguration = (props) => {
  useChangePageTitle('AdminConfiguration');

  const adminConfigData = props.adminConfigData
  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const [state, setState] = React.useState({
    sliderValue: [0, 100],
    slider: false,
    email: false,
    emailList: [],
    emailSeverityThreshold: 'high',
    SMS: false,
    SMSList: [],
    SMSSeverityThreshold: 'high',
    clientName: null,
    clientLogo: null,
  });

  const [trainingState, setTrainingState] = useState({
    trainingFlag: false,
    videoType: null,
    frequency: null,
    completionIndicator: null,
    video: false,
    pdf: false,
    quiz: false,
    yellowClick: false,
    redClick: false,
  });

  const [extraEmailInputs, setExtraEmailInputs] = useState([]);
  const [phoneInputs, setPhoneInputs] = useState([]);
  const [extraPhoneInputs, setExtraPhoneInputs] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);

  const [currentEmails, setCurrentEmails] = useState([]);
  const [emailInputs, setEmailInputs] = useState([]);
  const [currentPhoneNumbers, setCurrentPhoneNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isLoadingTraining, setIsLoadingTraining] = useState();

  const { getAccessTokenSilently } = useAuth0();

  if (props.selectedClient.clientId === undefined || props.selectedClient.clientId === '') {
    var clientId = props.defaultClient;
  } else {
    var clientId = props.selectedClient.clientId;
  }

  const trainingPush = () => {
    props.history.push('/Dashboard/TrainingSuite');

    window.scrollTo(0, 0);
  };
  //get admin config data

  // const adminConfigData = useQuery('admin-data', async () => {
  //   const token = await getAccessTokenSilently();
  //   return fetchApiData('https://portalapi-v2.phishcloud.io/api/portalapi-v2', token, 'adminConfigs','AccountAdmin',clientId);
  // });

  // console.log("adminConfig",adminConfigData,props)

  const getAdminConfigSettings = async () => {
    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
    const token = await getAccessTokenSilently();
    return new Promise(async (resolve, reject) => {
      setIsLoadingTraining(true);
      setIsLoading(true);

      fetch(api, {
        method: 'get',
        headers: {
          Action: 'adminConfigs',
          id: clientId,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            resolve(data);
            setState({
              sliderValue: [
                data.settings.ClientSafetyParameters.lowerSafetyLimit,
                data.settings.ClientSafetyParameters.upperSafetyLimit,
              ],
              slider: data.settings.ClientSafetyParameters.Enabled,
              email: data.settings.AlertSettings.AlertEmailSettings.Enabled,
              initialEmailList: data.settings.AlertSettings.AlertEmailSettings.Emails,
              emailSeverityThreshold: data.settings.AlertSettings.AlertEmailSettings.SeverityThreshold,
              SMS: data.settings.AlertSettings.alertSMSSettings.Enabled,
              SMSList: data.settings.AlertSettings.alertSMSSettings.PhoneNumbers,
              SMSSeverityThreshold: data.settings.AlertSettings.alertSMSSettings.SeverityThreshold,
              clientName: data.clientInfo.clientName,
              clientLogo: data.clientInfo.clientLogo,
            });
            setCurrentEmails(data.settings.AlertSettings.AlertEmailSettings.Emails);
            setCurrentPhoneNumbers(data.settings.AlertSettings.alertSMSSettings.PhoneNumbers);
            setIsLoading(false);

            setTrainingState({
              trainingFlag: data.settings.trainingSettings.trainingFlag,
              videoType: data.settings.trainingSettings.videoType,
              frequency: data.settings.trainingSettings.trainingFrequency,
              completionIndicator: data.settings.trainingSettings.completionIndicator,
              yellowClick: data.settings.trainingSettings.ongoingTraining.yellowClick,
              redClick: data.settings.trainingSettings.ongoingTraining.redClick,
            });
            setIsLoadingTraining(false);

            //console.log(data)
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    });
  };

  useEffect(() => {
    getAdminConfigSettings();
  }, []);

  //add country codes to phone numbers retrieved on page load
  if (extraPhoneInputs != undefined) {
    for (var i = 0; i < extraPhoneInputs.length; i++) {
      if (extraPhoneInputs[i].phoneNumber.startsWith('+1')) {
        extraPhoneInputs[i].countryCode = '+1';
      } else if (extraPhoneInputs[i].phoneNumber.startsWith('+81')) {
        extraPhoneInputs[i].countryCode = '+81';
      } else if (extraPhoneInputs[i].phoneNumber.startsWith('+44')) {
        extraPhoneInputs[i].countryCode = '+44';
      } else if (extraPhoneInputs[i].phoneNumber.startsWith('+90')) {
        extraPhoneInputs[i].countryCode = '+90';
      }
    }
  }

  const handleSubmitWithTraining = async (event) => {
    event.preventDefault();

    const token = await getAccessTokenSilently();
    if (props.accessLevel != undefined) {
      var data = {
        clientId: clientId,
        settings: {
          trainingSettings: {
            trainingFlag: trainingFlag,
            trainingFrequency: frequency,
            startDate: 'hardCoded',
            completionIndicator: completionIndicator,
            videoType: videoType,
            ongoingTraining: {
              yellowClick: yellowClick,
              redClick: redClick,
            },
          },
          clientSafetyParameters: {
            enabled: slider,
            upperSafetyLimit: sliderValue[0],
            lowerSafetyLimit: sliderValue[1],
          },
          alertSettings: {
            alertEmailSettings: {
              severityThreshold: emailSeverityThreshold,
              emails: FinalEmailListObject,
              enabled: email,
            },
            alertSMSSettings: {
              severityThreshold: SMSSeverityThreshold,
              phoneNumbers: FinalPhoneListObject,
              enabled: SMS,
            },
          },
          customLandingPageUnknown: null,
          customLandingPageUnsafe: null,
          clientLogo,
          clientName,
        },
      };
      var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

      if (emailErrorCheck === true) {
        ShowErrorMessage('Invalid Email');
      } else if (phoneErrorCheck === true) {
        ShowErrorMessage('Invalid Phone Number');
      } else {
        fetch(api, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            id: clientId,
            Authorization: `Bearer ${token}`,
            Action: 'updateAdminSettings',
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            if (response.status != 200) {
              console.log(
                'Looks like there was a problem. Status Code: ' +
                  response.status +
                  '\nStatus details: ' +
                  response.content
              );
              return;
            } else {
              // console.log('Success! Status Code: ' +
              //     response.status);
              //ShowSuccessMessage('Settings Saved');
              //console.log(response)
              return;
            }
          })
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
          });
      }
    }
  };

  //-------Settings Saved Message------//
  const ShowSuccessMessage = (msg) => {
    let x = document.getElementById('snackbar');
    x.innerHTML = msg;
    x.className = 'show';
    setTimeout(function () {
      x.className = x.className.replace('show', '');
    }, 3000);
  };

  const ShowErrorMessage = (msg) => {
    let x = document.getElementById('errorSnackbar');
    x.innerHTML = msg;
    x.className = 'show';
    setTimeout(function () {
      x.className = x.className.replace('show', '');
    }, 3000);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleTrainingChange = (event) => {
    setTrainingState({ ...trainingState, [event.target.name]: event.target.checked });
  };

  const handleRadioChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleTrainingRadioChange = (event) => {
    setTrainingState({ ...trainingState, [event.target.name]: event.target.value });
  };
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const {
    slider,
    sliderValue,
    email,
    emailList,
    emailSeverityThreshold,
    SMS,
    SMSList,
    SMSSeverityThreshold,
    clientName,
    clientLogo,
  } = state;

  const { trainingFlag, videoType, frequency, completionIndicator, video, pdf, quiz, yellowClick, redClick } =
    trainingState;

  /////----------Email Logic-------------/////

  let emailCheck =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //-------Handle textfiled onChange--------//
  const handleEmailListChange = (i, event) => {
    if (event.target.name === 'uE' && event.target.value.match(emailCheck)) {
      const values = [...extraEmailInputs];
      values[i].email = event.target.value;
      values[i].errorValue = false;
      values[i].errorTextEmail = '';
      setExtraEmailInputs(values);
    } else if (event.target.name === 'uE') {
      const values = [...extraEmailInputs];
      values[i].email = event.target.value;
      values[i].errorValue = true;
      values[i].errorTextEmail = 'Invalid email';
      setExtraEmailInputs(values);
    } else if (event.target.name === 'usersName') {
      const values = [...extraEmailInputs];
      values[i].name = event.target.value;
      setExtraEmailInputs(values);
    }
  };

  //-----Add new email text field-----//
  const handleEmailListAdd = () => {
    const values = [...extraEmailInputs];
    values.push({ email: '', name: '', errorValue: false, errorTextEmail: '' });
    setExtraEmailInputs(values);
  };

  //-----Remove email text field-----//
  const handleEmailListRemove = (i) => {
    const values = [...extraEmailInputs];
    values.splice(i, 1);
    setExtraEmailInputs(values);
  };

  //-----Only names and emails for updateClientSettings API call-----//
  let emailListObject = extraEmailInputs.map((emails) => ({
    name: emails.name,
    email: emails.email,
  }));

  let FinalEmailListObject = emailListObject.concat(currentEmails);

  //-------Only error Status for error message/submission prevention-------//
  let onlyEmailErrorObject = extraEmailInputs.map((emails) => ({
    error: emails.errorTextEmail,
  }));

  const emailErrorCheck = onlyEmailErrorObject.some((el) => el.error === 'Invalid email');

  //-------Handle Email Table Row Delete------//
  const handleEmailTableRowRemove = (i) => {
    const values = [...currentEmails];
    values.splice(i, 1);
    setCurrentEmails(values);
  };

  //--------Existing Emails Table-------//

  const ExistingEmailsTable = () => {
    const emailRows = currentEmails;

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    if (currentEmails && currentEmails.length) {
      return (
        <TableContainer component={Paper} className={classes.tableRoot}>
          <Table
            className={classes.table}
            stickyHeader
            size="medium"
            aria-label="list of current emails for alert notifications"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} align="left">
                {getTranslation("Name", preferredLanguage)}
                </TableCell>
                <TableCell className={classes.tableHead} align="left">
                {getTranslation("Email", preferredLanguage)}
                </TableCell>
                <TableCell className={classes.tableHead} align="right">
                  {getTranslation("Delete", preferredLanguage)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailRows.map((row, idx) => (
                <TableRow key={`${row}-${idx}`}>
                  <TableCell className={classes.dateCell} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="right">
                    <IconButton type="button" edge="end" onClick={() => handleEmailTableRowRemove(idx)}>
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={emailRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      );
    } else if (currentEmails.length === 0) {
      return <span></span>;
    }
  };

  /////----------SMS/Phone Logic-------------/////

  const handlePhoneListChange = (i, event) => {
    //create a copy of the existing phoneNumber data for editing/working with
    const values = [...extraPhoneInputs];

    //For changing the country code input
    if (event.target.name === 'countryCode') {
      //const values = [...extraPhoneInputs];
      values[i].countryCode = event.target.value;

      //Check the number in the phone number field every time the country code is changed
      let recheckPhoneNumber = parsePhoneNumber(event.target.value + values[i].phoneNumber);
      if (recheckPhoneNumber != undefined && !recheckPhoneNumber.isValid()) {
        values[i].errorValue = true;
        values[i].errorTextPhone = 'Invalid phone number';
      } else {
        values[i].errorValue = false;
        values[i].errorTextPhone = '';
      }

      setExtraPhoneInputs(values);
    }

    //For changing the phone number input
    if (
      event.target.value != undefined &&
      event.target.value != null &&
      event.target.value != ' ' &&
      event.target.name === 'phoneNumber' &&
      values[i].countryCode != undefined
    ) {
      //use the phone validation library to parse the entered country code and phone number for validation
      let enteredPhoneNumber = parsePhoneNumber(values[i].countryCode + event.target.value);

      if (enteredPhoneNumber != undefined && enteredPhoneNumber.isValid()) {
        //check if the phone number is valid and then set the values for that field set

        values[i].phoneNumber = event.target.value;
        values[i].errorValue = false;
        values[i].errorTextPhone = '';
        setExtraPhoneInputs(values);
      } else {
        //handle if the phone number is not valid - set error true and error message

        values[i].phoneNumber = event.target.value;
        values[i].errorValue = true;
        values[i].errorTextPhone = 'Invalid phone number';
        setExtraPhoneInputs(values);
      }
    } else if (event.target.name === 'usersName') {
      //handle the user's name input and set the values for that field set

      values[i].name = event.target.value;

      setExtraPhoneInputs(values);
    }
  };

  //-----Add new phone text field-----//
  const handlePhoneListAdd = () => {
    const values = [...extraPhoneInputs];
    values.push({ phoneNumber: '', name: '', countryCode: '', errorValue: false, errorTextPhone: '' });
    setExtraPhoneInputs(values);
  };

  //-----Remove phone text field-----//
  const handlePhoneListRemove = (i) => {
    const values = [...extraPhoneInputs];
    values.splice(i, 1);
    setExtraPhoneInputs(values);
  };

  //-----Only phone Numbers for updateClientSettings API call-----//
  let PhoneListObject = extraPhoneInputs.map((phoneNumbers) => ({
    name: phoneNumbers.name,
    phoneNumber: phoneNumbers.phoneNumber.startsWith('+')
      ? phoneNumbers.phoneNumber
      : phoneNumbers.countryCode + phoneNumbers.phoneNumber.replace(/-/g, '').split(' ').join(''),
  }));

  let FinalPhoneListObject = PhoneListObject.concat(currentPhoneNumbers);

  //console.log(FinalPhoneListObject)

  //-------Only error Status for error message/submission prevention-------//
  let onlyPhoneErrorObject = extraPhoneInputs.map((phoneNumbers) => ({
    error: phoneNumbers.errorTextPhone,
  }));

  const phoneErrorCheck = onlyPhoneErrorObject.some((el) => el.error === 'Invalid phone number');

  //--------Slider styling--------//
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%' + theme.spacing(3) * 2,
    },
    margin: {
      height: theme.spacing(4),
      width: theme.spacing(6),
    },
    Typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      flex: '1 1 100%',
    },
  }));

  function AirbnbThumbComponent(props) {
    return <span {...props}></span>;
  }

  const classes = useStyles();

  //--------Handle slider change-------//
  const handleSliderChange = (event, newValue) => {
    setState({ ...state, sliderValue: newValue });
  };

  //-------Handle Table Row Delete------//
  const handlePhoneTableRowRemove = (i) => {
    const values = [...currentPhoneNumbers];
    values.splice(i, 1);
    setCurrentPhoneNumbers(values);
  };

  //--------Existing Phone Numbers Table-------//

  const rows = currentPhoneNumbers;

  const ExistingPhoneNumbersTable = () => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    if (currentPhoneNumbers && currentPhoneNumbers.length) {
      return (
        <TableContainer component={Paper} className={classes.tableRoot}>
          <Table
            className={classes.table}
            stickyHeader
            size="medium"
            aria-label="list of current phone numbers for alert notifications"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} align="left">
                {getTranslation("Name", preferredLanguage)}

                </TableCell>
                <TableCell className={classes.tableHead} align="left">
                {getTranslation("Phone Number", preferredLanguage)}
 
                </TableCell>
                <TableCell className={classes.tableHead} align="right">
                {getTranslation("Delete", preferredLanguage)}

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <TableRow key={`${row}-${idx}`}>
                  <TableCell className={classes.dateCell} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.phonenumber}</TableCell>
                  <TableCell align="right">
                    <IconButton type="button" edge="end" onClick={() => handlePhoneTableRowRemove(idx)}>
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      );
    } else if (currentPhoneNumbers.length === 0) {
      return <span></span>;
    }
  };

  const TitleToolbarWithButton = (props) => {
    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" gutterBottom id="settingsTitle">
          {props.title}
        </Typography>

        <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '15px' }}>
        <TranslationModule text={"Apply Settings"} targetLanguage={props.preferredLanguage}/>
 
        </Button>
      </Toolbar>
    );
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      {isLoading}
      <form onSubmit={handleSubmitWithTraining} autoComplete="off" >
        <div className="configWrap" style={{ marginRight: '30px' }}>
          <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
          {getTranslation("Admin Tools", preferredLanguage)}{' '}
            <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '15px' }}>
          {getTranslation("Apply Settings", preferredLanguage)}
            </Button>
          </Typography>
          <br />
          {/* <Grid container spacing={2}> */}

          <AppBar position="static">
        <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
          <Tab label={getTranslation("General", preferredLanguage)} {...a11yProps(0)} />
          <Tab label={getTranslation("Notification", preferredLanguage)}{...a11yProps(1)} />
          <Tab label={getTranslation("Training", preferredLanguage)} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <Grid item xs={12} sm={12} md={12}>
              <Paper id="company-settings-rectangle">
                <FormGroup style={{ fontSize: '12px' }}>
                  <Typography variant="h6" className={classes.Typography} gutterBottom>
                  {getTranslation("Confidence Level", preferredLanguage)}{' '}
 
                  </Typography>
                  <FormControlLabel
                    style={{ paddingBottom: '20px' }}
                    control={<Switch color="primary" checked={slider} onChange={handleChange} name="slider" />}
                    label={getTranslation("manually set Confidence Level", preferredLanguage)} targetLanguage={props.preferredLanguage}
                  />
                  <br />
                  <br />
                  <br />
                  <Slider
                    value={sliderValue}
                    onChange={handleSliderChange}
                    ThumbComponent={AirbnbThumbComponent}
                    valueLabelDisplay="on"
                    getAriaLabel={(index) => (index === 0 ? 'Minimum confidence level' : 'Maximum confidence level')}
                    defaultValue={[20, 40]}
                    disabled={!slider}
                  />
                </FormGroup>
              </Paper>
              <Paper id="company-settings-rectangle">
              <Typography variant="h6" className={classes.Typography} gutterBottom>
              {getTranslation("Admin Messaging", preferredLanguage)}{' '}
              </Typography>
              <AdminMessageForm portalKeyData={props.portalKeyData} preferredLanguage={preferredLanguage} getTranslation={getTranslation} />
                

              </Paper>
            </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Grid item xs={12} sm={12} md={12}>
              <Paper id="company-settings-rectangle">
                <FormGroup style={{ fontSize: '12px', maxWidth: 750 }}>
                  <Typography variant="h6" className={classes.Typography} gutterBottom>
                  {getTranslation("Alerts", preferredLanguage)} 
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                  {getTranslation("How would you like to receive alert notifications?", preferredLanguage)} 
                  </Typography>
                  <br />
                  <br />

                  <Row>
                    <FormControlLabel
                      control={<Switch color="primary" checked={email} onChange={handleChange} name="email" />}
                      label={getTranslation("Email Alerts", preferredLanguage)}
                    />
                    <br />
                    <br />

                    <Button
                      type="button"
                      disabled={!email}
                      onClick={() => handleEmailListAdd()}
                      variant="contained"
                      color="primary"
                      style={{ margin: '10px', height: '56px', width: '59px' }}
                    >
                      <PersonAddIcon />
                    </Button>

                    {extraEmailInputs.map((field, idx) => {
                      return (
                        <div key={`${field}-${idx}`} style={{ padding: '10px', width: '100%' }}>
                          <TextField
                            required
                            disabled={!email}
                            //error={field.errorValue}
                            //helperText={field.errorTextEmail}
                            placeholder="Name"
                            value={field.name}
                            label={getTranslation("Name", preferredLanguage)}
                            name="usersName"
                            variant="outlined"
                            autoComplete="off"
                            style={{
                              maxWidth: 400,
                              minWidth: 170,
                              width: '45%',
                              paddingRight: '0px',
                              marginRight: '10px',
                              marginBottom: '15px',
                            }}
                            onChange={(e) => handleEmailListChange(idx, e)}
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />
                          <TextField
                            required
                            disabled={!email}
                            error={field.errorValue}
                            helperText={field.errorTextEmail}
                            placeholder="Email"
                            value={field.email}
                            label={getTranslation("Email", preferredLanguage)}
                            name="uE"
                            variant="outlined"
                            autoComplete="off"
                            style={{ maxWidth: 400, minWidth: 170, width: '45%', paddingRight: '0px' }}
                            onChange={(e) => handleEmailListChange(idx, e)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ paddingRight: '0px' }}>
                                  <IconButton type="button" edge="end" onClick={() => handleEmailListRemove(idx)}>
                                    <HighlightOffIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />
                        </div>
                      );
                    })}

                    {email && currentEmails.length ? <ExistingEmailsTable /> : null}
                  </Row>
                  <br />
                  <br />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <br />
                    <br />
                    <Typography variant="subtitle1" gutterBottom style={{ width: '100%' }}>
                    {getTranslation("For what severity level(s)?")}
                    </Typography>

                    <br />
                    <RadioGroup
                      aria-label="email alert severity threshold"
                      row
                      name="emailSeverityThreshold"
                      value={emailSeverityThreshold}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="high"
                        control={<Radio color="primary" disabled={!email} />}
                        label={getTranslation("Only High", preferredLanguage)}
                      />
                      <FormControlLabel
                        value="medium"
                        control={<Radio color="primary" disabled={!email} />}
                        label={getTranslation("High & Medium", preferredLanguage)}
                      />
                      <FormControlLabel value="all" control={<Radio color="primary" disabled={!email} />} label={getTranslation("All", preferredLanguage)} />
                    </RadioGroup>
                  </Row>
                </FormGroup>
                <br />
                <br />
                <FormGroup style={{ fontSize: '12px', maxWidth: 750 }}>
                  <Row>
                    <FormControlLabel
                      control={<Switch color="primary" checked={SMS} onChange={handleChange} name="SMS" />}
                      label={getTranslation("SMS Alerts", preferredLanguage)}
                    />
                    <br />
                    <br />

                    <Button
                      type="button"
                      disabled={!SMS}
                      onClick={() => handlePhoneListAdd()}
                      variant="contained"
                      color="primary"
                      style={{ margin: '10px', height: '56px' }}
                    >
                      <b>+</b>
                      <TextsmsIcon style={{ marginLeft: '0px' }} />
                    </Button>

                    {
                      //map through the phone inputs (existing user names and phone numbers are mapped out on page load, then additional phone inputs
                      //are made and mapped as the user adds more
                    }

                    {extraPhoneInputs.map((field, idx) => {
                      return (
                        <div key={`${field}-${idx}`} style={{ padding: '10px', width: '100%' }}>
                          <TextField
                            required
                            disabled={!SMS}
                            placeholder={getTranslation("Enter text", preferredLanguage)}
                            value={field.name}
                            label={getTranslation("Name", preferredLanguage)}
                            name="usersName"
                            variant="outlined"
                            autoComplete="off"
                            style={{
                              maxWidth: 400,
                              minWidth: 280,
                              width: '45%',
                              paddingRight: '0px',
                              marginRight: '10px',
                              marginBottom: '15px',
                            }}
                            onChange={(e) => handlePhoneListChange(idx, e)}
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />

                          <br />

                          <TextField
                            disabled={!SMS}
                            required
                            error={field.errorValue}
                            helperText={field.errorTextPhone}
                            placeholder={getTranslation("Enter text", preferredLanguage)}
                            value={field.phoneNumber}
                            label={getTranslation("Phone", preferredLanguage)}
                            name="phoneNumber"
                            variant="outlined"
                            autoComplete="off"
                            style={{
                              marginLeft: 0,
                              maxWidth: 400,
                              minWidth: 280,
                              width: '45%',
                              paddingRight: '0px',
                              height: '59px',
                            }}
                            onChange={(e) => handlePhoneListChange(idx, e)}
                            InputProps={{
                              startAdornment: (
                                <FormControl required style={{ marginRight: 70 }}>
                                  <Select
                                    required
                                    labelId="County Calling Code"
                                    name="countryCode"
                                    value={field.countryCode}
                                    onChange={(e) => handlePhoneListChange(idx, e)}
                                    label={getTranslation("Country Code", preferredLanguage)}
                                    defaultValue={field.countryCode}
                                    style={{ marginRight: 0, paddingRight: 0, width: '90px' }}
                                  >
                                    <MenuItem value={'+' + 1}>
                                      <Flag code="US" height="15" style={{ paddingRight: '5px' }} />
                                      US
                                    </MenuItem>
                                    <MenuItem value={'+' + 44}>
                                      <Flag code="GB" height="15" style={{ paddingRight: '5px' }} />
                                      UK
                                    </MenuItem>
                                    <MenuItem value={'+' + 81}>
                                      <Flag code="JP" height="15" style={{ paddingRight: '5px' }} />
                                      JP
                                    </MenuItem>
                                    <MenuItem value={'+' + 90}>
                                      <Flag code="TR" height="15" style={{ paddingRight: '5px' }} />
                                      TR
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              ),
                              endAdornment: (
                                <InputAdornment position="end" style={{ paddingRight: '0px' }}>
                                  <IconButton type="button" edge="end" onClick={() => handlePhoneListRemove(idx)}>
                                    <HighlightOffIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />
                        </div>
                      );
                    })}

                    {SMS && currentPhoneNumbers != undefined ? <ExistingPhoneNumbersTable /> : null}
                  </Row>
                  <br />
                  <br />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <br />
                    <br />
                    <Typography variant="subtitle1" gutterBottom style={{ width: '100%' }}>
                    {getTranslation("For what severity level(s)?", preferredLanguage)}
                    </Typography>
                    <br />
                    <RadioGroup
                      aria-label="SMS alert severity threshold"
                      row
                      name="SMSSeverityThreshold"
                      value={SMSSeverityThreshold}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="high"
                        control={<Radio color="primary" disabled={!SMS} />}
                        label={getTranslation("Only High", preferredLanguage)}
                      />
                      <FormControlLabel
                        value="medium"
                        control={<Radio color="primary" disabled={!SMS} />}
                        label={getTranslation("High & Medium", preferredLanguage)}
                      />
                      <FormControlLabel value="all" control={<Radio color="primary" disabled={!SMS} />} label={getTranslation("All", preferredLanguage)} />
                    </RadioGroup>
                  </Row>
                </FormGroup>
              </Paper>
            </Grid>      </TabPanel>
      <TabPanel value={value} index={2}>
      {!isLoadingTraining ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper id="company-settings-rectangle">
                  <FormGroup style={{ fontSize: '12px' }}>
                    <Toolbar>
                      <Typography variant="h6" className={classes.title} gutterBottom>
                      {getTranslation("Training")} 
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        width="25%"
                        style={{ fontSize: '.8rem', float: 'right' }}
                        onClick={trainingPush}
                      >
                      {getTranslation("Start Assigning Training", preferredLanguage)}

                      </Button>
                    </Toolbar>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={trainingFlag}
                          onChange={handleTrainingChange}
                          name="trainingFlag"
                        />
                      }
                      label="Training"
                    />

                    {trainingFlag ? (
                      <React.Fragment>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1" gutterBottom>
                          {getTranslation("Video Style", preferredLanguage)}
                          </Typography>
                          <br />
                          <RadioGroup
                            aria-label="SMS alert severity threshold"
                            row
                            name="videoType"
                            value={videoType}
                            onChange={handleTrainingRadioChange}
                          >
                            <FormControlLabel value="A" control={<Radio color="primary" />} label={getTranslation("Anime", preferredLanguage) } />
                            <FormControlLabel value="C" control={<Radio color="primary" />} label={getTranslation("Corporate", preferredLanguage)} />
                          </RadioGroup>
                          <br />

                          <Typography variant="subtitle1" gutterBottom>
                          {getTranslation("Training Frequency", preferredLanguage)}
                          </Typography>
                          <br />
                          <RadioGroup
                            aria-label="SMS alert severity threshold"
                            row
                            name="frequency"
                            value={frequency}
                            onChange={handleTrainingRadioChange}
                          >
                            <FormControlLabel
                              value="M"
                              control={
                                <Radio
                                  color="primary"
                                  //disabled={!SMS}
                                />
                              }
                              label={getTranslation("Monthly", preferredLanguage)}
                            />
                            <FormControlLabel
                              value="Q"
                              control={
                                <Radio
                                  color="primary"
                                  //disabled={!SMS}
                                />
                              }
                              label={getTranslation("Quarterly", preferredLanguage)}
                            />
                            <FormControlLabel
                              value="B"
                              control={
                                <Radio
                                  color="primary"
                                  //disabled={!SMS}
                                />
                              }
                              label={getTranslation("Bi-Annually", preferredLanguage)}
                            />
                            <FormControlLabel
                              value="A"
                              control={
                                <Radio
                                  color="primary"
                                  //disabled={!SMS}
                                />
                              }
                              label="Annually"
                            />
                          </RadioGroup>
                          <br />

                          <Typography variant="subtitle1" gutterBottom>
                          {getTranslation("Requirement", preferredLanguage)}
                          </Typography>
                          <br />
                          <RadioGroup
                            aria-label="SMS alert severity threshold"
                            row
                            name="completionIndicator"
                            value={completionIndicator}
                            onChange={handleTrainingRadioChange}
                          >
                            <FormControlLabel
                              value="V"
                              control={
                                <Radio
                                  color="primary"
                                  //disabled={!SMS}
                                />
                              }
                              label={getTranslation("Video watched", preferredLanguage)}
                            />
                            <FormControlLabel
                              value="P"
                              control={
                                <Radio
                                  color="primary"
                                  //disabled={!SMS}
                                />
                              }
                              label={getTranslation("PDF read", preferredLanguage)} 
                            />
                            <FormControlLabel
                              value="Q"
                              control={
                                <Radio
                                  color="primary"
                                  //disabled={!SMS}
                                />
                              }
                              label={getTranslation("Quiz taken with a passing score", preferredLanguage)}
                            />
                          </RadioGroup>
                          <br />
                        </Grid>

                        <Typography variant="subtitle1" gutterBottom>
                        {getTranslation("Ongoing", preferredLanguage)}
                        </Typography>

                        <br />
                        <Typography variant="subtitle2" gutterBottom>
                        {getTranslation("If user clicked on a yellow link with related training:", preferredLanguage)}
                        </Typography>

                        <RadioGroup
                          aria-label="SMS alert severity threshold"
                          row
                          name="yellowClick"
                          value={yellowClick}
                          onChange={handleTrainingRadioChange}
                        >
                          <FormControlLabel
                            value="suggestTrainingYellowClick"
                            control={
                              <Radio
                                color="primary"
                                //disabled={!SMS}
                              />
                            }
                            label={getTranslation("Suggest training", preferredLanguage)}
                          />
                          <FormControlLabel
                            value="requireTrainingYellowClick"
                            control={
                              <Radio
                                color="primary"
                                //disabled={!SMS}
                              />
                            }
                            label={getTranslation("Require training", preferredLanguage)}
                          />
                          <FormControlLabel
                            value="N"
                            control={
                              <Radio
                                color="primary"
                                //disabled={!SMS}
                              />
                            }
                            label={getTranslation("No action", preferredLanguage)} 
                          />
                        </RadioGroup>
                        <br />

                        <Typography variant="subtitle2" gutterBottom>
                        {getTranslation("If user clicked on a red link with related training:", preferredLanguage)}
                        </Typography>

                        <RadioGroup
                          aria-label="SMS alert severity threshold"
                          row
                          name="redClick"
                          value={redClick}
                          onChange={handleTrainingRadioChange}
                        >
                          <FormControlLabel
                            value="suggestTrainingRedClick"
                            control={
                              <Radio
                                color="primary"
                                //disabled={!SMS}
                              />
                            }
                            label={getTranslation("Suggest training", preferredLanguage)}
                          />
                          <FormControlLabel
                            value="requireTrainingRedClick"
                            control={
                              <Radio
                                color="primary"
                                //disabled={!SMS}
                              />
                            }
                            label={getTranslation("Require training", preferredLanguage)}
                          />
                          <FormControlLabel
                            value="N"
                            control={
                              <Radio
                                color="primary"
                                //disabled={!SMS}
                              />
                            }
                            label={getTranslation("No action", preferredLanguage)}
                          />
                        </RadioGroup>
                      </React.Fragment>
                    ) : null}

                    {/* <Typography variant="subtitle2" gutterBottom>
                                    If user risk is > xx:
                                </Typography>

                                <RadioGroup aria-label="SMS alert severity threshold"
                                    row
                                    name="SMSSeverityThreshold"
                                    //value={SMSSeverityThreshold}
                                    onChange={handleRadioChange}
                                >

                                    <FormControlLabel
                                        value="suggestTrainingUserRisk"
                                        control={<Radio color="primary"
                                        //disabled={!SMS}
                                        />}
                                        label="Suggest training"
                                    />
                                    <FormControlLabel
                                        value="requireTrainingUserRisk"
                                        control={<Radio color="primary"
                                        //disabled={!SMS}
                                        />}
                                        label="Require training"
                                    />
                                    <FormControlLabel
                                        value="noActionUserRisk"
                                        control={<Radio color="primary"
                                        //disabled={!SMS}
                                        />}
                                        label="No action"
                                    />
                                </RadioGroup> */}
                  </FormGroup>
                </Paper>
              </Grid>
            ) : (
              <span></span>
            )}
      </TabPanel>
          {/* <div id="snackbar" /> */}
          <div id="errorSnackbar" />

           
           



            {/* <Grid item>
              <span id="applySettingsButton">
                <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '15px' }}>
                  Apply Settings
                </Button>
              </span>
            </Grid> */}
          {/* </Grid> */}
        </div>
      </form>
    </div>
  );
};
export default CompanyConfiguration;
