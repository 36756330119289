import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import DeviceItem from './DeviceItem';
import './UserDevices.css';
import basePCUrl from './../../../../Globals';
var baseUrl = basePCUrl + 'UserData/GetUserDevices';

const UserDevices = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const devices = props.devices;
  //-------Logic/Render-------//
  const renderDeviceList = (devices) => {
    return (
      <div className="user-devices-text">
        {renderDeviceCount(devices)}
        <span className="device-list">
          <ul>
            {devices != null && devices != undefined ? (
              devices.map((value, index) => (
                // <DeviceItem key={index} deviceName={value.windowsUsername} deviceIP={value.ipAddress} />
                <li key={index}>
                  <h5>{value}</h5>
                </li>
              ))
            ) : (
              <span></span>
            )}
          </ul>
        </span>
      </div>
    );
  };

  const renderDeviceCount = () => {
    if (devices != null && devices != undefined) {
      let deviceCount = 0;
      for (var i = 0; i < devices.length; i++) {
        deviceCount++;
      }
      if (deviceCount === 1) {
        return <p id="deviceCount">( {deviceCount} ) {getTranslation("Device", preferredLanguage)}</p>;
      } else return <p id="deviceCount">( {deviceCount} ) {getTranslation("Devices", preferredLanguage)}</p>;
    } else {
      return <p id="deviceCount">{getTranslation("No Devices", preferredLanguage)}</p>;
    }
  };

  return <div>{renderDeviceList(devices)}</div>;
};
export default UserDevices;
