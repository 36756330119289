const CompanyInfoSeenLinksMockData = [
  {
    badLink: 'https://cafepress.com/donec/odio/justo.js',
    link_safety: 'Unsafe',
    link_times_seen: 18,
    link_details: 'http://reuters.com/hac/habitasse/platea.jsp',
  },
  {
    badLink: 'https://merriam-webster.com/et/ultrices/posuere/cubilia/curae.png',
    link_safety: 'Unsafe',
    link_times_seen: 21,
    link_details: 'http://epa.gov/ut/erat/id/mauris/vulputate.xml',
  },
  {
    badLink: 'https://uol.com.br/et/magnis/dis/parturient/montes.png',
    link_safety: 'Unknown',
    link_times_seen: 8,
    link_details: 'http://xrea.com/pede/ullamcorper/augue.png',
  },
  {
    badLink: 'http://mapquest.com/rutrum.html',
    link_safety: 'Unknown',
    link_times_seen: 73,
    link_details: 'https://wisc.edu/felis/eu/sapien/cursus/vestibulum.html',
  },
  {
    badLink: 'http://about.me/eu/felis/fusce/posuere/felis/sed/lacus.jsp',
    link_safety: 'Unsafe',
    link_times_seen: 88,
    link_details: 'http://google.ru/et/ultrices.html',
  },
  {
    badLink: 'https://nbcnews.com/pretium/nisl/ut/volutpat/sapien/arcu.xml',
    link_safety: 'Unsafe',
    link_times_seen: 18,
    link_details: 'https://netlog.com/id/mauris/vulputate/elementum/nullam/varius/nulla.json',
  },
  {
    badLink: 'https://umich.edu/ante.json',
    link_safety: 'Unsafe',
    link_times_seen: 74,
    link_details: 'https://barnesandnoble.com/vitae.jsp',
  },
  {
    badLink: 'https://arstechnica.com/lorem/quisque/ut.png',
    link_safety: 'Safe',
    link_times_seen: 59,
    link_details: 'https://oakley.com/ac/tellus/semper/interdum/mauris.aspx',
  },
  {
    badLink: 'https://about.com/condimentum.html',
    link_safety: 'Unsafe',
    link_times_seen: 23,
    link_details: 'https://disqus.com/in/blandit/ultrices/enim/lorem.jpg',
  },
  {
    badLink: 'http://constantcontact.com/sed/vestibulum/sit/amet/cursus/id.jsp',
    link_safety: 'Safe',
    link_times_seen: 47,
    link_details: 'http://seattletimes.com/odio/condimentum/id/luctus/nec.aspx',
  },
  {
    badLink: 'http://gov.uk/est/lacinia.json',
    link_safety: 'Unsafe',
    link_times_seen: 65,
    link_details: 'https://spiegel.de/duis/mattis/egestas/metus/aenean.aspx',
  },
  {
    badLink: 'https://wikia.com/lectus/suspendisse/potenti/in/eleifend/quam.png',
    link_safety: 'Safe',
    link_times_seen: 35,
    link_details: 'https://answers.com/primis/in.xml',
  },
  {
    badLink: 'http://abc.net.au/gravida/nisi/at/nibh/in/hac/habitasse.xml',
    link_safety: 'Unsafe',
    link_times_seen: 31,
    link_details: 'https://gizmodo.com/urna/pretium/nisl/ut.png',
  },
  {
    badLink: 'https://marketwatch.com/sem/praesent.html',
    link_safety: 'Unsafe',
    link_times_seen: 88,
    link_details: 'https://4shared.com/in.aspx',
  },
  {
    badLink: 'https://is.gd/dapibus/augue/vel/accumsan/tellus.png',
    link_safety: 'Unsafe',
    link_times_seen: 13,
    link_details: 'http://ebay.com/neque/vestibulum/eget/vulputate.png',
  },
  {
    badLink: 'http://jugem.jp/venenatis/tristique/fusce.js',
    link_safety: 'Unknown',
    link_times_seen: 42,
    link_details: 'http://discovery.com/purus/phasellus.xml',
  },
  {
    badLink: 'https://squidoo.com/lacus/at/velit/vivamus.jsp',
    link_safety: 'Unknown',
    link_times_seen: 85,
    link_details: 'http://google.com.br/cubilia/curae/donec/pharetra/magna.html',
  },
  {
    badLink: 'https://ebay.co.uk/sapien.png',
    link_safety: 'Safe',
    link_times_seen: 100,
    link_details: 'http://bloglines.com/volutpat/quam.html',
  },
  {
    badLink: 'https://patch.com/ultrices/phasellus/id/sapien/in/sapien/iaculis.json',
    link_safety: 'Safe',
    link_times_seen: 62,
    link_details: 'http://creativecommons.org/id/consequat/in/consequat/ut/nulla/sed.aspx',
  },
  {
    badLink: 'https://ucla.edu/a/libero/nam/dui/proin/leo.jpg',
    link_safety: 'Safe',
    link_times_seen: 56,
    link_details: 'https://zdnet.com/amet/lobortis/sapien.png',
  },
  {
    badLink: 'http://tripadvisor.com/aliquet/pulvinar/sed.jpg',
    link_safety: 'Safe',
    link_times_seen: 96,
    link_details: 'https://exblog.jp/suscipit/nulla/elit.js',
  },
  {
    badLink: 'https://e-recht24.de/diam/id/ornare/imperdiet/sapien.jpg',
    link_safety: 'Unknown',
    link_times_seen: 60,
    link_details: 'https://skyrock.com/sit/amet/nunc/viverra.aspx',
  },
  {
    badLink: 'http://cargocollective.com/habitasse/platea/dictumst/etiam/faucibus/cursus.png',
    link_safety: 'Unknown',
    link_times_seen: 39,
    link_details: 'http://tiny.cc/mus/etiam/vel/augue.js',
  },
  {
    badLink: 'https://smh.com.au/quam/a/odio/in/hac/habitasse.html',
    link_safety: 'Unknown',
    link_times_seen: 44,
    link_details: 'https://livejournal.com/pellentesque/volutpat/dui/maecenas/tristique.js',
  },
  {
    badLink: 'http://pbs.org/platea/dictumst/aliquam/augue/quam.jpg',
    link_safety: 'Unsafe',
    link_times_seen: 67,
    link_details: 'https://blogs.com/vitae/nisi/nam/ultrices/libero/non.png',
  },
  {
    badLink: 'https://toplist.cz/rhoncus.json',
    link_safety: 'Unknown',
    link_times_seen: 5,
    link_details: 'https://unc.edu/quam.js',
  },
  {
    badLink: 'http://usgs.gov/aliquam/augue/quam/sollicitudin/vitae.png',
    link_safety: 'Safe',
    link_times_seen: 88,
    link_details: 'http://dmoz.org/erat/fermentum/justo/nec/condimentum/neque/sapien.jsp',
  },
  {
    badLink: 'https://slideshare.net/ultrices/erat/tortor/sollicitudin.jpg',
    link_safety: 'Unknown',
    link_times_seen: 96,
    link_details: 'http://mail.ru/aliquam/erat/volutpat/in.jsp',
  },
  {
    badLink: 'http://ftc.gov/imperdiet/sapien/urna.js',
    link_safety: 'Safe',
    link_times_seen: 30,
    link_details: 'http://sciencedaily.com/phasellus/in.jpg',
  },
  {
    badLink: 'https://instagram.com/in/faucibus/orci/luctus/et.jpg',
    link_safety: 'Unknown',
    link_times_seen: 14,
    link_details: 'https://timesonline.co.uk/ullamcorper/purus/sit/amet/nulla/quisque/arcu.jpg',
  },
  {
    badLink: 'https://msn.com/posuere/metus.xml',
    link_safety: 'Unsafe',
    link_times_seen: 2,
    link_details: 'https://usgs.gov/orci/vehicula.png',
  },
  {
    badLink: 'https://zdnet.com/sapien.jpg',
    link_safety: 'Unsafe',
    link_times_seen: 21,
    link_details: 'https://vistaprint.com/volutpat/eleifend/donec/ut/dolor.aspx',
  },
  {
    badLink: 'http://theguardian.com/curabitur/gravida/nisi/at/nibh/in/hac.html',
    link_safety: 'Unknown',
    link_times_seen: 10,
    link_details: 'https://fda.gov/odio/condimentum/id/luctus/nec/molestie/sed.js',
  },
  {
    badLink: 'https://cdc.gov/vel/accumsan/tellus.js',
    link_safety: 'Unknown',
    link_times_seen: 72,
    link_details: 'http://addtoany.com/hac/habitasse/platea/dictumst/aliquam/augue.json',
  },
  {
    badLink: 'http://nytimes.com/metus/arcu/adipiscing/molestie/hendrerit.xml',
    link_safety: 'Unsafe',
    link_times_seen: 83,
    link_details: 'http://privacy.gov.au/molestie.jpg',
  },
  {
    badLink: 'https://joomla.org/mattis/pulvinar/nulla/pede/ullamcorper/augue.jsp',
    link_safety: 'Unknown',
    link_times_seen: 42,
    link_details: 'http://china.com.cn/libero.json',
  },
  {
    badLink: 'https://apple.com/ullamcorper/augue/a/suscipit.aspx',
    link_safety: 'Unsafe',
    link_times_seen: 95,
    link_details: 'https://newyorker.com/lacinia/eget/tincidunt/eget/tempus/vel.png',
  },
  {
    badLink: 'https://tinyurl.com/mattis/nibh.html',
    link_safety: 'Unknown',
    link_times_seen: 55,
    link_details: 'https://wisc.edu/ut/blandit/non/interdum/in/ante/vestibulum.html',
  },
  {
    badLink: 'https://twitpic.com/quisque/ut.html',
    link_safety: 'Unknown',
    link_times_seen: 48,
    link_details: 'http://vinaora.com/nulla/integer/pede.jpg',
  },
  {
    badLink: 'https://ihg.com/nam/nulla/integer/pede/justo.js',
    link_safety: 'Unknown',
    link_times_seen: 15,
    link_details: 'http://stanford.edu/quis/orci/nullam/molestie.xml',
  },
  {
    badLink: 'https://examiner.com/in/faucibus/orci.js',
    link_safety: 'Unknown',
    link_times_seen: 89,
    link_details: 'http://people.com.cn/justo/etiam/pretium.aspx',
  },
  {
    badLink: 'https://dyndns.org/suscipit/nulla/elit.xml',
    link_safety: 'Unknown',
    link_times_seen: 37,
    link_details: 'http://ucla.edu/suspendisse/potenti/in/eleifend/quam/a/odio.aspx',
  },
  {
    badLink: 'https://joomla.org/et.xml',
    link_safety: 'Safe',
    link_times_seen: 30,
    link_details: 'http://histats.com/ante/vestibulum/ante/ipsum/primis/in/faucibus.jsp',
  },
  {
    badLink: 'http://berkeley.edu/et/ultrices/posuere.png',
    link_safety: 'Unknown',
    link_times_seen: 52,
    link_details: 'https://1688.com/interdum/in/ante.html',
  },
  {
    badLink: 'http://skyrock.com/sit/amet/consectetuer.html',
    link_safety: 'Unsafe',
    link_times_seen: 58,
    link_details: 'http://sun.com/massa/donec/dapibus/duis/at/velit.js',
  },
  {
    badLink: 'http://comcast.net/viverra/eget/congue/eget/semper/rutrum/nulla.png',
    link_safety: 'Unsafe',
    link_times_seen: 93,
    link_details: 'http://paypal.com/rutrum/rutrum/neque.xml',
  },
  {
    badLink: 'https://princeton.edu/posuere/nonummy/integer/non.html',
    link_safety: 'Unknown',
    link_times_seen: 27,
    link_details: 'https://storify.com/blandit/ultrices/enim/lorem/ipsum/dolor.json',
  },
  {
    badLink: 'http://europa.eu/vel/est/donec.html',
    link_safety: 'Unknown',
    link_times_seen: 67,
    link_details: 'https://youku.com/eget/vulputate/ut.aspx',
  },
  {
    badLink: 'https://accuweather.com/morbi.js',
    link_safety: 'Safe',
    link_times_seen: 21,
    link_details: 'http://mac.com/morbi.xml',
  },
  {
    badLink: 'https://japanpost.jp/lacus/morbi/quis/tortor/id.html',
    link_safety: 'Unknown',
    link_times_seen: 69,
    link_details: 'http://dmoz.org/amet/turpis.html',
  },
];

export default CompanyInfoSeenLinksMockData;
