import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import './Error.css';

const ErrorUserNotFound = (props) => {
  useChangePageTitle('ErrorUserNotFound');

  const { logout } = useAuth0();
  const [count, setCount] = useState(15);

  useEffect(() => {
    if (count === 0) {
      logout({
        returnTo: 'https://portal.phishcloud.io/PortalSignIn',
      });
    }

    // exit early when we reach 0
    if (!count) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setCount(count - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [count]);

  return (
    <div className="errorContainer">
      <div className="errorBody">
        <p className="errorText">
          Your login information was not associated with a phishcloud account.
          <br />
          <br />
          Click{' '}
          <Link
            onClick={() =>
              logout({
                returnTo: 'https://portal.phishcloud.io/PortalSignIn',
              })
            }
          >
            here
          </Link>{' '}
          to try again with different credentials or wait {count} seconds.
        </p>
      </div>
    </div>
  );
};
export default ErrorUserNotFound;
