import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

//--------------Material UI Imports--------------//
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import TranslationModule from '../../../Translate/TranslationModule';
import './MSSP.css';

const MSSPReportGenButton = (props) => {
  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

  return (
    <Button
      onClick={props.toggleMSSPNewFeatureDialogue}
      color="primary"
      variant="contained"
      style={{ height: '56px', marginLeft: '25px', borderRadius: '50px' }}
      disableElevation
    >
      <TranslationModule text={"Generate Report"} targetLanguage={props.preferredLanguage}/>
    </Button>
  );
};
export default MSSPReportGenButton;
