
import React, { useEffect } from 'react';
import { useHistory, useLocation, Route, Switch, Redirect, matchPath } from 'react-router-dom';

const RedirectOnNotFound = ({ children, redirectTo }) => {
  const history = useHistory();
  const location = useLocation();

  const isPathMatched = (path, location) => {
    return matchPath(location.pathname, { path, exact: true });
  };

  const checkNestedPaths = (children, location, parentPath = '') => {
    //console.log(children)
    return children.some((child) => {
      const path = child.props.path || child.props.computedMatch.path;
      const fullPath = `${parentPath}${path}`;
      //console.log(fullPath)
      return (
        isPathMatched(fullPath, location) ||
        (child.props.children && checkNestedPaths(child.props.children, location, fullPath))
      );
    });
  };

  useEffect(() => {
    // Check if the current location matches any of the routes or nested routes
    const isRouteMatched = checkNestedPaths(children, location);
    //console.log(isRouteMatched)
    // Check if the current location has query parameters
    const hasQueryParams = location.search.trim() !== '';

    // If no route is matched, redirect to the specified page
    if (!isRouteMatched && !hasQueryParams) {
      history.push('/Dashboard/History');
    } else if (!isRouteMatched && location.pathname.startsWith('/Dashboard/')) {
      history.push('/Dashboard/History');
    }
  }, [children, history, location.pathname, redirectTo]);

  return <Switch>{children}</Switch>;
};
export default RedirectOnNotFound;
