import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { useAuth0 } from "@auth0/auth0-react";

import { fetchApiData } from '../../../utils/service';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import CyberSecurityNewsfeed from './CyberSecurityNewsfeed/CyberSecurityNewsfeed';
import GoogleLinkHistoryChart from './GoogleLinkHistoryChart/GoogleLinkHistoryChart';
import LinkHistoryTable from './LinkHistoryTable/LinkHistoryTable';
import TrainingColumn from './TrainingColumn/TrainingColumn';
import './UserLinkHistory.css';
import RestructuredUserStatistitcsWidgetsRow from './UserStatisticsWidgets/RestructuredUserStatisticsWidgetsRow';

//----Accounting for null Chart Data----//
const today = new Date();
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const thirdDay = new Date(today.getTime() + 48 * 60 * 60 * 1000);
const fourthDay = new Date(today.getTime() + 72 * 60 * 60 * 1000);
const fifthDay = new Date(today.getTime() + 96 * 60 * 60 * 1000);
const sixthDay = new Date(today.getTime() + 120 * 60 * 60 * 1000);
const seventhDay = new Date(today.getTime() + 144 * 60 * 60 * 1000);

const accountForNullChartData = [
  [today.toJSON(), 0, 0, 0],
  [tomorrow.toJSON(), 0, 0, 0],
  [thirdDay.toJSON(), 0, 0, 0],
  [fourthDay.toJSON(), 0, 0, 0],
  [fifthDay.toJSON(), 0, 0, 0],
  [sixthDay.toJSON(), 0, 0, 0],
  [seventhDay.toJSON(), 0, 0, 0],
];
const accountForNullTableData = {
  safe: [{ lastLink: '', safety: '', cl: '', timeSeen: '', linkDetails: '' }],
  unknown: [{ lastLink: '', safety: '', cl: '', timeSeen: '', linkDetails: '' }],
  unsafe: [{ lastLink: '', safety: '', cl: '', timeSeen: '', linkDetails: '' }],
};

const RestructuredUserLinkHistory = (props) => {
  useChangePageTitle('History');
  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1;
  //console.log('renderCount', renderCounter.current);
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [training, setTraining] = useState(true);

  const handleChange = (event) => {
    setTraining(!training);
  };


const userBoxData = props.userBoxData
const linkHistoryData = props.linkHistoryData
const trainingData = props.trainingData
const currentNewsData = props.currentNewsData
const newsFeed = props.newsFeed
const adminConfigData = props.adminConfigData


  //-------getUserDeviceData API Call-------//
  const accountForNullDeviceData = [{ windowsUsername: 'No devices detected', ipAddress: 0 }];

  const useStyles = makeStyles((theme) => ({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  }));

  const classes = useStyles();

  const preferredLanguage = props.preferredLanguage;
  const isLoading = props.isLoading
  const hasTraining = props.hasTraining

  return (
    <div id="userHistoryContainer">
      <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
      {props.getTranslation("my history", preferredLanguage)}
      </Typography>
      <React.Fragment>
        <div>
          {isLoading ? (
            <Skeleton style={{ maxWidth: '2000px', width: '80vw', height: '400px' }} />
          ) : isLoading === false ? (
            <RestructuredUserStatistitcsWidgetsRow
              loading={isLoading}
              getTranslation={props.getTranslation}
              risk={userBoxData.userRiskScore}
              history={props.history}
              safeCount={userBoxData.safeLinks}
              unknownCount={userBoxData.unknownLinks}
              unsafeCount={userBoxData.unsafeLinks}
              devices={userBoxData.deviceList}
              preferredLanguage={preferredLanguage}
            />
          ) : (
            <Skeleton style={{ maxWidth: '2000px', width: '80vw', height: '400px' }} />
          )}
        </div>
        {isLoading || hasTraining === true ? (
          <Row>
            <Col md="8">
              <div>
                {isLoading ? (
                  <Skeleton style={{ width: '100%', height: '400px' }} />
                ) : isLoading === false ? (
                  <LinkHistoryTable
                    accessLevel={props.accessLevel}
                    history={props.history}
                    tableData={linkHistoryData?.linkHistory[0] || []}
                    isLoading={isLoading}
                    getTranslation={props.getTranslation}
                    preferredLanguage={preferredLanguage}
                  />
                ) : (
                  <Skeleton style={{ width: '100%', height: '400px' }} />
                )}
              </div>
              <div>
                {isLoading ? (
                  <Skeleton style={{ width: '100%', height: '400px' }} />
                ) : isLoading === false ? (
                  <GoogleLinkHistoryChart
                    history={props.history}
                    chartData={linkHistoryData?.chartData || []}
                    getTranslation={props.getTranslation}
                    preferredLanguage={preferredLanguage}
                  />
                ) : (
                  <Skeleton style={{ width: '100%', height: '400px' }} />
                )}
              </div>
            </Col>
            <Col md="4">
              {isLoading ? (
                <Skeleton width="100%" height="100%" />
              ) : isLoading === false ? (
                <TrainingColumn
                  trainingData={trainingData.trainingItems || []}
                  history={props.history}
                  getTranslation={props.getTranslation}
                  preferredLanguage={preferredLanguage}
                />
              ) : (
                <Skeleton width="100%" height="100%" />
              )}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <div>
                {
                  isLoading ? (
                    <Skeleton style={{ width: '100%', height: '400px' }} />
                  ) : (
                    <LinkHistoryTable
                      accessLevel={props.accessLevel}
                      history={props.history}
                      tableData={linkHistoryData?.linkHistory[0]}
                      isLoading={isLoading}
                      getTranslation={props.getTranslation}
                      preferredLanguage={props.preferredLanguage}
                    />
                  )
                  // ) : (
                  //   <Skeleton style={{ width: '100%', height: '400px' }} />
                  // )
                }
              </div>
              <div>
                {
                  isLoading ? (
                    <Skeleton style={{ width: '100%', height: '400px' }} />
                  ) : (
                    <GoogleLinkHistoryChart
                      history={props.history}
                      chartData={linkHistoryData.chartData}
                      getTranslation={props.getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )
                  // : (
                  //   <Skeleton style={{ width: '100%', height: '400px' }} />
                  // )
                }
              </div>
            </Col>
          </Row>
        )}
        <div>
          <Row>
            <Col>
              {isLoading ? (
                <Skeleton style={{ width: '100%', height: '400px' }} />
              ) : (
                <CyberSecurityNewsfeed currentNews={currentNewsData.newsUrls} newsFeed={newsFeed} getTranslation={props.getTranslation} preferredLanguage={preferredLanguage} />
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </div>
  );
};
export default RestructuredUserLinkHistory;



  // const linkAnalysisData = useQuery('linkAnalysis', async () => {
  //   const token = await getAccessTokenSilently();
  //   return fetchApiData('https://portalapi-v2.phishcloud.io/api/portalapi-v2', token, 'linkAnalysis');
  // });

  //--------------getUserLinkHistory API Call---------------//

  //----Accounting for null Table Data----//

  //----Account for Error----//

  // const pushToErrorPage = () => {
  //   props.history.push('/Dashboard/Error');
  // };

  //const [speedTestResults, setSpeedTestResults] = useState([]);

  // console.log(speedTestResults);
  // var textFile = null,
  //   makeTextFile = function (text) {
  //     var data = new Blob([text], { type: 'text/plain' });

  //     // If we are replacing a previously generated file we need to
  //     // manually revoke the object URL to avoid memory leaks.
  //     if (textFile !== null) {
  //       window.URL.revokeObjectURL(textFile);
  //     }

  //     textFile = window.URL.createObjectURL(data);

  //     return textFile;
  //   };

  // var create = document.getElementById('create'),
  //   textbox = document.getElementById('textbox');

  // if (create != undefined) {
  //   create.addEventListener(
  //     'click',
  //     function () {
  //       var link = document.getElementById('downloadlink');
  //       link.href = makeTextFile(speedTestResults);
  //       link.style.display = 'block';
  //     },
  //     false
  //   );
  // }

  // const getUserLinkHistory = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     let time1 = performance.now();

  //     const token = await getAccessTokenSilently();

  //     fetch(basePCUrl + 'UserData/GetUserLinkHistory', {
  //       credentials: 'same-origin',
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //       .then((response) => {
  //         if (response.status !== 200) {
  //           console.log('Looks like there was a problem. Status Code: ' + response.status);

  //           return;
  //         }

  //         response.json().then((data) => {
  //           if (data === null && data.status === 'ok') {
  //             setTableData(accountForNullTableData);
  //             setChartData(accountForNullChartData);
  //             setUnknownCount(0);
  //             setUnsafeCount(0);
  //             setSafeCount(0);
  //             setRiskData(0);
  //             resolve(data);
  //           } else if (data.status === 'Error') {
  //             setError(true);
  //             setTableData(accountForNullTableData);
  //             setChartData(accountForNullChartData);
  //             setUnknownCount(0);
  //             setUnsafeCount(0);
  //             setSafeCount(0);
  //             resolve(data);
  //           } else if (data.status === 'NotInitialized' || data.status === 'notInitialized') {
  //             setTableData('');
  //             setChartData(accountForNullChartData);
  //             setUnknownCount(0);
  //             setUnsafeCount(0);
  //             setSafeCount(0);
  //             setChartData(data.chartData);
  //             setTableData(data.linkHistory);
  //             setSafeCount(data.safeCount);
  //             setUnknownCount(data.unknownCount);
  //             setUnsafeCount(data.unsafeCount);
  //             setError(false);
  //             resolve(data);
  //           } else {
  //             setError(false);
  //             resolve(data);
  //             let time2 = performance.now();
  //             ///console.log(msToTime('GetUserHistory', time2 - time1));
  //           }
  //         });
  //       })
  //       .catch(function (err) {
  //         console.log('Fetch Error :-S', err);
  //       });
  //   });
  // };

  //-------getUserDeviceData API Call-------//
  //const accountForNullDeviceData = [{ "windowsUsername": "No devices detected", "ipAddress": 0 }]

  // const getUserDeviceData = async () => {
  //     return new Promise(async (resolve, reject) => {
  //         let time1 = performance.now();
  //         const token = await getAccessTokenSilently();
  //         setIsLoadingUserDevices(true);
  //         var data = {
  //             identifier: "yo"
  //         };

  //         fetch(basePCUrl + 'UserData/GetUserDevices', {
  //             credentials: "same-origin",
  //             method: 'POST',
  //             body: JSON.stringify(data),
  //             headers: {
  //                 Authorization: `Bearer ${token}`,
  //                 'Content-Type': 'application/json'
  //             }
  //         }).then((response) => {

  //             if (response.status !== 200) {
  //                 console.log('Looks like there was a problem. Status Code: ' +
  //                     response.status);
  //                 return;
  //             }

  //             response.json().then((data) => {
  //                 if (data.status === "Error") {
  //                     console.log("User Devices Error")
  //                     setDevices(accountForNullDeviceData)
  //                     resolve(data.devices);
  //                     setError(true);

  //                     setIsLoadingUserDevices(false)
  //                 } else if (data === null && data.status === "ok") {
  //                     setDevices(accountForNullDeviceData)
  //                     resolve(data.devices);

  //                 } else {
  //                     //setDevices(data.devices)
  //                     resolve(data.devices);
  //                     let time2 = performance.now();
  //                     console.log(msToTime("GetUserDevices", time2 - time1));

  //                 }
  //             });
  //         })
  //             .catch(function (err) {
  //                 console.log('Fetch Error :-S', err);
  //             });
  //     })
  // }

  // const getUserTraining = async () => {

  //     return new Promise(async (resolve, reject) => {
  //         let time1 = performance.now();
  //         const trainingAPI = 'https://zsvz1vz9rg.execute-api.us-west-2.amazonaws.com/prod'
  //         const token = await getAccessTokenSilently();

  //         setIsLoadingTraining(true);

  //         fetch(trainingAPI, {
  //             method: 'GET',
  //             headers: {
  //                 Authorization: `Bearer ${token}`,
  //                 //testacct: uid,
  //                 'Action': 'userTraining',
  //                 'Content-Type': 'application/json'
  //             }
  //         }).then((response) => {

  //             if (response.status !== 200) {
  //                 console.log('Looks like there was a problem. Status Code: ' +
  //                     response.status);
  //                 //setError(true);
  //                 return;
  //             }

  //             response.json().then((data) => {
  //                 //setTrainingData(data.trainingItems);

  //                 resolve(data.trainingItems);
  //                 let time2 = performance.now();
  //                 console.log(msToTime("userTraining", time2 - time1));
  //             });
  //         })
  //             .catch(function (err) {
  //                 console.log('Fetch Error :-S', err);
  //             });
  //     })
  // }
