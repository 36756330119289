import React, { useEffect, useState } from 'react';
//import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Chart } from 'react-google-charts';
import { useAuth0 } from "@auth0/auth0-react";

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

const CompanyOverviewThreatModelChart = (props) => {
  const theme = useTheme();

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  // --------Google Chart Logic -------- //
  const renderChartData = () => {
    const dateRange = props.dateRange;

    if (props.chartData != null && props.chartData != undefined && props.chartData.length > 0) {
      //COPY ARRAYS TO ALTER/MANIPULATE
      let alterChartData = props.chartData.map((arr) => arr.slice());
      let parseDateChartData = props.chartData.map((arr) => arr.slice());

      let emailList = props.chartEmails.map((arr) => arr.slice());
      let finalEmailList = emailList.map((arr) => arr.split('@')[0]);

      //REMOVE DATES FROM ARRAY - WILL ADD THEM BACK WHEN THEY ARE FORMATTED
      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 1);
        }
      }

      //PARSE DATES WITH .MAP INTO NEW ARRAY
      if (dateRange === 'today') {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0]));
          //let dateTime = new Date((date.getTime() + Math.abs(date.getTimezoneOffset())));
          let dateTime = new Date(date.getTime() - 25200000);

          return {
            dateTime,
          };
        });
      } else {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0]));
          //let dateTime = new Date((date.getTime() + Math.abs(date.getTimezoneOffset()) + 86400000));
          let dateTime = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()));

          return {
            dateTime,
          };
        });
      }

      //CONVERT ARRAY OF OBJECTS TO ARRAY
      let finalFormattedDateTime = formattedChartData.map(({ dateTime }) => dateTime);

      // ADD BACK FORMATTED DATES AND REFORMAT INTO ARRAY OF ARRAYS SO DATA IS USABLE WITH GOOGLE CHARTS

      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 0, finalFormattedDateTime[i]);
        }
      }

      finalEmailList.splice(0, 0, 'Day');
      alterChartData.splice(0, 0, finalEmailList);
      //console.log(finalEmailList)

      let total = 0;
      for (var i = 1; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          total += i;
        }
      }

      var index = alterChartData[0].indexOf('sm');

      if (index !== -1) {
        alterChartData[0][index] = 'Social Media';
      }

      var index1 = alterChartData[0].indexOf('se');

      if (index1 !== -1) {
        alterChartData[0][index1] = 'Search Engines';
      }
      var index2 = alterChartData[0].indexOf('oa');

      if (index2 !== -1) {
        alterChartData[0][index2] = 'Outlook App';
      }
      var index3 = alterChartData[0].indexOf('be');

      if (index3 !== -1) {
        alterChartData[0][index3] = 'Browser Email';
      }
      var index4 = alterChartData[0].indexOf('bc');

      if (index4 !== -1) {
        alterChartData[0][index4] = 'Browser Chat Apps';
      }

      // --------- End of Chart Logic -------- //
      return <AlertInfoChart chartData={alterChartData} ticks={finalFormattedDateTime} />;
    } else {
      return <p> Could not load chart...</p>;
    }
  };

  const dateRange = props.dateRange;

  const AlertInfoChart = (props) => {
    if (dateRange === 'today') {
      return (
        <div>
          <Chart
            //width={'80%'}
            height={'100%'}
            chartType="LineChart"
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            options={{
              //colors: ['dodgerblue', '#7206c4', '#f0921f', '#097138'],
              chartArea: { left: 45, width: '70%', height: '50%' },
              backgroundColor: { fill: 'transparent' },
              vAxis: {
                viewWindow: { min: 0 },
                logScale: true,
                minValue: 0,
                color: theme.palette.text.primary,
              },
              hAxis: {
                format: ['MMM DD YYYY'],
                slantedText: true,
                slantedTextAngle: 45,
                showTextEvery: 1,
                maxAlternation: 1,
                minTextSpacing: 12,
                textStyle: { fontSize: 12, color: theme.palette.text.primary },
                ticks: props.ticks,
              },
              legend: {
                textStyle: { fontSize: 12, color: theme.palette.text.primary },
              },
            }}
            //rootProps={{ 'data-testid': '4' }}
          />
        </div>
      );
    } else if (dateRange === 'sevenDays') {
      return (
        <div>
          <Chart
            //width={'80%'}
            height={'100%'}
            chartType="LineChart"
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            options={{
              //colors: ['dodgerblue', '#7206c4', '#f0921f', '#097138'],
              chartArea: { left: 45, width: '70%', height: '50%' },
              backgroundColor: { fill: 'transparent' },
              vAxis: {
                viewWindow: { min: 0 },
                logScale: true,
                minValue: 0,
                textStyle: { color: theme.palette.text.primary },
              },
              hAxis: {
                format: 'MM/dd',
                slantedText: true,
                slantedTextAngle: 45,
                showTextEvery: 1,
                maxAlternation: 1,
                //showTextEvery: 2,
                minTextSpacing: 12,
                ticks: props.ticks,
                textStyle: { color: theme.palette.text.primary },
              },
              legend: {
                textStyle: { fontSize: 12, color: theme.palette.text.primary },
              },
            }}
            //rootProps={{ 'data-testid': '4' }}
          />
        </div>
      );
    } else if (dateRange === 'thirtyDays') {
      return (
        <div>
          <Chart
            //width={'80%'}
            height={'100%'}
            chartType="LineChart"
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            options={{
              //colors: ['dodgerblue', '#7206c4', '#f0921f', '#097138'],
              chartArea: { left: 45, width: '70%', height: '50%' },
              backgroundColor: { fill: 'transparent' },
              vAxis: {
                viewWindow: { min: 0 },
                logScale: true,
                minValue: 0,
                color: theme.palette.text.primary,
              },
              hAxis: {
                format: 'MM/dd',
                slantedText: true,
                slantedTextAngle: 45,
                //showTextEvery: 1,
                //maxAlternation: 1,
                //showTextEvery: 1,
                minTextSpacing: 8,
                ticks: props.ticks,
                textStyle: { color: theme.palette.text.primary },
              },
              legend: {
                textStyle: { fontSize: 12, color: theme.palette.text.primary },
              },
            }}
            //rootProps={{ 'data-testid': '4' }}
          />
        </div>
      );
    }
  };

  const columns = [
    {
      type: 'date',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
  ];

  //-------Chart Title------//

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const useStyles = makeStyles({
    paper: {},
    root: {
      width: '100%',
      fontFamily: 'Montserrat, sans-serif',

      maxWidth: '700',
      minWidth: '400',
    },
    container: {
      height: '40vh',
      maxHeight: '400',
      minHeight: '200',
      width: '100%',
      maxWidth: '700',
      minWidth: '400',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
  });

  const paperClasses = useStyles();

  return <div className={paperClasses.root}>{renderChartData()}</div>;
};
export default CompanyOverviewThreatModelChart;
