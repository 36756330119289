import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import UnknownLinks from './Images/phisch-cautiouslinks3.png';
import UnsafeLinks from './Images/phishc-unsafelinks3.png';
import SafeLinks from './Images/phishc-safelinks2.png';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';

import './MoreInfo.css';

const MoreInfo = () => {
  useChangePageTitle('MoreInfo');

  return (
    <div id="moreInfoBody">
      <Container fluid>
        <h1>How PhishCloud Works</h1>
        <p id="infoTitleDescription">
          PhishCloud shows you which links are safe, unsafe, or should require caution in real-time.{' '}
        </p>
        <br />
        <div id="moreInfoImagesContainer">
          <p id="infoDescription">
            PhishCloud will highlight links that are <span id="infoSafe">safe</span> with green.
          </p>
          <br />
          <br />
          <img id="moreInfoImage" src={SafeLinks} alt="safe links" />
          <br />
          <br />
          <p id="infoDescription">
            PhishCloud will highlight links that require <span id="infoUnknown">caution</span> with yellow.{' '}
          </p>
          <br />
          <br />
          <img id="moreInfoImage" src={UnknownLinks} alt="unknown links" />
          <br />
          <br />
          <p id="infoDescription">
            PhishCloud will warn of links that are <span id="infoUnsafe">unsafe</span> with red.
          </p>
          <br />
          <br />
          <img id="moreInfoImage" src={UnsafeLinks} alt="unsafe links" />
        </div>
      </Container>
    </div>
  );
};
export default MoreInfo;
