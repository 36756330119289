import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <header>
        <Navbar
          style={{ width: '100vw' }}
          className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3"
          light
        >
          <Container>
            <h5>{this.props.navTitle}</h5>
          </Container>
        </Navbar>
      </header>
    );
  }
}
