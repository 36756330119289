import React from 'react';
import './RegisterError.css';

const RegisterError = (props) => {
  return (
    <div className="errorContainer">
      <br />
      <br />
      <br />
      <br />
      <h2 className="errorHeader">Oops! We're Sorry.</h2>
      <div className="errorBody">
        <p className="errorText">
          There was an error. Return to sign up:{' '}
          <a href="https://testportal.phishcloud.io/Register">https://testportal.phishcloud.io/Register</a>
        </p>
        <br />
      </div>
    </div>
  );
};
export default RegisterError;
