import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Chart } from 'react-google-charts';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const UserRiskDonut = (props) => {
  const safe = props.totalSafe;
  const unknown = props.totalUnknown;
  const unsafe = props.totalUnsafe;
  const preferredLanguage = props.preferredLanguage;
  const getTranslation = props.getTranslation

  //-----Attempting Log Scale for Pie Chart-----/
  const safeLog = Math.log(safe) / Math.LN10;
  const unknownLog = Math.log(unknown) / Math.LN10;
  const unsafeLog = Math.log(unsafe) / Math.LN10;

  const title = props.title;

  const GoogleChart = (props) => {
    return (
      <Chart
        width={'100%'}
        height={'150px'}
        chartType="PieChart"
        loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
        data={[
          ['Risk', 'Percentage'],
          [getTranslation('Safe', preferredLanguage), safe],
          [getTranslation('Unknown', preferredLanguage), unknown],
          [getTranslation('Unsafe', preferredLanguage), unsafe],
        ]}
        options={{
          backgroundColor: { fill: 'transparent' },
          pieHole: 0.5,
          legend: { position: 'none' },
          pieSliceText: 'none',
          slices: {
            0: { color: '#2CCC72' },
            1: { color: '#F1C411' },
            2: { color: '#EE311E' },
          },
          height: '100%',
          width: '100%',
          tooltip: {
            ignoreBounds: true,
            textStyle: { fontSize: '9px' },
            isHtml: true,
          },
        }}
        rootProps={{ 'data-testid': '3' }}
      />
    );
  };

  //-------Chart Title------//

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const useStyles = makeStyles({
    paper: {},
    root: {
      width: '100%',
      fontFamily: 'Montserrat, sans-serif',
      maxWidth: '700',
      minWidth: '400',
    },
    container: {
      height: '40vh',
      maxHeight: '400',
      minHeight: '200',
      width: '100%',
      maxWidth: '700',
      minWidth: '400',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
  });

  const paperClasses = useStyles();

  return <GoogleChart />;
};
export default UserRiskDonut;
