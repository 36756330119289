import React, { useState, useEffect, useRef } from 'react';
//import Preview from 'preview-url-component'
import LinkPreview from './LinkPreview';
import { Container, Col, Row } from 'reactstrap';
import phishCloudLogo from './../../Assets/Logos/FullNameLogoWhite.png';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LandingPageModal from './LandingPageModal'
import { useAuth0 } from "@auth0/auth0-react";
import basePCUrl from './../../Globals';
import landingPageCodes from './LandingPageCodes';

const LandingPageBody = (props) => {
  const [finalUrl, setFinalUrl] = useState('');
  const [clickedSafety, setClickedSafety] = useState('');
  const [clientCode, setClientCode] = useState('');
  const [landingPageModal, setLandingPageModal] = useState(false)
  const toggleLandingPageModal = () => setLandingPageModal(!landingPageModal);

  const { getAccessTokenSilently } = useAuth0();

  const analyzeLink = () => {
    var decodedLink = decodeURIComponent(window.location.search);
    let params = new URLSearchParams(decodedLink);

    if (params.get('link') !== undefined && finalUrl === '') {
      setFinalUrl(params.get('link'));
      setClickedSafety(params.get('safety'));
    }

    if (params.get('c') !== undefined && params.get('c') !== null) {
      setClientCode(params.get('c'));
    } else {
      setClientCode('RvEHcO0hUH');
    }
  };

  useEffect(() => {
    analyzeLink();
  }, []);

  function findClientInfoByCode(code) {
    if (landingPageCodes.hasOwnProperty(code)) {
      return landingPageCodes[code];
    } else {
      return null; // Return null if the code is not found in the object
    }
  }

  const clientInfo = findClientInfoByCode(clientCode);

  const historyPagePush = (props) => {
    window.location.replace('https://portal.phishcloud.io/Dashboard/History');
  };

  const finalUrlPush =()=> {
    window.location.replace(finalUrl);
  }

  const useStyles = makeStyles({
    unsafeProceedButton: {
      '&:hover': {
        borderColor: 'red',
        boxShadow: '0 0 10px red',
     },
    },
    unknownProceedButton: {
      '&:hover': {
        borderColor: '#f1c411',
        boxShadow: '0 0 10px #f1c411',
     },
    },
  });
  const classes = useStyles();


  //---------Submit Safe Link Report------------//

  const submitLinkReportSafe = async (link, safety, confidenceLevel) => {
    const token = await getAccessTokenSilently();
    toggleLandingPageModal()
  
    var data = {
      link: link,
      confidenceLevel: confidenceLevel,
      safety: safety,
      proposedSafety: 'safe',
    };
  
  
    fetch(basePCUrl + 'Actions/SubmitLinkReport', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(data);
          return;
        }
  
        response.json().then((data) => {
          console.log(data);
          //historyPagePush()
          
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };


  //-----------Submit Unsafe Link Report--------//

  const submitLinkReportUnsafe = async (link, safety, confidenceLevel) => {
    const token = await getAccessTokenSilently();
    toggleLandingPageModal()

    var data = {
      link: link,
      confidenceLevel: confidenceLevel,
      safety: safety,
      proposedSafety: 'unsafe',
    };

    fetch(basePCUrl + 'Actions/SubmitLinkReport', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(data);
          return;
        }

        response.json().then((data) => {
          console.log(data);
          historyPagePush();
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  return (
    <div>
      {finalUrl !== '' ? (
        <div id={clickedSafety + 'LandingPageContainer'}>
          <Col lg="12">
            <div id={clickedSafety + 'Border'}>

              <Row id="landingPageTopBanner" style={{ backgroundColor: clientInfo.styling.bannerColor }}>

                <Col>
                  {/* <Typography variant="h4" component="h3" gutterBottom>
                    Company Name
                  </Typography> */}
                </Col>
                <Col className="text-right">

                  <img id="companyLogo" src={clientInfo.logo} alt="Company Logo" />

                </Col>
              </Row>

              <br />
              <br />
              <div id="linkDetailsContainer">

                <Row>
                  <Col style={{ paddingRight: '15px' }}>
                    <h3 style={{ fontWeight: 400 }}>
                      {clickedSafety === 'unsafe' ? (
                        'You have clicked through the following unsafe link:'
                      ) : (
                        <span>
                          You have clicked on a link that you should treat with caution. <br /> There isn't enough
                          information to determine its safety:
                        </span>
                      )}{' '}
                    </h3>
                    <br />
                    <p id="clickedLink" style={{ color: clientInfo.styling.clickedLinkFontColor }}>
                      {finalUrl}
                    </p>
                    <br />
                    <p>Which directs to this site:</p>

                    <LinkPreview safety={clickedSafety} linkForPreview={finalUrl} clientInfo={clientInfo} />
                    {/* <p>Is this where you intended to go?</p> */}
                  </Col>
                </Row>

                <div>
                  <br />
                  <br />
                  <br />
                  <p>

                    {clickedSafety === 'unsafe'
                      ? clientInfo.messages.unsafeMessage
                      : 'The '+
                      clientInfo.clientName + ' security team has reason to believe that the URL you clicked on is unknown and you should proceed with caution.  If you know that this URL is not malicious, please hit the greeen "thumbs up" button to let us know and we will address it.'}
                  </p>
                </div>
              </div>
              <div id="returnToPortalContainer">
                {clickedSafety === 'unsafe' ? (
                  <Button
                    id="voteSafe"
                    variant="contained"
                    onClick={() => submitLinkReportUnsafe(finalUrl, clickedSafety)}
                  >
                    &#128077;
                  </Button>
                ) : (
                  <span>
                    <Button
                      id="voteSafe"
                      variant="contained"
                      onClick={() => submitLinkReportUnsafe(finalUrl, clickedSafety)}
                    >
                      &#128077;
                    </Button>
                    <Button
                      id="voteUnsafe"
                      variant="contained"
                      onClick={() => submitLinkReportUnsafe(finalUrl, clickedSafety)}
                    >
                      &#128078;
                    </Button>
                  </span>
                )}

                <Button
                className={clickedSafety === 'unknown' ? classes.unknownProceedButton : classes.unsafeProceedButton }
                  style={{
                    float: 'right',
                    marginRight: '20px',
                    backgroundColor: clientInfo.styling.returnToPortalButtonBackgroundColor,
                    marginBottom: '2rem',
                  }}
                  variant="contained"
                  onClick={finalUrlPush}
                >
                  Proceed to Link
                </Button>
                <Button
                  style={{
                    float: 'right',
                    backgroundColor: clientInfo.styling.returnToPortalButtonBackgroundColor,
                    marginBottom: '2rem',
                  }}
                  variant="contained"
                  onClick={() => historyPagePush()}
                >
                  Return to the Portal
                </Button>
              </div>
              <LandingPageModal isOpen={landingPageModal} toggle={toggleLandingPageModal} historyPagePush={historyPagePush} />

            </div>
          </Col>
        </div>
      ) : null}
    </div>
  );
};
export default LandingPageBody;

//-----Premade link preview-----//
//< Preview
//url = "http://all-prize-giveaway.life/?u=1nup806&o=0wywy2l&t=k2Dr"
//loadingText = "loading link preview..."
//notFound = "URL Not Found!"
///>
