import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Button, Col, Row } from 'reactstrap';
import { lighten, withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircleWidget from '../../CircleWidget/CircleWidget';
import DevicesIcon from '@material-ui/icons/Devices';
import UserRiskDonut from '../UserRiskDonut/UserRiskDonut';
import UserDevices from '../UserDevices/UserDevices';
//import DeviceItem from '../UserDevices/DeviceItem';
import './UserStatisticsWidgets.css';

const RestructuredUserStatisticsWidgets = (props) => {
  const [userRiskStyle, setUserRiskStyle] = useState();

  //------Tooltip Styles-----//
  const UserRiskExplanationTooltip = withStyles((theme) => ({
    tooltip: {
      //padding: 20,
      fontSize: 16,
    },
  }))(Tooltip);

  const handleLargeNumbers = (number) => {
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      return number;
    }
  };

  const totalSeen = props.safeCount + props.unknownCount + props.unsafeCount;
  const safeCount = props.safeCount;
  const unsafeCount = props.unsafeCount;
  const unknownCount = totalSeen - safeCount - unsafeCount;
  const devices = props.devices;
  const risk = props.risk;
  const loading = props.loading;
  const getTranslation = props.getTranslation
  const preferredLanguage = props.preferredLanguage

  useEffect(() => {
    if (loading === false) {
      if (risk >= 86) {
        setUserRiskStyle('highUserRisk');
      } else if (risk <= 60) {
        setUserRiskStyle('lowUserRisk');
      } else if (risk >= 61 && risk <= 85) {
        setUserRiskStyle('medUserRisk');
      }
    }
  }, []);

  const translateUserRiskExplanation =()=> {
    return getTranslation("User risk is an indication of how safe you are based on the relative safety of links you've encountered in the past 30 days.", preferredLanguage)
  }

  const renderHistoryCircleWdigets = (props) => {
    return (
      <React.Fragment>
        {userRiskStyle != undefined ? (
          <Paper id={userRiskStyle}>
            <span id="companySafety">
              <p>
              {getTranslation("user risk", preferredLanguage)}
                <UserRiskExplanationTooltip
                  title={
                    translateUserRiskExplanation()
                  }
                  placement="bottom"
                  arrow
                >
                  <span style={{ padding: '5px' }}>
                    <FontAwesomeIcon icon={['fas', 'info-circle']} />
                  </span>
                </UserRiskExplanationTooltip>
              </p>
            </span>

            <Row>
              <span id="rowCircleWidget">
                <CircleWidget
                  icon={<FontAwesomeIcon icon={['fas', 'link']} />}
                  circleColor="lightgrey"
                  number={handleLargeNumbers(totalSeen)}
                  text={getTranslation("links seen", preferredLanguage)}
                  preferredLanguage={preferredLanguage}

                />
              </span>
              <span id="rowCircleWidget">
                <CircleWidget
                  icon={<FontAwesomeIcon icon={['fas', 'check']} />}
                  circleColor="lightgrey"
                  number={handleLargeNumbers(safeCount)}
                  text={getTranslation("safe links", preferredLanguage)}
                  preferredLanguage={preferredLanguage}
                />
              </span>
              <span id="rowCircleWidget">
                <CircleWidget
                  icon={<FontAwesomeIcon icon={['fas', 'lock']} />}
                  circleColor="lightgrey"
                  number={handleLargeNumbers(unsafeCount)}
                  text={getTranslation("unsafe links", preferredLanguage)}
                  preferredLanguage={preferredLanguage}

                />
              </span>
              <span>
                <Paper id="DonutStatisticsWidget" elevation={1}>
                  <UserRiskDonut
                    totalSafe={safeCount}
                    totalUnsafe={unsafeCount}
                    totalUnknown={unknownCount}
                    title={'test'}
                    preferredLanguage={preferredLanguage}
                    getTranslation={getTranslation}
                  />

                  <span style={{ textAlign: 'center' }}>
                    <h6>{getTranslation("seen links", preferredLanguage)}</h6>
                  </span>
                </Paper>
              </span>
              <Paper id="DeviceStatisticsWidget" elevation={1}>
                <UserDevices devices={devices} preferredLanguage={preferredLanguage} getTranslation={getTranslation}/>
              </Paper>
              <Paper id="DeviceStatisticsWidget" elevation={1}>
                <div style={{ textAlign: 'center', marginTop: '50%' }}>
                  <h6>TBD</h6>
                </div>
              </Paper>
            </Row>
          </Paper>
        ) : null}
      </React.Fragment>
    );
  };

  return <div>{renderHistoryCircleWdigets()}</div>;
};
export default RestructuredUserStatisticsWidgets;
