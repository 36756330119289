import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from "@auth0/auth0-react";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { postApiData } from '../../../utils/service';
import { useQuery } from 'react-query';

const LanguageDropdown = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  //const [chosenLanguage, setChosenLanguage] = useState(localStorage.getItem("preferred-language") || "EN-US");
  var chosenLanguage = localStorage.getItem('preferred-language') || 'EN';
  const [chosenLanguageChange, setChosenLanguageChange] = useState(false);
  const isLoading = props.isLoading;
  const preferredLanguage = props.preferredLanguage;

  // useEffect(() => {
  //   const preferredLanguage = localStorage.getItem('preferred-language');
  //   const supportedLanguages = ['zh', 'ja', 'fr', 'de', 'it', 'es', 'nl', 'pt', 'en', 'no'];

  //   if (!preferredLanguage && supportedLanguages.includes(navigator.language.split('-')[0])) {
  //     localStorage.setItem(
  //       'preferred-language',
  //       navigator.language.split('-')[0] === 'en' ? 'EN' : navigator.language.split('-')[0]
  //     );
  //     //console.log('I am using the navigator language');
  //   } else {
  //     return;
  //   }
  // }, []);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  let userSettings = props.userSettings;
  let isNotLoading = props.isNotLoading;

  var setPreferredLanguageData;

  const savePreferredLanguage = useQuery(
    ['setUserSettings'],
    async () => {
      const token = await getAccessTokenSilently();

      return postApiData(
        'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
        token,
        setPreferredLanguageData,
        'setUserSettings'
      );
    },
    {
      enabled: false,
      onSuccess: () => {
        setChosenLanguageChange(false);
        //console.log("I have made the save settings call.")
        //window.location.reload();
      },
    }
  );

  const handleChange = (event) => {
    chosenLanguage = event.target.value;
    props.setPreferredLanguage(event.target.value, 'preferred-language');

    setPreferredLanguageData = {
      chrome: userSettings?.Chrome?.Value,
      firefox: userSettings?.Firefox?.Value,
      outlook: userSettings?.Outlook?.Value,
      darkmode: userSettings?.DarkMode,
      userlanguage: event.target.value,
    };

    setTimeout(() => {
      savePreferredLanguage.refetch();
    }, 2000);

    setTimeout(() => {
      if (
        props.location.pathname === '/Dashboard/AdminTrainingDashboard' ||
        props.location.pathname === '/Dashboard/TrainingDashboard'
      ) {
        window.location.reload();
      }
    }, 3000);

    //window.dispatchEvent(new Event("storageLanguageChanged"));
  };

  return (
    //     <FormControl variant="outlined" className={classes.formControl}>
    //   <Select
    //     labelId="date range select"
    //     value={dateRange}
    //     onChange={(e) => setDateRange(e.target.value)}
    //     style={{ marginLeft: '0px', marginRight: '35px', width: '150px' }}
    //   >
    //     <MenuItem value="8">7 days</MenuItem>
    //     <MenuItem value="15">14 days</MenuItem>
    //     <MenuItem value="31">30 days</MenuItem>
    //   </Select>
    // </FormControl>
    <div>
      {userSettings ? (
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={preferredLanguage}
            onChange={(event) => handleChange(event)}
            label="Language"
          >
            <MenuItem value={'EN'}>English - US</MenuItem>
            {/* <MenuItem value={'EN'}>English - GB</MenuItem> */}
            <MenuItem value={'AR'}>Arabic</MenuItem>
            <MenuItem value={'ZH'}>Chinese</MenuItem>
            <MenuItem value={'NL'}>Dutch</MenuItem>
            <MenuItem value={'FR'}>French</MenuItem>
            <MenuItem value={'DE'}>German</MenuItem>
            <MenuItem value={'IT'}>Italian</MenuItem>
            <MenuItem value={'JA'}>Japanese</MenuItem>
            <MenuItem value={'NB'}>Norwegian</MenuItem>
            {/* <MenuItem value={'PT'}>Portuguese - BR</MenuItem> */}
            <MenuItem value={'PT'}>Portuguese - PT</MenuItem>
            <MenuItem value={'ES'}>Spanish</MenuItem>
          </Select>
        </FormControl>
      ) : null}
    </div>
  );
};
export default LanguageDropdown;
