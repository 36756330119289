import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import UpdatedCircleWidget from '../CircleWidget/UpdatedCircleWidget';
import companyLogo from '../../LandingPages/Images/logo-here.png';
import logoBadge from '../Assets/Logos/WhiteShield.png';
import { DateCreateFullDate } from '../DateCreate/DateCreateFullDate';

//--------------Material UI Imports--------------//
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import MSSPClientCardDialogue from './MSSPClientCardDialogue.js';
import MSSPSparkLineChart from './MSSPSparkLineChart';
import './MSSP.css';
import { getTarget } from 'reactstrap/lib/utils';

const MSSPClientCard = (props) => {
  const [MSSPClientCardDialogueState, setMSSPClientCardDialogueState] = useState(false);
  const toggleMSSPClientCardDialogue = () => setMSSPClientCardDialogueState(!MSSPClientCardDialogueState);

  const [expanded, setExpanded] = useState(false);

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      marginBottom: 20,
    },
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
    tableHeaderBlank: {
      width: 130,
    },
    tableHeaderStatus: {
      width: 90,
      textAlign: 'center',
    },
    tableHeaderSparkline: {
      textAlign: 'center',
    },
    tableCellSafetyScore: {
      textAlign: 'center',
    },
    tableCellThreatModelTitle: {
      maxWidth: 220,
    },
    tableCellThreatModelTitleSmallCard: {
      width: '80%',
    },
    tableCellThreatModelTitleSmallCardIcon: {
      witdh: '20%',
    },
    tableCellThreatModel: {
      minWidth: 70,
      paddingLeft: 0,
      paddingRight: 0,
    },
    gridItem: {
      maxHeight: 650,
      minWidth: 420,
    },
  });

  const classes = useStyles();

  const pushToAlerts = () => {
    // props.setSelectedClient(clientId)
    props.history.push('/Alerts');
  };

  const randomizedTestDataForSparkline = (dataLength, highestRandomNumber) => {
    var x = new Array(dataLength);

    for (var i = 0; i < x.length; i++) {
      x[i] = new Array(i + 1, Math.floor(Math.random() * highestRandomNumber));
    }

    x.unshift(['x', 'data']);

    return x;
  };

  let today = new Date();

  //----------Function to determine color of safety score dot----------//
  function safetyScoreIcon(safetyScore) {
    return safetyScore == 'yellow' ? (
      <span aria-label="yellow dot" role="img">
        🟡
      </span>
    ) : safetyScore == 'green' ? (
      <span aria-label="green dot" role="img">
        🟢
      </span>
    ) : safetyScore == 'red' ? (
      <span aria-label="green dot" role="img">
        🔴
      </span>
    ) : (
      'N/A'
    );
  }

  var userRiskBorder = 'defaultClientCardBorder';

  if (props.riskLevel) {
    if (props.riskLevel >= 86) {
      var userRiskBorder = 'highClientCardBorder';
    } else if (props.riskLevel <= 60) {
      var userRiskBorder = 'lowClientCardBorder';
    } else if (props.riskLevel >= 61 && props.riskLevel <= 85) {
      var userRiskBorder = 'medClientCardBorder';
    }
  }

  const setClientAndPushToCompanyOverview = () => {
    props.setSelectedClient({ name: props.companyName, clientId: props.clientId });
    props.history.push('/Dashboard/CompanyOverviewAccountAdmin');
  };


  const threatModelDotCells = () => {
    return (
      <React.Fragment>
        <Tooltip title={getTranslation("Browser Email", preferredLanguage)} >
          <TableCell className={classes.tableCellThreatModel}>
            BE &nbsp;| {safetyScoreIcon(props.threatModel.browserEmail)}
          </TableCell>
        </Tooltip>
        <Tooltip title={getTranslation("Chat Apps", preferredLanguage)} >
          <TableCell className={classes.tableCellThreatModel}>
            CA &nbsp; | {safetyScoreIcon(props.threatModel.chatApps)}
          </TableCell>
        </Tooltip>
        <Tooltip title={getTranslation("Email Apps", preferredLanguage)} >
          <TableCell className={classes.tableCellThreatModel}>
            EA &nbsp; | {safetyScoreIcon(props.threatModel.emailApp)}
          </TableCell>
        </Tooltip>
        <Tooltip title={getTranslation("Search Engines", preferredLanguage)} >
          <TableCell className={classes.tableCellThreatModel}>
            SE &nbsp; | {safetyScoreIcon(props.threatModel.searchEngine)}
          </TableCell>
        </Tooltip>
        <Tooltip title={getTranslation("Social Media", preferredLanguage)} >
          <TableCell className={classes.tableCellThreatModel}>
            SM &nbsp; | {safetyScoreIcon(props.threatModel.socialMedia)}
          </TableCell>
        </Tooltip>
      </React.Fragment>
    );
  };

  const threatModelTableRow = () => {
    if (props.cardsPerRow === 4 && !MSSPClientCardDialogueState) {
      return (
        <React.Fragment>
          <TableRow>
            <TableCell className={classes.tableCellThreatModelTitleSmallCard}>Threat Model</TableCell>
            <TableCell className={classes.tableCellThreatModelTitleSmallCardIcon}>
              <Tooltip title={!expanded ? getTranslation('View Threat Model Status', preferredLanguage) : getTranslation('Close Threat Model Status', preferredLanguage)}>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="view update log"
                >
                  {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
          <TableRow>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {threatModelDotCells()}
            </Collapse>
          </TableRow>
        </React.Fragment>
      );
    } else {
      return (
        <TableRow>
          <TableCell className={classes.tableCellThreatModelTitle}>{getTranslation("Threat Model", preferredLanguage)}</TableCell>
          {threatModelDotCells()}
        </TableRow>
      );
    }
  };

  const CardContents = (props) => {
    return (
      <Grid item>
        <Grid
          container
          direction="row"
          //justify="space-between"
          style={{ flexWrap: 'noWrap' }}
          spacing={3}
          className={classes.root}
        >
          <Grid item alignItems="center" xs={2}>
            <img
              id="companyLogoMSSPCard"
              src={props.companyLogo ? props.companyLogo : companyLogo}
              alt="Company Logo"
            />
          </Grid>
          <Grid item xs={8}>
            <Grid item>
              <Typography variant="h6">{props.companyName}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" gutterBottom>
              {getTranslation("Contact", preferredLanguage)} : {props.companyContact}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
              {getTranslation("Last Contacted", preferredLanguage)} : <DateCreateFullDate date={props.lastContactedDate} />
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={props.toggle}
                style={{ fontSize: '12px', marginTop: '10px' }}
                disableElevation
              >
               {getTranslation("Send / Generate Report", preferredLanguage)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={setClientAndPushToCompanyOverview}
                style={{ fontSize: '12px', marginTop: '10px', marginLeft: '10px' }}
                disableElevation
              >
                 {getTranslation("Access Account", preferredLanguage)} 
              </Button>
            </Grid>
          </Grid>
          <Grid alignItems="flex-start">
            {!MSSPClientCardDialogueState ? (
              <Grid item xs={1} style={{ height: '100%', alignItems: 'flex-start' }}>
                <Tooltip title={getTranslation("View Large Card", preferredLanguage)}>
                  <IconButton aria-label="View Large Card" onClick={toggleMSSPClientCardDialogue}>
                    <OpenInNewIcon style={{ float: 'right' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              <IconButton aria-label="Close Card" onClick={toggleMSSPClientCardDialogue}>
                <CloseIcon style={{ float: 'right' }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Table className={classes.table} size={'small'} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderBlank}></TableCell>
                <TableCell className={classes.tableHeaderStatus}></TableCell>
                <TableCell className={classes.tableHeaderSparkline}>7 {getTranslation("Day Sparkline", preferredLanguage)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                {getTranslation("Active Alerts", preferredLanguage)}
                </TableCell>
                <TableCell className={classes.tableCellSafetyScore}>{safetyScoreIcon(props.alertScore)}</TableCell>
                <TableCell>
                  {' '}
                  <MSSPSparkLineChart
                    color="rgb(238, 49, 30)"
                    color2="rgb(241, 196, 17)"
                    data={props.chartDataAlerts}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                {getTranslation("Active Users", preferredLanguage)}
                </TableCell>
                <TableCell className={classes.tableCellSafetyScore}>{safetyScoreIcon(props.activeUserScore)}</TableCell>
                <TableCell>
                  <MSSPSparkLineChart color="#2CCC72" data={props.chartDataActiveUsers} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>{threatModelTableRow()}</TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  };

  const ListContent = () => {
    return (
      <List>
        <Paper id={userRiskBorder} className={classes.paper}>
          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar variant="square" src={props.companyLogo} className={classes.large}></Avatar>
            </ListItemAvatar>
            <ListItemText primary={props.companyName} />
            <ListItemSecondaryAction>
              <Tooltip title={getTranslation("View Large Card", preferredLanguage)} >
                <IconButton aria-label="View Large Card" edge="end" onClick={toggleMSSPClientCardDialogue}>
                  <OpenInNewIcon style={{ float: 'right' }} />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
      </List>
    );
  };

  return (
    <React.Fragment>
      {!props.listView ? (
        <Grid item className={classes.gridItem} xs={12} sm={12} md={8} lg={props.cardsPerRow}>
          <Paper id={userRiskBorder} style={{ padding: '20px' }}>
            {CardContents(props)}
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {ListContent(props)}
        </Grid>
      )}
      <MSSPClientCardDialogue
        toggle={toggleMSSPClientCardDialogue}
        isOpen={MSSPClientCardDialogueState}
        userRiskBorder={userRiskBorder}
        content={CardContents(props)}
        preferredLanguage={preferredLanguage}
        getTranslation={getTranslation}
      />
    </React.Fragment>
  );
};
export default MSSPClientCard;

{
  /* <Grid item item xs={11} sm={11} md={11} lg={11}> 
                    <MSSPSparkLineChart color="#EE311E" data={randomizedTestDataForSparkline(60, 50)}/>
                </Grid> */
}

{
  /* 
               <Grid item style={{marginRight: '20px'}} >
                   <Badge badgeContent={props.activeAlertTotal} color="secondary" >
                  <Link to={"/Dashboard/Alerts"}><img id="logoBadge"  src={logoBadge} alt="Company Logo"/></Link>
               </Badge>
               </Grid> */
}
