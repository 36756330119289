import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { fetchApiData } from '../../../../utils/service';
import useChangePageTitle from '../../CustomHooks/useChangePageTitle';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import useGetTrainingByID from '../useGetTrainingByID';
import TrainingCompleteModal from './TrainingCompleteModal';
import TrainingFailModal from './TrainingFailModal';
import TrainingQuestion from './TrainingQuestion';
import UpdatedTrainingQuiz from './UpdatedTrainingQuiz'



const adminConfigAPI = "https://portalapi-v2.phishcloud.io/api/portalapi-v2"
const TrainingQuiz = (props) => {
  useChangePageTitle('TrainingQuiz');
  const location=useLocation()
  const [trainingId, setTrainingId] = useState('');
  const [state, setState] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [togglesDisabled, setTogglesDisabled] = useState(false);
  const [helperText, setHelperText] = useState();
  const [modal, setModal] = useState(false);
  const [failModal, setFailModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const adminConfigData = useQuery('admin-data', async () => {
    const token = await getAccessTokenSilently();
    return fetchApiData(adminConfigAPI, token, 'adminConfigs');
  });
  useEffect(() => {
    analyzeLink();
  }, []);

  const videoType= adminConfigData.data ? adminConfigData.data?.settings?.trainingSettings?.videoType : '';

  const {
    trainingTitle,
    trainingQuiz,
    trainingVideo,
    isLoading,
  } = useGetTrainingByID(trainingId, videoType);
  const analyzeLink = () => {
    let parsedQuery = queryString.parse(location.search);
    let parsedId = parsedQuery.id;

    if (parsedId !== undefined) {
      let parsedId = parsedQuery.id;
      //var decodedLink = decodeURIComponent(parsedId);
      setTrainingId(parsedId);
    }
  };
  
 

  if (adminConfigData.isLoading ) {
    return <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox"/>;
  }

  const toggle = () => {
    setModal(!modal);
  };

  const failToggle = () => {
    setFailModal(!failModal);
    window.scrollTo({ top: 100, left: 100, behavior: 'smooth' });
  };

  const handleRadioChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };


  var usersAnswers = Object.keys(state).map((key) => {
    return parseInt(state[key]);
  });


  const saveTrainingProgress = async (event) => {
    window.scrollTo({ top: 100, left: 100, behavior: 'smooth' });

    //event.preventDefault();
    const token = await getAccessTokenSilently();
    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

    var data = { modId: trainingId };

    fetch(api, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        Action: 'userTrainingUpdate',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          //setError(true);
          // console.log(error);
          return;
        }

        response.json().then((data) => {
          return;
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const quizList = [];

  for (var key in trainingQuiz) {
    if (trainingQuiz.hasOwnProperty(key)) {
      var obj = trainingQuiz[key];

      quizList.push({ question: obj.Question, answerList: obj.AnswerList, answerKey: obj.AnswerKey });
    }
  }

  quizList.forEach(function (element, index) {
    element.questionIndex = index;
  });

  const correctAnswers = Object.keys(quizList).map((key) => {
    return quizList[key].answerKey;
  });

  const compareAnswersToKey =
    usersAnswers.length === correctAnswers.length &&
    usersAnswers.every(function (element, index) {
      return element === correctAnswers[index];
    });

  const submitAnswers = (event) => {
    event.preventDefault();
    if (compareAnswersToKey === true) {
      saveTrainingProgress();
      setModal(true);
    } else {
      setShowResults(true);
      setTogglesDisabled(true);
      setFailModal(true);
      setHelperText('Sorry, wrong answer!');
      return;
    }
  };

  const restartQuiz = () => {
    window.location.reload();
    return false;
  };

  return (
    <div>
      <Typography variant="h4" component="h4">
        {trainingTitle != null ? trainingTitle : ''}
      </Typography>
      <br />
      {/* <form onSubmit={submitAnswers}>
        {quizList.map((quiz, idx) => (
          <TrainingQuestion
            handleRadioChange={handleRadioChange}
            togglesDisabled={togglesDisabled}
            index={idx}
            state={state}
            showResults={showResults}
            helperText={helperText}
            questionIndex={quiz.questionIndex}
            question={quiz.question}
            answers={quiz.answerList}
            answerKey={quiz.answerKey}
            usersAnswers={usersAnswers}
          />
        ))}

        <br />
        <br />
        {!showResults ? (
          <Button type="submit" style={{ marginBottom: '20px' }} variant="contained" color="primary">
            Submit Answers
          </Button>
        ) : (
          <Button onClick={restartQuiz} style={{ marginBottom: '20px' }} variant="contained" color="primary">
            Retake Quiz
          </Button>
        )}
      </form> */}
      {trainingQuiz ?<UpdatedTrainingQuiz quizData={trainingQuiz} trainingId={trainingId} trainingVideo={trainingVideo} history={props.history}/> : <LoadingSpinner />}
    </div>
  );
};
export default TrainingQuiz;
