import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import useForm from '../CustomHooks/useForm';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './LinkAnalysis.css';
import LinkAnalysisResults from './LinkAnalysisResults';
import useFetchGlobalAndCompanyStatistics from './useFetchGlobalAndCompanyStatistics';
import useFetchLinkAnalysisAdmin from './useFetchLinkAnalysisAdmin';

const LinkAnalysis = (props) => {
  useChangePageTitle('LinkAnalysis');

  const { values: inputUrl, bind: bindInputUrl } = useForm('');

  const [finalUrl, setFinalUrl] = useState('');

  const { data, domainDetails, isLoading } = useFetchLinkAnalysisAdmin(finalUrl);

  const { companyStatistics, globalStatistics, isLoadingGC } = useFetchGlobalAndCompanyStatistics(finalUrl);

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

  const analyzeLink = () => {
    let parsedQuery = queryString.parse(props.location.search);
    let parsedLink = parsedQuery.link;

    if (parsedLink !== undefined && finalUrl === '') {
      var decodedLink = decodeURIComponent(parsedLink);
      setFinalUrl(decodedLink);
    }
  };

  useEffect(() => {
    analyzeLink();
  }, [finalUrl]);

  const submitOnEnterKey = (event) => {
    if (event.keyCode === 13) {
      setFinalUrl(inputUrl);
    }
  };

  return (
    <div>
      <div id="analyzeInit">
        <div className="link-wrap">
          <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
          {getTranslation('Analzye Link', preferredLanguage)}
          </Typography>

          <InputBase
            name="inputUrl"
            {...bindInputUrl}
            className="inputLink"
            placeholder="Enter URL"
            onKeyDown={(event) => submitOnEnterKey(event)}
          />

          <Button className="search" onClick={() => setFinalUrl(inputUrl)} outline color="white">
            <FontAwesomeIcon icon={['fas', 'search']} />
          </Button>
        </div>
      </div>
      {isLoading ? <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" /> : ''}
      {data === null || domainDetails === null || companyStatistics === null || globalStatistics === null ? (
        <div></div>
      ) : (
        data &&
        domainDetails && (
          <LinkAnalysisResults
            alertSafety={data.safety}
            link={data.link}
            safety={data.safety}
            history={props.history}
            location={props.location}
            confidenceLevel={data.confidenceLevel}
            timesClicked={data.timesClicked}
            timesSeen={data.timesSeen}
            countryName={domainDetails.countryName}
            code={domainDetails.countryCode}
            domain={data.domain}
            created={domainDetails.created}
            companyStatistics={companyStatistics}
            globalStatistics={globalStatistics}
            getTranslation={getTranslation}
            preferredLanguage={preferredLanguage}
          />
        )
      )}
    </div>
  );
};
export default LinkAnalysis;
