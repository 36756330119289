
﻿import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
//-----Material Ui imports-----//
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { byFips } from 'country-code-lookup';
import React, { useEffect, useState } from 'react';
import { Col, ModalBody, Row } from 'reactstrap';
import basePCUrl from '../../../Globals';
import { DateCreateFullDate } from '../DateCreate/DateCreateFullDate';
import { useAuth0 } from "@auth0/auth0-react";

import { FlagIcon } from './FlagIcon';

const LinkAnalysisAdminResults = (props) => {
  const [reportSafeModal, setReportSafeModal] = useState(false);
  const toggleSafeModal = () => setReportSafeModal(!reportSafeModal);
  const [reportUnsafeModal, setReportUnsafeModal] = useState(false);
  const toggleUnsafeModal = () => setReportUnsafeModal(!reportUnsafeModal);
  const [expanded, setExpanded] = React.useState(false);

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const mobileCheck = useMediaQuery('(min-width:830px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const countryLengthCheck = (value) => {
    if (value === undefined || value === null) {
      return <span>{getTranslation("Not Reported", preferredLanguage)}</span>;
    } else if (value === 'Error Country Code Not Found') {
      return <span>{getTranslation("Not Reported", preferredLanguage)}</span>;
    } else if (value.length >= 30) {
      return <span style={{ fontSize: '12px' }}>{value}</span>;
    } else {
      return value;
    }
  };

  const accountForUrlLength = (linkForCheck) => {
    if (linkForCheck.length >= 80 && mobileCheck === false) {
      return <span style={{ fontSize: '20px' }}>{linkForCheck.slice(0, 50)}...</span>;
    } else if (linkForCheck.length >= 80 && mobileCheck === true) {
      return <span>{linkForCheck.slice(0, 80)}...</span>;
    } else {
      return linkForCheck;
    }
  };

  //----------Account For Link Length-----------//
  const accountForLinkLength = (desiredLength, linkForCheck) => {
    if (linkForCheck === undefined) {
      return <span></span>;
    } else if (linkForCheck.length >= desiredLength) {
      return (
        <span
          style={{
            fontSize: '24px',
            wordBreak: 'break-all',
          }}
        >
          {linkForCheck.slice(0, desiredLength)}...
        </span>
      );
    } else return <span style={{ fontSize: '1.1rem', wordBreak: 'break-all' }}>{linkForCheck}</span>;
  };

  const accountForRedirectLinkLength = (linkForCheck) => {
    if (linkForCheck === undefined) {
      return <span></span>;
    } else if (linkForCheck.length >= 100) {
      return (
        <p style={{ fontSize: '.9rem', wordBreak: 'break-all', wordWrap: 'break-word' }}>
          {linkForCheck.slice(0, 100)}...
        </p>
      );
    } else return <p style={{ fontSize: '.9rem', wordBreak: 'break-all', wordWrap: 'break-word' }}>{linkForCheck}</p>;
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.grey,
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 18,
    },
  }))(Tooltip);

  //---------Submit Safe Link Report------------//

  const { getAccessTokenSilently } = useAuth0();

  const submitLinkReportSafe = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      link: props.link,
      confidenceLevel: props.confidenceLevel,
      safety: props.safety,
      proposedSafety: 'safe',
    };

    fetch(basePCUrl + 'Actions/SubmitLinkReport', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          setTimeout(function () {
            setReportSafeModal(false);
          }, 3000);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  //-----------Submit Unsafe Link Report--------//
  const submitLinkReportUnsafe = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      link: props.link,
      confidenceLevel: props.confidenceLevel,
      safety: props.safety,
      proposedSafety: 'unsafe',
    };

    fetch(basePCUrl + 'Actions/SubmitLinkReport', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          setTimeout(function () {
            setReportUnsafeModal(false);
          }, 3000);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const reportSafe = () => {
    toggleSafeModal();
    submitLinkReportSafe();
  };

  const reportUnsafe = () => {
    toggleUnsafeModal();
    submitLinkReportUnsafe();
  };

  //-----------Report Safe Modal-----------//
  const ReportSafeModalButton = () => {
    return (
      <React.Fragment>
        <Button
          style={{ float: 'right' }}
          variant="contained"
          className={classes.button}
          onClick={reportSafe}
          id="reportSafe"
          startIcon={<CheckIcon />}
        >
          {getTranslation('report safe', preferredLanguage)}
        </Button>
      </React.Fragment>
    );
  };

  const ReportSafeModal = () => {
    return (
      <Dialog open={reportSafeModal} onClose={toggleSafeModal}>
        <ModalBody>
          <br></br>
          <h3>{getTranslation('thank you for your feedback', preferredLanguage)}</h3>
          <br></br>
        </ModalBody>
      </Dialog>
    );
  };

  //-----------Report Unsafe Modal-----------//
  const ReportUnsafeModalButton = () => {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={reportUnsafe}
          id="reportUnsafe"
          startIcon={<FlagRoundedIcon />}
        >
          {getTranslation('report unsafe', preferredLanguage)}
        </Button>
      </React.Fragment>
    );
  };

  const ReportUnsafeModal = () => {
    return (
      <Dialog open={reportUnsafeModal} toggle={toggleUnsafeModal}>
        <ModalBody>
          <br></br>
          <h3> {getTranslation('thank you for your feedback', preferredLanguage)}</h3>
          <br></br>
        </ModalBody>
      </Dialog>
    );
  };

  //--------------From History Table Message--------------//

  const FromHistoryTableMessage = () => {
    if (props.history.location.state != undefined) {
      if (props.history.location.state.safety != undefined && props.history.location.state.safety != props.safety) {
        return (
          <div className={classes.root}>
            <Alert severity="info">
            {getTranslation("This link's safety status has been updated since you last encountered it.", preferredLanguage)}
              </Alert>
          </div>
        );
      } else {
        return <span></span>;
      }
    } else {
      return <span></span>;
    }
  };

  //-----------Warning Bars-----------//
  const unsafeWarning = (
    <div id="unsafeWarning">
      <Row>
        <Grid container item xs={12}>
          <span style={{ fontSize: '1rem' }}>
            <ErrorIcon fontSize="large" />
            {getTranslation("DANGER: This link is marked as unsafe", preferredLanguage)}
          </span>
        </Grid>
      </Row>
    </div>
  );

  const unknownWarning = (
    <Row id="unknownWarning">
      <Grid container item xs={6}>
        <span style={{ fontSize: '1.1rem' }}>
          {' '}
          <WarningRoundedIcon />
          {getTranslation("WARNING: This link has not yet been validated", preferredLanguage)}
        </span>
      </Grid>
      <Grid container xs={6} style={{ alignContent: 'end', paddingRight: '35px' }} justify="flex-end">
        <span style={{ marginRight: '15px', fontSize: '1.1rem' }}> {getTranslation("Open Link", preferredLanguage)}:</span>
        <a href={props.link} target="_blank">
          <Button variant="contained" startIcon={<WarningRoundedIcon />} id="unknownWarningButton">
          {getTranslation("proceed with caution", preferredLanguage)}
          </Button>
        </a>
      </Grid>
    </Row>
  );

  //----------Redirect Chain Title-----------//
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const TitleToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle">
        {getTranslation(props.title, preferredLanguage)}

        </Typography>
      </Toolbar>
    );
  };

  //---------Icon Stlyes--------//
  const useIconStyles = makeStyles({
    arrow: {
      width: 40,
      height: 40,
    },
    openListDots: {
      width: 30,
      height: 30,
    },
  });

  const icons = useIconStyles();

  //-------------- Direct Chain List -------------//

  const useListStyles = makeStyles((theme) => ({
    root: {
      width: 'inherit',
      //maxWidth: '600px',
      backgroundColor: theme.palette.background.paper,
      fontSize: '.9rem',
    },
    listText: {
      fontSize: '.9rem',
      wordWrap: 'breakWord',
      wordBreak: 'breakAll',
      width: 'iherit',
      overFlow: '',
    },
  }));

  const RedirectChainList = () => {
    const listStyles = useListStyles();

    return (
      <div className={listStyles.root}>
        <List aria-label="redirect chain list">
          {props.linkChain.map((value) => (
            <div>
              <ListItem className={listStyles.listText}>
                <ListItemText primary={accountForRedirectLinkLength(value)} />
              </ListItem>
              <Divider variant="fullWidth" />
            </div>
          ))}
        </List>
      </div>
    );
  };

  //-----------Last User Seen Table-----------//

  const checkSeenRows = () => {
    if (props.companyStatistics.userSeenList === undefined || props.companyStatistics.userSeenList === 0) {
      return ['none'];
    } else {
      return props.companyStatistics.userSeenList;
    }
  };

  const columns = [
    {
      id: 'lastUsersSeen',
      label: getTranslation("last users seen", preferredLanguage),
      width: 'auto',
      //minWidth: 100,
      //maxWidth: 100,
      align: 'left',
      format: (value) => value.toString(),
    },
  ];

  const useStyles = makeStyles({
    root: {
      width: 'inherit',
      minWidth: '180px',

      height: '100%',
      fontFamily: 'Montserrat, sans-serif',
      //padding: 5
    },
    header: {
      width: 'inherit',
    },
    container: {
      height: 330,
      width: 'auto',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
    table: {
      width: '100%',
      wordWrap: 'breakWord',
      fontFamily: 'Montserrat, sans-serif',
    },
    redirectCard: {
      width: '100%',
      //maxWidth: "600px"
    },
  });

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const renderTable = () => {
    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      fontWeight: '600',
                      fontFamily: 'Montserrat, sans-serif',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {checkSeenRows().map((value) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    <TableCell
                    //style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif', }}
                    >
                      {value}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  //-----------Last User Clicked Table-----------//

  const checkClickedRows = () => {
    if (props.companyStatistics.userClickList === undefined) {
      return ['none'];
    } else {
      return props.companyStatistics.userClickList;
    }
  };
  const clickedColumns = [
    {
      id: 'lastUsersClicked',
      label: getTranslation("last users clicked", preferredLanguage),
      //minWidth: 100,
      //maxWidth: 100,
      align: 'left',
      format: (value) => value.toString(),
    },
  ];

  const renderClickedTable = () => {
    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader label="sticky table">
            <TableHead>
              <TableRow>
                {clickedColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      fontWeight: '600',
                      fontFamily: 'Montserrat, sans-serif',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {checkClickedRows().map((value) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    <TableCell
                    //style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}
                    >
                      {value}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  const firstLinkChain = () => {
    if (props.linkChain != undefined) {
      return props.linkChain[0];
    }
  };

  const lastLinkChain = () => {
    if (props.linkChain != undefined) {
      return props.linkChain.slice(-1)[0];
    }
  };

  const StatisticsTable = (props) => {
    return (
      <Table className={classes.table} stickyHeader size="medium" aria-label="redirect chain log">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}>
            {getTranslation("times seen", preferredLanguage)}{' '}
              <span style={{ padding: '5px' }}>
                <FontAwesomeIcon icon={['fas', 'eye']} />
              </span>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}>
            {getTranslation("times clicked", preferredLanguage)}{' '}
              <span style={{ padding: '5px' }}>
                <FontAwesomeIcon icon={['fas', 'mouse-pointer']} />
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{props.timesSeen}</TableCell>
            <TableCell align="center">{props.timesClicked}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const redirectChainTable = () => {
    return (
      <Table className={classes.table} stickyHeader size="medium" aria-label="redirect chain log">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}>{getTranslation("Initial Link", preferredLanguage)}</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}>{getTranslation("Redirect Link", preferredLanguage)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{accountForRedirectLinkLength(firstLinkChain())}</TableCell>
            <TableCell align="center" style={{ width: '20%' }}>
              <ArrowForwardIcon className={icons.arrow} />
            </TableCell>
            <TableCell>{accountForRedirectLinkLength(lastLinkChain())}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const safetyExplanation = getTranslation("PhishCloud risk assessment score is determined by industry standard anti-phishing sources, user reported feedback, and hueristic analysis.", preferredLanguage)
  //--------------Return------------//

  return (
    <Grid spacing={2} className="linkInfoContatiner">
      <Grid item xs={12}>
        <FromHistoryTableMessage />
        <br />
        <Paper id={props.alertSafety}>
          {props.safety === 'unsafe' ? unsafeWarning : props.safety === 'unknown' ? unknownWarning : ''}

          <Row id="link">
            <span id="linkStyled">
              {props.safety === 'safe' ? (
                <a href={props.link} target="_blank">
                  {accountForLinkLength(80, props.link)}
                  <LaunchRoundedIcon fontSize="large" />
                </a>
              ) : (
                accountForLinkLength(80, props.link)
              )}
            </span>
          </Row>
          <Row id="bottomRow">
            <Grid container item xs={4} sm={4} md={6} style={{ paddingLeft: '20px' }} alignItems="flex-end">
              <span id="rating">
              {getTranslation(props.safety, preferredLanguage)}{' '}
                <span id="riskAM">
                  {' '}
                  {props.confidenceLevel}/100
                  <LightTooltip
                    arrow
                    title={safetyExplanation}
                  >
                    <InfoOutlinedIcon fontSize="large" />
                  </LightTooltip>
                </span>
              </span>
            </Grid>
            <Grid
              container
              item
              xs={8}
              sm={8}
              md={6}
              style={{ alignContent: 'end', paddingRight: '35px' }}
              justify="flex-end"
            >
              {props.safety === 'unknown' || props.safety === 'unsafe' ? ReportSafeModalButton() : ''}
              {ReportUnsafeModalButton()}
            </Grid>
            {ReportSafeModal()}
            {ReportUnsafeModal()}
          </Row>
        </Paper>
      </Grid>

      <Grid container spacing={3} direction="row">
        <Grid item container xs={12} sm={4} md={4}>
          <Paper style={{ width: '100%' }}>
            <Row>
              <TitleToolbar title={getTranslation("global statistics", preferredLanguage)}  className={classes.root} />
            </Row>
            <Row style={{ marginRight: '0px' }}>
              <StatisticsTable
                timesClicked={parseInt(props.globalStatistics.globalClicks)}
                timesSeen={parseInt(props.globalStatistics.globalSeens)}
              />
            </Row>
          </Paper>
        </Grid>
        <Grid item container xs={12} sm={4} md={4}>
          <Paper style={{ width: '100%' }}>
            <Row>
              <TitleToolbar title={getTranslation("Company Statistics", preferredLanguage)} className={classes.root} />
            </Row>
            <Row style={{ marginRight: '0px' }}>
              <StatisticsTable
                timesClicked={parseInt(props.companyStatistics.companyClicks)}
                timesSeen={parseInt(props.companyStatistics.companySeens)}
              />
            </Row>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Card className="linkInfoCardAdmin">
            <Row>
              <TitleToolbar title={getTranslation("Link Information", preferredLanguage)} className={classes.root} />
            </Row>
            <CardContent>
              <Row>
                <span className="analysisTitle">{getTranslation("Country of Origin", preferredLanguage)}:</span>

                <Col xs={4} className="countryName">
                  {countryLengthCheck(byFips(props.countryName || 'US')?.country) ?? 'Not Available'}
                </Col>

                <Col xs={3} className="flag">
                  <FlagIcon code={props.code} />
                </Col>
              </Row>
              <br />
              <Row>
              <span className="analysisTitle">
                {getTranslation("Domain", preferredLanguage)}: </span>
                <Col>{props.domain}</Col>
              </Row>
              <br />
              <Row>
                <span className="analysisTitle">
                {getTranslation("Date Created", preferredLanguage)}: </span>
                <Col>
                  <DateCreateFullDate date={props.created} />
                </Col>
              </Row>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} sm={12} md={6}>
          <Row style={{ marginRight: '0px' }}>
            <Card className={classes.redirectCard}>
              <div className="redirectChainTitle">
                <TitleToolbar title={getTranslation("Redirect Chain", preferredLanguage)} className={classes.root} />
              </div>
              <div className="redirectChain">
                {redirectChainTable()}

                <Tooltip title={!expanded ? getTranslation('View Redirect Chain List', preferredLanguage) : getTranslation('Close Redirect Chain List', preferredLanguage)}>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label={getTranslation("view redirect chain list", preferredLanguage)}
                  >
                    {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </Tooltip>
              </div>

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <RedirectChainList />
                </CardContent>
              </Collapse>
            </Card>
          </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper style={{ paddingRight: '15px' }}>
            <Row>
              <TitleToolbar title={getTranslation("Company Statistics", preferredLanguage)} className={classes.root} />
            </Row>
            <Row>
              <Col xs="6">{renderTable()}</Col>
              <Col xs="6">{renderClickedTable()}</Col>
            </Row>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LinkAnalysisAdminResults;

//---------------For Future Release-----------//

//< span style = {{ fontWeight: '300' }}> last updated __ hours ago </span >

//< Row >
//<h4 id="widgetCollectionHeader">Company Statistics</h4>
//                        </Row >
//<Row>

//    <div className="linkAnalysisWidget">
//        <CircleWidget
//            icon={<FontAwesomeIcon icon={['fas', 'mouse-pointer']} />}
//            circleColor="lightgrey"
//            height={200}
//            width={200}
//            text={"Times Clicked"}
//            number={props.timesClicked}
//        />
//    </div>
//    <div className="linkAnalysisWidget">
//        <CircleWidget
//            icon={<FontAwesomeIcon icon={['fas', 'eye']} />}
//            circleColor="lightgrey"
//            height={200}
//            width={200}
//            text={"Times Seen"}
//            number={props.timesSeen}
//        />
//    </div>
//</Row>

//< Col lg = "5" >
//    <div className="confidenceLevelCard">
//        <Row>
//            <h5>Confidence Level:</h5>
//            <p>We looked at [] variables, and [] thought this link was malicious.</p>
//        </Row>
//    </div>
//                    </Col >

//< ListItem >
//<ListItemText primary="Redirect Chain Step 1" />
//                </ListItem >
//<Divider />
//<ListItem >
//    <ListItemText primary="Redirect Chain Step 1" />
//</ListItem>
//<Divider />
//<ListItem >
//    <ListItemText primary="Redirect Chain Step 2" />
//</ListItem>
//<Divider />
//<ListItem >
//    <ListItemText primary="Redirect Chain Step 3" />
//</ListItem>
//<Divider />
//<ListItem >
//    <ListItemText primary="Redirect Chain Step 4" />
//</ListItem>

