import React, { useState, useEffect } from 'react';
import { Alert, Row, Col, Button, Input, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import BoxWidgetRedone from '../../BoxWidget/BoxWidgetRedone';
import BoxWidgetTwoHeaders from '../../BoxWidget/BoxWidgetTwoHeaders';

const CompanyInfoStatisticsBoxWidgets = (props) => {
  const [stylingStatus, setStylingStatus] = useState('unknown');

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const handleLargeNumbers = (number) => {
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      return number;
    }
  };

  const getActualUnsafeSeenTotal = () => {
    return (
      props.boxWidgetsData.scannedLinks.seen -
      (props.boxWidgetsData.unknownLinks.seen + props.boxWidgetsData.unsafeLinks.seen)
    );
  };

  const getActualUnsafeClickedTotal = () => {
    return (
      props.boxWidgetsData.scannedLinks.clicked -
      (props.boxWidgetsData.unknownLinks.clicked + props.boxWidgetsData.unsafeLinks.clicked)
    );
  };

  // useEffect(() => {
  //     if (props.boxWidgetsData.companySafety === "not that safe" || props.boxWidgetsData.companySafety === "medium") {
  //     setStylingStatus("company-medium")
  // } else if (props.boxWidgetsData.companySafety === "safe") {

  //     } else if (props.boxWidgetsData.companySafety === 'getting risky' || props.boxWidgetsData.companySafety === "unsafe") {

  // } else {
  //     setStylingStatus("unknown")
  // }
  // }, []);

  useEffect(() => {
    if (props.boxWidgetsData.companyRiskScore) {
      if (props.boxWidgetsData.companyRiskScore >= 86) {
        setStylingStatus('company-unsafe');
      } else if (props.boxWidgetsData.companyRiskScore <= 60) {
        setStylingStatus('company-safe');
      } else if (props.boxWidgetsData.companyRiskScore >= 61 && props.boxWidgetsData.companyRiskScore <= 85) {
        setStylingStatus('company-medium');
      }
    }
  }, [props.boxWidgetsData.companyRiskScore]);

  return (
    <div id={stylingStatus}>
      <span id="companySafety">
        <p>{getTranslation("Company Overall Safety", preferredLanguage)} : {getTranslation(props.boxWidgetsData.companySafety, preferredLanguage)}</p>
      </span>

      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        //spacing={1}
        id="widgets"
      >
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <BoxWidgetRedone
            icon={<FontAwesomeIcon icon={['fas', 'users']} />}
            boxHeader={getTranslation('Total Users', preferredLanguage)}
            //backgroundColor="#fafafa"
            //border="none"
            //height={200}

            //width={200}
            iconMarginTop={0}
            number={props.boxWidgetsData.userCount}
            //text={"Total Users"}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <BoxWidgetRedone
            icon={<FontAwesomeIcon icon={['fas', 'user-clock']} />}
            boxHeader={getTranslation('Active Users', preferredLanguage)}
            //backgroundColor="#fafafa"
            //height={200}
            //width={200}
            //border="none"
            //boxShadow="none"

            iconMarginTop={0}
            number={props.boxWidgetsData.activeUsers}
            //text={"Active Users"}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <BoxWidgetTwoHeaders
            icon={<FontAwesomeIcon icon={['fas', 'link']} />}
            boxHeader={getTranslation('All Links', preferredLanguage)}
            //backgroundColor="#fafafa"
            //height={200}
            //width={200}
            //border="none"
            //boxShadow="none"

            iconMarginTop={0}
            number1={handleLargeNumbers(props.boxWidgetsData.scannedLinks.seen)}
            number2={handleLargeNumbers(props.boxWidgetsData.scannedLinks.clicked)}
            text1={getTranslation('Seen', preferredLanguage)}
            text2={getTranslation('Clicked', preferredLanguage)}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <BoxWidgetTwoHeaders
            icon={<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />}
            boxHeader={getTranslation('Unknown', preferredLanguage)}
            //backgroundColor="#fafafa"
            //height={200}
            //width={200}
            //border="none"
            //boxShadow="none"

            iconMarginTop={0}
            number={129}
            number1={handleLargeNumbers(props.boxWidgetsData.unknownLinks.seen)}
            number2={handleLargeNumbers(props.boxWidgetsData.unknownLinks.clicked)}
            text1={getTranslation('Seen', preferredLanguage)}
            text2={getTranslation('Clicked', preferredLanguage)}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <BoxWidgetTwoHeaders
            icon={<FontAwesomeIcon icon={['fas', 'exclamation']} />}
            boxHeader={getTranslation('Unsafe', preferredLanguage)}
            //backgroundColor="#fafafa"
            //height={200}
            //width={200}
            //border="none"
            //boxShadow="none"

            iconMarginTop={0}
            number1={handleLargeNumbers(props.boxWidgetsData.unsafeLinks.seen)}
            number2={handleLargeNumbers(props.boxWidgetsData.unsafeLinks.clicked)}
            text1={getTranslation('Seen', preferredLanguage)}
            text2={getTranslation('Clicked', preferredLanguage)}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <BoxWidgetTwoHeaders
            icon={<FontAwesomeIcon icon={['fa', 'traffic-light']} />}
            boxHeader={getTranslation('Safety Rules', preferredLanguage)}
            //backgroundColor="#fafafa"
            //height={200}
            //width={200}
            //border="none"
            //boxShadow="none"

            iconMarginTop={0}
            number1={props.boxWidgetsData.safetyRuleLinks.seen}
            number2={props.boxWidgetsData.safetyRuleLinks.clicked}
            text1={getTranslation('Seen', preferredLanguage)}
            text2={getTranslation('Clicked', preferredLanguage)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default CompanyInfoStatisticsBoxWidgets;
