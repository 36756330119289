import React, { useEffect, useState } from 'react';

const TrainingTotalsWidget = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <p id="widgetNumber">{props.widgetNumber}</p>
      <p id="widgetTitle" style={{ color: props.color }}>
        {props.widgetTitle}
      </p>
    </div>
  );
};
export default TrainingTotalsWidget;
