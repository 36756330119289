import React, { useEffect, useState } from 'react';

//------Material UI-----/
import { makeStyles } from '@material-ui/core/styles';
import SchoolIcon from '@material-ui/icons/School';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const TrainingItemCard = (props) => {
  const [toggle, setToggle] = useState(false);

  const handleChange = (event) => {
    setToggle(!toggle);

    if (toggle === false) {
      var itemToBeAdded = { modId: event.target.name, status: 'required' };
    } else if (toggle === true) {
      var itemToBeAdded = { modId: event.target.name, status: 'suggested' };
    }

    const index = props.modId.findIndex((p) => p.modId === itemToBeAdded.modId);

    if (index === -1) {
      props.modId.push(itemToBeAdded);
    } else {
      props.modId[index] = itemToBeAdded;
    }
  };

  const trainingDetailPush = (trainingDetails) => {
    props.history.push('/Dashboard/Training?pad=&id=' + trainingDetails);
  };

  console.log(props.trainingDetails)

  useEffect(() => {
    //if (props.status !== undefined) {
    if (props.status === 'required') {
      setToggle(true);
    } else {
      setToggle(false);
    }
    // }
  }, [props.status]);

  //------Card Styling----//
  const useStyles = makeStyles((theme) => ({
    root: {
      //height: '20%',
      //minHeight: 420,
      //maxHeight: 450
    },
    media: {
      height: 160,
    },
    overlineTypography: {
      color: '#B5B5B5',
      fontFamily: 'Montserrat, sans-serif',
    },
    statusTypography: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
    },
    titleTypography: {
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.4em',
      paddingBottom: 12,
    },
    descriptionTypography: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.1em',
      height: 100,
      paddingBottom: 10,
    },
    cardActions: {
      //marginTop: 30,
      //marginBottom: 16,
      //height: 20,
      float: 'right',
    },
    button: {
      fontFamily: 'Montserrat, sans-serif',
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={4} md={4} lg={4}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            style={{ width: '100%' }}
            className={classes.media}
            image={props.image}
            title={props.accessibleTitle}
            onClick={() => trainingDetailPush(props.trainingDetails)}
          />
          <CardContent>
            <Typography className={classes.titleTypography} gutterBottom variant="h5" component="h2">
              {props.title}
            </Typography>

            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography className={classes.statusTypography} align="left" gutterBottom variant="overline">
                  {props.status}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.overlineTypography} align="right" gutterBottom variant="overline">
                  <AccessTimeIcon fontSize="inherit" /> {props.timeEstimate}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          {props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin' ? (
            <FormControlLabel
              style={{ fontSize: '9px', marginLeft: '0' }}
              control={<Switch color="primary" checked={toggle} onChange={handleChange} name={props.trainingDetails} />}
              label="Make this training required for your organization"
            />
          ) : (
            <span></span>
          )}
          <Button
            className={classes.button}
            size="small"
            color="primary"
            onClick={() => trainingDetailPush(props.trainingDetails)}
          >
            View this training
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default TrainingItemCard;

//window.onunload = function () { window.scrollTo(0, 0);
//< Typography className = { classes.descriptionTypography } variant = "body2" color = "textSecondary" component = "p" >
//    { props.description }
//</Typography >
