import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { loadStripe } from '@stripe/stripe-js';
import { set } from 'date-fns';
import parsePhoneNumber from 'libphonenumber-js/max';
import React, { useEffect, useState } from 'react';
import basePCUrl from '../../../Globals';
import { useAuth0 } from "@auth0/auth0-react";

import './NewUser.css';
import NewUserLoadingModal from './NewUserLoadingModal';
import NewUserNavBar from './NewUserNavBar';

const stripePromise = loadStripe('pk_live_lQFJBBHu11xProHTpcc4SRnE00x9Tc5Eyo');

const NewUser = (props) => {

  const [loadingNewRegister, setLoadingNewRegister] = useState(false)

  const [purchaseProduct, setPurchaseProduct] = useState(false);
  const { loading, getAccessTokenSilently } = useAuth0();

  const [errorEmailText, setErrorEmailText] = useState();
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailConflict, setErrorEmailConflict] = useState(false);

  const [errorPhoneText, setErrorPhoneText] = useState();
  const [errorPhone, setErrorPhone] = useState(false);

  const [errorPasswordText, setErrorPasswordText] = useState();
  const [errorPasswordList, setErrorPasswordList] = useState([]);
  const [errorPassword, setErrorPassword] = useState();

  const [errorNumberOfUsers, setErrorNumberOfUsers] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const [sessionId, setSessionId] = useState();

  const [formState, setFormState] = React.useState({
    email: '',
    priceId: '',
    password: '',
    name: '',
    firstName: '',
    lastName: '',
    companyName: '',
    billingAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    numberOfUsers: '',
  });

  const [digits, setDigits] = useState();
  const [min, setMin] = useState();
  const [uppercase, setUppercase] = useState();
  const [lowercase, setLowercase] = useState();
  const [spaces, setSpaces] = useState();
  const [symbols, setSymbols] = useState();

  const { email, name, priceId, password, firstName, lastName, companyName, city, state, zip, country, numberOfUsers } =
    formState;

  //const stripePromise = ('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  //----------Handle EMAIL change/validation-----------//

  var emailCheck =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleEmailChange = (event) => {
    if (event.target.name === 'email' && event.target.value.match(emailCheck)) {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value.toLowerCase(),
      });
      setErrorEmailText('');
      setErrorEmail(false);
    } else if (
      event.target.name === 'email' &&
      event.target.value.length > 0 &&
      !event.target.value.match(emailCheck)
    ) {
      setErrorEmailText('Invalid Email');
      setErrorEmail(true);
    } else if (event.target.name === 'email' && event.target.value.length === 0) {
      setErrorEmail(false);
    }
  };

  //----------Handle PHONE change/validation-----------//
  const handlePhoneChange = (event) => {
    if (
      event.target.value != undefined &&
      event.target.value != null &&
      event.target.value != ' ' &&
      event.target.name === 'phone'
    ) {
      //let selectedCountryCode = extraPhoneInputs[i].countryCode
      let enteredPhoneNumber = parsePhoneNumber('+1' + event.target.value);

      if (enteredPhoneNumber != undefined && enteredPhoneNumber.isValid()) {
        setFormState({ ...formState, [event.target.name]: event.target.value });
        setErrorPhoneText('');
        setErrorPhone(false);
      } else if (enteredPhoneNumber != undefined && !enteredPhoneNumber.isValid()) {
        setErrorPhoneText('Invalid Phone Number');
        setErrorPhone(true);
      } else if (event.target.name === 'phoneNumber' && event.target.value.length === 0) {
        setErrorPhone(false);
      }
    }
  };

  //----------Handle Password change/validation-----------//

  var passwordValidator = require('password-validator');
  var schema = new passwordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 digits
    .has()
    .not()
    .spaces() // Should not have spaces
    .has()
    .symbols(1)
    .is()
    .not()
    .oneOf(['Passw0rd', 'Password123']); // Blacklist these values

  const handlePasswordChange = (event) => {
    if (
      event.target.value != undefined &&
      event.target.value != null &&
      event.target.value != ' ' &&
      event.target.name === 'password'
    ) {
      let enteredPassword = event.target.value;

      if (enteredPassword != undefined && schema.validate(enteredPassword)) {
        setFormState({ ...formState, [event.target.name]: event.target.value });
        setErrorPassword(false);
        setErrorPasswordList([]);
      } else if (enteredPassword != undefined && !schema.validate(enteredPassword)) {
        //console.log(schema.validate(enteredPassword, { list: true }))
        setErrorPasswordList(schema.validate(enteredPassword, { list: true }));

        setErrorPassword(true);
      } else if (event.target.name === 'password' && event.target.value.length === 0) {
        setErrorPassword(false);
        setErrorPasswordList([]);
        setSpaces(false);
      }
    }
  };

  const showPasswordRulesNotMet = [];

  // --------Password Rules Tooltip--------//
  useEffect(() => {
    //console.log(errorPasswordList);
    {
      if (errorPasswordList != [] && !errorPasswordList.includes('digits')) {
        setDigits(true);
        //console.log('I just set digits to true');
      } else if (errorPasswordList.includes('digits')) {
        setDigits(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('min')) {
        setMin(true);
      } else if (errorPasswordList.includes('min')) {
        setMin(false);
      }
    }
    {
      if (errorPasswordList != [] && !errorPasswordList.includes('uppercase')) {
        setUppercase(true);
      } else if (errorPasswordList.includes('uppercase')) {
        setUppercase(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('lowercase')) {
        setLowercase(true);
      } else if (errorPasswordList.includes('lowercase')) {
        setLowercase(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('spaces')) {
        setSpaces(true);
      } else if (errorPasswordList.includes('spaces')) {
        setSpaces(false);
      }
    }

    {
      if (errorPasswordList != [] && !errorPasswordList.includes('symbols')) {
        setSymbols(true);
      } else if (errorPasswordList.includes('symbols')) {
        setSymbols(false);
      }
    }
  }, [errorPasswordList.length]);

  var finalPasswordList = (
    <List>
      {digits === false ? (
        <ListItem style={{ color: 'red' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain as least one number
        </ListItem>
      ) : digits === true ? (
        <ListItem style={{ color: 'green' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" /> must contain as least one number
        </ListItem>
      ) : (
        <ListItem>must contain as least one number</ListItem>
      )}
      {min === false ? (
        <ListItem style={{ color: 'red' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain least eight characters
        </ListItem>
      ) : min === true ? (
        <ListItem style={{ color: 'green' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain least eight characters
        </ListItem>
      ) : (
        <ListItem>must contain least eight characters</ListItem>
      )}
      {uppercase == false ? (
        <ListItem style={{ color: 'red' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain at least one uppercase letter
        </ListItem>
      ) : uppercase === true ? (
        <ListItem style={{ color: 'green' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain at least one uppercase letter
        </ListItem>
      ) : (
        <ListItem>must contain at least one uppercase letter</ListItem>
      )}
      {lowercase === false ? (
        <ListItem style={{ color: 'red' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain at least one lowercase letter
        </ListItem>
      ) : lowercase === true ? (
        <ListItem style={{ color: 'green' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain at least one lowercase letter
        </ListItem>
      ) : (
        <ListItem>must contain at least one lowercase letter</ListItem>
      )}
      {symbols === false ? (
        <ListItem style={{ color: 'red' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain at least one special character
        </ListItem>
      ) : symbols === true ? (
        <ListItem style={{ color: 'green' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain at least one special character
        </ListItem>
      ) : (
        <ListItem>must contain at least one special character</ListItem>
      )}
      {spaces === false ? (
        <ListItem style={{ color: 'red' }}>
          <ErrorIcon id="passwordTooltipListIcon" /> must contain no spaces
        </ListItem>
      ) : spaces === true ? (
        <ListItem style={{ color: 'green' }}>
          <CheckCircleIcon id="passwordTooltipListIcon" />
          must contain no spaces
        </ListItem>
      ) : (
        <ListItem>must contain no spaces</ListItem>
      )}
    </List>
  );

  const [openTooltip, setOpenTooltip] = React.useState(false);
  var activeTextarea = document.activeElement;

  const checkPasswordActiveField = () => {
    //console.log(activeTextarea.id);
    if (activeTextarea.id === 'password' && errorPassword === true) {
      setOpenTooltip(true);
    } else {
      setOpenTooltip(false);
    }
  };
  useEffect(() => {
    checkPasswordActiveField();
  }, [errorPasswordList.length]);

  // console.log(errorPasswordList.length);
  // console.log(digits);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      //backgroundColor: '#f5f5f9',
      backgroundColor: theme.palette.common.black,
      //color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  //--------Handle number of Users errors--------//

  const wholeNumberCheck = /^\d+$/;

  const handleNumberOfUsers = (event) => {
    if (event.target.name === 'numberOfUsers' && !event.target.value.match(wholeNumberCheck)) {
      setErrorNumberOfUsers(true);
    } else if (event.target.name === 'numberOfUsers' && event.target.value.match(wholeNumberCheck)) {
      setErrorNumberOfUsers(false);
      setFormState({ ...formState, [event.target.name]: event.target.value });
    }
  };

  const NumberOfUsersErrorAlert = () => {
    const classes = useStyles();
    if (errorNumberOfUsers === true) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorNumberOfUsers(false)}>
            Number of users must be a positive number.
          </Alert>
        </div>
      );
    } else if (errorNumberOfUsers === false) {
      return null;
    }
  };

  //------------Render Error Message-----------//

  const ErrorMessageAlert = () => {
    const classes = useStyles();
    if (errorMessage != undefined && errorMessage.includes('Email Already Exists')) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorMessage(null)}>
            {errorMessage} <a href="https://portal.phishcloud.io">Sign in here</a>
          </Alert>
        </div>
      );
    } else if (errorMessage != undefined) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorMessage(null)}>
            {errorMessage}
          </Alert>
        </div>
      );
    } else if (errorMessage === undefined || errorMessage === null) {
      return null;
    }
  };

  //------------Manually Set PriceIds for Subscription Types------------//

  const [finalPriceId, setFinalPriceId] = useState();
  const [finalNumberOfUsers, setFinalNumberOfUsers] = useState();
  //console.log(numberOfUsers - 1);

  const checkPriceID = (event) => {
    handleChange(event);
    //getCheckoutSessionID();
  };

  useEffect(() => {
    if (priceId != '') {
      setFinalNumberOfUsers(parseInt(numberOfUsers));
      if (priceId === 'lite') {
        setFinalPriceId('price_1HgZUQK21vNgoVk1LOj4OS7M');
      } else if (priceId === 'pro') {
        setFinalPriceId('price_1HgZvGK21vNgoVk1gxshzLKU');
      }
    }
    //console.log(finalPriceId);
  }, [priceId]);

  const handleCheckoutClick = async (event) => {
    event.preventDefault();
    if (errorNumberOfUsers === false && numberOfUsers != 0) {
      // When the customer clicks on the button, redirect them to Checkout.

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          // Replace with the ID of your price
          {
            price: finalPriceId,
            quantity: finalNumberOfUsers,
          },
        ],
        mode: 'subscription',
        successUrl: 'https://portal.phishcloud.io/Register/Verify?pad=&signUpEmail=' + email,
        cancelUrl: 'https://portal.phishcloud.io/Register',
        billingAddressCollection: 'required',
        customerEmail: email,
      });

      if (error) {
        pushToRegisterErrorPage();
      }
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    }
  };

  //------------Card Element-----------//

  const pushToSuccessPage = () => {
    props.history.push('/Register/Verify?pad=&signUpEmail=' + email);
  };

  const pushToRegisterErrorPage = () => {
    props.history.push('/Register/Error');
  };

  //------------Create Stripe Customer on Sign Up-------------//

  const createAuth0Account = async () => {
    //event.preventDefault();
    //const token = await getAccessTokenSilently();

    setLoadingNewRegister(true)
    const finalEmail = email.toLowerCase();

    if (!companyName) {
      var data = {
        email: finalEmail,
        name: name,
        description: finalEmail,
        password: password,
        trial: startTrialButton,
      };
    } else {
      var data = {
        email: finalEmail,
        name: name,
        description: companyName,
        password: password,
        trial: startTrialButton,
      };
    }

    fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        //Authorization: `Bearer ${token}`,
        Action: 'createAccount',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          //setError(true);
          // console.log(error);
          //setTopGlobalBadLinks(null);
          pushToRegisterErrorPage();
          return;
        }

        response.json().then((data) => {
          if (data.error != undefined && data.error.includes('error')) {
            setErrorMessage(data.error);
          } else {
            //console.log(data);
            setLoadingNewRegister(false)
            pushToSuccessPage();
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  //------------Create Stripe Customer before Stripe Checkout-------------//

  const createStripeCustomerBeforeStripeCheckout = async () => {
    //event.preventDefault();
    //const token = await getAccessTokenSilently();

    const finalEmail = email.toLowerCase();
    if (!companyName) {
      var data = {
        email: finalEmail,
        name: name,
        description: finalEmail,
        password: password,
        trial: startTrialButton,
      };
    } else {
      var data = {
        email: finalEmail,
        name: name,
        description: companyName,
        password: password,
        trial: startTrialButton,
      };
    }

    fetch(basePCUrl + 'StripeClient/CreateStripeCustomer', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        //Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          //setError(true);
          // console.log(error);
          //setTopGlobalBadLinks(null);
          pushToRegisterErrorPage();
          return;
        }

        response.json().then((data) => {
          if (data.status.includes('already exists')) {
            setErrorMessage(data.status);
          } else if (data.status.includes('Error') || data.status.includes('error')) {
            setErrorMessage(data.status);
            //console.log(data.status);
          } else {
            //console.log(data);
            setErrorMessage();
            togglePurchaseForm();
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();

  const EmailConflictAlert = () => {
    const classes = useStyles();
    if (errorEmailConflict === true) {
      return (
        <div className={classes.root}>
          <Alert severity="error" onClose={() => setErrorEmailConflict(false)}>
            That email is already registered with PhishCloud.
          </Alert>
        </div>
      );
    } else if (errorEmailConflict === false) {
      return null;
    }
  };

  const togglePurchaseForm = () => {
    setPurchaseProduct(!purchaseProduct);
  };

  const [startTrialButton, setStartTrialButton] = useState();
  const [createUserAndPurchaseButton, setCreateUserAndPurchaseButton] = useState();

  const formSubmission = (event) => {
    event.preventDefault();
    // if (startTrialButton === true) {
      if (
        name != '' &&
        name != undefined &&
        email != '' &&
        email != undefined &&
        password != '' &&
        password != undefined &&
        errorPassword === false &&
        errorEmail === false
      ) {
        createAuth0Account();
      }
    // } else if (createUserAndPurchaseButton === true) {
    //   if (
    //     name != '' &&
    //     name != undefined &&
    //     email != '' &&
    //     email != undefined &&
    //     password != '' &&
    //     password != undefined &&
    //     errorPassword === false &&
    //     errorEmail === false &&
    //     errorEmailConflict === false
    //   ) {
    //     createStripeCustomerBeforeStripeCheckout();
    //     setErrorPassword();
    //   }
    }
  

  return (
    <React.Fragment>
      <head>
        <script src="https://js.stripe.com/v3/"></script>
      </head>
      {!loading ? (
        <React.Fragment>
          {/* <NewUserNavBar /> */}
          
          <div id="signUpParent">
            <div id="payment-form">
              <form id="createUserForm" onSubmit={formSubmission}>
                <section>
                  {purchaseProduct === false ? (
                    <Paper id="signUpFieldset">
                      <label>
                        <div id="textfieldTitle">Name</div>
                        <TextField
                          name="name"
                          class="field"
                          //placeholder="Name"
                          onChange={handleChange}
                          className="newUserInput"
                          InputProps={{ disableUnderline: true }}
                          required
                        />
                      </label>

                      <label>
                        <span id="textfieldTitle">Company Name</span>
                        <TextField
                          name="companyName"
                          class="field"
                          onChange={handleChange}
                          className="newUserInput"
                          InputProps={{ disableUnderline: true }}
                          //placeholder="Company Name"
                        />
                      </label>

                      <label>
                        {errorEmail === false ? (
                          <div id="textfieldTitle">Email</div>
                        ) : (
                          <div id="textfieldTitle" style={{ color: 'red' }}>
                            Invalid Email
                          </div>
                        )}
                        <TextField
                          required
                          //disabled={!email}
                          //placeholder="Email"
                          //value={email}
                          name="email"
                          //style={{width: '100%',  }}
                          onChange={handleEmailChange}
                          type="email"
                          class="field"
                          className="newUserInput"
                          InputProps={{ disableUnderline: true }}
                        />
                      </label>

                      <label>
                        <HtmlTooltip
                          title={finalPasswordList}
                          open={openTooltip}
                          onOpen={() => setOpenTooltip(true)}
                          onClose={() => setOpenTooltip(false)}
                          disableHoverListener
                          placement="left"
                          arrow
                        >
                          {errorPassword === true ? (
                            <div id="textfieldTitle">
                              Password
                              <span style={{ color: 'red', padding: '5px' }}>
                                <LockOutlinedIcon />
                              </span>
                            </div>
                          ) : errorPassword === false ? (
                            <div id="textfieldTitle">
                              Password
                              <span style={{ color: 'green', padding: '5px' }}>
                                <LockOutlinedIcon />
                              </span>
                            </div>
                          ) : (
                            <div id="textfieldTitle">
                              Password
                              <span style={{ padding: '5px' }}>
                                <LockOutlinedIcon />
                              </span>
                            </div>
                          )}
                        </HtmlTooltip>

                        <TextField
                          name="password"
                          id="password"
                          class="field"
                          type="password"
                          //placeholder="Password"
                          className="newUserInput"
                          onChange={handlePasswordChange}
                          InputProps={{ disableUnderline: true }}
                          required
                        />
                      </label>
                    </Paper>
                  ) : null}
<NewUserLoadingModal
        isOpen={loadingNewRegister}
      />
                  {purchaseProduct === false ? (
                    <button
                      id="stripeButton"
                      // onClick={() => {
                      //   setStartTrialButton(true);
                      //   setCreateUserAndPurchaseButton(false);
                      // }}
                      type="submit"
                    >
                      {' '}
                      REGISTER
                    </button>
                  ) : null}
                </section>

                <br />
                <ErrorMessageAlert />
                <br />

                {/* {purchaseProduct === false ? (
                  <Button
                    style={{ textTransform: 'none' }}
                    id="toggleAndCreateUser"
                    onClick={() => {
                      setCreateUserAndPurchaseButton(true);
                      setStartTrialButton(false);
                    }}
                    type="submit"
                    disableElevation
                  >
                    I would like to purchase PhishCloud
                  </Button>
                ) : (
                  <Button
                    onClick={togglePurchaseForm}
                    style={{ textTransform: 'none', marginTop: '-340px' }}
                    disableElevation
                  >
                    Go back to trial sign up <ArrowBackIcon />
                  </Button>
                )} */}

                <br />
                <br />
                <p id="alreadySignedUp">
                  Already signed up? Sign in to the portal here:{' '}
                  <a href="https://portal.phishcloud.io/">portal.phishcloud.io/</a>{' '}
                </p>
              </form>
              <div id="purchase-form">
                <form>
                  {purchaseProduct === true ? (
                    <section>
                      <h2 id="paymentTitle">Payment Information</h2>
                      <fieldset id="signUpFieldset">
                        <label>
                          <span id="textfieldTitle">Number of Users</span>
                          <input
                            name="numberOfUsers"
                            class="field"
                            type="number"
                            onChange={handleNumberOfUsers}
                            min="0"
                            oninput="this.value = 
                                                    !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                            style={{ width: '100%', paddingLeft: '10px' }}
                            className="newUserInput"
                            required
                          />
                        </label>
                        <label class="select">
                          <span id="textfieldTitleSubscription">Subscription Type</span>
                          <div id="subscriptionType" className="newUserInput" class="field US">
                            <select name="priceId" onChange={handleChange} id="newUserSelectPriceId" required>
                              <option selected disabled value="">
                                Subscription type
                              </option>
                              <option value="lite">Lite</option>
                              <option value="pro">Pro</option>
                            </select>
                          </div>
                        </label>
                      </fieldset>
                      <NumberOfUsersErrorAlert />
                      <br />
                      <button id="stripeButton" class="payment-button" type="submit" onClick={handleCheckoutClick}>
                        {' '}
                        Sign Up
                      </button>
                    </section>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default NewUser;

// < EmailConflictAlert />

//------------Get Checkout Session ID-------------//

//useEffect(() => {
//    if (priceId != "") {
//        getCheckoutSessionID();

//    }

//}, [priceId]);

//const getCheckoutSessionID = async () => {

//    //const token = await getAccessTokenSilently();

//    var data = {

//        priceId: "lite"

//    };

//    fetch('api/StripeClient/CreateCheckoutSession', {
//        credentials: "same-origin",
//        method: 'POST',
//        body: JSON.stringify(data),
//        headers: {
//            //Authorization: `Bearer ${token}`,
//            'Content-Type': 'application/json'
//        }
//    }).then((response) => {

//        if (response.status !== 200) {
//            console.log('Looks like there was a problem. Status Code: ' +
//                response.status);
//            //setError(true);
//            // console.log(error);
//            //setTopGlobalBadLinks(null);
//            return;
//        }

//        response.json().then((data) => {

//            if (data.status === "Error" || data.status === "error") {
//                console.log("Get Checkout Session ID Fetch Error")
//            } else {
//                console.log(data)
//            }
//        });
//    })
//        .catch(function (err) {
//            console.log('Fetch Error :-S', err);
//        });

//}
