import { Button, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from "@auth0/auth0-react";

import { fetchApiData } from '../../../utils/service';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import TrainingCard from './TrainingCard';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ViewListIcon from '@material-ui/icons/ViewList';
import AppsIcon from '@material-ui/icons/Apps';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import TrainingDashboardTabs from './TrainingDashboardTabs';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: '#061739',
    minWidth: '75vw',
    marginRight: '1rem',
    marginLeft: '1rem',
    //padding: '2rem',
    // '& h2': {
    //   textAlign: 'center',
    // },
  },
  clickActionContainer: {
    border: '#061739',
    padding: '2rem',
  },
  paperBody: {
    marginBottom: '2rem',
    // '& p': {
    //   textAlign: 'center',
    // },
  },

  button: {
    width: '100%',
    height: '70px',
    backgroundColor: '#0055A2',
    '&:hover': {
      color: 'white',
      backgroundColor: '#428BC9',
    },
  },
  circleContainer: {
    display:'flex',
    justifyContent:'center',
  },
  circle: {
    background: '#E9F2F9',
    borderRadius: '50%',
    display: 'flex',
    color: 'black',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    width: '200px',
    height: '200px',
    padding: '10px',
    '& span': {
      flexDirection: 'column',
    },
    '& p': {
      fontWeight: 'bold',
    },
    '&$focused': {
      borderWidth: '10px',
      borderColor: '#061739',
    },
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      background: '#428BC9',
      borderColor: '#061739',
    },
  },
  media: {
    height: 160,
  },
}));
const trainingAPI = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

export default function UserTrainingDashboard(props) {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [courseList, setCourseList] = useState([]);
  const [filterType, setFilterType] = useState('required');
  const [cardsPerRow, setCardsPerRow] = useState(3);
  const [listView, setListView] = useState(false);

  const { isLoading, data, isError, error } = useQuery('user-training', async () => {
    const token = await getAccessTokenSilently();
    return fetchApiData(trainingAPI, token, 'userTraining', props.accessLevel, props.selectedClient.clientId);
  });


 
  //const data = props.trainingData

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  useEffect(() => {
    setCourseList(data?.trainingItems || []);
  }, [data]);

  function filterRequiredTraining(trainingItems) {
    return trainingItems.filter(item => item.status === 'required');
  }
  
  

  useEffect(() => {
    const requiredTraining = filterRequiredTraining(data?.trainingItems || []);
    setCourseList(requiredTraining)
  }, [data?.trainingItems]);

  if (props.isLoading) {
    return <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />;
  }



  function findObjectsWithKeyValueAndCookie(array, key, value, cookieName) {
    console.log(array)
    const matchingObjects = array.filter(obj => obj[key] === value);
    const values = matchingObjects.map(obj => obj[key]);
  
    const cookieValue = Cookies.get(cookieName);
  
    return {
      matchingObjects,
      values,
      cookieValue,
    };
  }

  const handleFilterTraining = (type) => {
    setFilterType(type);
  
    const keyToSearch = 'status'; // Modify this key based on your data structure
  
    // Check if the value exists in the cookie
    const { matchingObjects, values, cookieValue } = findObjectsWithKeyValueAndCookie(
      data?.trainingItems,
      keyToSearch,
      type,
    );
  
    let filteredCourses = [];


    function checkModIdForKeyInArray(arrayOfObjects) {
      const initialFilter = data?.trainingItems.filter(course => course.progress === 100)
    
      arrayOfObjects.forEach(obj => {
        if (obj.modId) {
          const modIdValue = obj.modId;
          const cookieValue = Cookies.get(modIdValue);
    
          if (cookieValue) {
            initialFilter.push(obj);
          }
        }
      });
    
      return initialFilter;
    }
    
  
    if (type === 'required' || type === 'Not Assigned') {
      filteredCourses = matchingObjects;
    } else if (type === 'All') {
      filteredCourses = data?.trainingItems;
    } else if (type === 'Completed') {
      filteredCourses = checkModIdForKeyInArray(data?.trainingItems)

    }
  
    setCourseList(filteredCourses);
  };
  

  const handleChange = (event) => {
    setCardsPerRow(event.target.value);
  };

  return (
    <>
      <div className={classes.root}>
     {props.accessLevel === "CompanyAdmin" || props.accessLevel === "AccountAdmin" ? <TrainingDashboardTabs /> : null}
        <h2>{getTranslation("User Training Dashboard", preferredLanguage)}</h2>
        <Paper className={classes.paperBody}>
          {/* <Grid container spacing={3} className={classes.clickActionContainer}>
            <Grid item xs={12} sm={6} md={4} className={classes.circleContainer}>
              <Button
                //style={filterType === 'required' ? { borderWidth: '10px', borderColor: '#061739' } : {}}
                className={classes.circle}
                onClick={() => handleFilterTraining('required')}
              >
                <p>Required Modules</p>
                <h2>{data?.numberAssigned || 0}</h2>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.circleContainer}>
              <Button
                className={classes.circle}
                //style={filterType === 'Not Assigned' ? { borderWidth: '10px', borderColor: '#061739' } : {}}
                onClick={() => handleFilterTraining('Not Assigned')}
              >
                <p>Suggested Modules</p>
                <h2>{data?.trainingItems.length - data?.numberAssigned || 0}</h2>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.circleContainer}>
              <Button
                className={classes.circle}
                //style={filterType === 'Completed' ? { borderWidth: '10px', borderColor: '#061739' } : {}}
                onClick={() => handleFilterTraining('Completed')}
              >
                <p>Completed Modules</p>
                <h2>{data?.numberCompleted || 0}</h2>
              </Button>
            </Grid>
          </Grid> */}
        </Paper>
        <div style={{ margin: '10px', display: 'flex' }}>
          <Chip
      className={classes.chip}
        label={getTranslation('Completed', preferredLanguage)}
        onClick={() => handleFilterTraining('Completed')}
        color="info"
        style={{ margin: '5px' }}
      />
        <Chip
      className={classes.chip}
        label={getTranslation('Required', preferredLanguage)} 
        onClick={() => handleFilterTraining('required')}

        color="info"
        style={{ margin: '5px' }}
      />
        <Chip
      className={classes.chip}
        label={getTranslation('Not Assigned', preferredLanguage)} 
        onClick={() => handleFilterTraining('Not Assigned')}
        color="info"
        style={{ margin: '5px' }}
      />
          <Button
            style={{ marginLeft: '10px' }}
            variant="contained"
            color="primary"
            onClick={() => handleFilterTraining('All')}
          >
           {getTranslation("Clear Filter", preferredLanguage)} 
          </Button>
          {/* <Grid>
          <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Cards</InputLabel>
              <Select labelId="cards per row" value={cardsPerRow} onChange={handleChange} label="Cards">
                <MenuItem value={12}>1</MenuItem>
                <MenuItem value={6}>2</MenuItem>
                <MenuItem value={4}>3</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item>
           {!listView ? <Tooltip title={getTranslation("List View", preferredLanguage)}>
              <IconButton
                aria-label="View as List"
                className={classes.listViewButton}
                onClick={() => setListView(!listView)}
              >
                <ViewListIcon style={{ float: 'right' }} />
              </IconButton>
            </Tooltip> :   <Tooltip title={getTranslation("Grid View", preferredLanguage)}>
              <IconButton
                aria-label="View as Grid"
                className={classes.listViewButton}
                onClick={() => setListView(!listView)}
              >
                <AppsIcon style={{ float: 'right' }} />
              </IconButton>
            </Tooltip> }
            </Grid>
            <Grid item>
          
            </Grid>
        </div>
<Grid container spacing={1} direction="row" alignItems="stretch"> 
        {isLoading ? <LoadingSpinner className="centeredLoader" box="centeredLoaderBox" /> : courseList !== undefined && courseList.length > 0 && !listView ? (
          courseList.map((training, idx) => <Grid item xs={12} sm={6} md={6} lg={4}><TrainingCard key={idx + '1'} listView={listView} courseDetails={training} preferredLanguage={props.preferredLanguage} getTranslation={getTranslation}/></Grid>)
        ) : courseList !== undefined && courseList.length > 0 && listView ? (
          courseList.map((training, idx) => <Grid item xs={12} sm={12} md={12} lg={12}><TrainingCard key={idx + '1'} listView={listView} courseDetails={training} preferredLanguage={props.preferredLanguage} getTranslation={getTranslation} /></Grid>)
        ) : ( 
          <span>{getTranslation("No training available", preferredLanguage)}</span>
        )}
        </Grid>
      </div>
    </>
  );
}
