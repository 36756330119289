import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from "@auth0/auth0-react";

import { postApiData, fetchApiData } from '../../../../utils/service';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import AssignTrainingTable from './AssignTrainingTable';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const reportColumns = [
  { title: 'Course Name', field: 'title' },
  {
    title: 'Estimated Time',
    field: 'timeEstimate',
    align: 'center',
    render: (rowData) => <p>{(Math.floor(rowData.timeEstimate / 60) || 0) + ' minutes'}</p>,
  },
];
const getUserAPI = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
//const getUserAPI = 'https://portalapidev.phishcloud.io/api/UserManagement/SearchUserByEmail';

const courseStatsAPI = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

export default function AssignTraining(props) {
  const theme = useTheme();
  const tableRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [value, setValue] = React.useState('view-training');
  const [selectedUser, setSelectedUser] = React.useState({
    assignees: [],
    courseList: [],
  });

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  var getByEmail = {
    email: '@',
  };
  const assignCourse = useQuery(
    'assign-course',
    async () => {
      const token = await getAccessTokenSilently();
      return postApiData(courseStatsAPI, token, selectedUser, 'setUserTraining');
    },
    {
      enabled: false,
    }
  );
  const { isLoading, data, isError, error, refetch } = useQuery(
    'getUserList',
    async () => {

      const token = await getAccessTokenSilently();
      return fetchApiData(getUserAPI, token, 'getUserList', props.accessLevel, props.selectedClient );


    },
    {
      enabled: false,
    }
  );


  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleChangeRadio = (event) => {
    const {
      target: { value },
    } = event;
    if (value === 'assign-all') {
      let userList = data?.users.map(({ email }) => email);
      setSelectedUser({ ...selectedUser, assignees: userList });
    }
    value === 'select-user' && setSelectedUser({ ...selectedUser, assignees: [] });
    setValue(event.target.value);
    console.log(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const userData = typeof value === 'string' ? value.split(',') : value;

    setPersonName(userData);
    setSelectedUser({ ...selectedUser, assignees: userData });
  };

  const handleClickOpen = (event, data) => {
    console.log('dataEvent', data);
    let courseList = data?.map((course) => {
      return {
        trainingModule: course.modId,
        courseDesignation: 'required',
        completionDate: '2022-04-01',
      };
    });

    setSelectedUser({ ...selectedUser, courseList });
    refetch();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser({
      assignees: [],
      courseList: [],
    });
    tableRef.current.onAllSelected(false);
  };

  const handleAssignCourse = () => {
    console.log('query', selectedUser);
    assignCourse.refetch();
    setOpenPopup(true);
    tableRef.current.onAllSelected(false);
    setSelectedUser({
      assignees: [],
      courseList: [],
    });
    setOpen(false);
  };
  const getTableHeading = () => {
    return (
      <div>
        <h4>{`Training Courses (${props?.courseList?.length})`}</h4>
      </div>
    );
  };

  if (isLoading || assignCourse.isLoading) {
    return <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const checkBoxTheme = createMuiTheme({
    palette: {
      primary: {
        main: '#428BC9',
      },
      secondary: {
        main: '#428BC9',
      },
    },

  });


  return (
    <div className="traininig-suite-container">
      <MuiThemeProvider theme={checkBoxTheme}>
      <MaterialTable
        title={getTableHeading()}
        columns={reportColumns}
        data={props.courseList || []}
        tableRef={tableRef}
       // onSelectionChange={((event, selectedRow) => console.log(selectedRow.tableData.id))}
        options={{
          //actionsColumnIndex: -1,
          search: false,
          selection: true,
          
          // rowStyle: rowData => ({
          //   backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          // })
        }}
        actions={[
          {
            tooltip: 'Assign Selected Courses',
            pageSize: 10,
            icon: 'add',
            onClick: (event, data) => handleClickOpen(event, data),
          },
        ]}

      />
      </MuiThemeProvider>
      <div>
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
          <DialogTitle>{getTranslation("Select User to Assign Course", preferredLanguage)}</DialogTitle>
          <DialogContent>
            {
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" value={value} onChange={handleChangeRadio}>
                  <FormControlLabel
                    value="assign-all"
                    control={<Radio color="primary" />}
                    label={getTranslation("Assign all", preferredLanguage)}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="select-user"
                    control={<Radio color="primary" />}
                    label={getTranslation("Select User", preferredLanguage)}
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            }
            {value === 'select-user' && (
              <div>
                <FormControl style={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">{getTranslation("User", preferredLanguage)}</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {data &&
                      data?.users.map((user) => (
                        <MenuItem key={user.email} value={user.email} style={getStyles(user.email, personName, theme)}>
                          {user.email}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{getTranslation("Cancel", preferredLanguage)}</Button>
            <Button onClick={handleAssignCourse}>{getTranslation("Ok", preferredLanguage)}</Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* <AssignTrainingTable props={props}/> */}
    </div>
  );
}
