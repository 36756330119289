import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './AccountInfo.css';
import { Row, Col } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { DateCreate } from './../DateCreate/DateCreate';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import basePCUrl from '../../../Globals';
var baseUrl = basePCUrl + 'UserData/GetAccountInfo';

const AccountInfo = (props) => {
  useChangePageTitle('Account Info');

  const getTranslation = props.getTranslation
  const preferredLanguage = props.preferredLanguage

  const [userData, setUserData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

  const getAccountInfo = async () => {
    setUserData(null);
    setLoading(true);
    const token = await getAccessTokenSilently();

    fetch(baseUrl, {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          setUserData(data);
          setLoading(false);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };



  useEffect(() => {
    getAccountInfo();
  }, []);


  return (
    <div id="userPage">
      {!isLoading ? (
        <div>
      <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
      {getTranslation("Account Information", preferredLanguage)} 
      </Typography>
      
        <div className="panel-body">
          <div className="profileBody">
            <Row>
              <Col xs="auto">
                {' '}
                <b>{getTranslation("Company", preferredLanguage)}:</b> <p>{userData.company}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                {' '}
                <b>{getTranslation("Name", preferredLanguage)}:</b>{' '}
                <p>
                  {userData.firstName} {userData.lastName}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <b>{getTranslation("Email", preferredLanguage)}:</b> <p>{userData.email}</p>
              </Col>
            </Row>

            <Row>
              <Col xs="auto">
                {' '}
                <b> {getTranslation("Access Level", preferredLanguage)}:</b> <p>{userData.accessLevel}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                {' '}
                <b> {getTranslation("Last Seen", preferredLanguage)}:</b>{' '}
                <p>
                  <DateCreate date={userData.lastSeen} />
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                {' '}
                <b> {getTranslation("Date Signed Up", preferredLanguage)}:</b>{' '}
                <p>
                  <DateCreate date={userData.dateSignedUp} />
                </p>
              </Col>
            </Row>
          </div>
        </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default AccountInfo;
