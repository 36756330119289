import React from 'react';
import useChangePageTitle from './Dashboard/CustomHooks/useChangePageTitle';


const ExtensionWelcomePage = () => {
  useChangePageTitle('ExtensionWelcomePage');

  return (
    <div style={{marginLeft: '25%', marginRight: '25%', backgroundColor: "#fafafa"}}>
        <div style={{textAlign: 'center'}}><img src="https://lp-custom-data.s3.us-west-2.amazonaws.com/quickLogo.png" /></div>
        <div style={{minWidth: '300px'}}>
            <div style={{textAlign: 'center', marginTop: '50px', marginBottom: '50px'}}>
            <h3 style={{fontWeight: '600'}}>Welcome to PhishCloud!</h3>
            </div>

        <p>PhishCloud looks at cyber security in a new way, specifically designing a tool to help you spot a phishing URL before you click on it! Our goal is to help you be safer whenever you're online - email, social media, search engines and anywhere else!</p>
    
    <p>You will need a PhishCloud account for our extention to work. If you have an account already, <a title="PhishCloud Login" href="https://login.phishcloud.io/" style={{color: "#0000EE"}}>log in</a>! If you're new to PhishCloud you can <a href="https://portal.phishcloud.io/register" style={{color: "#0000EE"}}>register</a> for a free 14-day trial!</p>
  
    <p>Once you're logged into the PhishCloud portal, you will start seeing links highlighted automtically in your favorite search enginges, social media, and online emails! For more information about PhishCloud and how we protect you like no other product on the market, take a look at our videos:</p>
  
    <p><a href="https://youtu.be/m2Dgu-vP1wU" style={{color: "#0000EE"}}>PhishCloud Tutorial (Pt I)</a></p>
    <p><a href="https://youtu.be/JJ_1O8A0SC0" style={{color: "#0000EE"}}>PhishCloud Tutorial (Pt II)</a></p>
  
    <p>Thank you and we hope you enjoy your PhishCloud experience!</p>
  
    <p>If you have any issues, please let us know at support@phishcloud.com</p>
  
    <p>Respectfully,</p>
    <p>The PhishCloud Team</p>
    </div>
    </div>
        
  );
};
export default ExtensionWelcomePage;