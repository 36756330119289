import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import './InitialDownloads.css';
import styles from './InitialDownloads.less';
import transitions from './Transitions.less';
import { Layout } from './../Signup/Layout';
import chromeStoreLink from '../ChromeStoreLinkGlobal';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';
//import NavDots from './NavDots';

const InitialDownloads = (props) => {
  useChangePageTitle('InitialDownloads');

  const [readyForStep3, setReadyForStep3] = useState(false);
  const toggleReadyForStep3 = () => setReadyForStep3(!readyForStep3);

  const [readyForStep4, setReadyForStep4] = useState(false);
  const toggleReadyForStep4 = () => setReadyForStep4(!readyForStep4);

  const pushToDownloadsPage = () => {
    props.history.push('/Dashboard/Downloads');
  };

  const Wizard = () => {
    const [state, updateState] = useState({
      form: {},
      transitions: {
        enterRight: `${transitions.animated} ${transitions.enterRight}`,
        enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
        exitRight: `${transitions.animated} ${transitions.exitRight}`,
        exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
        intro: `${transitions.animated} ${transitions.intro}`,
      },
      demo: true, // uncomment to see more
    });

    const updateForm = (key, value) => {
      const { form } = state;

      form[key] = value;
      updateState({
        ...state,
        form,
      });
    };

    // Do something on step change
    const onStepChange = (stats) => {
      // console.log(stats);
    };

    const setInstance = (SW) =>
      updateState({
        ...state,
        SW,
      });

    const { SW, demo } = state;

    return (
      <Layout>
        <div className="container">
          <div className={'jumbotron'}>
            <div style={{ overflowY: 'hidden' }}>
              <div className={`col-12 col-sm-6 offset-sm-3 ${styles['rsw-wrapper']}`}>
                <First hashKey={'FirstStep'} update={updateForm} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  };

  //const InstanceDemo = ({ SW }) => (
  //    <Fragment>
  //        <h4>Control from outside component</h4>
  //        <button className={'btn btn-secondary'} onClick={SW.previousStep}>Previous Step</button>
  //        &nbsp;
  //    <button className={'btn btn-secondary'} onClick={SW.nextStep}>Next Step</button>
  //    </Fragment>
  //);

  /**
   * Stats Component - to illustrate the possible functions
   * Could be used for nav buttons or overview
   */
  const Stats = ({ currentStep, firstStep, goToStep, lastStep, nextStep, previousStep, totalSteps, step }) => (
    <div>
      {step < totalSteps ? (
        <button className="btn btn-primary btn-block" onClick={nextStep}>
          Continue
        </button>
      ) : (
        <a href="https://portal.phishcloud.io/Dashboard/Configuration">
          <button className="btn btn-success btn-block" onClick={nextStep}>
            <h4>Head to the PhishCloud Portal</h4>
          </button>
        </a>
      )}
    </div>
  );

  const GoBack = ({ currentStep, firstStep, goToStep, lastStep, nextStep, previousStep, totalSteps, step }) => (
    <div>
      <hr />
      {step > 1 && (
        <button className="btn btn-default btn-block" onClick={previousStep}>
          Go Back
        </button>
      )}
    </div>
  );

  //const First = props => {
  //    const update = (e) => {
  //        props.update(e.target.name, e.target.value);
  //    };

  //    return (
  //        <div>
  //            <h4>This guide will walk you through the initial setup of PhishCloud for your desktop and browser.</h4>
  //            <GoBack step={1} {...props} />
  //            <Stats step={1} {...props} />
  //        </div>
  //    );
  //};

  const First = (props) => {
    const update = (e) => {
      props.update(e.target.name, e.target.value);
    };

    return (
      <div>
        <h3>Download Desktop Program</h3>
        <br />
        <h4>Single User:</h4>
        <h6>Download and install the desktop agent and follow the instructions there:</h6>
        <br />
        <table>
          <tr>
            <td>Desktop All-in-One 2.1.0</td>
            <td id="button">
              <a href="https://s3-us-west-2.amazonaws.com/files.phishcloud.io/2.1.0/PhishCloudDesktopInstaller2.1.0.exe">
                <button id="download">Download</button>
              </a>
            </td>
          </tr>
        </table>
        <br />
        <h6>
          For administrators, please download and install both the latest Desktop GPO & Certificates found on the
          Downloads page:
        </h6>
        <br />
        <br />
        <button className="btn  btn-block" onClick={pushToDownloadsPage}>
          Go to Downloads
        </button>
      </div>
    );
  };

  const Second = (props) => {
    const validate = () => {
      // if (confirm('Are you sure you want to go back?')) {
      //   // eslint-disable-line
      //   props.previousStep();
      // }
    };

    return (
      <div>
        <h3>Download Desktop Program</h3>
        <br />
        <h4>Single User:</h4>
        <h6>
          Are you installing PhishCloud for use on your own computer? For single users, please download and install the
          latest All-in-One package:
        </h6>
        <br />
        <table>
          <tr>
            <td>Desktop All-in-One 2.1.0</td>
            <td id="button">
              <a href="https://s3-us-west-2.amazonaws.com/files.phishcloud.io/2.1.0/PhishCloudDesktopInstaller2.1.0.exe">
                <button id="download">Download</button>
              </a>
            </td>
          </tr>
        </table>
        <br />
        <br />
        <h4>Administrator:</h4>
        <h6>
          Are you an administrator or installing and pushing Phishcloud to multiple computers? For administrators,
          please download and install both the latest Desktop GPO & Certificates:
        </h6>
        <br />
        <table>
          <tr>
            <td>Desktop GPO 2.1.0</td>
            <td id="button">
              <a href="https://s3-us-west-2.amazonaws.com/files.phishcloud.io/2.1.0/PhishCloudDesktopInstaller2.1.0GPO.msi">
                <button id="download">Download</button>
              </a>
            </td>
          </tr>
          <tr>
            <td>Certificates</td>
            <td id="button">
              <a href="http://files.phishcloud.io/Certs/PhishCloudCerts.zip">
                <button id="download">Download</button>
              </a>
            </td>
          </tr>
        </table>
        <br />
        <Row>
          <Col sm="1">
            <input name="readyForStep3" type="checkbox" checked={readyForStep3} onChange={toggleReadyForStep3} />
          </Col>
          <Col sm="11">
            <div>
              <h6>I have downloaded and installed the desktop program and I am ready for the next step.</h6>
            </div>
          </Col>
        </Row>
        <GoBack step={2} {...props} />
        {readyForStep3 === false ? <div></div> : <Stats step={2} {...props} />}
      </div>
    );
  };

  console.log(readyForStep3);

  const Third = (props) => {
    const validate = () => {
      // if (confirm('Are you sure you want to go back?')) {
      //   // eslint-disable-line
      //   props.previousStep();
      // }
    };

    return (
      <div>
        <h3>Step 3</h3>
        <h2>Download Browser Extensions</h2>
        <br />
        <br />
        <h4>Please make sure you are using the browser you want to download the extension for.</h4>
        <table>
          <tr>
            <td>Outlook Extension 3.0.2</td>
            <td id="button">
              <a
                target="_blank"
                href="https://s3-us-west-2.amazonaws.com/files.phishcloud.io/OutlookPlugins/PhishCloud+Outlook+Add-in+3.0.2.msi"
              >
                <button id="download">Download</button>
              </a>
            </td>
          </tr>
          <tr>
            <td>Chrome Extension</td>
            <td id="button">
              <a target="_blank" href={chromeStoreLink}>
                <button id="download">Install</button>
              </a>
            </td>
          </tr>
          <tr>
            <td>Firefox Extension</td>
            <td id="button">
              <a target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/phishcloud/">
                <button id="download">Install</button>
              </a>
            </td>
          </tr>
        </table>
        <Row>
          <Col sm="1">
            <input name="readyForStep4" type="checkbox" checked={readyForStep4} onChange={toggleReadyForStep4} />
          </Col>
          <Col sm="11">
            <div>
              <h6>I have downloaded and installed the browser extension and I am ready for the next step.</h6>
            </div>
          </Col>
        </Row>
        <GoBack step={3} {...props} />
        {readyForStep4 === false ? <div></div> : <Stats step={3} {...props} />}
      </div>
    );
  };

  const Last = (props) => {
    const submit = () => {
      alert('You did it! Yay!'); // eslint-disable-line
    };

    return (
      <div>
        <div className={'text-center'}>
          <h2>Downloads and Installations complete!</h2>
          <h4>Please resync the Phishcloud desktop application and restart your browser.</h4>
          <h4>You are now ready to begin using PhishCloud.</h4>
        </div>
        <GoBack step={4} {...props} />
        <Stats step={4} {...props} />
      </div>
    );
  };

  return (
    <div id="InitialDownloadsContainer">
      <Wizard />
    </div>
  );
};
export default InitialDownloads;

//< StepWizard
//onStepChange = { onStepChange }
//isHashEnabled
////transitions={state.transitions} // comment out for default transitions
//nav = {< NavDots />}
//instance = { setInstance }
//    >
//    <First hashKey={'FirstStep'} update={updateForm} />
//<Second form={state.form} />
//< Third />
//<Last />

//</StepWizard >

//< div style = {{ fontSize: '21px', fontWeight: '200' }}>

//    <div>Current Step: {currentStep}</div>
//    <div>Total Steps: {totalSteps}</div>

//            </div >

//< button className = 'btn btn-block btn-default' onClick = { firstStep } > First Step</button >
// <button className='btn btn-block btn-default' onClick={lastStep}>Last Step</button>
//<button className='btn btn-block btn-default' onClick={() => goToStep(2)}>Go to Step 2</button>
