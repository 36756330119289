import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { DateCreate } from './../DateCreate/DateCreate';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteCheckModal from './DeleteCheckModal';
import { postApiData } from '../../../utils/service';
import { useQuery } from 'react-query';

import basePCUrl from '../../../Globals';

const LinkSafetyRulesTable = (props) => {
  const clientId = props.clientId;
  const callRoute = props.callRoute;

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const [error, setError] = useState();

  const [deleteCheckModal, setDeleteCheckModal] = useState(false);
  const toggleDeleteCheck = () => {
    setDeleteCheckModal(!deleteCheckModal);
  };

  const [deleteLoading, setDeleteLoading] = useState(false)

  const [isDeleteChecked, setIsDeleteChecked] = useState(false);
  const resetForm = () => {
    setIsDeleteChecked(false);
  };

  //--------Deletion Logic-------//

  const [rulesForDeletion, setRulesForDeletion] = useState([]);
  const [selected, setSelected] = React.useState([]);

  const { getAccessTokenSilently } = useAuth0();


  useEffect(() => {
    if (selected != undefined) {

      let finalRulesForDeletion = selected.map((value) => {
        const mappedValue = {
          safety: value.rating,
          type: value.type,
          createDate: value.createDate // assuming you want to include createDate in all mapped objects
        };
      
        // Check the type and map the value accordingly
        switch (value.type) {
          case 'domain':
            mappedValue.domain = value.value;
            break;
          case 'url':
            mappedValue.url = value.value;
            break;
          case 'subdomain':
            mappedValue.subdomain = value.value;
            break;

          // Add more cases if needed for other types
          default:
            break;
        }
      
        return mappedValue;
      });
      setRulesForDeletion(
        finalRulesForDeletion
        );
    }

  }, [selected]);


  const deleteRules = useQuery(
    ['removeLDR'],
    async () => {
      const token = await getAccessTokenSilently();
      var data = {
        data: rulesForDeletion,
      };
      return postApiData(
        'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
        token,
        data,
        'removeLDR'
      );
    },
    {
      enabled: false,
      onSuccess: () => {
        if (deleteRules.data?.status === 'Error') {
          setError(true);
        } else {
          toggleDeleteCheck();
          resetForm();
          props.retrieveLinkSafetyRules();
          setSelected([]);
        }
       
      },
    }
  );

  const completeDeletion =()=> {
    deleteRules.refetch()
  }

  //-----rows & Table Logic-----//

  let rows = props.rows;

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const headCells = [
    //{ id: 'name', numeric: false, disablePadding: true, label: '' },
    { id: 'type', numeric: false, disablePadding: false, label: getTranslation("Type", preferredLanguage) },
    { id: 'value' || 'value', numeric: false, disablePadding: false, label: getTranslation("Value", preferredLanguage) },
    { id: 'rating', numeric: false, disablePadding: false, label: getTranslation("Rating", preferredLanguage) },
    { id: 'createDate', numeric: false, disablePadding: false, label: getTranslation("Date Rated", preferredLanguage) },
  ];

  const useHeaderStyles = makeStyles((theme) => ({
    tableHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
    },
  }));

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headerClasses = useHeaderStyles();

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rules' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className={headerClasses.tableHeader}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} {getTranslation("selected", preferredLanguage)}
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {getTranslation("Your Designated Safety Rules", preferredLanguage)}
          </Typography>
        )}

        {numSelected === 0 ? (
          <IconButton disabled onClick={toggleDeleteCheck} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={toggleDeleteCheck} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '@media(max-width:600px)': {
        width: '80%',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      fontFamily: 'Montserrat, sans-serif',
    },
    container: {
      maxHeight: 400,
      '@media(max-width:600px)': {
        maxHeight: '200px',
      },
    },
    table: {
      minWidth: 750,
      '@media(max-width:820px)': {
        minWidth: 550,
      },
    },
    tableCell: {
      fontFamily: 'Montserrat, sans-serif',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('dateCreated');

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, dateCreated) => {
    const selectedIndex = selected.indexOf(dateCreated);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, dateCreated);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (dateCreated) => selected.indexOf(dateCreated) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const checkSafety = (value) => {
    if (value === 'unsafe') {
      return <span id="designationUnsafe">{value}</span>;
    } else if (value === 'safe') {
      return <span id="designationSafe">{value}</span>;
    } else if (value === 'unknown') {
      return <span id="designationUnknown"> {value}</span>;
    }
  };

  const accountForUrlLength = (linkForCheck) => {
    if (linkForCheck === undefined) {
      return '';
    } else if (linkForCheck.length >= 60) {
      return <span style={{ fontSize: '12px' }}>{linkForCheck.slice(0, 50)}...</span>;
    } else {
      return <span style={{ fontSize: '14px', wordWrap: 'break-word' }}>{linkForCheck}</span>;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //onClick={event => handleClick(event, row.dateCreated)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.createDate}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {row.createDate != undefined ? (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(event) => handleClick(event, row)}
                          />
                        ) : (
                          <span></span>
                        )}
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        {row.type}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {accountForUrlLength(row.value)}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {checkSafety(row.rating)}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {row.createDate != undefined ? <DateCreate date={row.createDate} /> : <span></span>}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={classes.tableCell}
        />
      </Paper>
      <DeleteCheckModal
        isOpen={deleteCheckModal}
        toggle={toggleDeleteCheck}
        handleDeletion={completeDeletion}
        isDeleteChecked={isDeleteChecked}
        setIsDeleteChecked={setIsDeleteChecked}
        deleteLoading={deleteRules.isLoading}
      />
    </div>
  );
};
export default LinkSafetyRulesTable;
