import React, { useEffect } from 'react';
import { useHistory, useLocation, Route, Switch, Redirect, matchPath } from 'react-router-dom';

const RedirectOnNotFoundTopLevel = ({ children, redirectTo }) => {
    const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Check if the current location matches any of the routes
    const isRouteMatched = children.some((child) => {
      const path = child.props.path || child.props.computedMatch.path;
      //console.log(path)
      return matchPath(location.pathname, { path, exact: true });
    });

    // If no route is matched, redirect to the specified page
   if (!isRouteMatched && !location.pathname.startsWith('/Dashboard/')) {
    history.push(redirectTo);
  }
  }, [children, history, location.pathname, redirectTo]);

  return <Switch>{children}</Switch>;
}; export default RedirectOnNotFoundTopLevel;
