import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Auth0Lock from 'auth0-lock';
import phishcloudIcon from './logo.png';
import './FirstLogin.css';
import InstallationMessage from './InstallationMessage';
import Auth0SpaLogin from './auth0SpaLogin';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';
import EmailLogin from '../EmailLogin/EmailLogin';

const LoginPortal = (props) => {
  useChangePageTitle('PortalLogin');
  const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();
  // const [isDelayed, setIsDelayed] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsDelayed(false);
  //   }, 3000);

  //   // Cleanup timer on unmount
  //   return () => clearTimeout(timer);
  // }, []);

  console.log("Is Loading: " + isLoading)
  console.log("Is Authenticated: " + isAuthenticated)
  console.log("User: " + user)
  console.log(window.location.href)

  const renderMainBody = () => {
    if (window.location.search.includes('error=')) {
      props.history.push('/Error');
    } else if (!isLoading && !isAuthenticated && window.location.href.indexOf("#access_token=") > -1) {
      loginWithRedirect()
      console.log(window.location.href)
    } else if (!isLoading && !isAuthenticated) {
      return <EmailLogin />;
    } else if (!isLoading && isAuthenticated) {
      props.history.push('/Dashboard/Callback');
    } else if (isLoading) {
      return <h3>Loading...</h3>;
    } else {
      return <p>Oops, something went wrong.</p>;
    }
  };

  return (
    <div>
      {/* {isDelayed ? <h3>Loading...</h3> : renderMainBody()} */}
      {renderMainBody()}
    </div>
  );
};
export default LoginPortal;

//<PhishCloudAuth0Lock redirectUrl="https://localhost:44348/DashBoard/History"  history={props.history} location={props.location}  />
//<PhishCloudAuth0Lock redirectUrl="https://portal.phishcloud.io/DashBoard/History"  history={props.history} location={props.location}  />
