import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import './MSSP.css';

const MSSPHeader = (props) => {
  const useStyles = makeStyles((theme) => ({
    returnButton: {
      fontFamily: 'Montserrat, sans-serif',
    },
    returnButtonMobile: {
      padding: '20px',
    },
    sectionDesktop: {
      display: 'none',
      float: 'right',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      float: 'right',
      //padding: '20px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    grow: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      height: '100%',
    },
    toolbar: {
      backgroundColor: theme.palette.background.default,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  const getCurrentltySelectedClientName =(currentlySelectedClient)=> {
    const company = props.accountAdminData.find(company => company.clientId === currentlySelectedClient);
    return company ? company.companyName : null;

  }

  return (
    <React.Fragment>
      {props.selectedClient.clientId === '' ? (
        <Toolbar className={classes.toolbar} id="MSSPHeaderContainerDefaultClient">
          <div id="clientViewing"></div>
          <div id="returnToACMP">
            <Link to="/Dashboard/AccountManagement">
              <div className={classes.sectionDesktop}>
                <Button size="small" className={classes.returnButton}>
                {props.getTranslation("return to account management", props.preferredLanguage)} 
                <ArrowBackIcon />
                </Button>
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionMobile}>
                <IconButton size="medium" className={classes.returnButtonMobile}>
                  <ArrowBackIcon fontSize="large" />
                </IconButton>
              </div>
            </Link>
          </div>
        </Toolbar>
      ) : props.selectedClient.clientId === props.defaultClient ? (
        <Toolbar className={classes.toolbar} id="MSSPHeaderContainerDefaultClient">
          <div id="clientViewing">
            <Button size="small" disabled style={{ textTransform: 'none', color: theme.palette.text.primary }}>
            {props.getTranslation("currently managing", props.preferredLanguage)} : {getCurrentltySelectedClientName(props.selectedClient)}
            </Button>
          </div>
          <div id="returnToACMP">
            <div className={classes.sectionDesktop}>
              <Link to="/Dashboard/AccountManagement">
                <Button size="small" className={classes.returnButton}>
                {props.getTranslation("return to account management", props.preferredLanguage)} <ArrowBackIcon />
                </Button>
              </Link>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionMobile}>
              <Link to="/Dashboard/AccountManagement">
                <IconButton size="medium" className={classes.returnButtonMobile}>
                  <ArrowBackIcon fontSize="large" />
                </IconButton>
              </Link>
            </div>
          </div>
        </Toolbar>
      ) : (
        <Toolbar className={classes.toolbar} id="MSSPHeaderContainer">
          <div id="clientViewing">
            {props.selectedClient.clientId === props.defaultClient ? (
              <Button size="small" disabled style={{ color: theme.palette.text.primary, textTransform: 'none' }}>
                {props.getTranslation("currently managing", props.preferredLanguage)}: {getCurrentltySelectedClientName(props.selectedClient)}
              </Button>
            ) : (
              <Button size="small" disabled style={{ color: theme.palette.text.primary, textTransform: 'none' }}>
                  {props.getTranslation("currently viewing", props.preferredLanguage)}: {getCurrentltySelectedClientName(props.selectedClient)}
              </Button>
            )}
          </div>
          <div id="returnToACMP">
            <div className={classes.sectionDesktop}>
              <Link to="/Dashboard/AccountManagement">
                <Button size="small" className={classes.returnButton}>
                {props.getTranslation("return to account management", props.preferredLanguage)} <ArrowBackIcon />
                </Button>
              </Link>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionMobile}>
              <Link to="/Dashboard/AccountManagement">
                <IconButton size="medium" className={classes.returnButtonMobile}>
                  <ArrowBackIcon fontSize="large" />
                </IconButton>
              </Link>
            </div>
          </div>
        </Toolbar>
      )}
    </React.Fragment>
  );
};
export default MSSPHeader;
