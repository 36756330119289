import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';
import './CampaignSignUpForm.css';

const ThankYou = (props) => {
    useChangePageTitle('PhishingCampaignSignupThankYou');

  return (
    <div className="content">
      <div className="container">
        <div className="row align-items-stretch justify-content-center no-gutters">
          <div className="messageContainer">
            <img src="https://lp-custom-data.s3.us-west-2.amazonaws.com/quickLogo.png" />
            <p className="thankYouMessage">
              <br />
              We will follow up with you shortly regarding your phishing simulation.
              <br />
              <br />
              Respectfully,
              <br />
              The PhishCloud Team
              <br/>
            </p>
          </div>

          {/* <img src={NameAndShieldLogo} /> */}
          {/* <p>Phishcloud is currently checking the safety link you just clicked.</p> */}
        </div>
      </div>
    </div>
  );
};
export default ThankYou;
