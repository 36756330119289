import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Alert from '@material-ui/lab/Alert';
import { byFips } from 'country-code-lookup';
import React, { useState } from 'react';
import { Col, ModalBody, Row } from 'reactstrap';
import basePCUrl from '../../../Globals';
import { useAuth0 } from "@auth0/auth0-react";

import { DateCreateFullDate } from '../DateCreate/DateCreateFullDate';
import { FlagIcon } from './FlagIcon';

const LinkAnalysisResults = (props) => {
  const [reportSafeModal, setReportSafeModal] = useState(false);
  const toggleSafeModal = () => setReportSafeModal(!reportSafeModal);

  const [reportUnsafeModal, setReportUnsafeModal] = useState(false);
  const toggleUnsafeModal = () => setReportUnsafeModal(!reportUnsafeModal);

  const [requestAccessModal, setRequestAccesModal] = useState(false);
  const toggleRequestAccessModal = () => setRequestAccesModal(!requestAccessModal);

  const [proposedSafety, setProposedSafety] = useState('');

  const mobileCheck = useMediaQuery('(min-width:830px)');

  const { getAccessTokenSilently } = useAuth0();

  const preferredLanguage = props.preferredLanguage;
  const getTranslation = props.getTranslation;

  const countryLengthCheck = (value) => {
    if (value === undefined || value === null) {
      return <span> {getTranslation('not reported', preferredLanguage)}</span>;
    } else if (value === 'Eror Country Code Not Found') {
      return <span> {getTranslation('not reported', preferredLanguage)}</span>;
    } else if (value.length >= 30) {
      return <span style={{ fontSize: '14px' }}>{value}</span>;
    } else {
      return value;
    }
  };

  const accountForUrlLength = (linkForCheck) => {
    if (linkForCheck.length >= 80 && mobileCheck === false) {
      return <span style={{ fontSize: '20px' }}>{linkForCheck.slice(0, 50)}...</span>;
    } else if (linkForCheck.length >= 80 && mobileCheck === true) {
      return <span>{linkForCheck.slice(0, 80)}...</span>;
    } else {
      return linkForCheck;
    }
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.grey,
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 18,
    },
  }))(Tooltip);

  //---------Submit Safe Link Report------------//

  const submitLinkReportSafe = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      link: props.link,
      confidenceLevel: props.confidenceLevel,
      safety: props.safety,
      proposedSafety: 'safe',
    };

    fetch(basePCUrl + 'Actions/SubmitLinkReport', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          setTimeout(function () {
            setReportSafeModal(false);
          }, 3000);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  //-----------Submit Unsafe Link Report--------//
  const submitLinkReportUnsafe = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      link: props.link,
      confidenceLevel: props.confidenceLevel,
      safety: props.safety,
      proposedSafety: 'unsafe',
    };

    fetch(basePCUrl + 'Actions/SubmitLinkReport', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {

          setTimeout(function () {
            setReportUnsafeModal(false);
          }, 3000);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const reportSafe = () => {
    toggleSafeModal();
    submitLinkReportSafe();
  };

  const reportUnsafe = () => {
    toggleUnsafeModal();
    submitLinkReportUnsafe();
  };

  //-----------Report Safe Modal-----------//
  const ReportSafeModalButton = () => {
    return (
      <React.Fragment>
        <Button
          style={{ float: 'right' }}
          variant="contained"
          className={classes.button}
          onClick={reportSafe}
          id="reportSafe"
          startIcon={<CheckIcon />}
        >
          {getTranslation('report safe', preferredLanguage)}
        </Button>
      </React.Fragment>
    );
  };

  const ReportSafeModal = () => {
    return (
      <Dialog open={reportSafeModal} onClose={toggleSafeModal}>
        <ModalBody>
          <br></br>
          <h3>{getTranslation('thank you for your feedback', preferredLanguage)}</h3>
          <br></br>
        </ModalBody>
      </Dialog>
    );
  };

  //-----------Report Unsafe Modal-----------//
  const ReportUnsafeModalButton = () => {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={reportUnsafe}
          id="reportUnsafe"
          startIcon={<FlagRoundedIcon />}
        >
          {getTranslation('report unsafe', preferredLanguage)}
        </Button>
      </React.Fragment>
    );
  };

  const ReportUnsafeModal = () => {
    return (
      <Dialog open={reportUnsafeModal} toggle={toggleUnsafeModal}>
        <ModalBody>
          <br></br>
          <h3> {getTranslation('thank you for your feedback', preferredLanguage)}</h3>
          <br></br>
        </ModalBody>
      </Dialog>
    );
  };

  //----------Redirect Chain Title-----------//
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const TitleToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle">
        {getTranslation(props.title, preferredLanguage)}
        </Typography>
      </Toolbar>
    );
  };

  //--------------From History Table Message--------------//

  const FromHistoryTableMessage = () => {
    if (props.history.location.state != undefined) {
      if (props.history.location.state.safety != undefined && props.history.location.state.safety != props.safety) {
        return (
          <div className={classes.root}>
            <Alert severity="info">
            {getTranslation("This link's safety status has been updated since you last encountered it.", preferredLanguage)}
            </Alert>
          </div>
        );
      } else {
        return <span></span>;
      }
    } else {
      return <span></span>;
    }
  };

  //-----------Warning Bars-----------//
  const unsafeWarning = (
    <div id="unsafeWarning">
      <Row>
        <Grid container item xs={12}>
          <span style={{ fontSize: '1rem' }}>
            <ErrorIcon fontSize="large" />
            {getTranslation("DANGER: This link is marked as unsafe", preferredLanguage)}
          </span>
        </Grid>
      </Row>
    </div>
  );

  const unknownWarning = (
    <Row id="unknownWarning">
      <Grid container item xs={6}>
        <span style={{ fontSize: '1.1rem' }}>
          {' '}
          <WarningRoundedIcon />{' '}
          {getTranslation("WARNING: This link has not yet been validated", preferredLanguage)}
        </span>
      </Grid>
      <Grid container xs={6} style={{ alignContent: 'end', paddingRight: '35px' }} justify="flex-end">
        <span style={{ marginRight: '15px', fontSize: '1.1rem' }}>
        {getTranslation("open link", preferredLanguage)}:
        </span>
        <a href={props.link} target="_blank">
          <Button variant="contained" startIcon={<WarningRoundedIcon />} id="unknownWarningButton">
          {getTranslation("proceed with caution", preferredLanguage)}
          </Button>
        </a>
      </Grid>
    </Row>
  );

  const accountForLinkLength = (linkForCheck) => {
    if (linkForCheck === undefined) {
      return <span></span>;
    } else if (linkForCheck.length >= 80) {
      return <span style={{ fontSize: '24px' }}>{linkForCheck.slice(0, 80)}...</span>;
    } else return linkForCheck;
  };

  const useStyles = makeStyles({
    root: {
      width: '100%',
      minWidth: '180px',

      height: '100%',
      fontFamily: 'Montserrat, sans-serif',
      //padding: 5
    },
    header: {
      width: 'inherit',
    },
    container: {
      height: 330,
      width: 'auto',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
  });

  const classes = useStyles();

  const StatisticsTable = (props) => {
    return (
      <Table className={classes.table} stickyHeader size="medium" aria-label="redirect chain log">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}>
            {getTranslation("times seen", preferredLanguage)}
              <span style={{ padding: '5px' }}>
                <FontAwesomeIcon icon={['fas', 'eye']} />
              </span>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}>
            {getTranslation("proceed with caution", preferredLanguage)}{' '}
              <span style={{ padding: '5px' }}>
                <FontAwesomeIcon icon={['fas', 'mouse-pointer']} />
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{props.timesSeen}</TableCell>
            <TableCell align="center">{props.timesClicked}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const safetyExplanation = (
    getTranslation("PhishCloud risk assessment score is determined by industry standard anti-phishing sources, user reported feedback, and hueristic analysis.", preferredLanguage)
  );

  return (
    <Grid spacing={2} className="linkInfoContatiner">
      <Grid item xs={12}>
        <FromHistoryTableMessage />
        <br />
        <Paper id={props.alertSafety}>
          {props.safety === 'unsafe' ? unsafeWarning : props.safety === 'unknown' ? unknownWarning : ''}

          <Row id="link">
            <span id="linkStyled">
              {props.safety === 'safe' ? (
                <a href={props.link} target="_blank">
                  {accountForUrlLength(props.link)}
                  <LaunchRoundedIcon fontSize="large" />
                </a>
              ) : (
                accountForLinkLength(props.link)
              )}
            </span>
          </Row>
          <Row id="bottomRow">
            <Grid container item xs={4} sm={4} md={6} style={{ paddingLeft: '20px' }} alignItems="flex-end">
              <span id="rating">
              {getTranslation(props.safety, preferredLanguage)}{' '}
                <span id="riskAM">
                  {' '}
                  {props.confidenceLevel}/100
                  <LightTooltip arrow title={safetyExplanation}>
                    <InfoOutlinedIcon fontSize="large" />
                  </LightTooltip>
                </span>
              </span>
            </Grid>
            <Grid
              container
              item
              xs={8}
              sm={8}
              md={6}
              style={{ alignContent: 'end', paddingRight: '35px' }}
              justify="flex-end"
            >
              {props.safety === 'unknown' || props.safety === 'unsafe' ? ReportSafeModalButton() : ''}
              {ReportUnsafeModalButton()}
            </Grid>
            {ReportSafeModal()}
            {ReportUnsafeModal()}
          </Row>
        </Paper>
      </Grid>

      <Grid container spacing={3} direction="row">
        <Grid item container xs={12} sm={6} md={4}>
          <Paper style={{ width: '100%' }}>
            <Row>
              <TitleToolbar title={getTranslation("global statistics", preferredLanguage)} className={classes.root} />
            </Row>
            <Row style={{ marginRight: '0px' }}>
              <StatisticsTable
                timesSeen={parseInt(props.globalStatistics.globalSeens)}
                timesClicked={parseInt(props.globalStatistics.globalClicks)}
              />
            </Row>
          </Paper>
        </Grid>
        <Grid item container xs={12} sm={6} md={4}>
          <Paper style={{ width: '100%' }}>
            <Row>
              <TitleToolbar title={getTranslation("company statistics", preferredLanguage)} className={classes.root} />
            </Row>
            <Row style={{ marginRight: '0px' }}>
              <StatisticsTable
                timesClicked={parseInt(props.companyStatistics.companySeens)}
                timesSeen={parseInt(props.companyStatistics.companySeens)}
              />
            </Row>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card className="linkInfoCardAdmin">
            <Row>
              <TitleToolbar title={getTranslation("link information", preferredLanguage)} className={classes.root} />
            </Row>
            <CardContent>
              <Row>
                <span className="analysisTitle">
                {getTranslation("country of origin", preferredLanguage)}
                </span>

                <Col xs={4} className="countryName">
                  {countryLengthCheck(byFips(props.countryName || 'US')?.country) || 'Not reported'}
                </Col>

                <Col xs={3} className="flag">
                  <FlagIcon code={props.code} />
                </Col>
              </Row>
              <br />
              <Row>
                <span className="analysisTitle">
                {getTranslation("Domain", preferredLanguage)}
                </span>
                <Col>{props.domain}</Col>
              </Row>
              <br />
              <Row>
                <span className="analysisTitle">
                {getTranslation("date created", preferredLanguage)}
                </span>
                <Col>
                  <DateCreateFullDate date={props.created} />
                </Col>
              </Row>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LinkAnalysisResults;

//<ModalHeader toggle={toggleSafeModal}></ModalHeader>
//< Button onClick = { submitLinkReport } type = "submit" size = "lg" color = "success" block > Ok</Button >
//---------For Future Release--------//

//< span style = {{ fontWeight: '300', fontSize: '16px' }}> last updated __ hours ago </span >

//< div className = "redirectChainContainer" >
//    <div className="redirectChainTitle">
//        <TitleToolbar />
//    </div>
//    <div className="redirectChain">
//        <Row>
//            <Col sm="5">
//                <div className="redirectStart">
//                    <h6>http.bitly/xy</h6>
//                </div>
//            </Col>
//            <Col sm="2">
//                <div className="arrow">
//                    <ArrowForwardIcon className={icons.arrow} />
//                </div>
//                <div className="chainList">
//                    <RedirectListPopover />

//                </div>
//            </Col>
//            <Col sm="5">
//                <div className="redirectStart">
//                    <h6>www.google.com </h6>
//                </div>
//            </Col>
//        </Row>
//    </div>
//                            </div >
