import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { loadStripe } from '@stripe/stripe-js';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import './Billing.css';
import basePCUrl from '../../../Globals';

const Billing = (props) => {
  useChangePageTitle('Billing');

  const [billingLink, setBillingLink] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const retrieveBillingLink = async () => {
    const token = await getAccessTokenSilently();

    fetch(basePCUrl + 'StripeClient/GetSessionUrl', {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          console.log(data);
          if (data.accessLevel === 'Error') {
            console.log('Billing Error');
          } else {
            setBillingLink(data.billingLink);
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    retrieveBillingLink();
  }, []);

  console.log(billingLink);

  return (
    <div>
      <h1>Billing </h1>
      <a href={billingLink}>
        <h5>{billingLink}</h5>
      </a>
    </div>
  );
};
export default Billing;
