import React, { useState, useEffect, useRef, } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import ReactPlayer from 'react-player';
//----------Reactstrap---------//
import { Form, FormGroup, Input, ModalBody } from 'reactstrap';

//----------Material UI Imports---------//
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const TrainingFailModal = (props) => {
  const [videoType, setVideoType] = useState()
  const [videoProgress, setVideoProgress] = useState()
  const reactPlayerRef = useRef();
  const { getAccessTokenSilently } = useAuth0();

  const pushToDashboard = () => {
    props.history.push({
      pathname: '/Dashboard/History',
    });
    window.scrollTo({ top: 110, left: 100, behavior: 'smooth' });
  };

  const resetQuiz =()=> {
  props.setCurrentQuestion(1); // Reset to the first question
  props.setSelectedAnswer(null); // Clear the selected answer
  props.setUserAnswers([]); // Clear the user's previous answers
  props.setScore(0); // Reset the score to 0
  props.setIsNextDisabled(true);
  props.toggle()
  saveTrainingProgress()
  }


  const saveTrainingProgress = async (event) => {
    //event.preventDefault();
    const token = await getAccessTokenSilently();
    var api = 'https://zsvz1vz9rg.execute-api.us-west-2.amazonaws.com/prod';
    
    const date = new Date();
    var data = {
      trainingModule: props.trainingId,
      status: 'required',
      startDate: date,
      videoProgress: videoProgress
    };

    fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        Action: 'updateUserTraining',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          //setError(true);
          // console.log(error);
          return;
        }

        response.json().then((data) => {
          return;
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const getAdminConfigSettings = async () => {
    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
    const token = await getAccessTokenSilently();

    return new Promise(async (resolve, reject) => {

      fetch(api, {
        method: 'get',
        headers: {
          Action: 'adminConfigs',
          //testacct: uid,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          response.json().then((data) => {
            if (data !== 'access token check failed') {
              setVideoType(data.settings.trainingSettings.videoType)
              resolve(data);
            } else {
              resolve(data);
              return;
            }
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    });
  };

  useEffect(() => {
    getAdminConfigSettings();
  }, []);

  return (
    <Paper>
      <Dialog onClose={props.toggle} disableBackdropClick={true} aria-labelledby="Quiz Results " open={props.modal}>
        <ModalBody style={{ height: '60vh', width: 'auto', maxWidth: "1000px", }}>
          <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
            Quiz Results
          </Typography>
          <br></br>
          <p style={{ color: 'red' }}>You got {props.correctAnswersCount} out of {props.quizLength} correct for a score of {props.scorePercentage}%</p>
          <p style={{ color: 'red' }}>
            {' '}
            You did not receive a passing score. Please watch the training video and try again.{' '}
          </p>

          <ReactPlayer
              url={props.trainingVideo}
              ref={reactPlayerRef}
              className="react-player"
              width="100%"
              //height="100%"
              style={{maxWidth: "1000px", justifyContent: "center"}}
              controls={true}
              onPause={(progress) => {
                console.log(
                  //progress,
                  "Percentage: " + Math.floor((reactPlayerRef.current.getCurrentTime() / reactPlayerRef.current.getDuration()) * 100)
                );
              }}
             // onProgress={console.log(Math.floor((reactPlayerRef.current.getCurrentTime() / reactPlayerRef.current.getDuration()) * 100))}
              onEnded={(progress) => { setVideoProgress(Math.floor((reactPlayerRef.current.getCurrentTime() / reactPlayerRef.current.getDuration()) * 100))}}
            />
        </ModalBody>
          <br/>
          <br/>
        <Button variant="contained" disabled={videoProgress === 100 ? false : true} color="primary" style={{ width: '80%', marginBottom: '1rem', alignSelf: 'center'}} onClick={resetQuiz}>
          Return to Quiz
        </Button>
      </Dialog>
    </Paper>
  );
};
export default TrainingFailModal;
