import { id } from 'date-fns/locale';

export const fetchEmailData = (url, data) => {
  return fetch(url, {
    method: 'POST',
    //credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
    },
  }).then((res) => {
    const result = res.json();
    return result;
  });
};

export const fetchApiData = (url, token, actionType, accessLevel, clientId) => {
  if (localStorage.getItem('usid')) {
    var usid = localStorage.getItem('usid');
    var headers = {
      user_id: usid,
      id: clientId,
      ...(actionType && { Action: actionType }),
      //...(queryId && { id: queryId }),
      ...(accessLevel === 'AccountAdmin' && { id: clientId }),
      'Content-Type': 'application/json',
    };
  } else {
    var headers = {
      Authorization: `Bearer ${token}`,
      id: clientId,
      ...(actionType && { Action: actionType }),
      //...(queryId && { id: queryId }),
      ...(accessLevel === 'AccountAdmin' && { id: clientId }),
      'Content-Type': 'application/json',
    };
  }
  return fetch(url, {
    method: 'GET',
    headers: headers,
  }).then((res) => {
    const result = res.json();
    return result;
  });
};

export const fetchApiDataWithId = (url, id, actionType, accessLevel, clientId) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      //Authorization: `Bearer ${token}`,
      user_id: id,
      id: clientId,
      ...(actionType && { Action: actionType }),
      //...(queryId && { id: queryId }),
      ...(accessLevel === 'AccountAdmin' && { id: clientId }),
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    const result = res.json();
    return result;
  });
};

export const fetchOldApiData = (url, token) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    const result = res.json();
    return result;
  });
};

export const fetchLinkAnalysisData = (url, token, actionType, finalUrl) => {
  console.log('service', finalUrl);
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      ...(actionType && { Action: actionType }),
      ...(finalUrl && { link: finalUrl }),

      'Content-Type': 'application/json',
    },
  }).then((res) => {
    const result = res.json();
    return result;
  });
};

export const postApiData = (url, token, data, actionType) => {
  if (localStorage.getItem('usid')) {
    var usid = localStorage.getItem('usid');
    var headers = {
      user_id: usid,
      //id: clientId,
      ...(actionType && { Action: actionType }),
      //...(queryId && { id: queryId }),
      //...(accessLevel === 'AccountAdmin' && { id: clientId }),
      'Content-Type': 'application/json',
    };
  } else {
    var headers = {
      Authorization: `Bearer ${token}`,
      //id: clientId,
      ...(actionType && { Action: actionType }),
      //...(queryId && { id: queryId }),
      //...(accessLevel === 'AccountAdmin' && { id: clientId }),
      'Content-Type': 'application/json',
    };
  }
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: headers,
  }).then((res) => {
    const result = res.json();
    return result;
  });
};

export const postApiDataWithId = (url, id, data, actionType) => {
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: {
      //Authorization: `Bearer ${token}`,
      user_id: id,
      'Content-Type': 'application/json',
      ...(actionType && { action: actionType }),
    },
  }).then((res) => {
    const result = res.json();
    return result;
  });
};

export const postApiDataNoToken = (url, data, actionType) => {
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    const result = res.json();
    return result;
  });
};
