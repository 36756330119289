import Select from '@material-ui/core/Select';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
//import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Chart } from 'react-google-charts';
import { Link } from 'react-router-dom';


const CompanyInfoSafeUnsafe = (props) => {
  const [dateRange, setDateRange] = useState('sevenDays');
  const [safeUnsafeRows, setSafeUnsafeRows] = useState();

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  //--------Null Charts -------//
  const accountForNullChartData = [[new Date(), 0, 0, 0]];

  const theme = useTheme();

  let safeUnsafe = props.chartData;
  //--------------Unknown/Unsafe Activity "If" Date Range Logic----------------//
  useEffect(() => {
    if (safeUnsafe != undefined && safeUnsafe != null) {
      if (dateRange === 'today' && safeUnsafe.today != undefined && safeUnsafe.today.length != 0) {
        setSafeUnsafeRows(safeUnsafe.today);
      } else if (
        (dateRange === 'today' && safeUnsafe.today === undefined) ||
        (dateRange === 'today' && safeUnsafe.today.length === 0)
      ) {
        setSafeUnsafeRows(accountForNullChartData);
      } else if (dateRange === 'sevenDays' && safeUnsafe.sevenDays != undefined && safeUnsafe.sevenDays.length != 0) {
        setSafeUnsafeRows(safeUnsafe.sevenDays);
      } else if (
        (dateRange === 'sevenDays' && safeUnsafe.sevenDays === undefined) ||
        (dateRange === 'sevenDays' && safeUnsafe.sevenDays.length === 0)
      ) {
        setSafeUnsafeRows(accountForNullChartData);
      } else if (
        dateRange === 'thirtyDays' &&
        safeUnsafe.thirtyDays != undefined &&
        safeUnsafe.thirtyDays.length != 0
      ) {
        setSafeUnsafeRows(safeUnsafe.thirtyDays);
      } else if (
        (dateRange === 'thirtyDays' && safeUnsafe.thirtyDays === undefined) ||
        (dateRange === 'thirtyDays' && safeUnsafe.thirtyDays.length === 0)
      ) {
        setSafeUnsafeRows(accountForNullChartData);
      }
    } else if (safeUnsafe === undefined || safeUnsafe === null) {
      setSafeUnsafeRows(accountForNullChartData);
    }
  }, [safeUnsafe, dateRange]);

  //----Toolbar Material UI Styles----//
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
      zIndex: 1,
    },
    paper: {
      height: '346px',
      maxWidth: '1400px',
      //width: '100%',
      padding: '10px',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '15px',
    },
    loadingCircle: {
      marginLeft: '40%',
      marginTop: '50px',
    },
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  }));

  const TitleToolbarWithDateToggle = (props) => {
    const classes = useToolbarStyles();

    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle">
          <Link tag={Link} to={props.link} style={{ textDecoration: 'none' }}>
            {' '}
            {props.title}
          </Link>
        </Typography>

        <Select
          variant="outlined"
          value={props.value}
          onChange={(e) => setDateRange(e.target.value)}
          style={{ marginLeft: '0px', width: '200px' }}
        >
          <option value="today">{getTranslation("Today", preferredLanguage)}</option>
          <option value="sevenDays">7 {getTranslation("Days", preferredLanguage)}</option>
          <option value="thirtyDays">30 {getTranslation("Days", preferredLanguage)}</option>
        </Select>
      </Toolbar>
    );
  };

  //-------Chart Columns-------//

  const columns = [
    {
      type: 'date',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
    {
      type: 'number',
    },
  ];

  // --------Google Chart Logic -------- //
  const renderChartData = () => {
    if (props.chartData != null && props.chartData != undefined && props.chartData.length > 0) {
      //COPY ARRAYS TO ALTER/MANIPULATE
      let alterChartData = props.chartData.map((arr) => arr.slice());
      let parseDateChartData = props.chartData.map((arr) => arr.slice());

      //console.log(alterChartData);

      //REMOVE DATES FROM ARRAY - WILL ADD THEM BACK WHEN THEY ARE FORMATTED
      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 1);
        }
      }

      //PARSE DATES WITH .MAP INTO NEW ARRAY
      if (dateRange === 'today') {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0]));
          //let dateTime = new Date((date.getFullYear()) + "," + (date.getMonth() + 1) + "," + (date.getDate()));
          let dateTime = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()));

          return {
            dateTime,
          };
        });
      } else {
        var formattedChartData = parseDateChartData.map((value) => {
          let date = new Date(Date.parse(value[0]));
          let dateTime = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()) + 86400000);

          return {
            dateTime,
          };
        });
      }

      //CONVERT ARRAY OF OBJECTS TO ARRAY
      let finalFormattedDateTime = formattedChartData.map(({ dateTime }) => dateTime);

      //ADD BACK FORMATTED DATES AND REFORMAT INTO ARRAY OF ARRAYS SO DATA IS USABLE WITH GOOGLE CHARTS

      for (var i = 0; i < alterChartData.length; i++) {
        for (var j = 0; j <= alterChartData[i].length; j++) {
          if (j === 1) {
            break;
          }
          alterChartData[i].splice(j, 0, finalFormattedDateTime[i]);
        }
      }

      alterChartData.splice(0, 0, ['Day', 'Safe', 'Unknown', 'Unsafe']);

      // --------- End of Chart Logic -------- //
      return <AlertInfoChart chartData={alterChartData} ticks={finalFormattedDateTime} />;
    } else {
      return <p>{getTranslation("Could not load chart...", preferredLanguage)}</p>;
    }
  };

  const AlertInfoChart = (props) => {
    if (dateRange === 'today') {
      return (
        <div>
          <Chart
            chartType="BarChart"
            columns={columns}
            height={'100%'}
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            options={{
              chartArea: { width: '60%', height: '100%' },
              //isStacked: true,
              bar: { groupWidth: '95%' },
              colors: ['#2CCC72', '#F1C411', '#EE311E'],
              font: 'Montserrat',

              backgroundColor: { fill: 'transparent' },
              chart: {
                //title: 'Company Performance',
                //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
              hAxis: {
                title: 'Total Links',
                scaleType: 'log',
                textStyle: { color: theme.palette.text.primary },
              },
              vAxis: {
                format: ['MMM DD YYYY'],
                slantedText: true,
                slantedTextAngle: 45,
                ticks: props.ticks,
                //format: 'MMM dd yy HH:mm'
                textStyle: { color: theme.palette.text.primary },
                titleTextStyle: { color: theme.palette.text.primary },
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Chart
            chartType="BarChart"
            columns={columns}
            height={'100%'}
            loader={<div>{getTranslation("Loading Chart", preferredLanguage)}</div>}
            data={props.chartData}
            options={{
              chartArea: { width: '60%', height: '100%' },
              //isStacked: true,
              bar: { groupWidth: '95%' },
              colors: ['#2CCC72', '#F1C411', '#EE311E'],
              font: 'Montserrat',
              backgroundColor: { fill: 'transparent' },
              chart: {
                //title: 'Company Performance',
                //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
              hAxis: {
                title: 'Total Links',
                scaleType: 'log',
                textStyle: { color: theme.palette.text.primary },
              },
              vAxis: {
                format: 'MM/dd',
                slantedText: true,
                slantedTextAngle: 45,
                ticks: props.ticks,
                textStyle: { color: theme.palette.text.primary },
                titleTextStyle: { color: theme.palette.text.primary },

                //format: 'MMM dd yy HH:mm'
              },
              legend: {
                textStyle: { color: theme.palette.text.primary },
                backgroundColor: theme.palette.background.paper,
              },
            }}
          />
        </div>
      );
    }
  };

  //-------Chart Title------//

  const useStyles = makeStyles({
    paper: {},
    root: {
      width: '100%',
      fontFamily: 'Montserrat, sans-serif',
      maxWidth: '700',
      minWidth: '400',
    },
    container: {
      height: '40vh',
      maxHeight: '500',
      minHeight: '200',
      width: '100%',
      maxWidth: '700',
      minWidth: '400',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
  });

  const paperClasses = useStyles();

  return <div className={paperClasses.root}>{renderChartData()}</div>;
};
export default CompanyInfoSafeUnsafe;
