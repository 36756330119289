import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import queryString from 'querystring';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import useChangePageTitle from '../Dashboard/CustomHooks/useChangePageTitle';
import CheckoutForm from './CheckoutForm';
import basePCUrl from './../../Globals';

const Activation = (props) => {
  useChangePageTitle('Activation');

  const [code, setCode] = useState();

  const [email, setEmail] = useState();
  const [status, setStatus] = useState();

  const stripePromise = loadStripe('pk_live_lQFJBBHu11xProHTpcc4SRnE00x9Tc5Eyo');

  const retrieveEmail = () => {
    // const token = await getAccessTokenSilently();

    var data = {
      code: `${code}`,
    };

    fetch(basePCUrl + 'Signup/GetEmailFromCode', {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        //Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          console.log(data);

          if (data.accessLevel === 'Error') {
            console.log('Error');
          } else {
            setEmail(data.email);
            setStatus(data.status);
            //console.log("I set the email!")
            //console.log(data);
            if (data.status === 'user not found' && status !== 'ok') {
              setTimeout(function () {
                var errorDiv = document.getElementById('Error');
                if (errorDiv != null) errorDiv.style = '';
              }, 2000);
            }
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    //retrieveEmail();
    //setEmailAgain();
    //lock.show();
  }, []);

  return (
    <div id="SignUpBody">
      <h2 id="loading">Signup</h2>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};
export default Activation;

//onClick = {() => loginWithRedirect({})}
//and < a target = "_blank" href = "https://www.phishcloud.com/privacy-policy/" > privacy policy</a >.
