import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import useGetAlertDetails from './useGetAlertDetails';
import useForm from '../CustomHooks/useForm';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import AlertsDetailResults from './AlertsDetailResults';

import queryString from 'querystring';
import './../LinkAnalysis/LinkAnalysis.css';

const AlertsDetail = (props) => {
  useChangePageTitle('AlertDetails');

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const { values: inputUrl, bind: bindInputUrl, reset: resetInputUrl } = useForm('');

  const [alertId, setAlertId] = useState('');
  const [clientId, setClientId] = useState(props.selectedClient.clientId);

  useEffect(() => {
    if (props.selectedClient.clientId === undefined || props.selectedClient.clientId === '') {
      setClientId(props.defaultClient);
    } else {
      setClientId(props.selectedClient.clientId);
    }
  }, [alertId]);
  useEffect(() => {
    analyzeLink();
  }, [alertId]);

  const { isLoading, alertDetails, isError, error } = useGetAlertDetails(alertId, props.accessLevel, clientId);

  const analyzeLink = () => {
    let parsedQuery = queryString.parse(props.location.search);
    let parsedId = parsedQuery.id;

    if (parsedId !== undefined) {
      //var decodedLink = decodeURIComponent(parsedId);
      setAlertId(parsedId);
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />
      ) : (
        alertDetails && (
          <AlertsDetailResults
            history={props.history}
            alertDetails={alertDetails}
            clientId={props.selectedClient.clientId}
            accessLevel={props.accessLevel}
            preferredLanguage={preferredLanguage}
            getTranslation={getTranslation}
            //incidentCategories={incidentCategories}
          />
        )
      )}

      {/* {alertDetails === null ? (
        <div>No Match</div>
      ) : (
        <AlertsDetailResults
          history={props.history}
          alertDetails={alertDetails}
          //incidentCategories={incidentCategories}
        />
      )} */}
    </div>
  );
};
export default AlertsDetail;
