import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from '../CustomHooks/useForm';

const DeleteCheckModal = (props) => {
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //-----Designation Modal close button toggle-----//
  const closeDeleteCheckBtn = (
    <Button type="submit" outline color="white" onClick={props.toggle}>
      <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
    </Button>
  );

  const displayLoader =()=> {
    if (props.deleteLoading){
    return( <div style={{ textAlign: 'center', }}>
      <CircularProgress style={{color: "black"}} size={80}/>
      </div>
      )
    }
  }

  return (
    <div>
      <Dialog
        className={styles.root}
        onClose={props.toggle}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.toggle}></DialogTitle>
        <ModalBody>
          <Typography variant="h4" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
            Are you sure?
          </Typography>

          <br></br>
          {displayLoader()}
          <br/>
          <div className="deleteDesignationButton">
            <Button
              block
              onClick={props.handleDeletion}
              id="deleteAllSelected"
              fullWidth="true"
              variant="contained"
              color="secondary"
            >
              Delete Selected
            </Button>
          </div>
        </ModalBody>
      </Dialog>
    </div>
  );
};
export default DeleteCheckModal;

//< FormGroup check >
//    <input check style={{ marginLeft: '10px' }} type="checkbox" checked={props.isDeleteChecked} onClick={() => props.setIsDeleteChecked(!props.isDeleteChecked)}  ></input>
//    <Label check style={{ fontSize: '18px', marginLeft: '12px', padding: '10px' }} for="confirmDeleteCheck" >Yes, I want to delete these items</Label>
//                    </FormGroup >
