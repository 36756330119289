const CompanyInfoMockData = {
  CompanyOne: {
    SafetyStatus: 'companySafe',
    Statistics: {
      safety: 'SAFE',
      userCount: 117,
      activeUsers: 103,
      totalSeenLinks: 89602,
      totalClickedLinks: 56771,
      totalUnknownSeen: 308,
      totalUnkownClicked: 14,
      totalUnsafeSeen: 212,
      totalUnsafeClicked: 4,
      companyDesignationsSeen: 1113,
      companyDesignationsClicked: 2,
    },
    SafetyActivity: [
      ['Day', 'Safe', 'Unknown', 'Unsafe'],
      [new Date(), 11009, 34, 7],
      [new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 11740, 42, 7],
      [new Date(new Date().getTime() + 48 * 60 * 60 * 1000), 12078, 33, 4],
      [new Date(new Date().getTime() + 72 * 60 * 60 * 1000), 10989, 47, 3],
      [new Date(new Date().getTime() + 96 * 60 * 60 * 1000), 11807, 23, 5],
    ],
    Alerts: [
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unsafe',
        email: 'elmer@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'MED',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'joe@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'MED',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown to unsafe',
        email: 'carl@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'MED',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'elmer@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'MED',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown to safe',
        email: 'kat@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'LOW',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown',
        email: 'joe@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'LOW',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'vicki@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'LOW',
        status: 'Updated',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'ed@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'LOW',
        status: 'Updated',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'kat@company.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'LOW',
        status: 'Updated',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown to unsafe',
        email: 'elmer@company.com',
        url: 'www.phishy-linkz.com',
      },
    ],
    AlertsData: [
      ['Date', 'Low', 'Med', 'High'],
      [new Date(), 10, 4, 2],
      [new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 11, 6, 2],
      [new Date(new Date().getTime() + 48 * 60 * 60 * 1000), 6, 8, 3],
      [new Date(new Date().getTime() + 72 * 60 * 60 * 1000), 13, 9, 5],
      [new Date(new Date().getTime() + 96 * 60 * 60 * 1000), 11, 6, 5],
    ],
    UserActivity: [
      [{ type: 'number', label: 'Hours' }, 'Total Clicks', 'User 1 Clicks', 'User 2 Clicks', 'User 3 Clicks'],
      [1, 120, 46, 58, 42],
      [2, 136, 50, 64, 57],
      [3, 133, 67, 69, 59],
      [4, 140, 62, 75, 57],
      [5, 142, 63, 76, 58],
      [6, 156, 56, 69, 52],
      [7, 150, 59, 61, 54],
      [8, 135, 39, 79, 50],
      [9, 130, 42, 67, 45],
      [10, 126, 49, 50, 42],
      [11, 122, 35, 44, 37],
      [12, 120, 35, 44, 37],
    ],
    UserRisk: [
      {
        user: 'kmontilla0@artisteer.com',
        clicks: '1438',
        unknown: '16%',
        unsafe: '3%',
      },
      {
        user: 'wlassey1@symantec.com',
        clicks: '1405',
        unknown: '15%',
        unsafe: '2%',
      },
      {
        user: 'grosa2@wp.com',
        clicks: '1398',
        unknown: '14%',
        unsafe: '2%',
      },
      {
        user: 'mprestner3@dyndns.org',
        clicks: '1493',
        unknown: '14%',
        unsafe: '1%',
      },
      {
        user: 'bpape4@php.net',
        clicks: '1209',
        unknown: '13%',
        unsafe: '1%',
      },
      {
        user: 'malderson5@theatlantic.com',
        clicks: '1997',
        unknown: '13%',
        unsafe: '0%',
      },
      {
        user: 'atreace6@sourceforge.net',
        clicks: '1522',
        unknown: '12%',
        unsafe: '0%',
      },
      {
        user: 'hdenisard7@123-reg.co.uk',
        clicks: '1083',
        unknown: '11%',
        unsafe: '0%',
      },
      {
        user: 'kgiacovelli8@vinaora.com',
        clicks: '1106',
        unknown: '9%',
        unsafe: '0%',
      },
      {
        user: 'ddobrowolski9@samsung.com',
        clicks: '987',
        unknown: '7%',
        unsafe: '0%',
      },
    ],
    SeenLinks: [
      {
        badLink: 'https://cafepress.com/donec/odio/justo.js',
        link_safety: 'Unsafe',
        link_times_seen: 18,
        link_times_seen_company: 3,
      },
      {
        badLink: 'https://merriam-webster.com/et/ultrices/posuere/cubilia/curae.png',
        link_safety: 'Unsafe',
        link_times_seen: 21,
        link_times_seen_company: 0,
      },
      {
        badLink: 'https://uol.com.br/et/magnis/dis/parturient/montes.png',
        link_safety: 'Unknown',
        link_times_seen: 8,
        link_times_seen_company: 4,
      },
      {
        badLink: 'http://mapquest.com/rutrum.html',
        link_safety: 'Unknown',
        link_times_seen: 73,
        link_times_seen_company: 5,
      },
      {
        badLink: 'http://about.me/eu/felis/fusce/posuere/felis/sed/lacus.jsp',
        link_safety: 'Unsafe',
        link_times_seen: 88,
        link_times_seen_company: 2,
      },
      {
        badLink: 'https://nbcnews.com/pretium/nisl/ut/volutpat/sapien/arcu.xml',
        link_safety: 'Unsafe',
        link_times_seen: 18,
        link_times_seen_company: 0,
      },
      {
        badLink: 'https://umich.edu/ante.json',
        link_safety: 'Unsafe',
        link_times_seen: 74,
        link_times_seen_company: 8,
      },
      {
        badLink: 'https://arstechnica.com/lorem/quisque/ut.png',
        link_safety: 'Safe',
        link_times_seen: 59,
        link_times_seen_company: 1,
      },
      {
        badLink: 'https://about.com/condimentum.html',
        link_safety: 'Unsafe',
        link_times_seen: 23,
        link_times_seen_company: 0,
      },
      {
        badLink: 'http://constantcontact.com/sed/vestibulum/sit/amet/cursus/id.jsp',
        link_safety: 'Safe',
        link_times_seen: 47,
        link_times_seen_company: 0,
      },
    ],
    GlobalData: [
      ['Day', 'Global Safe', 'Global Unknown', 'Global Unsafe'],
      [new Date(), 110689, 32994, 7807],
      [new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 111740, 41562, 7045],
      [new Date(new Date().getTime() + 48 * 60 * 60 * 1000), 121078, 36443, 6304],
      [new Date(new Date().getTime() + 72 * 60 * 60 * 1000), 110989, 40765, 7231],
      [new Date(new Date().getTime() + 96 * 60 * 60 * 1000), 111807, 26903, 5795],
    ],
  },
  CompanyTwo: {
    SafetyStatus: 'companyUnsafe',
    Statistics: {
      safety: 'UNSAFE',
      userCount: 98,
      activeUsers: 87,
      totalSeenLinks: 95602,
      totalClickedLinks: 54930,
      totalUnknownSeen: 2798,
      totalUnkownClicked: 103,
      totalUnsafeSeen: 890,
      totalUnsafeClicked: 99,
      companyDesignationsSeen: 1764,
      companyDesignationsClicked: 82,
    },
    SafetyActivity: [
      ['Day', 'Safe', 'Unknown', 'Unsafe'],
      [new Date(), 7009, 1104, 217],
      [new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 8740, 1542, 397],
      [new Date(new Date().getTime() + 48 * 60 * 60 * 1000), 7909, 1253, 383],
      [new Date(new Date().getTime() + 72 * 60 * 60 * 1000), 7740, 1433, 293],
      [new Date(new Date().getTime() + 96 * 60 * 60 * 1000), 2740, 1636, 368],
    ],
    Alerts: [
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unsafe',
        email: 'ricky@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'gigi@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown to unsafe',
        email: 'ricky@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'cody@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown to safe',
        email: 'harry@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown',
        email: 'paula@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'HIGH',
        status: 'NEW',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'vince@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'MED',
        status: 'Updated',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'harry@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'MED',
        status: 'Updated',
        lastUpdated: '',
        type: 'LinkClicks',
        incident: 'unknown',
        email: 'ricky@business.com',
        url: 'www.phishy-linkz.com',
      },
      {
        severity: 'MED',
        status: 'Updated',
        lastUpdated: '',
        type: 'LinkReports',
        incident: 'unknown to unsafe',
        email: 'niel@business.com',
        url: 'www.phishy-linkz.com',
      },
    ],
    AlertsData: [
      ['Date', 'Low', 'Med', 'High'],
      [new Date(), 27, 19, 17],
      [new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 21, 16, 14],
      [new Date(new Date().getTime() + 48 * 60 * 60 * 1000), 26, 18, 15],
      [new Date(new Date().getTime() + 72 * 60 * 60 * 1000), 23, 22, 17],
      [new Date(new Date().getTime() + 96 * 60 * 60 * 1000), 31, 19, 19],
    ],
    UserActivity: [
      [{ type: 'number', label: 'Hours' }, 'Total', 'User 1', 'User 2', 'User 3'],
      [1, 145, 76, 48, 42],
      [2, 156, 80, 54, 57],
      [3, 163, 85, 59, 59],
      [4, 170, 82, 65, 57],
      [5, 172, 88, 56, 58],
      [6, 176, 99, 59, 52],
      [7, 160, 97, 41, 54],
      [8, 155, 79, 49, 50],
      [9, 150, 72, 47, 45],
      [10, 146, 79, 40, 42],
      [11, 142, 65, 34, 37],
      [12, 140, 55, 34, 37],
    ],
    UserRisk: [
      {
        user: 'FuegoPhil@Hersheys.com',
        clicks: '87%',
        unknown: '80%',
        unsafe: '75%',
      },
      {
        user: 'Lassiter@psych.com',
        clicks: '81%',
        unknown: '77%',
        unsafe: '68%',
      },
      {
        user: 'Bob@builder.com',
        clicks: '67%',
        unknown: '47%',
        unsafe: '68%',
      },
      {
        user: 'pizzaJoe@iHeartPizza.com',
        clicks: '67%',
        unknown: '44%',
        unsafe: '60%',
      },
      {
        user: 'jackMackenzie@bagpipes4U.com',
        clicks: '67%',
        unknown: '39%',
        unsafe: '58%',
      },
      {
        user: 'malderson5@theatlantic.com',
        clicks: '67%',
        unknown: '42%',
        unsafe: '55%',
      },
      {
        user: 'atreace6@sourceforge.net',
        clicks: '67%',
        unknown: '37%',
        unsafe: '41%',
      },
      {
        user: 'hdenisard7@123-reg.co.uk',
        clicks: '67%',
        unknown: '20%',
        unsafe: '35%',
      },
      {
        user: 'kgiacovelli8@vinaora.com',
        clicks: '67%',
        unknown: '19%',
        unsafe: '2%',
      },
      {
        user: 'ddobrowolski9@samsung.com',
        clicks: '67%',
        unknown: '10%',
        unsafe: '15%',
      },
    ],
    SeenLinks: [
      {
        badLink: 'https://cafepress.com/donec/odio/justo.js',
        link_safety: 'Unsafe',
        link_times_seen: 18,
        link_times_seen_company: 7,
      },
      {
        badLink: 'https://merriam-webster.com/et/ultrices/posuere/cubilia/curae.png',
        link_safety: 'Unsafe',
        link_times_seen: 21,
        link_times_seen_company: 0,
      },
      {
        badLink: 'https://uol.com.br/et/magnis/dis/parturient/montes.png',
        link_safety: 'Unknown',
        link_times_seen: 8,
        link_times_seen_company: 4,
      },
      {
        badLink: 'http://mapquest.com/rutrum.html',
        link_safety: 'Unknown',
        link_times_seen: 73,
        link_times_seen_company: 8,
      },
      {
        badLink: 'http://about.me/eu/felis/fusce/posuere/felis/sed/lacus.jsp',
        link_safety: 'Unsafe',
        link_times_seen: 88,
        link_times_seen_company: 9,
      },
      {
        badLink: 'https://nbcnews.com/pretium/nisl/ut/volutpat/sapien/arcu.xml',
        link_safety: 'Unsafe',
        link_times_seen: 18,
        link_times_seen_company: 11,
      },
      {
        badLink: 'https://umich.edu/ante.json',
        link_safety: 'Unsafe',
        link_times_seen: 74,
        link_times_seen_company: 8,
      },
      {
        badLink: 'https://arstechnica.com/lorem/quisque/ut.png',
        link_safety: 'Safe',
        link_times_seen: 59,
        link_times_seen_company: 8,
      },
      {
        badLink: 'https://about.com/condimentum.html',
        link_safety: 'Unsafe',
        link_times_seen: 23,
        link_times_seen_company: 0,
      },
      {
        badLink: 'http://constantcontact.com/sed/vestibulum/sit/amet/cursus/id.jsp',
        link_safety: 'Safe',
        link_times_seen: 47,
        link_times_seen_company: 10,
      },
    ],
    GlobalData: [
      ['Day', 'Global Safe', 'Global Unknown', 'Global Unsafe'],
      [new Date(), 110689, 32994, 7807],
      [new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 111740, 41562, 7045],
      [new Date(new Date().getTime() + 48 * 60 * 60 * 1000), 121078, 36443, 6304],
      [new Date(new Date().getTime() + 72 * 60 * 60 * 1000), 110989, 40765, 7231],
      [new Date(new Date().getTime() + 96 * 60 * 60 * 1000), 111807, 26903, 5795],
    ],
  },
};
export default CompanyInfoMockData;
