import LinearProgress from '@material-ui/core/LinearProgress';
//------------Material UI------------//
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useContext, useState } from 'react';
import { Route, Router } from 'react-router-dom';
import { useQuery } from 'react-query';
//import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '../.././react-sidenav.css';
//import { Switch } from '@material-ui/core';
import basePCUrl from '../../Globals';
//create font awesome library
import initFontAwesome from '../../utils/initFontAwesome';
import { useAuth0 } from "@auth0/auth0-react";
import AccountInfo from './AccountInfo/AccountInfo';
import Alerts from './Alerts/Alerts';
import AlertsDetail from './Alerts/AlertsDetail';
import Billing from './Billing/Billing';
//import CompanyOverview from './CompanyInfo/OldCompanyOverview';
import Callback from '../Callback';
import CompanyOverview from './CompanyInfo/CompanyOverview';
import CompanyConfiguration from './Configuration/CompanyConfiguration';
import Configuration from './Configuration/Configuration';
import useStickyState from './CustomHooks/useStickyState';
import './Dashboard.css';
import Install from './Downloads/Downloads';
import Error from './Error/Error';
import ErrorUserNotFound from './Error/ErrorUserNotFound';
import GettingPermissionsMessage from './GettingPermissionsMessage/GettingPermissionsMessage';
import history from './history';
import AboutPhishCloud from './Information/AboutPhishCloud';
import ContactUs from './Information/ContactUs';
import Layout from './Layout/Layout';
import LinkAnalysis from './LinkAnalysis/LinkAnalysis';
import LinkAnalysisAdmin from './LinkAnalysis/LinkAnalysisAdmin';
import LinkSafetyRules from './LinkSafetyRules/LinkSafetyRules';
import MSSPBaseView from './MSSP/MSSPBaseView';
import AccountAdminRoute from './Routes/AccountAdminRoute';
import AdminRoute from './Routes/AdminRoute';
import PrivateRoute from './Routes/PrivateRoute';
import SideNav from './SideNav/SideNav';
import SideNavMobile from './SideNav/SideNavMobile';
import Training from './Training/Training';
import TrainingItemView from './Training/TrainingItemView';
import TrainingNew from './Training/TrainingNew';
import TrainingQuiz from './Training/TrainingQuiz/TrainingQuiz';
import UserTrainingDashboard from './Training/UserTrainingDashboard';
import AdminTrainingDashboard from './Training/AdminTrainingDashboard';
import RestructuredUserLinkHistory from './UserLinkHistory/RestructuredUserLinkHistory';
import UserManagement from './UserManagement/UserManagement';
import WelcomeLandingPage from './WelcomeLandingPage/WelcomeLandingPage';
import { fetchApiData, fetchOldApiData, fetchApiDataWithId, postApiData } from '../../utils/service';
import Cookies from 'js-cookie';
//-----------Testing Product Intro----------//
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//----------Product Intro----------//
//import AdminConfigSteps from './ProductIntro/AdminConfigSteps';
import './ProductIntro/ProductTourSlides.css';

import ProductTourSlides from './ProductIntro/ProductTourSlides';
import { TourProvider } from '@reactour/tour';
import { useTour } from '@reactour/tour';

import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import UserFlowSteps from './ProductIntro/UserFlowSteps';
import AdminConfigSteps from './ProductIntro/AdminConfigSteps';
import UserConfigsSteps from './ProductIntro/UserConfigsSteps';
import { ContactsOutlined, ContactSupportOutlined } from '@material-ui/icons';
import RedirectOnNotFound from './RedirectOnNotFound';
//import { nodeName } from 'jquery';
initFontAwesome();
const componentMap = {
  User: PrivateRoute,
  CompanyAdmin: AdminRoute,
  AccountAdmin: AdminRoute,
};
const Dashboard = (props) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  let preferredLanguage = props.preferredLanguage;

  const [token, setToken] = useState();
  const [welcomePage, setWelcomePage] = useState(true);
  const [started5MinuteInterval, setStarted5MinuteInterval] = useState(false)

  // const [clientList, setClientList] = useState([
  //   {
  //     clientId: '',
  //     industry: '',
  //     name: 'no client selected',
  //     numberOfUsers: 0,
  //   },
  // ]);
  const [selectedClient, setSelectedClient] = useStickyState(
    0,
    'selected-client'
  );
  //const [defaultClient, setDefaultClient] = useState();

  //-----------Loading----------//
  const loadingStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginLeft: '15%',
      //marginTop: 200,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    title: {
      marginLeft: '15%',
      marginTop: 200,
    },
  }));

  const loadingClasses = loadingStyles();

  const darkMode = props.darkMode;
  const toggleDarkMode = props.toggleDarkMode;

  //--------API Calls---------//
  const isNotLoading = !isLoading;

  // useEffect(() => {
  //   const getUserMetadata = async () => {
  //     const domain = "phishcloud.auth0.com";
  
  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         authorizationParams: {
  //           audience: `https://${domain}/api/v2/`,
  //           scope: "read:current_user",
  //         },
  //       });

  //       const idToken = await getIdTokenClaims()
  
  //       const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
  //       const metadataResponse = await fetch(userDetailsByIdUrl, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });
  
  //       const { user_metadata } = await metadataResponse.json();
  //       console.log(user_metadata)
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };
  
  //   getUserMetadata();
  // }, [user]);

  const portalKey = useQuery(
    ['get_portal_key', isNotLoading, selectedClient],
    async () => {
      // if (localStorage.getItem('usid')) {
      //   let usid = localStorage.getItem('usid');
      //   return fetchApiDataWithId(
      //     'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
      //     usid,
      //     'get_portal_key',
      //     accessLevel,
      //     selectedClient.clientId
      //   );
      // } else if (!localStorage.getItem('usid')) {
        const domain = "phishcloud.auth0.com";

        const token = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope: "openid profile",
                  });
        return fetchApiData(
          'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
          token,
          'get_portal_key',
          accessLevel,
          selectedClient
        );
      //}
    },
    { enabled: isNotLoading, selectedClient }
  );

  const portalKeyData = portalKey?.data?.portalKey
  //console.log(portalKeyData)


  const portalData = useQuery(
    ['get_portal_data', isNotLoading, selectedClient],
    async () => {
      if (localStorage.getItem('usid')) {
        let usid = localStorage.getItem('usid');
        localStorage.setItem('usid', usid)
        setToken(usid)
        return fetchApiDataWithId(
          'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
          usid,
          'get_portal_data',
          accessLevel,
          selectedClient
        );
      } else if (!localStorage.getItem('usid')) {
        const token = await getAccessTokenSilently();
        setToken(token)
        return fetchApiData(
          'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
          token,
          'get_portal_data',
          accessLevel,
          selectedClient
        );
      }
    },
    { enabled: isNotLoading, selectedClient, 
      onSuccess: () => {
        if (portalData?.data?.user_data?.getPermissions?.cid && selectedClient === 0) {
          setSelectedClient(portalData?.data?.user_data?.getPermissions?.cid, 'selected-client')
        }
      } }
  );

  // useEffect(() => {
  //   if (!started5MinuteInterval) {
  //     setStarted5MinuteInterval(true);

  //     const functionToCall = () => {
  //       if (window.location.pathname !== "/Dashboard/TrainingQuiz" && window.location.pathname !== "/Dashboard/Training"
  //       && window.location.pathname !== "/Dashboard/AdminConfiguration"){
  //       portalData.refetch();
  //     }
  //     };

  //     let interval = setInterval(functionToCall, 300000);
  //     return () => clearInterval(interval);

  //   }
  // }, []);

  //console.log(portalData.data)

  //const isPortalLoading = portalData.isLoading;

  const permissions = portalData?.data?.user_data?.getPermissions;

  const accessLevel = portalData?.data?.user_data?.getPermissions?.accessLvl;

  const chosenLanguage = portalData?.data?.user_data?.getUserSettings?.UserLanguage;

  const defaultClient = portalData?.data?.user_data?.getPermissions?.cid;

  const hasTraining = portalData?.data?.user_data?.getPermissions.training;

  const currentNewsData = portalData?.data?.user_data?.currentNews;

  const newsFeed = portalData?.data?.user_data?.news_feed;

  const linkHistoryData = portalData?.data?.user_data?.getUserLinkHistory;

  const userBoxData = portalData?.data?.user_data?.getUserBoxStats;

  const trainingData = portalData?.data?.user_data?.userTraining;

  const adminTrainingData = portalData?.data?.training_admin;

  const userSettings = portalData?.data?.user_data?.getUserSettings;

  const adminConfigData = portalData?.data?.company_admin?.adminConfigs;

  const alertData = portalData?.data?.alerts_admin?.getAlertDetails;

  if (portalData?.data?.user_data?.getPermissions?.uid) {
    localStorage.setItem('usid', portalData?.data?.user_data?.getPermissions?.uid)
  }



 //  useEffect(() => {

 // }, [props.preferredLanguage]);

 //console.log(props.preferredLanguage)

 if (props.preferredLanguage === undefined || props.preferredLanguage === "undefined") {
  props.setPreferredLanguage("initialLanguage", 'preferred-language')
}

   if (props.preferredLanguage === "initialLanguage" && chosenLanguage !== undefined) {
      props.setPreferredLanguage(chosenLanguage, 'preferred-language')
    }

  if (portalData?.data?.company_admin) {
    var userClickOverviewData = portalData?.data?.company_admin.getCompanyUserOverview;
    var companyAlertStats = portalData?.data?.company_admin.getCompanyAlertStats;
    var wordCloud = portalData?.data?.company_admin.getWordCloud;
    var userClickActivity = portalData?.data?.company_admin.getCompanyClickStats;
    var mostRecentCompanySafeUnsafeStats = portalData?.data?.company_admin.getLinkActivity;
    var mostRecentGlobalSafeUnsafeStats = portalData?.data?.company_admin.getGlobalLinksSeen;
    var topGlobalBadLinks = portalData?.data?.company_admin.getGlobalDomainList;
    var companyBoxStats = portalData?.data?.company_admin.getCompanyBoxStats;
    var threatModel = portalData?.data?.company_admin.getThreatModel;
  }

  if (portalData?.data?.account_admin) {
    var accountAdminData = portalData?.data?.account_admin
  }

  const translationData = useQuery(
    ['initialTranslator', isNotLoading],
    async () => {
      if (localStorage.getItem('usid')) {
        const token = await getAccessTokenSilently();
        setToken(token)
        let usid = localStorage.getItem('usid');
        return fetchApiDataWithId(
          'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
          usid,
          'initialTranslator',
        );
      } else if (!localStorage.getItem('usid')) {
        const token = await getAccessTokenSilently();

        setToken(token)
        return fetchApiData(
          'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
          token,
          'initialTranslator',
        );
      }

     },
     { enabled: isNotLoading, selectedClient }
  );


  const translateText = async (text, languageCode) => {
    let url = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';
    const token = await getAccessTokenSilently();

    var dataForTranslation = {
      textToTranslate: text,
      targetLanguage: languageCode,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(dataForTranslation),
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${token}`,
          Action: 'translateText',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data.translatedText) {
        //localStorage.setItem();
        //setCookie(storageKey, data.translatedText, 30);
        return data;
      } else {
        throw new Error('Translation failed.');
      }
    } catch (error) {
      console.error('Translation error:', error);
      return text; // Return the original text if translation fails
    }
  };


  const getTranslation = (key, languageCode) => {
    // Check if the key exists in the translations
    if (key !== undefined && translationData.status === 'success') {
      let finalKey = key.toLowerCase();
      //console.log(finalKey)
      if (translationData?.data?.hasOwnProperty(finalKey)) {
        // Get the language translation object for the given key
        const languageTranslations = translationData?.data[finalKey];

        // Check if the chosen language exists, if not, default to 'EN'
        const chosenLanguage =
          languageTranslations[languageCode === 'EN-US' || languageCode === 'EN-GB' ? 'EN' : languageCode] ||
          languageTranslations['EN'];
          //console.log(chosenLanguage)
        return chosenLanguage;
      } else {
        // Fetch translation from API when key is undefined
        if (languageCode !== 'EN') {
          try {
            //console.log("I am trying the API")
            const translation = translateText(key, languageCode);
            const finalTranslation = translation.translatedText//.toString();
            //console.log(finalTranslation)
            return finalTranslation;
          } catch (error) {
            console.error('Translation error:', error);
            return key; // Return the original key if translation fails
          }
        } else {
          return key;
        }
      }
    }
  };

  //-------Auth-------//
  // useEffect(() => {
  // if(Cookies.get('AL') === undefined && permissions?.accessLvl) {
  //   var accessLevel = permissions?.accessLvl
  //   Cookies.set('AL', permissions?.accessLvl, { expires: 7 });
  // } else {
  //   var accessLevel = Cookies.get('AL')
  // }
  // }, []);

  let auth0Cookie = Cookies.get('auth0.is.authenticated');

  //const accessLevel = permissions?.accessLvl;

  //const accessLevel = "CompanyAdmin"

  const RouteComponent = accessLevel === 'User' ? PrivateRoute : AdminRoute;

  //console.log(selectedClient)

  return (
    <React.Fragment>
      {/* {translationData.status === 'success' ? ( */}
        <Layout
          isLoading={portalData.isLoading}
          userSettings={userSettings}
          translationData={translationData}
          getTranslation={getTranslation}
          setSelectedClient={setSelectedClient}
          selectedClient={selectedClient}
          accountAdminData={accountAdminData}
          defaultClient={defaultClient}
          toggleDarkMode={props.toggleDarkMode}
          accessLevel={accessLevel}
          darkMode={props.darkMode}
          preferredLanguage={preferredLanguage}
          setPreferredLanguage={props.setPreferredLanguage}
          history={history}
          location={props.location}
          isNotLoading={isNotLoading}
        >
          <div id="sideNavBody">
            {/* {accessLevel === undefined && !auth0Cookie? ( */}
            {accessLevel === undefined ? (
              <span></span>
            ) : (
              <div id="sideNav">
                <SideNav
                  training={hasTraining}
                  accessLevel={accessLevel}
                  history={history}
                  location={props.location}
                  preferredLanguage={preferredLanguage}
                />{' '}
              </div>
            )}
          </div>
          <div id="sideNavMobileLandscapeBody">
            {/* {accessLevel === undefined && !auth0Cookie ? ( */}
            {accessLevel === undefined ? (
              <span></span>
            ) : (
              <div id="sideNavMobileLandscape">
                {' '}
                <SideNavMobile
                  accessLevel={accessLevel}
                  history={history}
                  location={props.location}
                  preferredLanguage={preferredLanguage}
                />{' '}
              </div>
            )}
          </div>

          <div id="dashboardBody">
            {/* {loading ? (
            <div style={{width: '60vw'}}>
            <h3>Loading...</h3>
            <LinearProgress />
            </div>
          ) : (
            <span></span>
          )} */}

            {/* {accessLevel !== undefined && !welcomePage ? ( */}
            {!isLoading && isAuthenticated && accessLevel !== undefined ? (
              <main>
                <Router history={props.history} location={props.location}>
                  <RedirectOnNotFound history={props.history} location={props.location} redirectTo="/Dashboard/History">
                <PrivateRoute path="/Dashboard/Callback" component={(props) => <Callback history={props.history} />} />
                <PrivateRoute
                  path="/Dashboard/History"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <RestructuredUserLinkHistory
                      isLoading={portalData.isLoading}
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      translationData={translationData}
                      getTranslation={getTranslation}
                      history={props.history}
                      location={props.location}
                      preferredLanguage={preferredLanguage}
                      adminConfigData={adminConfigData}
                      linkHistoryData={linkHistoryData}
                      trainingData={trainingData}
                      hasTraining={hasTraining}
                      userBoxData={userBoxData}
                      currentNewsData={currentNewsData}
                      newsFeed={newsFeed}
                    />
                  )}
                />
                <PrivateRoute
                  accessLevel={accessLevel}
                  path="/Dashboard/AnalyzeLink"
                  component={(props) => (
                    <LinkAnalysis
                      accessLevel={accessLevel}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                <AdminRoute
                  path="/Dashboard/AnalyzeLinkAdmin"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <LinkAnalysisAdmin
                      accessLevel={accessLevel}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                <AdminRoute
                  path="/Dashboard/LinkSafetyRules"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <LinkSafetyRules
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />
                <AdminRoute
                  path="/Dashboard/UserManagement"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <UserManagement
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                {/* <PrivateRoute
                  path="/Dashboard/Configuration"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <Configuration
                      isLoading={isLoading}
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      userSettings={userSettings}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                /> */}

                <AdminRoute
                  path="/Dashboard/AdminConfiguration"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <CompanyConfiguration
                      adminConfigData={adminConfigData}
                      portalKeyData={portalKeyData}
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                <AdminRoute
                  path="/Dashboard/AdminTrainingDashboard"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <AdminTrainingDashboard
                      isLoading={portalData.isLoading}
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                      trainingData={trainingData}
                      adminTrainingData={adminTrainingData}
                    />
                  )}
                />

                <AccountAdminRoute
                  path="/Dashboard/AccountManagement"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <MSSPBaseView
                      accessLevel={accessLevel}
                      history={props.history}
                      location={props.location}
                      accountAdminData={accountAdminData}
                      setSelectedClient={setSelectedClient}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                <AdminRoute
                  path="/Dashboard/CompanyOverview"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <CompanyOverview
                      isLoading={portalData.isLoading}
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                      userClickOverviewData={userClickOverviewData}
                      companyAlertStats={companyAlertStats}
                      wordCloud={wordCloud}
                      userClickActivity={userClickActivity}
                      mostRecentCompanySafeUnsafeStats={mostRecentCompanySafeUnsafeStats}
                      mostRecentGlobalSafeUnsafeStats={mostRecentGlobalSafeUnsafeStats}
                      topGlobalBadLinks={topGlobalBadLinks}
                      companyBoxStats={companyBoxStats}
                      threatModel={threatModel}
                    />
                  )}
                />

                <AdminRoute
                  path="/Dashboard/Alerts"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <Alerts
                      alertData={alertData}
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      isLoading={portalData.isLoading}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                      isNotLoading={isNotLoading}
                    />
                  )}
                />
                <AdminRoute
                  path="/Dashboard/AlertsDetail"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <AlertsDetail
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      defaultClient={defaultClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                <PrivateRoute
                  path="/Dashboard/TrainingDashboard"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <UserTrainingDashboard
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                      trainingData={trainingData}
                      isLoading={portalData.isLoading}
                    />
                  )}
                />

                <AdminRoute
                  path="/Dashboard/AssignTraining"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <Training
                      isLoading={portalData.isLoading}
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                      trainingViewType="assign-training"
                      trainingData={trainingData}
                      adminTrainingData={adminTrainingData}
                    />
                  )}
                />
                <AdminRoute
                  path="/Dashboard/TrainingReport"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <Training
                      accessLevel={accessLevel}
                      selectedClient={selectedClient}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                      trainingViewType="training-report"
                      trainingData={trainingData}
                      adminTrainingData={adminTrainingData}
                    />
                  )}
                />

                <RouteComponent
                  path="/Dashboard/Training"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <TrainingItemView
                      accessLevel={accessLevel}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                      trainingData={trainingData}
                    />
                  )}
                />
                <RouteComponent
                  path="/Dashboard/TrainingQuiz"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <TrainingQuiz
                      accessLevel={accessLevel}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                <PrivateRoute
                  path="/Dashboard/Billing"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <Billing
                      accessLevel={accessLevel}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />
                <PrivateRoute
                  path="/Dashboard/Downloads"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <Install
                      accessLevel={accessLevel}
                      history={props.history}
                      location={props.location}
                      getTranslation={getTranslation}
                      preferredLanguage={preferredLanguage}
                    />
                  )}
                />

                <PrivateRoute
                  path="/Dashboard/account-info"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <AccountInfo getTranslation={getTranslation} preferredLanguage={preferredLanguage} />
                  )}
                />
                <PrivateRoute
                  path="/Dashboard/about-us"
                  accessLevel={accessLevel}
                  component={(props) => (
                    <AboutPhishCloud getTranslation={getTranslation} preferredLanguage={preferredLanguage} />
                  )}
                />
                <PrivateRoute
                  path="/Dashboard/contact-us"
                  accessLevel={accessLevel}
                  component={ContactUs}
                  getTranslation={getTranslation}
                  preferredLanguage={preferredLanguage}
                />
                <PrivateRoute
                  path="/Dashboard/Error"
                  accessLevel={accessLevel}
                  component={Error}
                  getTranslation={getTranslation}
                  preferredLanguage={preferredLanguage}
                />
                <PrivateRoute
                  path="/Dashboard/ErrorUserNotFound"
                  accessLevel={accessLevel}
                  component={ErrorUserNotFound}
                  getTranslation={getTranslation}
                  preferredLanguage={preferredLanguage}
                />
                <PrivateRoute
                  path="/Dashboard/chat"
                  accessLevel={accessLevel}
                  getTranslation={getTranslation}
                  preferredLanguage={preferredLanguage}
                  component={() => {
                    window.location.href = 'https://phishcloud.slack.com/archives/CLL45JM09';
                    return null;
                  }}
                />
                <Route
                  path="/email"
                  component={() => {
                    window.location.href = 'mailto: support@phishcloud.com';
                    return null;
                  }}
                />
                </RedirectOnNotFound>
                </Router>
              </main>
            ) : (
              <div style={{ width: '60vw', height: '80vh', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ marginTop: '25%', marginLeft: '10%' }}>
                  {/* <h3> {getTranslation('Loading', preferredLanguage)}...</h3> */}
                  <h3> Loading...</h3>

                  <LinearProgress style={{ display: 'block' }} />
                </div>
              </div>
              // <div>  <WelcomeLandingPage setWelcomePage={setWelcomePage} preferredLanguage={preferredLanguage} /></div>
            )}
            {/* {!loading && isAuthenticated === true && !accessLevel ? (
            // <GettingPermissionsMessage preferredLanguage={preferredLanguage} />
            <WelcomeLandingPage setWelcomePage={setWelcomePage} preferredLanguage={preferredLanguage} />
          ) : (
            <div></div>
          )} */}
          </div>
        </Layout>
      {/* // ) : (
      //   <div style={{ position: 'relative', display: 'block' }}>
      //     <div style={{ width: '80vw', height: '80vh', justifyContent: 'center', alignItems: 'center' }}>
      //       <div style={{ marginTop: '25%', marginLeft: '10%' }}>
      //         <h3>Loading...</h3>
      //         <LinearProgress style={{ display: 'block' }} />
      //       </div>{' '}
      //     </div>
      //   </div>
      // )} */}


      <div id="PCAT" className={localStorage.getItem('usid') ? localStorage.getItem('usid') : token}></div>
    </React.Fragment>
  );
};
export default Dashboard;
