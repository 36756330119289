import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Button, Col, Row } from 'reactstrap';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NewsFeedLinkPreview from './NewsFeedLinkPreview';
import './CyberSecurityNewsfeed.css';
import { render } from 'react-dom';

const CyberSecurityNewsfeed = (props) => {
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  let preferredLanguage = props.preferredLanguage
  let getTranslation = props.getTranslation
  let currentNews = props.currentNews


  //----------Table Title Toolbar-------//
  const TitleToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle">
        {props.text}
        </Typography>
      </Toolbar>
    );
  };


const newsFeed = props.newsFeed

const generateLinkPreviews = (data) => {
  return data.map((item, index) => {
    const { newsUrl, title, description, image } = item;
    return (
      <NewsFeedLinkPreview
        key={index}
        newsUrl={newsUrl}
        title={title}
        description={description}
        image={image}
        getTranslation={getTranslation}
        preferredLanguage={preferredLanguage}
      />
    );
  });
};

const renderedComponents = generateLinkPreviews(newsFeed);

  return (
    <Paper id="newsfeed-rectangle">
      <TitleToolbar text={props.getTranslation("newsfeed", props.preferredLanguage)}/>
      <div id="newsfeed-sections">
        {/* {currentNews != undefined
          ? currentNews.map((link, index) => (
              <span key={index}>
                <LinkPreview linkForPreview={link} targetLanguage={preferredLanguage}/>
                <br />
              </span>
            ))
          : null} */}
          {newsFeed != undefined ? renderedComponents : null}
      </div>
    </Paper>
  );
};
export default CyberSecurityNewsfeed;

//< p > Article title - Description of the article, excerpt from the article, etc... </p >
//    <hr />
//    <br />
//    <p>Article title - Description of the article, excerpt from the article, etc...</p>
//    <hr />
//    <br />
//    <p>Article title - Description of the article, excerpt from the article, etc...</p>
//    <hr />
