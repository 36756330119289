import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Container, Col, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TranslationModule from '../../Translate/TranslationModule';
import './LandingPage.css';

const LinkPreview = (props) => {
  const [linkData, setLinkData] = useState();
  const [loading, setLoading] = useState();
  const url = 'https://www.reddit.com/';
  let preferredLanguage = props.targetLanguage

  const getLinkPreviewData = async (linkForPreview) => {
    // const api = "https://getopengraph.herokuapp.com/";

    setLoading(true);

    var data = {
      finalUrl: props.linkForPreview,
    };

    fetch('https://pkr53cr5vj.execute-api.us-west-2.amazonaws.com/prod', {
      //credentials: "same-origin",
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          setLinkData({ title: 'There was an error', desc: 'No link information could be found' });
          return;
        }

        response.json().then((data) => {
          setLinkData(data);
          setLoading(false);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    getLinkPreviewData();
  }, []);

  const useStyles = makeStyles({
    unsafeRoot: {
      minWidth: 275,
      fontFamily: 'Montserrat',
      display: 'flex',
      //padding: '15px',
      width: '100%',
      backgroundColor: '#26282c',
      color: 'white',
      borderColor: props.clientInfo ? props.clientInfo.styling.linkPreviewCoverColor : '#0055a2',
      '&:hover': {
        background: "#191a1c",
        borderColor: 'red',
        boxShadow: '0 0 10px red',

     },
      // backgroundColor: '#c8895d'
    },
    unknownRoot: {
      minWidth: 275,
      fontFamily: 'Montserrat',
      display: 'flex',
      //padding: '15px',
      width: '100%',
      backgroundColor: '#26282c',
      color: 'white',
      borderColor: props.clientInfo ? props.clientInfo.styling.linkPreviewCoverColor : '#0055a2',
      '&:hover': {
        background: "#191a1c",
        borderColor: '#f1c411',
        boxShadow: '0 0 10px #f1c411',

     },
      // backgroundColor: '#c8895d'
    },
    regularRoot: {
      minWidth: 275,
      fontFamily: 'Montserrat',
      display: 'flex',
      //padding: '15px',
      width: '100%',
      //backgroundColor: '#26282c',
      //color: 'white',
      borderColor: props.backgroundColor,
      '&:hover': {
        //background: "#191a1c",
        borderColor: '#428BC9',
        boxShadow: '0 0 10px #428BC9',

     },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '10px',
      fontFamily: 'Montserrat',
    },
    title: {
      fontWeight: 600,
      fontFamily: 'Montserrat',
      marginBottom: '10px',
      // backgroundColor: '#c8895d',
      // color: 'black'
    },
    desc: {
      fontFamily: 'Montserrat',
      marginBottom: '10px',
    },
    cover: {
      width: '175px',
      minHeight: '125px',
      objectFit: 'cover',
      backgroundColor: props.clientInfo ? props.clientInfo.styling.linkPreviewCoverColor : '#0055a2',

    },
    pos: {
      //marginBottom: 12,
      fontFamily: 'Montserrat',
      fontStyle: 'italic',
      color: 'white'
    },
  });
  const classes = useStyles();


  return (
    <div id={"linkPreviewBody"}>
      {loading === false ? (
        <a href={linkData.urlRequested ? linkData.urlRequested : props.linkForPreview} target="_blank" style={{ width: '100%' }}>
          <Card className={props.safety === "unsafe" ? classes.unsafeRoot : props.safety === "unknown" ? classes.unknownRoot : classes.regularRoot} variant="outlined" id={props.clickedSafety + "LinkPreviewCard"}>
            {linkData.urlImage != null ? (
              <CardMedia
                className={classes.cover}
                component="img"
                alt={linkData.urlTitle}
                image={linkData.urlImage}
                title={linkData.urlTitle}
              />
            ) : (
              <CardMedia className={classes.cover} component="img" />
            )}
            <div className={classes.details}>
              <Typography className={classes.title} variant="h5" component="h3">
             {linkData.urlTitle}
              </Typography>
              <Typography className={classes.desc} variant="body2" component="p">
              {linkData.urlDescription ? linkData.urlDescription : "There was no meta data found for this link."} 
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
              {linkData.urlRequested ? linkData.urlRequested : props.linkForPreview}
 
              </Typography>
            </div>
          </Card>
        </a>
      ) : loading === true ? (
        <p style={{ fontSize: '20px' }}>  
        <TranslationModule text="loading preview..." targetLanguage={preferredLanguage}/>
        </p>
      ) : null}
    </div>
  );
};
export default LinkPreview;

//< CardActions >
//<Button size="small">Learn More</Button>
//</CardActions >
