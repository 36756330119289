import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

//--------------Material UI Imports--------------//
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TranslationModule from '../../../Translate/TranslationModule';
import './MSSP.css';

const MSSPCreateClientButton = (props) => {
  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();

  return (
    <IconButton
      onClick={props.toggleMSSPNewFeatureDialogue}
      style={{ marginLeft: '25px' }}
      color="primary"
      aria-label="upload picture"
      component="span"
    >
      <Tooltip title={<TranslationModule text={"Create Client"} targetLanguage={props.preferredLanguage}/>}>
        <AddCircleOutlineOutlinedIcon id="MSSPCreateClientButton" fontSize="large" />
      </Tooltip>
    </IconButton>
  );
};
export default MSSPCreateClientButton;
