import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateCreate } from '../DateCreate/DateCreate';
import CircleWidget from '../CircleWidget/CircleWidget';
import { Alert, Container, Row, Col } from 'reactstrap';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import { DateCreateTimeStamp } from './../DateCreate/DateCreateTimeStamp';
import EditAlertModal from './EditAlertModal';
import UpdateIcon from '@material-ui/icons/Update';
import TrafficIcon from '@material-ui/icons/Traffic';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteAlertModal from './DeleteAlertModal';
import AlertsSetSafetyRuleModal from './AlertsSetSafetyRuleModal';
import basePCUrl from '../../../Globals';
import './Alerts.css';

const AlertsDetailResults = (props) => {
  const [editAlertModal, setEditAlertModal] = useState(false);
  const toggleEditAlertModal = () => setEditAlertModal(!editAlertModal);

  const [alertDeleteCheckModal, setAlertDeleteCheckModal] = useState(false);
  const toggleAlertDeleteCheck = () => setAlertDeleteCheckModal(!alertDeleteCheckModal);

  const [setRuleModal, setSetRuleModal] = useState(false);
  const toggleSetRuleModal = () => setSetRuleModal(!setRuleModal);

  const [isAlertDeleteChecked, setIsAlertDeleteChecked] = useState(false);
  const [alertsForDelete, setAlertsForDelete] = useState([]);

  const [comment, setComment] = useState();

  const alertSeverity = props?.alertDetails?.severity;

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const severityColor = alertSeverity.includes('med')
    ? 'rgba(241,196,17, 0.5)'
    : alertSeverity.includes('low')
    ? 'rgba(30, 144, 255, 0.5 )'
    : 'rgba(255, 0, 0, 0.5)';

  const [expanded, setExpanded] = React.useState(false);

  const alertId = props.alertDetails.id;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { getAccessTokenSilently } = useAuth0();

  const capitalizeAndColorSeverity = (value) => {
    if (value === '1: low') {
      return <span id="low"> {value.toUpperCase()} </span>;
    } else if (value === '2: med') {
      return <span id="med"> {value.toUpperCase()} </span>;
    } else if (value === '3: high') {
      return <span id="high"> {value.toUpperCase()} </span>;
    }
  };

  const alertUrlPush = (alertUrl) => {
    props.history.push({
      pathname: '/Dashboard/AnalyzeLinkAdmin',
      search: '?pad=&link=' + encodeURIComponent(props.alertDetails.URL),
      state: { from: '/AlertDetails' },
    });
  };

  const backToTable = () => {
    props.history.push('/Dashboard/Alerts');
  };

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  //---------Icon Stlyes--------//
  const useIconStyles = makeStyles({
    arrow: {
      width: 40,
      height: 40,
    },
    openListDots: {
      width: 30,
      height: 30,
    },
  });

  const icons = useIconStyles();

  //---------Alert Log List Styling---------//

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      minWidth: '75vw',
      fontFamily: 'Montserrat, sans-serif',
      padding: 5,
      flexGrow: 1,
    },
    //table: {
    //    maxWidth: 500,
    //},
    tableHead: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: 15,
    },
    dateCell: {
      fontSize: 11,
    },
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
    button: {
      margin: theme.spacing(1),
    },
    card: {
      width: '100%',
      fontFamily: 'Montserrat, sans-serif',
    },
    cardHeader: {
      backgroundColor: severityColor,
    },
    avatar: {
      backgroundColor: severityColor,
      padding: '10px',
      fontSize: '1rem',
    },
    container: {
      display: 'block',
      width: '100%',
    },
  }));

  //---------Alert Log Table---------//

  const rows = props.alertDetails.updateLog;
  const classes = useStyles();

  const AlertLogTable = () => {
    return (
      <Table className={classes.table} size="medium" aria-label="alert log">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} align="left">
              {getTranslation('Date / Time', preferredLanguage)}
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
            {getTranslation('Type', preferredLanguage)}
            </TableCell>
            <TableCell className={classes.tableHead} align="left">
             {getTranslation('Comment', preferredLanguage)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow>
              <TableCell className={classes.dateCell} align="left">
                <DateCreateTimeStamp date={row.logDate} />
              </TableCell>
              <TableCell align="left">
                {getTranslation(row.logType, preferredLanguage)}
              </TableCell>
              <TableCell align="left">
                {getTranslation(row.logEntry, preferredLanguage)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  //---------Alert Popover Logic---------//

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertDetailsMenu, setAlertDetailsMenu] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event) => {
    setAlertDetailsMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAlertDetailsMenu(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  //----------Delete Alert Logic---------//

  const deleteAlert = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      id: props.alertDetails.alertId,
    };

    fetch(basePCUrl + 'alerts/DeleteAlert', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          toggleAlertDeleteCheck();
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  //----------Edit Alert API Call----------//

  const alertDetailIDPush = () => {
    props.history.push('?pad=&id=' + props.alertDetails.alertId);
  };

  const saveAlertEdits = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      id: props.alertDetails.alertId,
      status: props.alertDetails.status,
      owner: '',
      severity: props.alertDetails.severity,
      comments: comment,
      sla: props.alertDetails.SLA,
    };

    fetch(basePCUrl + 'alerts/EditAlert', {
      credentials: 'same-origin',
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then((data) => {});
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
    setTimeout(() => {
      alertDetailIDPush();
    }, 2000);
  };

  const alertDetailPush = () => {
    props.history.push('/Dashboard/Alerts');
  };

  const accountForLinkLength = (linkForCheck) => {
    if (linkForCheck.length >= 30) {
      return <span style={{ wordWrap: 'break-word' }}>{linkForCheck.slice(0, 90)}...</span>;
    } else {
      return <span style={{ wordWrap: 'break-word' }}>{linkForCheck}</span>;
    }
  };

  const completeDeletion = () => {
    deleteAlert();
    alertDetailPush();
  };

  //---------Return--------//
  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <Tooltip
                  title={getTranslation('Back to Alerts Table', preferredLanguage)}
                >
                  <IconButton aria-label="back to alerts table" onClick={backToTable}>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              }
              avatar={
                <Avatar aria-label="alert severity" className={classes.avatar}>
                  <NotificationsIcon />
                </Avatar>
              }
              title={
                props.alertDetails?.description || (
                  getTranslation('User clicked on an unknown link.', preferredLanguage)
                )
              }
              subheader={<DateCreateTimeStamp date={props.alertDetails.timestamp.createDate} />}
            />

            <CardContent style={{ lineHeight: '1.6' }}>
              <Grid container>
                <Grid item xs={6}>
                  <b>
                    {' '}
                    {getTranslation('Severity', preferredLanguage)} :
                  </b>{' '}
                  {capitalizeAndColorSeverity(props.alertDetails.severity)}
                  <br />
                  <b>
                    {' '}
                    {getTranslation('Status', preferredLanguage)} :
                  </b>{' '}
                 {getTranslation(props.alertDetails.status, preferredLanguage)}
                  <br />
                  <b>
                    {' '}
                    {getTranslation('URL', preferredLanguage)} :
                  </b>{' '}
                  {accountForLinkLength(props.alertDetails.URL)}
                  <br />
                  <b>
                    {' '}
                    {getTranslation('Confidence Level', preferredLanguage)} :
                  </b>{' '}
                  {props.alertDetails?.confidenceLevel || ''}
                  <br />
                  <b>
                    {' '}
                   {getTranslation('User', preferredLanguage)} :
                  </b>{' '}
                  {props.alertDetails.userName} <br />
                  {props.alertDetails.alertType === 'LinkClicks' ? (
                    <span>
                      {' '}
                      <b>
                        {' '}
                        {getTranslation('Device', preferredLanguage)}:
                      </b>{' '}
                      {props.alertDetails.deviceName}
                      <br />
                    </span>
                  ) : (
                    <span>
                      {' '}
                      <b>
                        {getTranslation('Device', preferredLanguage)}:
                      </b>{' '}
                      {props.alertDetails.deviceName}
                      <br />
                    </span>
                  )}
                  {props.alertDetails.alertType === 'LinkClicks' ? (
                    <span>
                      {' '}
                      <b>
                        {getTranslation('IP Address', preferredLanguage)}:
                      </b>{' '}
                      {props.alertDetails.ipAddress}
                      <br />
                    </span>
                  ) : (
                    <span>
                      {' '}
                      <b>
                        {getTranslation('IP Address', preferredLanguage)} :
                      </b>{' '}
                      {props.alertDetails.ipAddress}
                      <br />
                    </span>
                  )}
                  <b>SLA:</b> {props.alertDetails.SLA}
                  <br />
                  {props.alertDetails.alertType === 'LinkReports' ? (
                    <span>
                      <b>
                        {' '}
                        {getTranslation('Proposed Safety', preferredLanguage)}
                      </b>{' '}
                      {props.alertDetails?.baseAlert?.incidentDetails?.proposedSafety || ''} <br />
                    </span>
                  ) : (
                    <span>
                      <b>
                        {getTranslation('Proposed Safety', preferredLanguage)} :
                      </b>{' '}
                      {props.alertDetails?.baseAlert?.incidentDetails?.proposedSafety || ''}{' '}
                    </span>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <form>
                    <Typography className={classes.header} variant="h5" component="h3" gutterBottom>
                     { getTranslation('Quick edit', preferredLanguage)}
                    </Typography>
                    <TextField
                      id="leave a comment as a quick edit"
                      multiline
                      required
                      rows={5}
                      label={getTranslation('Leave a comment', preferredLanguage)}
                      variant="outlined"
                      style={{ width: '100%' }}
                      value={comment}
                      onChange={handleComment}
                    />
                    <br />
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ float: 'right', marginTop: '10px' }}
                      onClick={saveAlertEdits}
                    >
                      {getTranslation('Save', preferredLanguage)}
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions disableSpacing>
              <Tooltip title="Link Analysis">
                <IconButton aria-label="link analysis" onClick={alertUrlPush}>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Set Safety Rule">
                <IconButton aria-label="set safety rule" onClick={toggleSetRuleModal}>
                  <TrafficIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Alert">
                <IconButton aria-label="edit the alert" onClick={toggleEditAlertModal}>
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={
                  !expanded ? (
                    getTranslation('View Update Log', preferredLanguage)
                  ) : (
                    getTranslation('Close Update Log', preferredLanguage)
                  )
                }
              >
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="view update log"
                >
                  {!expanded ? <UpdateIcon /> : <ExpandLessIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton aria-label="delete this alert" color="secondary" onClick={toggleAlertDeleteCheck}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <AlertLogTable />
              </CardContent>
            </Collapse>
          </Card>
        </Grid>

        <EditAlertModal
          isOpen={editAlertModal}
          toggle={toggleEditAlertModal}
          id={props.alertDetails.id}
          history={props.history}
          alertDetails={props.alertDetails}
          preferredLanguage={props.preferredLanguage}
          getTranslation={props.getTranslation}
        />
        <DeleteAlertModal
          isOpen={alertDeleteCheckModal}
          toggle={toggleAlertDeleteCheck}
          confirmDeleteMessage={'Yes, I want to delete this alert.'}
          handleDeletion={completeDeletion}
          isAlertDeleteChecked={isAlertDeleteChecked}
          setIsAlertDeleteChecked={setIsAlertDeleteChecked}
          preferredLanguage={props.preferredLanguage}
          getTranslation={props.getTranslation}

        />
        <AlertsSetSafetyRuleModal
          isOpen={setRuleModal}
          setIsOpen={toggleSetRuleModal}
          alertUrl={props.alertDetails.URL}
          clientId={props.clientId}
          accessLevel={props.accessLevel}
          preferredLanguage={props.preferredLanguage}
          getTranslation={props.getTranslation}

        />
      </Grid>
    </div>
  );
};
export default AlertsDetailResults;

//< Typography className = { classes.header } variant = "h5" component = "h3" gutterBottom >
//    { props.alertDetails.baseAlert.title }
//        </Typography >

//< Row >
//<Col md="6">
//    <Paper id="alertDetailsColumnContainer" >
//        <div className={props.alertDetails.baseAlert.severity}>
//            <span id="alertDetailsColumnHeader"><b>Alert Link Details</b><IconButton onClick={alertUrlPush}>< LaunchIcon /></IconButton></span>
//            <div id="alertDetailsIncidentFormat">

//                <b>User:</b> {props.alertDetails.emails} <br />
//                {(props.alertDetails.type === "LinkClicks") ? <span> <b>Device:</b> {props.alertDetails.machineData.userName}<br /></span> : <span></span>}
//                {(props.alertDetails.type === "LinkClicks") ? <span> <b>IP Address:</b> {props.alertDetails.machineData.ipv4Address}<br /></span> : <span></span>}
//                <b>SLA:</b> {props.alertDetails.sla}<br />
//                <b>Severity:</b>  {capitalizeAndColorSeverity(props.alertDetails.baseAlert.severity)}<br />

//                <b>Confidence Level:</b> {props.alertDetails.baseAlert.incidentDetails.cl}<br />
//                {(props.alertDetails.type === "LinkReports") ? <span><b> Proposed Safety:</b> {props.alertDetails.baseAlert.incidentDetails.proposedSafety} <br /></span> : <span></span>}
//                <b>URL:</b> {accountForLinkLength(props.alertDetails.baseAlert.incidentDetails.url)}<br />

//            </div>
//        </div>
//    </Paper>
//</Col>
//<Col md="6">
//    <Paper id="alertLogContainer">
//        <div id="alertActionsHeader"><h4>Alert Actions</h4></div>
//        <div id="buttonContainerPadding">
//            <div id="buttonsGrid">

//                <Col md="6" className="d-flex flex-column align-items-center" >

//                    <AlertLogListPopover />
//                    <Button id="alertDetailsButton" variant="contained" className={classes.button} onClick={handleClick} color="primary" startIcon={<UpdateIcon />}>View Update Log</Button>
//                    <Button id="alertDetailsButton" variant="contained" className={classes.button} onClick={toggleEditAlertModal} color="primary" startIcon={<span id="alertDetailsButtonIcon"><EditIcon /></span>}>Edit Alert </Button>
//                    <Button id="alertDetailsButton" variant="contained" className={classes.button} onClick={toggleSetRuleModal} color="primary" startIcon={<span id="alertDetailsButtonIcon"><TrafficIcon /></span>}>Set Safety Rule</Button>

//                </Col>

//                <Col md="6" className="d-flex flex-column align-items-center">

//                    <Button id="alertDetailsButton" variant="contained" className={classes.button} onClick={backToTable} color="primary" startIcon={<span id="alertDetailsButtonIcon"><ArrowBackIcon /></span>}>Back To Table</Button>
//                    <Button id="alertDetailsButton" variant="contained" className={classes.button} onClick={alertUrlPush} color="primary" startIcon={<span id="alertDetailsButtonIcon"><LinkIcon /></span>}>Link Analysis</Button>
//                    <Button id="alertDetailsButtonDelete" variant="contained" className={classes.button} onClick={toggleAlertDeleteCheck} color="secondary" startIcon={<span id="alertDetailsButtonIcon"><DeleteIcon /></span>}>Delete Alert</Button>

//                </Col>

//            </div>
//        </div>
//    </Paper>
//</Col>
//            </Row >
