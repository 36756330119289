import React from 'react';

const Error = (props) => {
  return (
    <div>
      <div id="body">
        <p>
          We're sorry. There was an error. If this issue persists, please contact{' '}
          <a href="mailto:support@phishcloud.com">support@phishcloud.com</a>
        </p>
        <br />
        <p>{props.errorMessage != null ? props.errorMessage : ''}</p>
      </div>
    </div>
  );
};
export default Error;

//< p > { props.errorMessage != null ? props.errorMessage : "" }</p >
