import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import PropTypes from 'prop-types';
//-----Material Ui imports-----//
import { makeStyles, lighten } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import CompanyInfoMockData from './CompanyInfoMockData';
import basePCUrl from '../../../Globals';
var baseUrl = basePCUrl + 'UserData/GetUserSubmissionHistory';

const CompanyInfoUserRiskTable = (props) => {
  const [dateRange, setDateRange] = useState('sevenDays');
  const [userClickRows, setUserClickRows] = useState([
    {
      user: '',
      clicks: '',
      unknown: '',
      unsafe: '',
    },
  ]);

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  //------Null User Click Overview-----//
  const nullUserClickOverview = [
    {
      user: '',
      clicks: '',
      unknown: '',
      unsafe: '',
    },
  ];

  const userClickOverview = props.atRiskUsers;

  //-------Logic/Render-------//

  const handlePercentages = (value) => {
    if (value === NaN || value === 'NaN%') {
      return '0.00%';
    } else if (value != undefined) {
      const numberString = value.replace('%', '0');
      const number = parseFloat(numberString);
      return number.toFixed(2) + '%';
    } else {
      return;
    }
  };

  const removeEmailAddress = (email) => {
    return email.split('@')[0];
  };

  const columns = [
    {
      id: 'email',
      label: getTranslation('User', preferredLanguage),
      //minWidth: 100,
      //maxWidth: 120,
      width: '40%',
      align: 'left',
      format: (value) => value.toString(),
    },
    {
      id: 'clicks',
      label: getTranslation('Total Clicks', preferredLanguage),
      //minWidth: 30,
      // maxWidth: 30,
      width: '20%',
      align: 'left',
      format: (value) => value.toString(),
    },
    {
      id: 'unknownPercent',
      label: getTranslation('Unknown %', preferredLanguage),
      // minWidth: 30,
      // maxWidth: 30,
      width: '20%',
      align: 'left',
      format: (value) => value.toString(),
    },
    {
      id: 'unSafePercent',
      label: getTranslation('Unsafe %', preferredLanguage),
      // minWidth: 50,
      // maxWidth: 50,
      width: '10%',
      align: 'left',
      format: (value) => value.toString(),
    },
  ];

  const rowsForFilter = props.atRiskUsers.sort(function (a, b) {
    return b.clicks - a.clicks;
  });

  for (var i = rowsForFilter.length - 1; i >= 0; i--) {
    if (rowsForFilter[i].clicks === 0) {
      rowsForFilter.splice(i, 1);
    }
  }

  const rows = rowsForFilter;

  const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '100%',
      fontFamily: 'Montserrat, sans-serif',
    },
    container: {
      height: 200,

      width: '100%',
      //wordWrap: 'break-word',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
    tableHeader: {
      //backgroundColor: "#428BC9",
      backgroundColor: '#3252a8',
      color: 'white',
      width: 'inherit',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '12px',
      fontWeight: 600,
      padding: '10px',
    },
  });

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const renderTable = () => {
    if (props.atRiskUsers != null || undefined) {
      //const finalRows = Object.assign(props.atRiskUsers.map(v => ({ ['atRiskUsers']: v })));

      return (
        <div>
          <TableContainer className={classes.container}>
            <Table stickyHeader label="sticky table" style={{ tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className={classes.tableHeader}
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        fontWeight: '600',
                        fontFamily: 'Montserrat, sans-serif',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontWeight: '300', fontFamily: 'Montserrat, sans-serif' }}
                          >
                            {column.id === 'email' && value != undefined ? (
                              removeEmailAddress(value)
                            ) : column.id === 'clicks' && value != undefined ? (
                              value
                            ) : column.id === 'unknownPercent' && value != undefined ? (
                              handlePercentages(value)
                            ) : column.id === 'unSafePercent' && value != undefined ? (
                              handlePercentages(value)
                            ) : (
                              <span></span>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
           // labelRowsPerPage={<TranslationModule text={"Rows per Page"} targetLanguage={preferredLanguage}/>}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ fontWeight: '600', fontFamily: 'Montserrat, sans-serif' }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        className={classes.tableHeader}
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          fontWeight: '600',
                          fontFamily: 'Montserrat, sans-serif',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      );
    }
  };
  return <div>{renderTable()}</div>;
};
export default CompanyInfoUserRiskTable;
