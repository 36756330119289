import React, { useState, useEffect, useRef, createContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Auth0Lock from 'auth0-lock';

//------------Material UI------------//
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import SchoolIcon from '@material-ui/icons/School';

import NotificationsIcon from '@material-ui/icons/Notifications';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsIcon from '@material-ui/icons/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Dashboard.css';

const SideNavMobile = (props, location, history) => {
  const isActive = (value) => {
    if (location.pathname === value) {
      return 'activeSideNavLink';
    } else {
      return 'sideNavLink';
    }
  };

  const useMobileLandscapeStyles = makeStyles((theme) => ({
    root: {
      display: 'inline',
      height: '100vh',
      marginTop: '25px',
    },
    appBar: {
      backgroundColor: '#F4F7FC',
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'none',
      border: 'none',
    },
    menuButton: {
      marginRight: 20,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      minWidth: 20,
      maxWidth: 30,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      color: 'white',
    },
    drawerPaper: {
      marginTop: 75,
      width: 50,
      backgroundColor: '#061739',
      position: 'fixed',
      //height: "100vh",
      backgroundColor: theme.palette.sideNav.main,
      zIndex: 200,
    },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    list: {
      paddingTop: '10px',
      color: 'lightgrey',
      border: '1px, #061739',
      fontFamily: 'Montserrat, sans-serif',
      textDecoration: 'none',
      hover: {
        textDecoration: 'none',
      },
    },
  }));

  const classes = useMobileLandscapeStyles();

  //-----------Loading----------//
  const loadingStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const loadingClasses = loadingStyles();

  return (
    <div id="sideNavMobileLandscape" className={classes.root}>
      <CssBaseline />

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Divider />
        <div style={{ paddingTop: '70px' }}>
          <List className={classes.list} style={{ textDecoration: 'none' }}>
            {props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/AccountManagement')}
                key="MSSP"
                component={Link}
                to={'/Dashboard/AccountManagement'}
              >
                <ListItemIcon className={classes.list}>
                  <SecurityIcon />
                </ListItemIcon>
              </ListItem>
            ) : null}

            {props.accessLevel === 'AccountAdmin' || props.accessLevel === 'CompanyAdmin' ? (
              <ListItem
                button
                className={
                  props.accessLevel === 'AccountAdmin'
                    ? isActive('/Dashboard/CompanyOverviewAccountAdmin')
                    : isActive('/Dashboard/CompanyOverview')
                }
                key="CompanyOverview"
                component={Link}
                to={
                  props.accessLevel === 'AccountAdmin'
                    ? '/Dashboard/CompanyOverviewAccountAdmin'
                    : '/Dashboard/CompanyOverview'
                }
              >
                <ListItemIcon className={classes.list}>
                  <FontAwesomeIcon icon={['fa', 'chart-bar']} size="lg" />
                </ListItemIcon>
              </ListItem>
            ) : null}
          {props.training &&  <ListItem
              button
              className={isActive('/Dashboard/TrainingSuite')}
              key="Training"
              component={Link}
              to='/Dashboard/TrainingSuite'
            >
              <ListItemIcon className={classes.list} style={{ minWidth: 40 }}>
              <SchoolIcon />
              </ListItemIcon>
              <ListItemText>Training</ListItemText>
            </ListItem>}

            <ListItem
              button
              className={isActive('/Dashboard/History')}
              key="History"
              component={Link}
              to={'/Dashboard/History'}
            >
              <ListItemIcon className={classes.list}>
                <FontAwesomeIcon icon={['fas', 'history']} size="lg" />
              </ListItemIcon>
            </ListItem>
            {props.accessLevel === 'AccountAdmin' || props.accessLevel === 'CompanyAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/Alerts')}
                key="Alerts"
                component={Link}
                to={'/Dashboard/Alerts'}
              >
                <ListItemIcon className={classes.list}>
                  <NotificationsIcon />
                </ListItemIcon>
              </ListItem>
            ) : null}

            <ListItem
              button
              className={
                props.accessLevel === 'CompanyAdmin'
                  ? isActive('/Dashboard/AnalyzeLinkAdmin')
                  : isActive('/Dashboard/AnalyzeLink')
              }
              key={props.accessLevel === 'CompanyAdmin' ? 'AnalyzeLinkAdmin' : 'AnalyzeLink'}
              component={Link}
              to={props.accessLevel === 'CompanyAdmin' ? '/Dashboard/AnalyzeLinkAdmin' : '/Dashboard/AnalyzeLink'}
            >
              <ListItemIcon className={classes.list}>
                <FontAwesomeIcon icon={['fas', 'link']} size="lg" />
              </ListItemIcon>
            </ListItem>

            {props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/AdminConfiguration')}
                key="AdminConfiguration"
                component={Link}
                to={'/Dashboard/AdminConfiguration'}
              >
                <ListItemIcon className={classes.list}>
                  <FontAwesomeIcon icon={['fas', 'cog']} size="lg" />
                </ListItemIcon>
              </ListItem>
            ) : null}

            {/* <ListItem
              button
              className={isActive('/Dashboard/Configuration')}
              key="Configuration"
              component={Link}
              to={'/Dashboard/Configuration'}
            >
              <ListItemIcon className={classes.list}>
                <FontAwesomeIcon icon={['fas', 'sliders-h']} size="lg" />
              </ListItemIcon>
            </ListItem> */}

            {props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/LinkSafetyRules')}
                key="LinkSafetyRules"
                component={Link}
                to={'/Dashboard/LinkSafetyRules'}
              >
                <ListItemIcon className={classes.list}>
                  <FontAwesomeIcon icon={['fa', 'traffic-light']} size="lg" />
                </ListItemIcon>
              </ListItem>
            ) : null}

            {props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin' ? (
              <ListItem
                button
                className={isActive('/Dashboard/UserManagement')}
                key="UserManagement"
                component={Link}
                to={'/Dashboard/UserManagement'}
              >
                <ListItemIcon className={classes.list}>
                  <FontAwesomeIcon icon={['fas', 'users']} size="lg" />
                </ListItemIcon>
              </ListItem>
            ) : null}
          </List>
        </div>
      </Drawer>
    </div>
  );
};
export default SideNavMobile;
