import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

//------Material UI-----//
import { makeStyles } from '@material-ui/core/styles';
//import SchoolIcon from '@material-ui/icons/School';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TrainingItemCard from './TrainingItemCard';
import TrainingTotalsWidget from './TrainingTotalsWidget';
import basePCUrl from '../../../Globals';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';

//----Images/Assets----//
import TrainingImagePlaceholder from './Images/TrainingImagePlaceholder.png';

//----CSS/Styling Sheet----//
import './TrainingSuite.css';

const trainingAPI = 'https://zsvz1vz9rg.execute-api.us-west-2.amazonaws.com/prod';
const TrainingSuite = (props) => {
  useChangePageTitle('TrainingSuite');
  const {
    courseData: { isLoading, data, isError, error },
  } = props;
  //const [isLoading, setIsLoading] = useState();
  //const [data, setTrainingData] = useState(data);

  const [trainingStatus, setTrainingStatus] = useState([]);
  const [modId, setModId] = useState([]);

  const [trainingSetStatusArray, setTrainingSetStatusArray] = useState([]);
  const [modIdArray, setModIdArray] = useState([]);

  const [finalTrainingSettingsList, setFinalTrainingSettingsList] = useState();

  const { getAccessTokenSilently } = useAuth0();
  const clientId = props.selectedClient;

  //-------Settings Saved Message------//
  const ShowSuccessMessage = (msg) => {
    let x = document.getElementById('successMessage');
    x.innerHTML = msg;
    x.className = 'show';
    setTimeout(function () {
      x.className = x.className.replace('show', '');
    }, 7000);
  };

  //-----Grid Styling-----//
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    headerPaper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      alignContent: 'center',
      color: theme.palette.text.secondary,
    },
  }));

  const classes = useStyles();

  return (
    <div className="traininig-suite-container">
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.headerPaper}>
            <Typography variant="h5" component="h3">
            Training Suite
            </Typography>

            <br />

            <Grid container direction="row">
              <Grid item align="center" xs={12} sm={4} md={4} lg={4}>
                <TrainingTotalsWidget widgetNumber={data?.numberAssigned || 0} widgetTitle={'Assigned'} />
              </Grid>
              <Grid item align="center" xs={12} sm={4} md={4} lg={4}>
                <TrainingTotalsWidget widgetNumber={data?.numberInProgress || 0} widgetTitle={'In Progress'} />
              </Grid>
              <Grid item align="center" xs={12} sm={4} md={4} lg={4}>
                <TrainingTotalsWidget widgetNumber={data?.numberCompleted || 0} widgetTitle={'Completed'} />
              </Grid>
            </Grid>

            <br />
          </Paper>
        </Grid>

        {isLoading === false && data !== undefined ? (
          data.trainingItems.map((training, idx) => (
            <TrainingItemCard
              key={idx}
              title={training.description}
              status={training.status}
              timeEstimate={Math.floor(training.timeEstimate / 60) + ' minutes'}
              image={training.thumbnail}
              description={''}
              modId={modIdArray}
              accessibleTitle={training.description}
              trainingDetails={training.modId}
              history={props.history}
              accessLevel={props.accessLevel}
            />
          ))
        ) : (
          <span></span>
        )}
      </Grid>

      <br />
      <br />
      <div id="successMessage" />
      <Button variant="contained" color="primary">
        Save Training Settings
      </Button>
    </div>
  );
};
export default TrainingSuite;
