import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import basePCUrl from '../../../Globals';
import { useQuery } from 'react-query';
import { fetchLinkAnalysisData } from '../../../utils/service';
var baseV2Url = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';


export const useFetchLinkAnalysisAdmin = (finalUrl) => {
  //const [data, setData] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState({
    data: [],
    domainDetails: [],
    isLoading: true,
  });

  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  const fetchLinkAnalysisAdmin = async () => {
    setState({ data: null, domainDetails: null, isLoading: true });

    const token = await getAccessTokenSilently();

    if (localStorage.getItem('usid')) {
      var usid = localStorage.getItem('usid');
    var headers = {
      user_id: usid,
      Action: 'linkAnalysis',
       link: finalUrl,
       //...(queryId && { id: queryId }),
       //'Content-Type': 'application/json',
     }
    } else {
      var headers = {
        Authorization: `Bearer ${token}`,
        Action: 'linkAnalysis',
         link: finalUrl,
         //'Content-Type': 'application/json',
       }
    }

    fetch(baseV2Url, {
      method: 'get',
      headers: headers,
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {

          setState({
            data: data,
            domainDetails: data.domainDetails,
            isLoading: false,
          });

        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  useEffect(() => {
    setState({ data: null, domainDetails: null, isLoading: null });

    if (!isMount)  {
      fetchLinkAnalysisAdmin();
    }
  }, [finalUrl]);

  return state;
};
export default useFetchLinkAnalysisAdmin;
