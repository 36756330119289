import React, { useEffect, useState } from 'react';
import { Paper, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";

import TrainingCompleteModal from './TrainingCompleteModal';
import TrainingFailModal from './TrainingFailModal';
import { ContactsOutlined, SettingsCell } from '@material-ui/icons';
import Cookies from 'js-cookie';

const UpdatedTrainingQuiz = ({ quizData, trainingId, trainingVideo, history }) => {
  const totalQuestions = Object.keys(quizData).length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [failModal, setFailModal] = useState(false);

  const handleAnswerSelect = (event) => {
    setSelectedAnswer(event.target.value);
    setIsNextDisabled(false);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer === null) {
      return;
    }

    setUserAnswers([...userAnswers, parseInt(selectedAnswer)]);

    if (selectedAnswer === quizData[currentQuestion].AnswerKey.toString()) {
      setScore(score + 1);
    }

    if (currentQuestion < totalQuestions) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(null);
      setIsNextDisabled(true);
    } 
  };

  const submitFinalAnswers  =()=>{
      saveTrainingProgress([...userAnswers, parseInt(selectedAnswer)]);
  }

  
  const isLastQuestion = currentQuestion === totalQuestions;


  function isQuizPassed(userAnswers) {
    const correctAnswersCount = userAnswers.filter((answer, index) => {
      // Compare user's answer to the correct answer for each question (Assuming 0-based index)
      return answer === quizData[index + 1].AnswerKey
    }).length;

    return correctAnswersCount >= 8;
  }

var correctAnswersCount

  function calculateScore(quizData, userAnswers) {
    const totalQuestions = Object.keys(quizData).length;
    correctAnswersCount = 0;
  
    for (let i = 1; i <= totalQuestions; i++) {
      if (userAnswers[i - 1] === quizData[i].AnswerKey) {
        correctAnswersCount++;
      }
    }
    const percentageScore = ((correctAnswersCount / totalQuestions) * 100).toFixed(1);
    return parseFloat(percentageScore);
  }

  function setTrainingCookie(trainingId, trainingStatus) {
    const expirationTimeInMinutes = 30;
    const expirationTimeInMilliseconds = expirationTimeInMinutes * 60 * 1000;
  
    // Set the cookie with the calculated expiration time
    Cookies.set(trainingId, trainingStatus, { expires: new Date(Date.now() + expirationTimeInMilliseconds) });
  }
  

  const toggle = () => {
    setModal(!modal);
  };

  const failToggle = () => {
    setFailModal(!failModal);
    window.scrollTo({ top: 100, left: 100, behavior: 'smooth' });
  };

  function markTrainingAsComplete(trainingTitle) {
    // Store the training title with the value "complete" in local storage
    localStorage.setItem(trainingTitle, "complete");
    console.log(`Training "${trainingTitle}" marked as complete.`);
}

  const { getAccessTokenSilently } = useAuth0();
  const quizPassed = isQuizPassed(userAnswers);
  const saveTrainingProgress = async (finalQuizAnswers) => {
    window.scrollTo({ top: 100, left: 100, behavior: 'smooth' });
    
    if (calculateScore(quizData, finalQuizAnswers) >= 80){
      setModal(true);
      setTrainingCookie(trainingId, 'Completed')
    } else {
      setFailModal(true);
    }
    // if (quizPassed === true) {
    //   setModal(true);
    // } else {
    //   setFailModal(true);
    // }

    const token = await getAccessTokenSilently();
    var api = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

    var data = {
      modId: trainingId,
      quizResults: finalQuizAnswers,
      videoProgress: 0,
    };

    fetch(api, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        Action: 'updateUserTraining',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          return;
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  return (
    <Paper elevation={0} className="quiz-container" style={{ padding: '20px' }}>
      <Typography variant="h4">Question {currentQuestion} of {Object.keys(quizData).length}</Typography>
      <br/>
      <Typography variant="h5">{quizData[currentQuestion].Question}</Typography>
      <RadioGroup style={{marginLeft: '2rem'}} name="answer" value={selectedAnswer} onChange={handleAnswerSelect}>
        {quizData[currentQuestion].AnswerList.map((answer, index) => (
          <FormControlLabel key={index} value={index.toString()} control={<Radio />} label={answer} />
        ))}
      </RadioGroup>
      <br/>
      {isLastQuestion ? (
        <Button variant="contained" color="primary" onClick={() => {

          submitFinalAnswers();
        }}>
          Submit
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleNextQuestion} disabled={isNextDisabled}>
          Next
        </Button>
      )}
      <TrainingCompleteModal 
      toggle={toggle} 
      modal={modal} 
      history={history} 
      scorePercentage={calculateScore(quizData, [...userAnswers, parseInt(selectedAnswer)])}
      quizLength={Object.keys(quizData).length}
      correctAnswersCount={correctAnswersCount} />
      <TrainingFailModal
        toggle={failToggle}
        modal={failModal}
        history={history}
        trainingId={trainingId}
        trainingVideo={trainingVideo}
        setCurrentQuestion={setCurrentQuestion} // Reset to the first question
        setSelectedAnswer={setSelectedAnswer} // Clear the selected answer
        setUserAnswers={setUserAnswers} // Clear the user's previous answers
        setScore={setScore} // Reset the score to 0
        setIsNextDisabled={setIsNextDisabled}
        scorePercentage={calculateScore(quizData, [...userAnswers, parseInt(selectedAnswer)])}
        quizLength={Object.keys(quizData).length}
        correctAnswersCount={correctAnswersCount}
      />
    </Paper>
  );
};

export default UpdatedTrainingQuiz;
