import React, { useEffect, useState } from 'react'
import { Route, withRouter } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import EmailLogin from '../../EmailLogin/EmailLogin';
import Auth0SpaLogin from '../../Login/auth0SpaLogin';
import LoginCallback from '../../LoginCallback';

const PrivateRoute = ({ component: Component, path, location, accessLevel, ...rest }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const { getAccessTokenSilently } = useAuth0();

  const [isDelayed, setIsDelayed] = useState(true);
  const [delayCount, setDelayCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelayed(false);
    }, 2000);

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []);


  // const fn = async () => {
  //   await loginWithRedirect({
  //     appState: { targetUrl: path },
  //   });
  // };

  // useEffect(() => {
  //   if (loading || (isAuthenticated && accessLevel != undefined)) {
  //     return;
  //   }

  //   if (location !== undefined && location.pathname === path && !isAuthenticated) {
      
  //     fn();
  //   }
  // }, [loading, isAuthenticated, loginWithRedirect, path]);

  const checkAccessLevel = (accessLevel) => {
    if (accessLevel === 'User') {
      return true;
    } else if (accessLevel === 'CompanyAdmin') {
      return true;
    } else if (accessLevel === 'AccountAdmin') {
      return true;
    } else {
      return false;
    }
  };

  if (!isAuthenticated && isLoading) {
    localStorage.setItem('intendedRoute', window.location.pathname + window.location.search);
  }

  // let returnAuth0SpaLogin =()=> {
    
  //   return()
  // }

  // let waitToLogin =()=> {
  //   console.log("trying")
  //   setTimeout(returnAuth0SpaLogin, 2000);
    
  // }
 
  const render = (props) => (
    isAuthenticated === true ? <Component {...props} /> : <Auth0SpaLogin history={props.history} />
  );

  return (
    <Route
      path={path}
      render={(props) => isDelayed ? null : render(props)}
      {...rest}
    />
  );
};

export default PrivateRoute;
