


const translateText = async (text, languageCode, token) => {

  //const storageKey = `translation_${languageCode}_${text}`;
  //const storedTranslation = localStorage.getItem(storageKey);

  function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/`;
  }
  
  function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? decodeURIComponent(cookieValue[2]) : null;
  }
  
  const storageKey = `translation_${languageCode}_${text}`;
  const storedTranslation = getCookie(storageKey);

  
  // To set the value as a cookie
  
  //console.log(storedTranslation)

  if (storedTranslation) {
    return storedTranslation;
  } else {

  let url = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2'
    var dataForTranslation = {
      textToTranslate: text,
      targetLanguage: languageCode
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
      body: JSON.stringify(dataForTranslation),
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`,
        'Action': 'translateText',
        'Content-Type': 'application/json',
      },
        });
    
        const data = await response.json();
        if (data.translatedText) {
          //localStorage.setItem();
          //setCookie(storageKey, data.translatedText, 30);

          return data.translatedText;
        } else {
          throw new Error('Translation failed.');
        }
      } catch (error) {
        console.error('Translation error:', error);
        return text; // Return the original text if translation fails
      }  

    }
  }; export default translateText