import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useQuery } from 'react-query';
import { useAuth0 } from "@auth0/auth0-react";

import { DateCreate } from './../../DateCreate/DateCreate';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
//import './TrainingSuite.css';



const AssignTrainingTable =(props)=> {

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');

  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);

const getTranslation = props.getTranslation
const preferredLanguage = props.preferredLanguage

  // const [values, handleFilterChange] = useFilterForm({
  //   filter: '',
  // });

  const [list, setList] = useState();

  const handleChange = (e, index) => {
    const updatedList = [...list];
    updatedList[index].name = e.target.value;
    setList(updatedList);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '@media(max-width:600px)': {
        width: '80%',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    container: {
      maxHeight: 400,
      '@media(max-width:600px)': {
        maxHeight: '200px',
      },
    },
    table: {
      minWidth: 750,
      '@media(max-width:820px)': {
        minWidth: 550,
      },
    },
    tableCell: {
      fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    searchInput: {
      width: '300px',
  height: '40px',
  paddingLeft: '10px',
  paddingRight: '50px',
  border: '3px solid lightgray',
  borderRadius: '20px',
    },
    search:{  marginLeft: '-50px',
    paddingTop: '5px',
    height: '38px',
    width: '50px',
    color: 'lightgray'

    }
  }));

  const classes = useStyles();

  const theme = useTheme();

  let usertrainingData = props.usertrainingData

 
  const headCells = [
    { id: 'title', numeric: false, disablePadding: false, label:  getTranslation('Course Title', preferredLanguage) },
    { id: 'timeEstimate', numeric: false, disablePadding: false, label:  getTranslation('Estimated Completion Time',props.preferredLanguage) },
  ];

  const useHeaderStyles = makeStyles((theme) => ({
    tableHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      textAlign: 'center'
    },
    tableHeaderType: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      maxWidth: 20,
    },
  }));

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headerClasses = useHeaderStyles();

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={headerClasses.tableHeader}
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };



   

  //----------Handle Sorting-----------//
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


   //--------------rows & Table Logic--------------//

   let rows = props.props.courseList
   function desc(a, b, orderBy) {
     if (!a[orderBy] && !b[orderBy]) {
       return 0; // Both values are undefined, consider them equal.
     }
     if (!b[orderBy]) {
       return -1; // 'b' is undefined, consider 'a' greater.
     }
     if (!a[orderBy]) {
       return 1; // 'a' is undefined, consider 'b' greater.
     }
 
     if (b[orderBy] < a[orderBy]) {
       return -1;
     }
     if (b[orderBy] > a[orderBy]) {
       return 1;
     }
     return 0;
   }
 
   function stableSort(array, cmp) {
     const stabilizedThis = array.map((el, index) => [el, index]);
     stabilizedThis.sort((a, b) => {
       const order = cmp(a[0], b[0]);
       if (order !== 0) return order;
       return a[1] - b[1];
     });
     return stabilizedThis.map((el) => el[0]);
   }
 
   function getSorting(order, orderBy) {
     return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
   }
 

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      
        {/* <EnhancedTableToolbar /> */}
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.title }
                    >

                      <TableCell align="left" className={classes.tableCell}>
                        {row.title}
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        {(Math.floor(row.timeEstimate / 60) || 0) + ' minutes'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

    </div>
  );
}; export default AssignTrainingTable
