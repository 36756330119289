import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import GpoMessage from './EmailVerifiedMessages/GpoMessage';
import ManualMessage from './EmailVerifiedMessages/ManualMessage';
import InstalledMessage from './EmailVerifiedMessages/InstalledMessage';
import './Success.css';
import Auth0Lock from 'auth0-lock';
import basePCUrl from '../../../Globals';

const SuccessEmailVerification = (props) => {
  const { getUserInfo, loading } = useAuth0();

  const [accessToken, setAccessToken] = useState();

  const [error, setError] = useState(0);

  const [email, setEmail] = useState();

  const [emailVerified, setEmailVerified] = useState(false);

  const [alreadySentSuccess, setAlreadySentSuccess] = useState(false);

  const [installType, setInstallType] = useState();

  const [seconds, setSeconds] = useState(5);

  const SendSignUpSuccess = async (parsedEmail) => {
    var data = {
      email: `${parsedEmail}`,
    };

    fetch(basePCUrl + 'Signup/userSignupSuccess', {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        //Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          console.log(data);
          console.log(data.status);
          if (data.status === 'Error') {
            console.log('Error');
          } else {
            return;
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const searchParams = new URLSearchParams(props.location.search);
const parsedEmail = searchParams.get('email');
const message = searchParams.get('message');
const success = searchParams.get('success');


  function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) setInstallType(c.substring(nameEQ.length, c.length));
      console.log(c.substring(nameEQ.length, c.length));
    }
    return null;
  }

  useEffect(() => {
    if (!loading) {
      setEmail(parsedEmail);
      setError(false);
      console.log({ errorIs: error });

      if (
        !alreadySentSuccess &&
        (success === 'true' ||
          message === 'This URL can be used only once' ||
          message === 'This account is already verified.')
      ) {
        setEmailVerified(true);
        setAlreadySentSuccess(true);
        console.log('send success message');
        SendSignUpSuccess(parsedEmail);
      } else {
        setError(true);
      }
      //setInstallType(localStorage.getItem('installType'))
      readCookie('installType');
      var cookie = document.cookie;
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        window.location.replace('https://portal.phishcloud.io/BrowserCheck');
      }
    }
  });

  const SuccessMessage = () => {
    return (
      <div>
        {' '}
        <h2 id="result">Email Successfully Registered!</h2>
        We will redirect you to the extension store in {seconds} seconds.
        <br />
        <br />
        If you are not automatically redirected, please follow this link to the{' '}
        <a href="https://portal.phishcloud.io/BrowserCheck">extension store</a>.
      </div>
    );
  };

  //console.log(errorMessageRedirect)

  const ErrorMessage = () => {
    if (parsedEmail != undefined) {
      return (
        <div>
          <b>
            <p>
              Something Went Wrong. Please contact <a href="mailto:support@phishcloud.com">suport@phishcloud.com</a> if
              this issue persists.
            </p>
          </b>
          <br></br>
          <br></br>

          <p>
            If you have already installed phishcloud, go to <a href="https://phishcloud.io/">https://phishcloud.io/</a>{' '}
            to see your statistics or manage users(Admins only).
          </p>

          <br></br>
          <br></br>
          <br></br>
          <p>Error Message: {message}</p>
        </div>
      );
    }
  };

  const BodyRender = () => {
    if (error === true) {
      return ErrorMessage();
    } else if (error == false && emailVerified == true) {
      return SuccessMessage();
    } else {
      return <div>Loading...</div>;
    }
  };

  return <div id="resultBody">{BodyRender()}</div>;
};
export default SuccessEmailVerification;
