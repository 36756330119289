import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactUs.css';
import { Button, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import commentAlt from '@iconify/icons-fa-regular/comment-alt';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';

const ContactUs = () => {
  useChangePageTitle('ContactUs');

  return (
    <div class="contactUs">
      <h2 class="greeting">Contact Us</h2>

      <p id="body">
        Email us at: {''}{' '}
        <u>
          {' '}
          <span id="linkToEmail">
            <NavLink tag={Link} color="primary" to="/email">
              {' '}
              support@phishcloud.com
            </NavLink>
          </span>{' '}
        </u>
      </p>

      <p id="body">
        Or chat with us on our slack beta channel: {''}{' '}
        <Button className="go" color="WhiteSmoke">
          {' '}
          <span id="chatIcon">
            {' '}
            <Icon icon={commentAlt} />
            <NavLink tag={Link} className="text-dark" to="/chat">
              <b>Chat Now</b>{' '}
            </NavLink>{' '}
          </span>
        </Button>
      </p>
    </div>
  );
};
export default ContactUs;
