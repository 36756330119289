import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import './LoadingSpinner.css';

export class LoadingSpinner extends Component {
  render() {
    return (
      <div className={this.props.box}>
        {/* <h3>{this.props.caption}</h3> */}
        <CircularProgress id={this.props.className} />
      </div>
    );
  }
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  box: PropTypes.string,
};
