import React, { useEffect, useState } from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from '../CustomHooks/useForm';

const ResendActivationModal = (props) => {
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: 500,
      height: 70,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const resendActivation = () => {
    props.handleSendActivations();
    props.toggle();
  };

  return (
    <Paper>
      <Dialog
        className={styles.root}
        onClose={props.toggle}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.toggle} />

        <ModalBody>
          <h2 className="resendActivationModalLabel">{getTranslation('Resend Activation Instructions?', preferredLanguage)}</h2>
          <br></br>

          <div className="resendActivationButton">
            <Button
              block
              onClick={resendActivation}
              id="deleteAllSelected"
              fullWidth="true"
              variant="contained"
              color="primary"
            >
              {getTranslation('Yes', preferredLanguage)}
            </Button>
          </div>
        </ModalBody>
      </Dialog>
    </Paper>
  );
};
export default ResendActivationModal;

//< div className = "ModalForm" >

//    <FormGroup check >
//        <input check style={{ marginLeft: '5px' }} type="checkbox" checked={props.isUserDeleteChecked} onClick={() => props.setIsUserDeleteChecked(!props.isUserDeleteChecked)}  ></input>
//        <Label check style={{ fontSize: '14px', marginLeft: '8px', padding: '5px' }} for="confirmDeleteCheck">Yes, I want to resend activation instructions to these users.</Label>
//    </FormGroup>

//                </div >
