import { useState } from 'react';

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const reset = () => setValues('');

  return {
    values,
    setValues,
    reset: () => setValues(''),
    bind: {
      values,
      onChange: (event) => {
        setValues(event.target.value);
      },
    },
  };
};
export default useForm;
