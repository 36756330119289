import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import './BoxWidget.css';

const BoxWidget = (props) => {
  return (
    <Paper
      className="BoxWidgetRedone"
      elevation={props.elevation}
      style={{
        height: props.height,
        width: props.width,
        padding: props.padding,
        backgroundColor: props.backgroundColor,
        border: props.border,
        boxShadow: props.boxShadow,
      }}
    >
      <div id="boxContainer">
        <div style={{ backgroundColor: props.circleColor }}>
          <div id="boxIcon" style={{ marginTop: props.iconMarginTop, fontSize: props.iconSize }}>
            {props.icon}
          </div>
          <div id="boxHeader">{props.boxHeader}</div>
        </div>

        <div className="boxContent">
          <span id="boxNumber">{props.number}</span>
          <br />
          <span id="boxText">{props.text}</span>
        </div>
      </div>
    </Paper>
  );
};
export default BoxWidget;
