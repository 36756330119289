import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { DateCreate } from './../DateCreate/DateCreate';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send'; //For re-sending activation
import { Row, Col } from 'reactstrap';
import UserDeleteCheckModal from './UserDeleteCheckModal';
import EditUserModal from './EditUserModal';
import ResendActivationModal from './ResendActivationModal';
import basePCUrl from '../../../Globals';

const UserManagementTable = (props) => {
  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const [userDeleteCheckModal, setUserDeleteCheckModal] = useState(false);
  const toggleUserDeleteCheck = () => {
    setUserDeleteCheckModal(!userDeleteCheckModal);
    setIsUserDeleteChecked(false);
    setErrorMessage('');
  };
  const [isUserDeleteChecked, setIsUserDeleteChecked] = useState(false);

  const [editUserModal, setEditUserModal] = useState(false);
  const toggleEditUserModal = () => setEditUserModal(!editUserModal);

  const [userResendActivationModal, setUserUserResendActivationModal] = useState(false);
  const toggleResendActivationModal = () => setUserUserResendActivationModal(!userResendActivationModal);

  const [selectedUsersEmail, setSelectedUsersEmail] = useState([]);
  const [usersForEdit, setUsersForEdit] = useState([]);

  const [selected, setSelected] = React.useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const uid = props.uid;
  const clientId = props.clientId;
  const preferredLanguage = props.preferredLanguage;
  const getTranslation = props.getTranslation;

  const checkUserType = (value) => {
    if (value === 'CompanyAdmin') {
      return 'Company Admin';
    } else {
      return value;
    }
  };
  //-----------------Delete Users------------------//

  //-----Get list of selected emails-----//
  useEffect(() => {
    if (selected != undefined) {
      setSelectedUsersEmail(selected.map((value) => value.email));
    }
  }, [selected]);

  //------Get list of selected emails and accessLevels------//
  useEffect(() => {
    if (selected != undefined) {
      setUsersForEdit(selected.map((value) => (value.email, value.accessLevel)));
    }
  }, [selected]);

  //-----Capitalize Access Level-----//
  const capitalizeAccessLevel = (accessLevel) => {
    return accessLevel.charAt(0).toUpperCase() + accessLevel.slice(1);
  };

  //-----Delete Users API call-----//

  const deleteMultipleUsers = async () => {
    const token = await getAccessTokenSilently();

    var data = selectedUsersEmail;

    fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
      method: 'POST',
      headers: {
        action: 'deleteUser',
        'content-type': 'application/json',
        id: props.clientId,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          //setErrorMessage(data.status);
          if (clientId !== undefined) {
            setTimeout(props.retrieveUsers(clientId), 10000);
          } else {
            setTimeout(props.retrieveUsers(''), 10000);
          }
          setSelected([]);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const completeDeletion = () => {
    deleteMultipleUsers();
    //sendDeActivationsToUsers();
  };

  //------Tried to delete yourself error message-----//
  const displayCannotDeleteYourselfMessage = () => {
    if (errorMessage.includes('CannotDeleteYourself')) {
      return (
        <h5 style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>
          {getTranslation('Error: You cannot delete yourself', preferredLanguage)} .
        </h5>
      );
    }
  };

  //------Tried to delete the only admin error message-----//
  const displayCannotDeleteOnlyAdminMessage = () => {
    if (errorMessage.includes('CannotDeleteOnlyAdmin')) {
      return (
        <h5 style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>
          {getTranslation(' Error: You cannot delete the only company admin', preferredLanguage)} .
        </h5>
      );
    }
  };

  //-------FOR TESTING - Test for displaying error messages - NOT IN USE------//
  const testErrorMessageMethod = () => {
    if (errorMessage.includes('ok')) {
      return <h5 style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>This test was successful.</h5>;
    }
  };

  //------------Check for Errors on submission/API call to delete users-------------//
  useEffect(() => {
    if (errorMessage.includes('CannotDeleteOnlyAdmin')) {
      displayCannotDeleteOnlyAdminMessage();
    } else if (errorMessage.includes('CannotDeleteYourself')) {
      displayCannotDeleteYourselfMessage();
    } else if (errorMessage.includes('ok')) {
      toggleUserDeleteCheck();
      testErrorMessageMethod();
      props.retrieveUsers();
    } else if (errorMessage === 'Error') {
      toggleUserDeleteCheck();
      Error();
    }
    //console.log("errorMessage useEffect is working and returns" + errorMessage)
  }, [errorMessage]);

  //----------------Resend Activations---------------//
  const resendActivationsToUsers = async () => {
    const token = await getAccessTokenSilently();

    var data = selectedUsersEmail;

    fetch('https://portalapi-v2.phishcloud.io/api/portalapi-v2', {
      method: 'POST',
      headers: {
        action: 'resendEmail',
        'content-type': 'application/json',
        testacct: uid,
        //uid,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }

        response.json().then((data) => {
          //setErrorMessage(data.status);
          toggleResendActivationModal();
          setSelected([]);
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  //-----------Send DeActivation Email Users - NOT IN USE-----------//
  const sendDeActivationsToUsers = async () => {
    const token = await getAccessTokenSilently();

    var data = {
      listOfEmails: selectedUsersEmail,
    };

    fetch(basePCUrl + 'Actions/EmailUserDeActivation', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);

          return;
        }

        response.json().then((data) => {
          if (data.status === 'Error') {
            console.log('resend Activation Instruction Error');
          } else {
            return;
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  //--------------rows & Table Logic--------------//

  let rows = props.rows;
  function desc(a, b, orderBy) {
    if (!a[orderBy] && !b[orderBy]) {
      return 0; // Both values are undefined, consider them equal.
    }
    if (!b[orderBy]) {
      return -1; // 'b' is undefined, consider 'a' greater.
    }
    if (!a[orderBy]) {
      return 1; // 'a' is undefined, consider 'b' greater.
    }

    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const headCells = [
    //{ id: 'name', numeric: false, disablePadding: true, label: '' },
    { id: 'email', numeric: false, disablePadding: false, label: getTranslation('User Email', preferredLanguage) },
    { id: 'accessLevel', numeric: false, disablePadding: false, label: getTranslation('Access Level', preferredLanguage) },
    { id: 'dateCreated', numeric: false, disablePadding: false, label: getTranslation('Date Created', preferredLanguage) },
    { id: 'lastActiveDate', numeric: false, disablePadding: false, label: getTranslation('Last Active', preferredLanguage) },
  ];

  const useHeaderStyles = makeStyles((theme) => ({
    tableHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
    },
    tableHeaderType: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      maxWidth: 20,
    },
  }));

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headerClasses = useHeaderStyles();

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rules' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className={headerClasses.tableHeader}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
      '@media(max-width:820px)': {
        height: '28px',
      },
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    largeButton: {
      margin: theme.spacing.unit,
      //padding: 20
    },
    icons: {
      margin: theme.spacing.unit,
      '@media(max-width:830px)': {
        fontSize: 20,
      },
    },
    title: {
      flex: '1 1 100%',
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} {getTranslation('selected', preferredLanguage)}
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle"></Typography>
        )}

        {numSelected === 0 || numSelected > 1 ? (
          <IconButton
            className={classes.icons}
            disabled="true"
            //className={classes.largeButton}
            aria-label="edit"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        ) : (
          ''
        )}

        {numSelected === 0 ? (
          <IconButton
            className={classes.icons}
            disabled="true"
            // className={classes.largeButton}
            aria-label="send activation"
          >
            <SendIcon fontSize="inherit" />
          </IconButton>
        ) : (
          ''
        )}

        {numSelected === 0 ? (
          <IconButton
            className={classes.icons}
            disabled="true"
            //className={classes.largeButton}
            aria-label="delete"
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ) : (
          ''
        )}

        {numSelected === 1 ? (
          <Tooltip placement="bottom" title={getTranslation('Edit', preferredLanguage)}>
            <IconButton
              className={classes.icons}
              onClick={toggleEditUserModal}
              //className={classes.largeButton}
              aria-label="edit"
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip placement="bottom" title={getTranslation('Resend Activation', preferredLanguage)}>
            <IconButton
              className={classes.icons}
              onClick={toggleResendActivationModal}
              //className={classes.largeButton}
              aria-label="delete"
            >
              <SendIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip placement="bottom" title={getTranslation('Delete', preferredLanguage)}>
            <IconButton
              className={classes.icons}
              onClick={toggleUserDeleteCheck}
              //className={classes.largeButton}
              aria-label="delete"
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '@media(max-width:600px)': {
        width: '80%',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    container: {
      maxHeight: 400,
      '@media(max-width:600px)': {
        maxHeight: '200px',
      },
    },
    table: {
      minWidth: 750,
      '@media(max-width:820px)': {
        minWidth: 550,
      },
    },
    tableCell: {
      fontFamily: 'Montserrat, sans-serif',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('dateCreated');

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, dateCreated) => {
    const selectedIndex = selected.indexOf(dateCreated);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, dateCreated);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (dateCreated) => selected.indexOf(dateCreated) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //onClick={event => handleClick(event, row.dateCreated)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.email}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {row.email != undefined ? (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(event) => handleClick(event, row)}
                          />
                        ) : (
                          <span></span>
                        )}
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        {row.email}
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        {row.accessLevel}
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        {row.dateCreated != undefined ? <DateCreate date={row.dateCreated} /> : <span></span>}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {row.lastActiveDate != undefined && row.lastActiveDate !== row.dateCreated ? (
                          <DateCreate date={row.lastActiveDate} />
                        ) : (
                          <span>{getTranslation('Never logged in', preferredLanguage)}</span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <UserDeleteCheckModal
        isOpen={userDeleteCheckModal}
        toggle={toggleUserDeleteCheck}
        handleDeletion={completeDeletion}
        isUserDeleteChecked={isUserDeleteChecked}
        setIsUserDeleteChecked={setIsUserDeleteChecked}
        displayCannotDeleteOnlyAdminMessage={displayCannotDeleteOnlyAdminMessage}
        displayCannotDeleteYourselfMessage={displayCannotDeleteYourselfMessage}
        testErrorMessageMethod={testErrorMessageMethod}
        callRoute={props.callRoute}
        requestType={props.requestType}
        accessLevel={props.accessLevel}
        clientID={props.clientId}
        preferredLanguage={preferredLanguage}
        getTranslation={getTranslation}
      />
      <EditUserModal
        isOpen={editUserModal}
        toggle={toggleEditUserModal}
        selected={selectedUsersEmail[0]}
        retrieveUsers={props.retrieveUsers}
        resetSelected={setSelected}
        callRoute={props.callRoute}
        requestType={props.requestType}
        accessLevel={props.accessLevel}
        clientID={props.clientId}
        preferredLanguage={preferredLanguage}
        getTranslation={getTranslation}
      />

      <ResendActivationModal
        isOpen={userResendActivationModal}
        toggle={toggleResendActivationModal}
        handleSendActivations={resendActivationsToUsers}
        isUserDeleteChecked={isUserDeleteChecked}
        setIsUserDeleteChecked={setIsUserDeleteChecked}
        callRoute={props.callRoute}
        requestType={props.requestType}
        accessLevel={props.accessLevel}
        clientID={props.clientId}
        preferredLanguage={preferredLanguage}
        getTranslation={getTranslation}
      />
    </div>
  );
};
export default UserManagementTable;
