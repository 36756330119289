import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import classnames from 'classnames';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    overflow: 'hidden',
  },
  
}));

const CircularIndeterminate =()=> {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={200} />
    </div>
  );
}

const NewUserLoadingModal = (props) => {
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      //width: 500,
      //height: 70,
      textAlign: 'center',
      //backgroundColor: rgba(255, 255, 255, 0),
      overflow: 'hidden',
    },
    // paper: {
    //     backgroundColor: rgba(255, 255, 255, 0),
    //   }
  });


  //-----Designation Modal close button toggle-----//

  return (
<Paper className={styles.paper}>
      <Dialog
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
        className={styles.root}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        {/* <DialogTitle id="customized-dialog-title" onClose={props.toggle}></DialogTitle> */}

        <CircularIndeterminate />

      </Dialog>
      </Paper>
  );
};
export default NewUserLoadingModal;