import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AlertsTable from './AlertsTable';
import AlertsTableCollapsible from './AlertsTableCollapsible';
import EmptyAlertsTable from './EmptyAlertsTable';
import useFilterForm from '../CustomHooks/useFilterForm';
import useCheckPermissionsForAPICalls from '../CustomHooks/useCheckPermissionsForAPICalls';
import useChangePageTitle from '../CustomHooks/useChangePageTitle';
import './Alerts.css';
import basePCUrl from '../../../Globals';
import { fetchApiData } from '../../../utils/service';
import { useQuery } from 'react-query';

const baseV2Url = 'https://portalapi-v2.phishcloud.io/api/portalapi-v2';

const Alerts = (props) => {
  useChangePageTitle('Alerts');

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props. getTranslation

  const [alertData, setAlertData] = useState(props.alertData);
  const [alertAmount, setAlertAmount] = useState();
  const [isLoading, setIsLoading] = useState();

  const { getAccessTokenSilently } = useAuth0();

  const useStyles = makeStyles({
    header: {
      fontFamily: 'Montserrat, sans-serif',
      //fontWeight: 300
    },
  });

  const classes = useStyles();


  const pushToErrorPage = () => {
    props.history.push('/Dashboard/Error');
  };


  if (props.selectedClient === undefined || props.selectedClient === '') {
    var clientId = props.defaultClient;
  } else{
    var clientId = props.selectedClient;
  }



  const getAlertDetails = useQuery(
    ['getAlertDetails',],
    async () => {
      // if (localStorage.getItem('usid')) {
      //   let usid = localStorage.getItem('usid');
      //   return fetchApiDataWithId(
      //     'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
      //     usid,
      //     'get_portal_key',
      //     accessLevel,
      //     selectedClient.clientId
      //   );
      // } else if (!localStorage.getItem('usid')) {
        const token = await getAccessTokenSilently();
        return fetchApiData(
          'https://portalapi-v2.phishcloud.io/api/portalapi-v2',
          token,
          'getAlertDetails',
          props.accessLevel,
          clientId
        );
      //}
    },
   // { enabled: false }
  );

  
  // const refreshCompanyAlerts = async () => {
  //   setIsLoading(true);
  //   const token = await getAccessTokenSilently();

  //       var fetchDetails = {
  //         //credentials: 'same-origin',
  //         method: 'get',
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //           Action: 'getAlertDetails',
  //           id: clientId,
  //         },
  //       };

  //     fetch(baseV2Url, fetchDetails)
  //       .then((response) => {
  //         if (response.status !== 200) {
  //           pushToErrorPage();
  //           console.log('Looks like there was a problem. Status Code: ' + response.status);
  //           //setError(true);
  //           // console.log(error);
  //           return;
  //         }

  //         response.json().then((data) => {
  //           if (data.status === 'Error') {
  //             console.log('Alert Fetch Error');
  //           } else {
  //             setAlertData(data);
  //             setIsLoading(false);
  //             //setAlertAmount(data.portalAlerts.length);
  //             //console.log(data);
  //           }
  //         });
  //       })
  //       .catch(function (err) {
  //         console.log('Fetch Error :-S', err);
  //       });
  // };

  useEffect(() => {
    //getCompanyAlerts();

    //refreshCompanyAlerts();
    getAlertDetails.refetch()
    setAlertData(getAlertDetails?.data)
  }, [clientId]);

  //console.log(alertData)

  return (
    <div>
      <Typography className={classes.header} variant="h4" component="h3" gutterBottom>
      {getTranslation("Alerts", preferredLanguage)}
      </Typography>

      <div>
        {getAlertDetails.isLoading ? (
          <LoadingSpinner className="LinkTableSpinner" box="LinkTableSpinnerBox" />
        ) : !getAlertDetails.isLoading && getAlertDetails.data && getAlertDetails.data.length >= 1 ? (
          <AlertsTableCollapsible
            history={props.history}
            //requestType={requestType}
            //callRoute={callRoute}
            clientId={clientId}
            accessLevel={props.accessLevel}
            refreshAlerts={() => getAlertDetails.refetch()}
            isLoading={getAlertDetails.isLoading}
            alertData={getAlertDetails.data}
            getTranslation={getTranslation}
            preferredLanguage={preferredLanguage}
          />
        ) : !getAlertDetails.isLoading && getAlertDetails.data?.length === 0 ? (
          <EmptyAlertsTable history={props.history} refreshAlerts={() => getAlertDetails.refetch()} />
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};
export default Alerts;

