import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from 'reactstrap';
import NavLink from 'reactstrap/lib/NavLink';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './Layout.css';
import NavBar from '../NavBar/NavBar';

const Layout = (props) => {
  const { isLoading, isAuthenticated } = useAuth0();

  return (
    <React.Fragment>
      <NavBar
      isLoading={props.isLoading}
      history={props.history}
      location={props.location}
        userSettings={props.userSettings}
        translationData={props.translationData}
        getTranslation={props.getTranslation}
        setSelectedClient={props.setSelectedClient}
        selectedClient={props.selectedClient}
        accountAdminData={props.accountAdminData}
        defaultClient={props.defaultClient}
        accessLevel={props.accessLevel}
        alertBadgeNumber={props.alertBadgeNumber}
        alertData={props.alertData}
        alertAmount={props.alertAmount}
        preferredLanguage={props.preferredLanguage}
        setPreferredLanguage={props.setPreferredLanguage}
        userConfigurationForNavbar={props.userConfigurationForNavbar}
        toggleDarkMode={props.toggleDarkMode}
        darkMode={props.darkMode}
        isNotLoading={props.isNotLoading}
      />
      <div style={{ position: 'relative', display: 'block' }}>{props.children}</div>
      {!isAuthenticated & !isLoading && (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              //margin: '-50px 0 0 -50px',
              padding: '20px',
            }}
          >
            {props.history.push('/PortalSignIn')}
            {/* <NavLink tag={Link} className="text-dark" to="/PortalSignIn">
              <Button color="primary" variant="contained" style={{ width: '300px', height: '50px' }}>
                Log in
              </Button>
            </NavLink> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Layout;
