import React, { Component } from 'react';
import Moment from 'react-moment';

export class DateCreate extends Component {
  render() {
    const date = this.props.date;

    if (this.props.date === '0001-01-01T00:00:00' || this.props.date === '' || this.props.date === null) {
      return <p> Unknown </p>;
    } else {
      return <Moment format="MM/DD">{date}</Moment>;
    }
  }
}

/*Formats for dates
 * <Moment>{date}</Moment> will show (example) Thu Nov 14 2019 15:26:24 GMT-0800
 * <Moment format ="L">{date}</Moment> will show as listed below
 * L will show Month/Day/Year (example) 11/14/2019
 * LL wil show full month name with date and year (example) November 14, 2019
 * LLL adds time to LL (example) November 14, 2019 3:42 PM
 * LLLL adds day name (example) Thursday, November 14, 2019 3:43 PM
 * chaning to lowercase L abbreviates all of the above (example) Thu, Nov 14, 2019 3:43 PM
 /*/
