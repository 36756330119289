import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaExclamation } from 'react-icons/fa';
import PropTypes from 'prop-types';

export class CheckStatus extends Component {
  render() {
    const checkStatus = () => {
      if (this.props.value === 'unknown') {
        return (
          <FontAwesomeIcon
            style={{ paddingRight: '5px', fontSize: '25px', color: '#F1C411' }}
            icon={['fas', 'exclamation-triangle']}
          />
        );
      } else if (this.props.value === 'safe') {
        return (
          <FontAwesomeIcon
            style={{ paddingRight: '5px', fontSize: '25px', color: '#2CCC72' }}
            icon={['fas', 'check']}
          />
        );
      } else if (this.props.value === 'unsafe') {
        return <FaExclamation style={{ paddingRight: '5px', fontSize: '25px', color: '#EE311E' }} />;
      }
    };

    return <span>{checkStatus(this.props.value)}</span>;
  }
}
