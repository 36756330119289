import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
//-----Material Ui imports-----//
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useStickyState from '../../CustomHooks/useStickyState';
import { DateCreate } from '../../DateCreate/DateCreate';
import { CheckStatus } from './CheckStatus';
import './LinkHistoryTable.css';


const LinkHistoryTable = (props) => {
  const [safety, setSafety] = useStickyState('unknown', 'safety');

  const [rows, setRows] = useState([
    { lastLink: 'No ' + safety + ' links in your history yet!', safety: '', cl: '', timeSeen: '', linkDetails: '' },
  ]);


  const accountForNullData = [
    { lastLink: 'No ' + safety + ' links in your history yet!', safety: '', cl: '', timeSeen: '', linkDetails: '' },
  ];

  const preferredLanguage = props.preferredLanguage
  const getTranslation = props.getTranslation

  //-----------------Logic/Render-----------------//

  //------Dropdown Select Logic----//

  useEffect(() => {
    if (props.tableData != undefined && props.tableData != null) {
      if (safety === 'safe' && props.tableData.safe != undefined && props.tableData.safe.length != 0) {
        setRows(props.tableData.safe);
      } else if (
        (safety === 'safe' && props.tableData.safe === undefined) ||
        (safety === 'safe' && props.tableData.safe.length === 0)
      ) {
        setRows(accountForNullData);
      } else if (safety === 'unknown' && props.tableData.unknown != undefined && props.tableData.unknown.length != 0) {
        setRows(props.tableData.unknown);
      } else if (
        (safety === 'unknown' && props.tableData.unknown === undefined) ||
        (safety === 'unknown' && props.tableData.unknown.length === 0)
      ) {
        setRows(accountForNullData);
      } else if (safety === 'unsafe' && props.tableData.unsafe != undefined && props.tableData.unsafe.length != 0) {
        setRows(props.tableData.unsafe);
      } else if (
        (safety === 'unsafe' && props.tableData.unsafe === undefined) ||
        (safety === 'unsafe' && props.tableData.unsafe.length === 0)
      ) {
        setRows(accountForNullData);
      }
    } else if (props.tableData === undefined) {
      setRows(accountForNullData);
    }
  }, [safety]);

  //const rows = sortedBySafetyTableData

  // useEffect(() => {
  //   //if (props.tableData === undefined) {
  //   //    setSortedBySafetyTableData(accountForNullData)
  //   //} else if (props.tableData != undefined) {
  //   //    setSortedBySafetyTableData(props.tableData.unknown)
  //   //}
  // }, []);

  //--Check Link Length and style accordingly--//
  const accountForLinkLength = (linkForCheck) => {
    if (linkForCheck.length >= 60) {
      return <span style={{ fontSize: '12px' }}>{linkForCheck.slice(0, 50)}...</span>;
    } else {
      return <span style={{ fontSize: '14px', wordWrap: 'break-word' }}>{linkForCheck}</span>;
    }
  };

  //-----Push Link Details to correct view (User/Admin)-----//
  const linkDetailPush = (linkDetails, linkSafety) => {
    if (props.accessLevel === 'CompanyAdmin' || props.accessLevel === 'AccountAdmin') {
      props.history.push({
        pathname: '/Dashboard/AnalyzeLinkAdmin',
        search: '?pad=&link=' + encodeURIComponent(linkDetails),
        state: { safety: linkSafety },
      });
    } else if (props.accessLevel === 'User') {
      props.history.push({
        pathname: '/Dashboard/AnalyzeLink',
        search: '?pad=&link=' + encodeURIComponent(linkDetails),
        state: { safety: linkSafety },
      });
    }
  };

  //----------Table Title Toolbar-------//
  const TitleToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle">
        {getTranslation("link history", preferredLanguage)}
        </Typography>

        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="safety select"
            value={safety}
            onChange={(e) => setSafety(e.target.value)}
            style={{ marginLeft: '0px', marginRight: '35px', width: '150px' }}
          >
            <MenuItem id="defaultTableSelect" disabled value="">
            {getTranslation("safety status", preferredLanguage)}
            </MenuItem>
            <MenuItem id="safeTableSelect" value="safe">
            {getTranslation("safe", preferredLanguage)}
            </MenuItem>
            <MenuItem id="unknownTableSelect" value="unknown">
            {getTranslation("unknown", preferredLanguage)}
            </MenuItem>
            <MenuItem id="unsafeTableSelect" value="unsafe">
            {getTranslation("unsafe", preferredLanguage)}
            </MenuItem>
          </Select>
        </FormControl>
      </Toolbar>
    );
  };

  //------------------------Table Logic------------------------//

  ////------Normal Sorting Logic------////
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const headCells = [
    { id: 'lastLink', numeric: false, disablePadding: false, label: getTranslation('Link', preferredLanguage) },
    { id: 'safety', numeric: false, disablePadding: false, label: getTranslation('Status/Safety', preferredLanguage) },
    { id: 'cl', numeric: false, disablePadding: false, label: getTranslation('Confidence Level', preferredLanguage) },
    { id: 'timeSeen', numeric: false, disablePadding: false, label: getTranslation('Date Seen', preferredLanguage) },
    { id: 'linkDetails', numeric: false, disablePadding: false, label: '' },
  ];

  const useHeaderStyles = makeStyles((theme) => ({
    tableHeader: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
    },
  }));

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headerClasses = useHeaderStyles();

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={headerClasses.tableHeader}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    //numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    //onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      flex: '1 1 100%',
    },
  }));

  const useStyles = makeStyles({
    paper: {
      marginBottom: '10px',
    },
    root: {
      width: '98%',
      fontFamily: 'Montserrat, sans-serif',
    },
    container: {
      height: 'auto',

      maxHeight: '300px',
      minHeight: '400',
      width: '100%',
      maxWidth: '700',
      minWidth: '400',

      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
    table: {
      maxHeight: '40vh',
      minHeight: '20vh',
      width: '100%',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: '300',
    },
    tableCell: {
      fontFamily: 'Montserrat, sans-serif',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  });

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('lastUpdated');

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useStickyState(25, 'rowsPerPage');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className="table-rectangle">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TitleToolbar />
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
              //style={{ tableLayout: 'auto' }}
            >
              <EnhancedTableHead
                classes={classes}
                //numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={false}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              {props.tableData === undefined && rows === undefined ? (
                <span></span>
              ) : (
                <TableBody>
                  {stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          //onClick={event => handleClick(event, row.dateCreated)}
                          role="checkbox"
                          //aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          //selected={isItemSelected}
                        >
                          <TableCell align="left" className={classes.tableCell}>
                            {' '}
                            {accountForLinkLength(row.lastLink)}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            <span>
                              <CheckStatus className="tableIcon" value={safety} />                
                              {getTranslation(safety, preferredLanguage)}{' '}
                            </span>
                          </TableCell>
                          <TableCell className={classes.tableCell}>{row.cl}</TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {' '}
                            <DateCreate date={row.timeSeen} />
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            <span className="linkDetails" onClick={() => linkDetailPush(row.lastLink, safety)}>
                            {getTranslation("link details", preferredLanguage)}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, rows.length]}
            labelRowsPerPage={getTranslation("rows per page", preferredLanguage)}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
            className={classes.tableCell}
          />
        </Paper>
      </div>
    </div>
  );
};
export default LinkHistoryTable;
